import React from "react";

const ViewImageByUrl = (props) => {
  let { imageUrl } = props;
  if (imageUrl?.indexOf("https") !== 0 && imageUrl?.indexOf("http") !== 0) {
    imageUrl = `${process.env.REACT_APP_MAIN_BACKEND_HOST}${imageUrl}`;
  }
  return (
    <div className="tooltip_new">
      {imageUrl?.slice(0, 25)}...
      <span className="tooltiptext_new">
        <a href={imageUrl} target="_blank" rel="noreferrer">
          {imageUrl}
        </a>
        <br />
        <img
          style={{ height: 50, marginTop: 10 }}
          alt="techsimplus"
          src={`${imageUrl}`}
        />
      </span>
    </div>
  );
};

export default ViewImageByUrl;
