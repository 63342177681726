import React, { useState, useEffect } from "react";
import { Button, Card, Row, Collapse } from "reactstrap";
import {
  Colxx,
  Separator,
} from "../../../../../../components/common/CustomBootstrap";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import AlertMessage from "../../../../../../helpers/alerts";
import {
  updateKeyPointAPI,
  deleteKeyPointAPI,
  createNewKeyPointAPI,
} from "../../../../../../services/master";
import parse from "html-react-parser";
import {
  errorHandler,
  getOneCourseDataAction,
} from "../../../../../../redux/master/actions";
import DeleteConfirmationPopUp from "../../../../../../components/common/deleteConfirmationPopup";
import { iconOptions } from "../../../../../../constants/iconsOptions";

const CourseKeyPoints = (props) => {
  const currentCourse = useSelector((state) => state.master.oneCourse);
  const dispatch = useDispatch();

  const [currentFieldChar, setCurrentFieldChar] = useState("");
  const [isKeyDeleting, setIsKeyDeleting] = useState(false);
  const [currentKey, setCurrentKey] = useState(null);
  let defaultValues = {
    icon: "",
    name: "",
  };
  const [values, setValues] = useState(defaultValues);

  const deleteCourseKeyPoint = (_id) => {
    deleteKeyPointAPI(_id).then(
      (res) => {
        AlertMessage("Course Key Point Deleted Successfully", "success");
        setValues(defaultValues);
        dispatch(getOneCourseDataAction(currentCourse.id));
      },
      (error) => {
        AlertMessage("Internal Server Error", "error");
      }
    );
  };

  const onDeleteAction = () => {
    console.log(currentKey.id);
    deleteCourseKeyPoint(currentKey.id);
    setCurrentKey(null);
    setIsKeyDeleting(false);
  };

  const onSubmitHandler = async () => {
    let newValues = {
      ...values,
      course: currentCourse.id,
      icon: values?.icon?.value ? values?.icon?.value : "",
    };

    if (newValues.name === "" || !newValues.name) {
      AlertMessage("Key Point Name Required");
      return;
    }
    if (currentKey) {
      updateKeyPointAPI(newValues, { _id: currentKey.id }).then(
        (res) => {
          setCurrentKey(null);
          setValues(defaultValues);
          dispatch(getOneCourseDataAction(currentCourse.id));
        },
        (error) => {
          dispatch(errorHandler(error));
        }
      );
    } else {
      createNewKeyPointAPI(newValues).then(
        (res) => {
          dispatch(getOneCourseDataAction(currentCourse.id));
          setCurrentKey(null);
          setValues(defaultValues);
        },
        (error) => {
          dispatch(errorHandler(error));
        }
      );
    }
  };

  useEffect(() => {
    if (currentKey) {
      setValues({
        icon: iconOptions.find((x) => x.value === currentKey.icon),
        name: currentKey.name,
      });
    }
  }, [currentKey]);

  props = {
    ...props,
    isDeleting: isKeyDeleting,
    setIsDeleting: setIsKeyDeleting,
    currentData: currentKey,
    setCurrentData: setCurrentKey,
    onDeleteClick: onDeleteAction,
  };

  return (
    <div className="card-body accordion-content pt-0">
      <DeleteConfirmationPopUp {...props} />
      <Row className="">
        <Colxx lg="12" xl="7">
          {currentCourse?.course_keypoint?.map((key, index) => {
            console.log(`../../../../../../assets/img${key.icon}`);
            return (
              <React.Suspense>
                <Card className="mb-2  br-20px p-2 pl-4">
                  <div className="d-flex justify-content-spacebetween">
                    <div>
                      {key.icon ? (
                        <img
                          className="w-20px h-20px mr-20px"
                          src={
                            require(`../../../../../../assets/img${key.icon}`)
                              .default
                          }
                        />
                      ) : (
                        <img
                          className="w-20px h-20px mr-20px"
                          src={
                            require(`../../../../../../assets/img/icons/solid/circle.svg`)
                              .default
                          }
                        />
                      )}
                      <>{parse(key.name)}</>
                    </div>
                    <div>
                      <i
                        onClick={() => setCurrentKey(key)}
                        className="simple-icon-pencil c_ed7117 ml-4px cursor-pointer"
                      />
                      <i
                        onClick={() => {
                          setIsKeyDeleting(true);
                          setCurrentKey(key);
                        }}
                        className="simple-icon-trash c_B00020 ml-4px cursor-pointer"
                      />
                    </div>
                  </div>
                </Card>
              </React.Suspense>
            );
          })}
        </Colxx>

        <Colxx lg="12" xl="4">
          <label className="input-label fw-600 fs-14px">Icon</label>
          <Select
            options={iconOptions}
            isClearable
            className="react-select"
            value={values.icon}
            menuIsOpen={currentFieldChar.length > 2}
            onInputChange={(data, { action }) => {
              if (action !== "set-value") {
                setCurrentFieldChar(data);
              }
            }}
            onChange={(e) => setValues({ ...values, icon: e })}
          />
          <label className="input-label fw-600 fs-14px">Key Point</label>
          <textarea
            type={"text"}
            className="form-control br-10px"
            placeholder=""
            value={values.name}
            onChange={(data) =>
              setValues({
                ...values,
                name: data.target.value,
              })
            }
          />
        </Colxx>

        <div className="d-flex justify-content-flex-end w-100percent">
          <Button
            size={"sm"}
            color="secondry"
            onClick={onSubmitHandler}
            className="br-20px c_white shadow-box create-new-button mt-30px"
          >
            {!currentKey && "Create"}
            {currentKey && "Update"}
          </Button>
        </div>
      </Row>
    </div>
  );
};

export default CourseKeyPoints;
