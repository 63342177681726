import { NotificationManager } from "../components/common/react-notifications";

const AlertMessage = (msg, type = "info", subTitle = "") => {
  if (type === "info") {
    NotificationManager.info(
      subTitle,
      msg,
      3000,
      null,
      null,
      "filled  rounded"
    );
  } else if (type === "success") {
    NotificationManager.success(
      subTitle,
      msg,
      3000,
      null,
      null,
      "filled  rounded"
    );
  } else if (type === "warning") {
    NotificationManager.warning(
      subTitle,
      msg,
      3000,
      null,
      null,
      "filled  rounded"
    );
  } else if (type === "error") {
    NotificationManager.error(
      subTitle,
      msg,
      3000,
      null,
      null,
      "filled  rounded"
    );
  } else if (type === "primary") {
    NotificationManager.primary(
      subTitle,
      msg,
      3000,
      null,
      null,
      "filled  rounded"
    );
  } else if (type === "secondary") {
    NotificationManager.secondary(
      subTitle,
      msg,
      3000,
      null,
      null,
      "filled  rounded"
    );
  }
};

export default AlertMessage;
