import React, { useState, useEffect } from "react";
import { Button, Card, Row } from "reactstrap";
import filterIcon from "../../../../assets/img/icons/solid/filter.svg";
import {
  Colxx,
  Separator,
} from "../../../../components/common/CustomBootstrap";
import { MDBDataTable } from "mdbreact";
import { useSelector, useDispatch } from "react-redux";
import Switch from "rc-switch";
import Select from "react-select";
import {
  deleteBlogDataAction,
  errorHandler,
  getAllBlogsDataAction,
} from "../../../../redux/master/actions";
import DeleteConfirmationPopUp from "../../../../components/common/deleteConfirmationPopup";
import { updateBlogsAPI } from "../../../../services/master";

import StudentFilterSection from "./components/blogFilter";
import { selectCustomStyles } from "../../../../helpers/Utils";
import { blogReviewStatusOptions } from "../../../../constants/selectOptions";
import ViewImageByUrl from "../../../../components/common/viewImageByUrl";
import { blogEditUrl, blogViewUrl } from "../../../../constants/defaultValues";
const queryString = require("query-string");

const BlogsData = (props) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [toggleFilter, setToggleFilter] = useState(false);
  const [currentData, setCurrentData] = useState(null);

  const dispatch = useDispatch();
  const fetchedData = useSelector((state) => state.master.allBlogs.blogs);

  const [datatable, setDatatable] = React.useState({
    columns: [
      {
        label: "#",
        field: "sn",
        width: 50,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "id",
        },
        sort: "disabled",
      },

      {
        label: "Title",
        field: "title",
        width: 250,
      },
      {
        label: "Topic",
        field: "topic",
        width: 160,
      },

      {
        label: "Written By",
        field: "user",
        width: 200,
      },

      {
        label: "Blog Url",
        field: "url",
        width: 200,
      },
      {
        label: "Is Active",
        field: "is_active",
        width: 140,
      },
      {
        label: "Review Status",
        field: "review_status",
        width: 240,
      },

      {
        label: "SEO: OG Title",
        field: "og_title",
        width: 250,
      },

      {
        label: "SEO: OG Image",
        field: "og_image",
        width: 200,
        sort: "disabled",
      },
      {
        label: "SEO: Description",
        field: "seo_description",
        width: 250,
        sort: "disabled",
      },
      {
        label: "Total Likes",
        field: "total_likes",
        width: 150,
      },
      {
        label: "Read Time",
        field: "read_time",
        width: 150,
      },

      {
        label: "Date",
        field: "date",
        width: 200,
      },

      {
        label: "Action",
        field: "action",
        width: 100,
        sort: "disabled",
      },
    ],
    rows: [],
  });

  useEffect(() => {
    let allRows = [];
    fetchedData?.map((data, index) => {
      let oneRow = {
        sn: index + 1,
        title: data.title,
        url: data.url,
        total_likes: data.total_likes,
        read_time: `${data.read_time} Min.`,
        topic: data.topic?.name,
        og_title: data.seo_details?.og_title,
        og_image: data.seo_details?.og_image ? (
          <ViewImageByUrl imageUrl={`${data.seo_details?.og_image}`} />
        ) : (
          ""
        ),
        seo_description: (
          <div className="tooltip_new">
            {data.seo_details?.seo_description?.slice(0, 25)}...
            <span className="tooltiptext_new">
              {data.seo_details?.seo_description}
            </span>
          </div>
        ),
        user: data.user?.first_name + " " + data.user?.last_name,
        is_active: (
          <Switch
            className="custom-switch custom-switch-3-inverse custom-switch-small"
            checked={data.is_active}
            onChange={(status) => onUserStatusChange(data, "is_active", status)}
          />
        ),
        review_status: (
          <div className="mt-n4px">
            <Select
              styles={selectCustomStyles}
              options={blogReviewStatusOptions}
              value={blogReviewStatusOptions.find(
                (x) => x.value === data.review_status
              )}
              onChange={(d) => {
                onUserStatusChange(data, "review_status", d.value);
              }}
            />
          </div>
        ),
        date: new Date(data.date).toLocaleString("en-IN", {
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "numeric",
        }),

        action: (
          <span className="d-flex align-items-center">
            <a
              rel="noreferrer"
              target="_blank"
              href={
                data.is_active
                  ? `${blogViewUrl}/${data.url}`
                  : `${blogEditUrl}/${data.url}`
              }
            >
              <i className="simple-icon-eye cursor-pointer c_ed7117 mr-10px" />
            </a>
            <i
              onClick={() => {
                setIsDeleting(true);
                setCurrentData(data);
              }}
              className="simple-icon-trash cursor-pointer c_ed7117 mr-4px"
            />
          </span>
        ),
      };
      allRows.push(oneRow);
    });

    setDatatable({ ...datatable, rows: allRows });
  }, [fetchedData]);

  useEffect(() => {
    dispatch(getAllBlogsDataAction());
  }, []);

  const onDeleteClick = (_id) => {
    dispatch(deleteBlogDataAction(_id));
    setCurrentData(null);
    setIsDeleting(false);
  };

  const onUserStatusChange = (data, field, value) => {
    updateBlogsAPI(
      { title: data.title, [field]: value },
      { _id: data.id }
    ).then(
      (res) => {
        dispatch(getAllBlogsDataAction());
      },
      (error) => {
        dispatch(errorHandler(error));
      }
    );
  };

  props = {
    ...props,
    isDeleting,
    setIsDeleting,
    currentData,
    setCurrentData,
    onDeleteClick,
    isEditing,
    setIsEditing,
  };

  return (
    <React.Fragment>
      {isDeleting && <DeleteConfirmationPopUp {...props} />}
      <Row>
        <Colxx xxs="12">
          <div className="table-header-container">
            <span className="table-header-title">
              List of Blogs
              <p className="fs-14px c_black-60p">
                Here we have access of all the blogs written by any of the
                auther.
              </p>
            </span>
          </div>
          <Separator className={toggleFilter ? `mb-2` : "mb-5"} />
        </Colxx>
      </Row>

      <Row>
        <Colxx lg="12" xl="12">
          {toggleFilter && <StudentFilterSection />}
          <Card className="d-flex mb-3 br-20px" key={`faqItem_1`}>
            <div className="position-absolute w-100percent d-flex justify-content-flex-end mt-20px pr-30px">
              <img
                src={filterIcon}
                alt="filter-icon"
                onClick={() => {
                  setToggleFilter(!toggleFilter);
                }}
                className="w-20px filter-icon"
              />
            </div>
            <div className="d-flex flex-grow-1 min-width-zero">
              <div
                className="card-body accordion-content pt-0"
                style={{ maxWidth: "100%" }}
              >
                <MDBDataTable
                  fixed
                  hover
                  entriesOptions={[25, 50, 100, 500, 1000]}
                  entries={25}
                  pagesAmount={4}
                  data={datatable}
                  materialSearch
                  // searchTop
                  fullPagination
                  striped
                  theadColor="dark"
                  searching={false}
                  pagingTop
                  scrollX={true}
                  // scrollY
                  maxHeight={1000}
                  searchBottom={false}
                  // barReverse={true}
                />
              </div>
            </div>
          </Card>
        </Colxx>
      </Row>
    </React.Fragment>
  );
};

export default BlogsData;
