import React, { useState, useEffect } from "react";
import { Button, Card, Row, Collapse } from "reactstrap";
import {
  Colxx,
  Separator,
} from "../../../../../../components/common/CustomBootstrap";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { courseLabelOptions } from "../../../../../../constants/selectOptions";
import AlertMessage from "../../../../../../helpers/alerts";
import {
  addNewCourseAPI,
  addNewCourseCriteriaAPI,
  deleteCourseCriteriaAPI,
  updateCourseCriteriaAPI,
  updateCourseDetailsAPI,
} from "../../../../../../services/master";
import { getOneCourseDataAction } from "../../../../../../redux/master/actions";

const CourseSEODetails = (props) => {
  const { isAdding, isEditing, setIsAdding, setIsEditing } = props;
  const currentCourse = useSelector((state) => state.master.oneCourse);

  let defaultValues = {
    seo_description: "",
    seo_keywords: "",
    og_image: "",
    og_title: "",
    og_description: "",
    html_title: "",
  };
  const [values, setValues] = useState(defaultValues);

  const onSubmitHandler = async () => {
    if (currentCourse) {
      updateCourseDetailsAPI(values, { _id: currentCourse.id }).then(
        (res) => {
          AlertMessage("Course SEO Details Updated Successfully", "success");
        },
        (error) => {
          AlertMessage("Internal Server Error", "error");
        }
      );
    }
  };

  useEffect(() => {
    if (currentCourse) {
      setValues({
        seo_description: currentCourse.seo_description
          ? currentCourse.seo_description
          : "",
        seo_keywords: currentCourse.seo_keywords
          ? currentCourse.seo_keywords
          : "",
        og_image: currentCourse.og_image ? currentCourse.og_image : "",
        og_title: currentCourse.og_title ? currentCourse.og_title : "",
        og_description: currentCourse.og_description
          ? currentCourse.og_description
          : "",
      });
    }
  }, [currentCourse]);

  return (
    <div className="card-body accordion-content pt-0">
      <Row className="">
        <Colxx lg="12" xl="6">
          <label className="input-label fw-600 fs-14px">SEO Description</label>
          <textarea
            type={"text"}
            className="form-control br-10px"
            placeholder="ex. TechSim+"
            value={values.seo_description}
            onChange={(data) =>
              setValues({
                ...values,
                seo_description: data.target.value,
              })
            }
          />
        </Colxx>

        <Colxx lg="12" xl="6">
          <label className="input-label fw-600 fs-14px">SEO Keywords</label>
          <textarea
            type={"text"}
            className="form-control br-10px"
            placeholder=""
            value={values.seo_keywords}
            onChange={(data) =>
              setValues({
                ...values,
                seo_keywords: data.target.value,
              })
            }
          />
        </Colxx>

        <Colxx lg="12" xl="6" className="mt-2">
          <label className="input-label fw-600 fs-14px">
            OG (Sharing) Image
          </label>
          <input
            type={"text"}
            className="form-control br-10px"
            placeholder=""
            value={values.og_image}
            onChange={(data) =>
              setValues({
                ...values,
                og_image: data.target.value,
              })
            }
          />
        </Colxx>

        <Colxx lg="12" xl="6" className="mt-2">
          <label className="input-label fw-600 fs-14px">
            OG (Sharing) Title
          </label>
          <input
            type={"text"}
            className="form-control br-10px"
            placeholder=""
            value={values.og_title}
            onChange={(data) =>
              setValues({
                ...values,
                og_title: data.target.value,
              })
            }
          />
        </Colxx>

        <Colxx lg="12" xl="12" className="mt-2">
          <label className="input-label fw-600 fs-14px">
            OG (Sharing) Description
          </label>
          <input
            type={"text"}
            className="form-control br-10px"
            placeholder=""
            value={values.og_description}
            onChange={(data) =>
              setValues({
                ...values,
                og_description: data.target.value,
              })
            }
          />
        </Colxx>

        <div className="d-flex justify-content-flex-end w-100percent">
          <Button
            size={"sm"}
            color="secondry"
            onClick={onSubmitHandler}
            className="br-20px c_white shadow-box create-new-button mt-30px"
          >
            {isAdding && "Create"}
            {isEditing && "Update"}
          </Button>
        </div>
      </Row>
    </div>
  );
};

export default CourseSEODetails;
