import { fileUploadRequestAPI } from "../services/master";
const uploadAllFiles = (files, category, fileName) => {
  const fileRequests = new WeakMap();
  const defaultOptions = {
    url: `${process.env.REACT_APP_API_URL}/master/final-file-upload`,
    startingByte: 0,
    fileId: "",
  };

  var allFilePaths = [];

  const uploadFileChunks = (file, options) => {
    const formData = new FormData();
    const req = new XMLHttpRequest();
    const chunk = file.slice(options.startingByte);

    formData.append("chunk", chunk, file.name);
    formData.append("fileId", options.fileId);
    req.open("POST", options.url, true);
    req.setRequestHeader(
      "Content-Range",
      `bytes=${options.startingByte}-${options.startingByte + chunk.size}/${
        file.size
      }`
    );

    req.setRequestHeader("X-File-Id", options.fileId);
    req.setRequestHeader("category", category ? category : "Other");
    req.setRequestHeader("Dynamic-File", fileName ? fileName : file.name);
    req.setRequestHeader(
      "Authorization",
      "jwt " + localStorage.getItem("authToken")
    );

    fileRequests.get(file).request = req;
    req.send(formData);

    return new Promise((resolve, reject) => {
      req.onloadend = (e) => {
        resolve(JSON.parse(e.currentTarget.response));
      };
    });
  };

  const uploadFile = async (file, options) => {
    try {
      let fileUploadRequest = await fileUploadRequestAPI({
        fileName: file.name,
        category: category ? category : "Other",
      });
      options = { ...options, fileId: fileUploadRequest.data.fileId };
      fileRequests.set(file, { request: null, options });

      return new Promise((res, rej) => {
        uploadFileChunks(file, options).then((path) => {
          res(path);
        });
      });
    } catch (error) {
      return new Promise((res, rej) => {
        rej(error);
      });
    }
  };

  return new Promise((res, rej) => {
    [...files].forEach((file) => {
      uploadFile(file, { ...defaultOptions }).then(
        (path) => {
          allFilePaths.push(path.data.file_url);
          if (allFilePaths.length.toString() === files.length.toString()) {
            res(allFilePaths);
          }
        },
        (error) => {
          rej(error);
        }
      );
    });
  });
};

export default uploadAllFiles;
