import React, { useState, useEffect } from "react";
import { Button, Card, Row, Collapse } from "reactstrap";
import {
  Colxx,
  Separator,
} from "../../../../../../components/common/CustomBootstrap";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { courseLabelOptions } from "../../../../../../constants/selectOptions";
import AlertMessage from "../../../../../../helpers/alerts";
import {
  addNewCourseAPI,
  updateCourseDetailsAPI,
} from "../../../../../../services/master";

const CourseDifferentAssignment = (props) => {
  const { isAdding, isEditing } = props;
  const currentCourse = useSelector((state) => state.master.oneCourse);
  const [selectOptions, setSelectOptions] = useState({
    courseCategories: null,
    faqs: null,
    jobTags: null,
    teams: null,
    technologies: null,
  });

  let defaultValues = {
    categories: [],
    trainers: [],
    faq: [],
    technologies: [],
    job_tags: [],
  };
  const [values, setValues] = useState(defaultValues);

  const onSubmitHandler = async () => {
    let newValues = {};
    Object.keys(values)?.map((key, index) => {
      newValues[key] = [];
      values[key]?.map((field) => {
        newValues[key].push(field.value);
      });
    });

    updateCourseDetailsAPI(newValues, { _id: currentCourse?.id }).then(
      (res) => {
        AlertMessage("Assignment is done.", "success");
      },
      (err) => {
        if (err.response.data?.error)
          AlertMessage(err.response.data?.error?.msg);
        else AlertMessage("Internal Server Error");
      }
    );
  };

  useEffect(() => {
    if (currentCourse) {
      let oneObj = { ...defaultValues };

      currentCourse?.categories?.map((category, index) => {
        oneObj.categories.push({ label: category.name, value: category.id });
      });

      currentCourse?.trainers?.map((trainer, index) => {
        oneObj.trainers.push({ label: trainer.name, value: trainer.id });
      });

      currentCourse?.technologies?.map((technology, index) => {
        oneObj.technologies.push({
          label: technology.name,
          value: technology.id,
        });
      });

      currentCourse?.faq?.map((faq, index) => {
        oneObj.faq.push({
          label: faq.question,
          value: faq.id,
        });
      });

      currentCourse?.job_tags?.map((tag, index) => {
        oneObj.job_tags.push({
          label: tag.name,
          value: tag.id,
        });
      });

      setValues(oneObj);
    }
  }, [currentCourse]);

  const { courseCategories, faqs, jobTags, teams, technologies } = useSelector(
    (state) => state.master
  );

  useEffect(() => {
    let allOptions = {
      courseCategories: [],
      faqs: [],
      jobTags: [],
      teams: [],
      technologies: [],
    };
    courseCategories.map((x) =>
      allOptions.courseCategories.push({
        label: x.name,
        value: x.id,
      })
    );

    faqs.map((x) =>
      allOptions.faqs.push({
        label: x.question,
        value: x.id,
      })
    );

    jobTags.map((x) =>
      allOptions.jobTags.push({
        label: x.name,
        value: x.id,
      })
    );

    teams.map((x) =>
      allOptions.teams.push({
        label: `${x.category} - ${x.name}`,
        value: x.id,
      })
    );

    technologies.map((x) =>
      allOptions.technologies.push({
        label: x.name,
        value: x.id,
      })
    );

    setSelectOptions(allOptions);
  }, [courseCategories, faqs, jobTags, teams, technologies]);

  return (
    <div className="card-body accordion-content pt-0">
      <Row className="">
        <Colxx lg="12" xl="6">
          <label className="input-label fw-600 fs-14px">Course Category</label>
          <Select
            isClearable
            isMulti
            options={selectOptions.courseCategories}
            className="react-select"
            classNamePrefix="react-select"
            value={values.categories}
            onChange={(e) => setValues({ ...values, categories: e })}
          />
        </Colxx>

        <Colxx lg="12" xl="6">
          <label className="input-label fw-600 fs-14px">Trainers</label>
          <Select
            isClearable
            isMulti
            options={selectOptions.teams}
            className="react-select"
            classNamePrefix="react-select"
            value={values.trainers}
            onChange={(e) => setValues({ ...values, trainers: e })}
          />
        </Colxx>

        <Colxx lg="12" xl="12" className="mt-3">
          <label className="input-label fw-600 fs-14px">Faq</label>
          <Select
            isClearable
            isMulti
            options={selectOptions.faqs}
            className="react-select"
            classNamePrefix="react-select"
            value={values.faq}
            onChange={(e) => setValues({ ...values, faq: e })}
          />
        </Colxx>

        <Colxx lg="12" xl="4" className="mt-3">
          <label className="input-label fw-600 fs-14px">Technologies</label>
          <Select
            isClearable
            isMulti
            options={selectOptions.technologies}
            className="react-select"
            classNamePrefix="react-select"
            value={values.technologies}
            onChange={(e) => setValues({ ...values, technologies: e })}
          />
        </Colxx>

        <Colxx lg="12" xl="4" className="mt-3">
          <label className="input-label fw-600 fs-14px">Job Tags</label>
          <Select
            isClearable
            isMulti
            options={selectOptions.jobTags}
            className="react-select"
            classNamePrefix="react-select"
            value={values.job_tags}
            onChange={(e) => setValues({ ...values, job_tags: e })}
          />
        </Colxx>

        <div className="d-flex justify-content-flex-end w-100percent">
          <Button
            size={"sm"}
            color="secondry"
            onClick={onSubmitHandler}
            className="br-20px c_white shadow-box create-new-button mt-30px"
          >
            {isAdding && "Create"}
            {isEditing && "Update"}
          </Button>
        </div>
      </Row>
    </div>
  );
};

export default CourseDifferentAssignment;
