import React, { useState, useEffect } from "react";
import { Button, Card, Row } from "reactstrap";
import {
  Colxx,
  Separator,
} from "../../../../components/common/CustomBootstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllCourseDataAction,
  getCourseCategoriesDataAction,
  getFaQDataAction,
  getJobTagsDataAction,
  getOneCourseDataAction,
  getTeamMemberDataAction,
  getTechnologiesDataAction,
} from "../../../../redux/master/actions";
import AddNewCourse from "./components/addNew";
import CourseListSection from "./components/courseList";
const queryString = require("query-string");

const Courses = (props) => {
  const dispatch = useDispatch();
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  let { course_id, action } = queryString.parse(props.location.search);

  const currentCourse = useSelector((state) => state.master.oneCourse);
  useEffect(() => {
    dispatch(getCourseCategoriesDataAction());
    dispatch(getJobTagsDataAction());
    dispatch(getTeamMemberDataAction());
    dispatch(getFaQDataAction());
    dispatch(getTechnologiesDataAction());
  }, []);

  useEffect(() => {
    if (action === "add-new") {
      setIsAdding(true);
    } else if (action === "edit" && course_id) {
      setIsEditing(true);
      setIsAdding(false);
      dispatch(getOneCourseDataAction(course_id));
    } else {
      setIsAdding(false);
      setIsEditing(false);
      dispatch(getAllCourseDataAction());
    }
  }, [action]);

  props = {
    ...props,
    isAdding,
    isEditing,
    setIsAdding,
    setIsEditing,
  };

  return (
    <React.Fragment>
      <Row>
        <Colxx xxs="12">
          {!isAdding && !isEditing && (
            <div className="table-header-container">
              <span className="table-header-title">
                List of Courses
                <p className="fs-14px c_black-60p">
                  Courses, on which provide trainings. Here we have all type of
                  courses like STTP, FDP's, Common Courses, Mega Courses and
                  School courses.
                </p>
              </span>
              <div>
                <button
                  onClick={() => {
                    props.history.push(
                      `${props.history.location.pathname}?action=add-new`
                    );
                  }}
                  className="shadow-box create-new-button"
                >
                  Add New Course
                </button>
              </div>
            </div>
          )}

          {(isAdding || isEditing) && (
            <div className="table-header-container">
              <span className="table-header-title">
                {isAdding && "Add New Course"}
                {isEditing && "Update Course Details"}
                <p className="fs-14px c_black-60p">
                  Courses, on which provide trainings. Here we have all type of
                  courses like STTP, FDP's, Common Courses, Mega Courses and
                  School courses.
                </p>
              </span>
              <div>
                <button
                  onClick={() => {
                    setIsAdding(false);
                    setIsEditing(false);
                    dispatch(getOneCourseDataAction());
                    props.history.push(`${props.history.location.pathname}`);
                  }}
                  className="shadow-box create-new-button bg-black-8p c_black-100p"
                >
                  X
                </button>
              </div>
            </div>
          )}
          <Separator className="mb-5" />
        </Colxx>
      </Row>

      {isAdding && <AddNewCourse {...props} />}
      {currentCourse && isEditing && <AddNewCourse {...props} />}
      {!isAdding && !isEditing && <CourseListSection {...props} />}
    </React.Fragment>
  );
};

export default Courses;
