import React, { useState, useEffect } from "react";
import { Button, Card, Row, Collapse } from "reactstrap";
import {
  Colxx,
  Separator,
} from "../../../../../../components/common/CustomBootstrap";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { courseLabelOptions } from "../../../../../../constants/selectOptions";
import AlertMessage from "../../../../../../helpers/alerts";
import {
  addNewCourseAPI,
  addNewCourseCriteriaAPI,
  deleteCourseCriteriaAPI,
  updateCourseCriteriaAPI,
  updateCourseDetailsAPI,
} from "../../../../../../services/master";
import { getOneCourseDataAction } from "../../../../../../redux/master/actions";

const CourseCriteria = (props) => {
  const { isAdding, isEditing, setIsAdding, setIsEditing } = props;
  const currentCourse = useSelector((state) => state.master.oneCourse);

  let defaultValues = {
    qualification: "",
    age: "",
    communication_skills: "",
    programming_skills: "",
    prerequisite: "",
  };
  const [values, setValues] = useState(defaultValues);
  const [currentCriteria, setCurrentCriteria] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentCourse) {
      setValues({ ...values, course: currentCourse.id });
    }
  }, [currentCourse]);

  const onSubmitHandler = async () => {
    if (currentCriteria) {
      updateCourseCriteriaAPI(values, { _id: currentCriteria.id }).then(
        (res) => {
          AlertMessage("Course Criteria Updated Successfully", "success");
        },
        (error) => {
          AlertMessage("Internal Server Error", "error");
        }
      );
    } else {
      addNewCourseCriteriaAPI(values).then(
        (res) => {
          AlertMessage("Course Criteria Created Successfully.", "success");
        },
        (error) => {
          AlertMessage("Internal Server Error");
        }
      );
    }
  };

  const deleteCourseCriteria = () => {
    deleteCourseCriteriaAPI({ _id: currentCriteria.id }).then(
      (res) => {
        AlertMessage("Course Criteria Deleted Successfully", "success");
        setValues(defaultValues);
      },
      (error) => {
        AlertMessage("Internal Server Error", "error");
      }
    );
  };

  useEffect(() => {
    if (currentCourse?.course_criteria) {
      let currentCriteria = currentCourse.course_criteria;

      setValues({
        qualification: currentCriteria.qualification,
        age: currentCriteria.age,
        communication_skills: currentCriteria.communication_skills,
        programming_skills: currentCriteria.programming_skills,
        prerequisite: currentCriteria.prerequisite,
      });
      setCurrentCriteria(currentCriteria);
    }
  }, [currentCourse]);

  return (
    <div className="card-body accordion-content pt-0">
      <Row className="">
        <Colxx lg="12" xl="6">
          <label className="input-label fw-600 fs-14px">Qualification</label>
          <textarea
            type={"text"}
            className="form-control br-10px"
            placeholder="ex. TechSim+"
            value={values.qualification}
            onChange={(data) =>
              setValues({
                ...values,
                qualification: data.target.value,
              })
            }
          />
        </Colxx>

        <Colxx lg="12" xl="6">
          <label className="input-label fw-600 fs-14px">Age</label>
          <textarea
            type={"text"}
            className="form-control br-10px"
            placeholder=""
            value={values.age}
            onChange={(data) =>
              setValues({
                ...values,
                age: data.target.value,
              })
            }
          />
        </Colxx>

        <Colxx lg="12" xl="6">
          <label className="input-label fw-600 fs-14px">
            Communication Skills
          </label>
          <textarea
            type={"text"}
            className="form-control br-10px"
            placeholder=""
            value={values.communication_skills}
            onChange={(data) =>
              setValues({
                ...values,
                communication_skills: data.target.value,
              })
            }
          />
        </Colxx>

        <Colxx lg="12" xl="6">
          <label className="input-label fw-600 fs-14px">
            Programming Skills
          </label>
          <textarea
            type={"text"}
            className="form-control br-10px"
            placeholder=""
            value={values.programming_skills}
            onChange={(data) =>
              setValues({
                ...values,
                programming_skills: data.target.value,
              })
            }
          />
        </Colxx>

        <Colxx lg="12" xl="6">
          <label className="input-label fw-600 fs-14px">Prerequisite</label>
          <textarea
            type={"text"}
            className="form-control br-10px"
            placeholder=""
            value={values.prerequisite}
            onChange={(data) =>
              setValues({
                ...values,
                prerequisite: data.target.value,
              })
            }
          />
        </Colxx>

        <div className="d-flex justify-content-flex-end w-100percent">
          {currentCriteria && (
            <Button
              size={"sm"}
              color="dark"
              onClick={deleteCourseCriteria}
              className="br-20px c_white shadow-box create-new-button mt-30px"
            >
              Delete
            </Button>
          )}
          <Button
            size={"sm"}
            color="secondry"
            onClick={onSubmitHandler}
            className="br-20px c_white shadow-box create-new-button mt-30px"
          >
            {isAdding && "Create"}
            {isEditing && "Update"}
          </Button>
        </div>
      </Row>
    </div>
  );
};

export default CourseCriteria;
