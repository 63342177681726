import { GET_DASHBOARD_DATA, SET_API_ERROR } from "./actionType";

const INIT_STATE = {
  dashboardData: null,
  apiError: false,
  apiErrorMessage: "",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DASHBOARD_DATA:
      return { ...state, dashboardData: action.payload };

    case SET_API_ERROR:
      return {
        ...state,
        apiError: action.payload.state,
        apiErrorMessage: action.payload.msg,
      };

    default:
      return { ...state };
  }
};
