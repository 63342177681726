// eslint-disable-next-line import/no-cycle
import { LOGIN_USER_SUCCESS, LOGOUT_USER, SET_USER_TYPE } from "../actions";

export const loginUserSuccessAction = (user) => ({
  type: LOGIN_USER_SUCCESS,
  payload: user,
});

export const setUserTypeAction = (data) => ({
  type: SET_USER_TYPE,
  payload: data,
});

export const logoutUser = (history) => {
  localStorage.removeItem("authToken");
  return (dispatch) => {
    dispatch({ type: LOGOUT_USER, payload: { history } });
    window.location.href = process.env.REACT_APP_LOGIN_URL;
  };
};
