import React, { useState, useEffect } from "react";
import { Button, Card, Row } from "reactstrap";
import {
  Colxx,
  Separator,
} from "../../../../../components/common/CustomBootstrap";
import { MDBDataTable } from "mdbreact";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import {
  addNewCompaniesDataAction,
  addNewTeamMemberDataAction,
  deleteCompaniesDataAction,
  deleteTeamMemberDataAction,
  getCompaniesDataAction,
  getTeamMemberDataAction,
  setAPIProgressAction,
  updateCompaniesDataAction,
  updateTeamMemberDataAction,
} from "../../../../../redux/master/actions";
import {
  companyCategoryOptions,
  teamCategoryOptions,
  yesNoOptions,
} from "../../../../../constants/selectOptions";
import AlertMessage from "../../../../../helpers/alerts";
import DeleteConfirmationPopUp from "../../../../../components/common/deleteConfirmationPopup";
import ViewImageByUrl from "../../../../../components/common/viewImageByUrl";
import { requiredFieldValidation } from "../../../../../helpers/Utils";

const TeamMembers = (props) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [companySelectOptions, setCompanySelectOptions] = useState([]);
  let defaultValues = {
    category: "",
    company: "",
    name: "",
    image: "",
    order: "",
    review: "",
    position: "",
    description: "",
    email: "",
    mobile: "",
    isEx: { label: "Yes", value: true },
  };
  const [values, setValues] = useState(defaultValues);

  const dispatch = useDispatch();
  const companies = useSelector((state) => state.master.companies);
  const fetchedData = useSelector((state) => state.master.teams);
  const isApiSuccess = useSelector((state) => state.master.isApiSuccess);

  const [datatable, setDatatable] = React.useState({
    columns: [
      {
        label: "#",
        field: "sn",
        width: 50,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "id",
        },
        sort: "disabled",
      },
      {
        label: "Category",
        field: "category",
        width: 120,
      },
      {
        label: "Name",
        field: "name",
        width: 200,
      },
      {
        label: "Email",
        field: "email",
        width: 250,
      },
      {
        label: "Mobile",
        field: "mobile",
        width: 150,
      },

      {
        label: "Position",
        field: "position",
        width: 200,
      },

      {
        label: "Description",
        field: "description",
        width: 200,
      },

      {
        label: "Review",
        field: "review",
        width: 110,
      },

      {
        label: "Order",
        field: "order",
        width: 110,
      },

      {
        label: "Image",
        field: "image",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        width: 100,
      },
    ],
    rows: [],
  });

  const onSubmitHandler = () => {
    let [isExist, field] = requiredFieldValidation(values, ["company"]);
    if (!isExist) {
      AlertMessage(`Field - ${field}`, "error", "This field required");
      return;
    }

    let newValues = { ...values, category: values.category.value };
    if (values.company?.value) {
      newValues = {
        ...newValues,
        company: values.company.value,
      };
    }

    newValues = { ...newValues, isEx: values.isEx.value };

    if (isEditing) {
      dispatch(updateTeamMemberDataAction(newValues, currentData.id));
    } else dispatch(addNewTeamMemberDataAction(newValues));
  };

  useEffect(() => {
    if (isApiSuccess) {
      setIsAdding(false);
      setIsEditing(false);
      setValues(defaultValues);
    }
  }, [isApiSuccess]);

  useEffect(() => {
    let allOptions = [];
    companies.map((company, index) => {
      allOptions.push({ label: company.name, value: company.id, key: index });
    });

    setCompanySelectOptions(allOptions);
  }, [companies]);

  useEffect(() => {
    let allRows = [];
    fetchedData?.map((data, index) => {
      let oneRow = {
        sn: index + 1,
        category: data.category,
        name: data.name,
        order: data.order,
        review: data.review,
        company: data.company,
        position: data.position,
        description: (
          <div className="tooltip_new">
            {data?.description?.slice(0, 25)}...
            <span className="tooltiptext_new">{data?.description}</span>
          </div>
        ),
        email: data.email,
        mobile: data.mobile,
        image: <ViewImageByUrl imageUrl={`${data.image}`} />,
        action: (
          <span>
            <i
              onClick={() => {
                onEditClick(data);
              }}
              className="simple-icon-pencil cursor-pointer c_ed7117 mr-4px"
            />
            <i
              onClick={() => {
                setIsDeleting(true);
                setCurrentData(data);
              }}
              className="simple-icon-trash cursor-pointer c_ed7117 mr-4px"
            />
          </span>
        ),
      };
      allRows.push(oneRow);
    });

    setDatatable({ ...datatable, rows: allRows });
  }, [fetchedData]);

  useEffect(() => {
    dispatch(getCompaniesDataAction());
    dispatch(getTeamMemberDataAction());
  }, []);

  const onDeleteClick = (_id) => {
    dispatch(deleteTeamMemberDataAction(_id));
    setCurrentData(null);
    setIsDeleting(false);
  };

  const onEditClick = (data) => {
    let valuesObj = {
      name: data.name,
      image: data.image,
      order: data.order,
      review: data.review,
      position: data.position,
      description: data.description,
      email: data.email,
      mobile: data.mobile,
      isEx: yesNoOptions.find((x) => x.value === data.isEx),
    };

    if (data.company) {
      let oneCompany = companySelectOptions.find(
        (x) => x.value === data.company.id
      );
      if (oneCompany) {
        valuesObj = { ...valuesObj, company: oneCompany };
      } else {
        valuesObj = { ...valuesObj, company: "" };
      }
    }

    if (data.category) {
      let oneCategory = teamCategoryOptions.find(
        (x) => x.value === data.category
      );
      if (oneCategory) {
        valuesObj = { ...valuesObj, category: oneCategory };
      }
    }

    setValues(valuesObj);
    setIsEditing(true);
    setCurrentData(data);
  };

  props = {
    ...props,
    isDeleting,
    setIsDeleting,
    currentData,
    setCurrentData,
    onDeleteClick,
  };

  return (
    <React.Fragment>
      {isDeleting && <DeleteConfirmationPopUp {...props} />}
      <Row>
        <Colxx xxs="12">
          <div className="table-header-container">
            <span className="table-header-title">
              List of Team Members
              <p className="fs-14px c_black-60p">
                This is the data of companies, where some of our students placed
                and some trainers are working in these companies.
              </p>
            </span>
            <div>
              <button
                onClick={() => {
                  setIsAdding(true);
                }}
                className="shadow-box create-new-button"
              >
                Add New Team Member
              </button>
            </div>
          </div>
          <Separator className="mb-5" />
        </Colxx>
      </Row>
      <Row>
        {!isAdding && !isEditing && (
          <Colxx lg="12" xl="12">
            <Card className="d-flex mb-3 br-20px" key={`faqItem_1`}>
              <div className="d-flex flex-grow-1 min-width-zero">
                <div
                  className="card-body accordion-content pt-0"
                  style={{ maxWidth: "100%" }}
                >
                  <MDBDataTable
                    fixed
                    hover
                    entriesOptions={[25, 50, 100, 500, 1000]}
                    entries={25}
                    pagesAmount={4}
                    data={datatable}
                    materialSearch
                    // searchTop
                    fullPagination
                    striped
                    theadColor="dark"
                    searching={false}
                    pagingTop
                    scrollX={true}
                    // scrollY
                    maxHeight={1000}
                    searchBottom={false}
                    // barReverse={true}
                  />
                </div>
              </div>
            </Card>
          </Colxx>
        )}
        {(isAdding || isEditing) && (
          <Colxx lg="12" xl="12">
            <Card className="d-flex mb-3 br-20px" key={`faqItem_1`}>
              <div className="d-flex flex-grow-1 min-width-zero">
                <div className="card-body accordion-content pt-0">
                  <Row className="mt-30px">
                    <Colxx lg="12" xl="6">
                      <label className="input-label fw-400 fs-14px">
                        Team Category
                      </label>
                      <Select
                        isClearable
                        options={teamCategoryOptions}
                        className="react-select"
                        classNamePrefix="react-select"
                        value={values.category}
                        onChange={(data) =>
                          setValues({ ...values, category: data })
                        }
                      />
                    </Colxx>

                    <Colxx lg="12" xl="4">
                      <label className="input-label fw-400 fs-14px">
                        Team Company
                      </label>
                      <Select
                        isClearable
                        options={companySelectOptions}
                        className="react-select"
                        classNamePrefix="react-select"
                        value={values.company}
                        onChange={(data) =>
                          setValues({ ...values, company: data })
                        }
                      />
                    </Colxx>

                    <Colxx lg="12" xl="2">
                      <label className="input-label fw-400 fs-14px">
                        It is Ex Company
                      </label>
                      <Select
                        isClearable
                        options={yesNoOptions}
                        className="react-select"
                        classNamePrefix="react-select"
                        value={values.isEx}
                        onChange={(data) =>
                          setValues({ ...values, isEx: data })
                        }
                      />
                    </Colxx>

                    <Colxx lg="12" xl="12" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Team Member Name
                      </label>
                      <input
                        type={"text"}
                        className="form-control br-10px"
                        placeholder="ex. Prateek Mishra"
                        value={values.name}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            name: data.target.value,
                          })
                        }
                      />
                    </Colxx>

                    <Colxx lg="12" xl="6" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Email
                      </label>
                      <input
                        type={"text"}
                        className="form-control br-10px"
                        placeholder="ex. training@techsimplus.com"
                        value={values.email}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            email: data.target.value,
                          })
                        }
                      />
                    </Colxx>

                    <Colxx lg="12" xl="6" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Mobile Number
                      </label>
                      <input
                        type={"text"}
                        className="form-control br-10px"
                        placeholder="ex. +91 9893762256"
                        value={values.mobile}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            mobile: data.target.value,
                          })
                        }
                      />
                    </Colxx>

                    <Colxx lg="12" xl="12" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Team Member Image URL
                      </label>
                      <input
                        type={"text"}
                        className="form-control br-10px"
                        placeholder="https://"
                        value={values.image}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            image: data.target.value,
                          })
                        }
                      />
                    </Colxx>

                    <Colxx lg="12" xl="4" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Order
                      </label>
                      <input
                        type={"number"}
                        className="form-control br-10px"
                        placeholder="ex.a1"
                        value={values.order}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            order: data.target.value,
                          })
                        }
                      />
                    </Colxx>

                    <Colxx lg="12" xl="4" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Review
                      </label>
                      <input
                        type={"text"}
                        className="form-control br-10px"
                        placeholder="ex. 4.9"
                        value={values.review}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            review: data.target.value,
                          })
                        }
                      />
                    </Colxx>

                    <Colxx lg="12" xl="4" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Position (Job Title)
                      </label>
                      <input
                        type={"text"}
                        className="form-control br-10px"
                        placeholder="ex. Director of TechSimPlus"
                        value={values.position}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            position: data.target.value,
                          })
                        }
                      />
                    </Colxx>

                    <Colxx lg="12" xl="12" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Description
                      </label>
                      <textarea
                        className="form-control br-10px"
                        placeholder="ex. About you..."
                        value={values.description}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            description: data.target.value,
                          })
                        }
                      />
                    </Colxx>

                    <div className="d-flex justify-content-flex-end w-100percent">
                      <Button
                        onClick={() => {
                          setIsAdding(false);
                          setIsEditing(false);
                          setCurrentData(null);
                          setValues({
                            category: "",
                            name: "",
                            image: "",
                            order: "",
                            review: "",
                            company: "",
                            position: "",
                            description: "",
                            email: "",
                            mobile: "",
                          });
                        }}
                        size={"sm"}
                        color="dark"
                        className="br-20px c_white shadow-box create-new-button mt-30px"
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={onSubmitHandler}
                        size={"sm"}
                        color="secondry"
                        className="br-20px c_white shadow-box create-new-button mt-30px"
                      >
                        Create
                      </Button>
                    </div>
                  </Row>
                </div>
              </div>
            </Card>
          </Colxx>
        )}
      </Row>
    </React.Fragment>
  );
};

export default TeamMembers;
