import React from "react";
import AppLayout from "../../../layout/AppLayout";

const StudentPanel = (props) => {
  return (
    <AppLayout subDomain={process.env.REACT_APP_STUDENT_SUB_DOMAIN}>
      <div>Student Panel</div>
    </AppLayout>
  );
};

export default StudentPanel;
