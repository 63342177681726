import { LOGIN_USER_SUCCESS, SET_USER_TYPE } from "../actions";

const INIT_STATE = {
  currentUser: null,
  userType: "student",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        currentUser: action.payload,
        error: "",
      };

    case SET_USER_TYPE:
      return {
        ...state,
        userType: action.payload,
      };

    default:
      return { ...state };
  }
};
