import React, { useEffect, useState } from "react";
import { createNewFeePaymentAPI } from "../../../../../../../services/master";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
} from "reactstrap";
import {
  Colxx,
  Separator,
} from "../../../../../../../components/common/CustomBootstrap";
import AlertMessage from "../../../../../../../helpers/alerts";
import { useDispatch } from "react-redux";
import { getOneStudentDataAction } from "../../../../../../../redux/master/actions";

const FeePaymentPopUp = (props) => {
  const { currentAssign, setCurrentAssign, feePayToggle, setFeePayToggle } =
    props;
  const [values, setValues] = useState({ paid_fee: 0 });
  const dispatch = useDispatch();

  const onSubmitHandler = () => {
    if (values.paid_fee === "0") {
      AlertMessage("Enter Some Amount");
      return;
    } else if (values.paid_fee === "" || !values.paid_fee) {
      AlertMessage("Enter Some Amount");
      return;
    }

    createNewFeePaymentAPI(values, { _id: currentAssign.id }).then((res) => {
      setFeePayToggle(!feePayToggle);
      setCurrentAssign(null);
      dispatch(getOneStudentDataAction(props.student_id));
    });
  };

  useEffect(() => {
    if (values.paid_fee > parseInt(props?.currentAssign?.remain_fee)) {
      setValues({ ...values, paid_fee: 0 });
      AlertMessage("Amount should not be greater then remain fee.");
    }
  }, [values]);

  return (
    <React.Fragment>
      <Modal
        isOpen={feePayToggle}
        toggle={() => {
          setFeePayToggle(!feePayToggle);
        }}
        backdrop={true}
        className="br-20px delete-modal"
      >
        <ModalHeader className="mt-n10px h-81px">
          <span className="fs-24px fw-600">Pay Student Fee.</span>
          <p className="mt-n5px">Here You can enter amount, you want to pay.</p>
        </ModalHeader>
        <ModalBody className="mt-n20px">
          <div className="d-flex justify-content-spacebetween">
            <p className="input-label">
              Name:{" "}
              <span className="fw-500">
                {props?.currentAssign?.student?.name}
              </span>
            </p>

            <p className="input-label">
              Remain Fee:{" "}
              <span className="fw-500">{props?.currentAssign?.remain_fee}</span>
            </p>
          </div>

          <div>
            <label className="input-label fw-400 fs-14px">
              Paid Fee <span className="c_B00020 ml-2px">*</span>
            </label>
            <input
              type={"number"}
              className="form-control br-10px"
              placeholder={`ex. ${props?.currentAssign?.remain_fee}`}
              value={values.paid_fee}
              onChange={(data) =>
                setValues({
                  ...values,
                  paid_fee: data.target.value,
                })
              }
            />
          </div>
          <div className="d-flex justify-content-flex-end">
            <Button
              size={"sm"}
              color="secondry"
              className="br-20px "
              onClick={() => {
                setFeePayToggle(!feePayToggle);
              }}
            >
              <b>Cancle</b>
            </Button>{" "}
            <Button
              onClick={onSubmitHandler}
              size={"sm"}
              color="secondry"
              className="br-20px c_white shadow-box create-new-button"
            >
              Pay Now
            </Button>{" "}
          </div>
          <Separator className="mt-2 mb-2" />

          <div>
            <label className="input-label fw-400 fs-14px">
              Previous Payments <span className="c_B00020 ml-2px">*</span>
            </label>

            {props.currentAssign?.enrollment_fee?.map((rec, index) => {
              return (
                <React.Fragment>
                  <Row>
                    <Colxx lg="1" md="2" sm="2" xs="2" xxs="12">
                      {index === 0 && (
                        <span className="input-label fw-400 fs-12px">
                          #<br />
                        </span>
                      )}
                      {index + 1}
                    </Colxx>
                    <Colxx lg="3" md="3" sm="3" xs="3" xxs="12">
                      {index === 0 && (
                        <span className="input-label fw-400 fs-12px">
                          Paid
                          <br />
                        </span>
                      )}
                      Rs.{rec.paid_amount}/-
                    </Colxx>
                    <Colxx lg="3" md="3" sm="3" xs="3" xxs="12">
                      {index === 0 && (
                        <span className="input-label fw-400 fs-12px">
                          Remain
                          <br />
                        </span>
                      )}
                      Rs.{rec.remain_amount}/-
                    </Colxx>
                    <Colxx lg="3" md="3" sm="3" xs="3" xxs="12">
                      {index === 0 && (
                        <span className="input-label fw-400 fs-12px">
                          Date
                          <br />
                        </span>
                      )}
                      {new Date(rec.date).toLocaleDateString()}
                    </Colxx>
                  </Row>
                  <Separator className="mt-1 mb-1" />
                </React.Fragment>
              );
            })}
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default FeePaymentPopUp;
