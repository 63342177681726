import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import TopNav from "../containers/navs/Topnav";
import Sidebar from "../containers/navs/Sidebar";
import Footer from "../containers/navs/Footer";

const AppLayout = (props) => {
  const { containerClassnames, children, history } = props;
  return (
    <div id="app-container" className={containerClassnames}>
      <TopNav history={history} />
      <Sidebar {...props} />
      <main>
        <div className="container-fluid">{children}</div>
      </main>
      {/* <Footer /> */}
    </div>
  );
};
const mapStateToProps = ({ menu }) => {
  const { containerClassnames } = menu;
  return { containerClassnames };
};
const mapActionToProps = {};

export default connect(mapStateToProps, mapActionToProps)(AppLayout);
