import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

const DeleteConfirmationPopUp = (props) => {
  const {
    isDeleting,
    setIsDeleting,
    currentData,
    setCurrentData,
    onDeleteClick,
  } = props;

  return (
    <Modal
      isOpen={isDeleting}
      toggle={() => {
        setIsDeleting(!isDeleting);
        setCurrentData(null);
      }}
      backdrop={true}
      className="br-20px delete-modal"
    >
      <ModalHeader className="mt-n10px h-81px">
        <span className="fs-24px fw-600">Are you sure?</span>
      </ModalHeader>
      <ModalBody className="mt-n10px">
        This action cannot be undone. This will permanently delete the data. If
        you still want to delete. Press Delete button.
      </ModalBody>
      <ModalFooter>
        <Button
          size={"sm"}
          color="secondry"
          className="br-20px "
          onClick={() => {
            setIsDeleting(!isDeleting);
            setCurrentData(null);
          }}
        >
          <b>Cancle</b>
        </Button>{" "}
        <Button
          size={"sm"}
          color="secondry"
          className="br-20px c_white shadow-box create-new-button"
          onClick={() => onDeleteClick(currentData.id)}
        >
          Delete
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
};

export default DeleteConfirmationPopUp;
