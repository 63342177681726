import icon0 from "../assets/img/icons/brands/supple.svg";
import icon1 from "../assets/img/icons/brands/bootstrap.svg";
import icon2 from "../assets/img/icons/brands/weixin.svg";
import icon3 from "../assets/img/icons/brands/connectdevelop.svg";
import icon4 from "../assets/img/icons/brands/medium.svg";
import icon5 from "../assets/img/icons/brands/square-facebook.svg";
import icon6 from "../assets/img/icons/brands/ioxhost.svg";
import icon7 from "../assets/img/icons/brands/fonticons.svg";
import icon8 from "../assets/img/icons/brands/figma.svg";
import icon9 from "../assets/img/icons/brands/reddit-alien.svg";
import icon10 from "../assets/img/icons/brands/angrycreative.svg";
import icon11 from "../assets/img/icons/brands/imdb.svg";
import icon12 from "../assets/img/icons/brands/steam.svg";
import icon13 from "../assets/img/icons/brands/square-xing.svg";
import icon14 from "../assets/img/icons/brands/d-and-d-beyond.svg";
import icon15 from "../assets/img/icons/brands/tumblr.svg";
import icon16 from "../assets/img/icons/brands/bandcamp.svg";
import icon17 from "../assets/img/icons/brands/red-river.svg";
import icon18 from "../assets/img/icons/brands/square-lastfm.svg";
import icon19 from "../assets/img/icons/brands/stackpath.svg";
import icon20 from "../assets/img/icons/brands/square-hacker-news.svg";
import icon21 from "../assets/img/icons/brands/strava.svg";
import icon22 from "../assets/img/icons/brands/dropbox.svg";
import icon23 from "../assets/img/icons/brands/meta.svg";
import icon24 from "../assets/img/icons/brands/microsoft.svg";
import icon25 from "../assets/img/icons/brands/creative-commons-nd.svg";
import icon26 from "../assets/img/icons/brands/square-tumblr.svg";
import icon27 from "../assets/img/icons/brands/dyalog.svg";
import icon28 from "../assets/img/icons/brands/squarespace.svg";
import icon29 from "../assets/img/icons/brands/accusoft.svg";
import icon30 from "../assets/img/icons/brands/lastfm.svg";
import icon31 from "../assets/img/icons/brands/phoenix-squadron.svg";
import icon32 from "../assets/img/icons/brands/square-snapchat.svg";
import icon33 from "../assets/img/icons/brands/yelp.svg";
import icon34 from "../assets/img/icons/brands/cloudflare.svg";
import icon35 from "../assets/img/icons/brands/deviantart.svg";
import icon36 from "../assets/img/icons/brands/airbnb.svg";
import icon37 from "../assets/img/icons/brands/usps.svg";
import icon38 from "../assets/img/icons/brands/envira.svg";
import icon39 from "../assets/img/icons/brands/fulcrum.svg";
import icon40 from "../assets/img/icons/brands/linux.svg";
import icon41 from "../assets/img/icons/brands/foursquare.svg";
import icon42 from "../assets/img/icons/brands/bilibili.svg";
import icon43 from "../assets/img/icons/brands/drupal.svg";
import icon44 from "../assets/img/icons/brands/cc-stripe.svg";
import icon45 from "../assets/img/icons/brands/square-youtube.svg";
import icon46 from "../assets/img/icons/brands/edge-legacy.svg";
import icon47 from "../assets/img/icons/brands/dev.svg";
import icon48 from "../assets/img/icons/brands/speakap.svg";
import icon49 from "../assets/img/icons/brands/android.svg";
import icon50 from "../assets/img/icons/brands/ethereum.svg";
import icon51 from "../assets/img/icons/brands/shirtsinbulk.svg";
import icon52 from "../assets/img/icons/brands/black-tie.svg";
import icon53 from "../assets/img/icons/brands/odnoklassniki.svg";
import icon54 from "../assets/img/icons/brands/fonticons-fi.svg";
import icon55 from "../assets/img/icons/brands/cc-amex.svg";
import icon56 from "../assets/img/icons/brands/js.svg";
import icon57 from "../assets/img/icons/brands/galactic-senate.svg";
import icon58 from "../assets/img/icons/brands/reacteurope.svg";
import icon59 from "../assets/img/icons/brands/openid.svg";
import icon60 from "../assets/img/icons/brands/gitlab.svg";
import icon61 from "../assets/img/icons/brands/sourcetree.svg";
import icon62 from "../assets/img/icons/brands/gg.svg";
import icon63 from "../assets/img/icons/brands/joget.svg";
import icon64 from "../assets/img/icons/brands/weibo.svg";
import icon65 from "../assets/img/icons/brands/aws.svg";
import icon66 from "../assets/img/icons/brands/sass.svg";
import icon67 from "../assets/img/icons/brands/cc-paypal.svg";
import icon68 from "../assets/img/icons/brands/superpowers.svg";
import icon69 from "../assets/img/icons/brands/rust.svg";
import icon70 from "../assets/img/icons/brands/sellcast.svg";
import icon71 from "../assets/img/icons/brands/java.svg";
import icon72 from "../assets/img/icons/brands/orcid.svg";
import icon73 from "../assets/img/icons/brands/tencent-weibo.svg";
import icon74 from "../assets/img/icons/brands/trade-federation.svg";
import icon75 from "../assets/img/icons/brands/viber.svg";
import icon76 from "../assets/img/icons/brands/ello.svg";
import icon77 from "../assets/img/icons/brands/wix.svg";
import icon78 from "../assets/img/icons/brands/mizuni.svg";
import icon79 from "../assets/img/icons/brands/leanpub.svg";
import icon80 from "../assets/img/icons/brands/bitbucket.svg";
import icon81 from "../assets/img/icons/brands/pagelines.svg";
import icon82 from "../assets/img/icons/brands/creative-commons.svg";
import icon83 from "../assets/img/icons/brands/github.svg";
import icon84 from "../assets/img/icons/brands/viacoin.svg";
import icon85 from "../assets/img/icons/brands/freebsd.svg";
import icon86 from "../assets/img/icons/brands/rebel.svg";
import icon87 from "../assets/img/icons/brands/square-steam.svg";
import icon88 from "../assets/img/icons/brands/fedex.svg";
import icon89 from "../assets/img/icons/brands/usb.svg";
import icon90 from "../assets/img/icons/brands/empire.svg";
import icon91 from "../assets/img/icons/brands/simplybuilt.svg";
import icon92 from "../assets/img/icons/brands/google-drive.svg";
import icon93 from "../assets/img/icons/brands/microblog.svg";
import icon94 from "../assets/img/icons/brands/etsy.svg";
import icon95 from "../assets/img/icons/brands/bimobject.svg";
import icon96 from "../assets/img/icons/brands/app-store-ios.svg";
import icon97 from "../assets/img/icons/brands/gripfire.svg";
import icon98 from "../assets/img/icons/brands/500px.svg";
import icon99 from "../assets/img/icons/brands/speaker-deck.svg";
import icon100 from "../assets/img/icons/brands/deskpro.svg";
import icon101 from "../assets/img/icons/brands/critical-role.svg";
import icon102 from "../assets/img/icons/brands/square-github.svg";
import icon103 from "../assets/img/icons/brands/vimeo-v.svg";
import icon104 from "../assets/img/icons/brands/stripe-s.svg";
import icon105 from "../assets/img/icons/brands/keybase.svg";
import icon106 from "../assets/img/icons/brands/yandex-international.svg";
import icon107 from "../assets/img/icons/brands/umbraco.svg";
import icon108 from "../assets/img/icons/brands/joomla.svg";
import icon109 from "../assets/img/icons/brands/google-play.svg";
import icon110 from "../assets/img/icons/brands/uniregistry.svg";
import icon111 from "../assets/img/icons/brands/d-and-d.svg";
import icon112 from "../assets/img/icons/brands/kaggle.svg";
import icon113 from "../assets/img/icons/brands/hubspot.svg";
import icon114 from "../assets/img/icons/brands/confluence.svg";
import icon115 from "../assets/img/icons/brands/square-gitlab.svg";
import icon116 from "../assets/img/icons/brands/servicestack.svg";
import icon117 from "../assets/img/icons/brands/npm.svg";
import icon118 from "../assets/img/icons/brands/square-font-awesome.svg";
import icon119 from "../assets/img/icons/brands/square-whatsapp.svg";
import icon120 from "../assets/img/icons/brands/creative-commons-nc.svg";
import icon121 from "../assets/img/icons/brands/galactic-republic.svg";
import icon122 from "../assets/img/icons/brands/square-instagram.svg";
import icon123 from "../assets/img/icons/brands/raspberry-pi.svg";
import icon124 from "../assets/img/icons/brands/mastodon.svg";
import icon125 from "../assets/img/icons/brands/square-git.svg";
import icon126 from "../assets/img/icons/brands/42-group.svg";
import icon127 from "../assets/img/icons/brands/angular.svg";
import icon128 from "../assets/img/icons/brands/untappd.svg";
import icon129 from "../assets/img/icons/brands/apper.svg";
import icon130 from "../assets/img/icons/brands/git-alt.svg";
import icon131 from "../assets/img/icons/brands/sitrox.svg";
import icon132 from "../assets/img/icons/brands/vk.svg";
import icon133 from "../assets/img/icons/brands/vimeo.svg";
import icon134 from "../assets/img/icons/brands/discourse.svg";
import icon135 from "../assets/img/icons/brands/hotjar.svg";
import icon136 from "../assets/img/icons/brands/symfony.svg";
import icon137 from "../assets/img/icons/brands/square-odnoklassniki.svg";
import icon138 from "../assets/img/icons/brands/skyatlas.svg";
import icon139 from "../assets/img/icons/brands/hacker-news.svg";
import icon140 from "../assets/img/icons/brands/instagram.svg";
import icon141 from "../assets/img/icons/brands/y-combinator.svg";
import icon142 from "../assets/img/icons/brands/shopify.svg";
import icon143 from "../assets/img/icons/brands/mendeley.svg";
import icon144 from "../assets/img/icons/brands/wodu.svg";
import icon145 from "../assets/img/icons/brands/fort-awesome-alt.svg";
import icon146 from "../assets/img/icons/brands/r-project.svg";
import icon147 from "../assets/img/icons/brands/edge.svg";
import icon148 from "../assets/img/icons/brands/yammer.svg";
import icon149 from "../assets/img/icons/brands/bots.svg";
import icon150 from "../assets/img/icons/brands/fly.svg";
import icon151 from "../assets/img/icons/brands/safari.svg";
import icon152 from "../assets/img/icons/brands/square-behance.svg";
import icon153 from "../assets/img/icons/brands/pied-piper-hat.svg";
import icon154 from "../assets/img/icons/brands/audible.svg";
import icon155 from "../assets/img/icons/brands/yoast.svg";
import icon156 from "../assets/img/icons/brands/fort-awesome.svg";
import icon157 from "../assets/img/icons/brands/square-dribbble.svg";
import icon158 from "../assets/img/icons/brands/python.svg";
import icon159 from "../assets/img/icons/brands/pix.svg";
import icon160 from "../assets/img/icons/brands/cc-apple-pay.svg";
import icon161 from "../assets/img/icons/brands/docker.svg";
import icon162 from "../assets/img/icons/brands/git.svg";
import icon163 from "../assets/img/icons/brands/blogger.svg";
import icon164 from "../assets/img/icons/brands/itch-io.svg";
import icon165 from "../assets/img/icons/brands/nfc-symbol.svg";
import icon166 from "../assets/img/icons/brands/creative-commons-nc-eu.svg";
import icon167 from "../assets/img/icons/brands/schlix.svg";
import icon168 from "../assets/img/icons/brands/mailchimp.svg";
import icon169 from "../assets/img/icons/brands/neos.svg";
import icon170 from "../assets/img/icons/brands/creative-commons-pd.svg";
import icon171 from "../assets/img/icons/brands/pinterest-p.svg";
import icon172 from "../assets/img/icons/brands/digg.svg";
import icon173 from "../assets/img/icons/brands/bitcoin.svg";
import icon174 from "../assets/img/icons/brands/whmcs.svg";
import icon175 from "../assets/img/icons/brands/delicious.svg";
import icon176 from "../assets/img/icons/brands/hackerrank.svg";
import icon177 from "../assets/img/icons/brands/telegram.svg";
import icon178 from "../assets/img/icons/brands/cc-discover.svg";
import icon179 from "../assets/img/icons/brands/centercode.svg";
import icon180 from "../assets/img/icons/brands/erlang.svg";
import icon181 from "../assets/img/icons/brands/medrt.svg";
import icon182 from "../assets/img/icons/brands/salesforce.svg";
import icon183 from "../assets/img/icons/brands/meetup.svg";
import icon184 from "../assets/img/icons/brands/square-vimeo.svg";
import icon185 from "../assets/img/icons/brands/wizards-of-the-coast.svg";
import icon186 from "../assets/img/icons/brands/markdown.svg";
import icon187 from "../assets/img/icons/brands/instalod.svg";
import icon188 from "../assets/img/icons/brands/padlet.svg";
import icon189 from "../assets/img/icons/brands/goodreads.svg";
import icon190 from "../assets/img/icons/brands/unity.svg";
import icon191 from "../assets/img/icons/brands/creative-commons-sampling-plus.svg";
import icon192 from "../assets/img/icons/brands/invision.svg";
import icon193 from "../assets/img/icons/brands/mix.svg";
import icon194 from "../assets/img/icons/brands/gratipay.svg";
import icon195 from "../assets/img/icons/brands/magento.svg";
import icon196 from "../assets/img/icons/brands/bluetooth.svg";
import icon197 from "../assets/img/icons/brands/houzz.svg";
import icon198 from "../assets/img/icons/brands/expeditedssl.svg";
import icon199 from "../assets/img/icons/brands/the-red-yeti.svg";
import icon200 from "../assets/img/icons/brands/canadian-maple-leaf.svg";
import icon201 from "../assets/img/icons/brands/sith.svg";
import icon202 from "../assets/img/icons/brands/algolia.svg";
import icon203 from "../assets/img/icons/brands/ussunnah.svg";
import icon204 from "../assets/img/icons/brands/sellsy.svg";
import icon205 from "../assets/img/icons/brands/jira.svg";
import icon206 from "../assets/img/icons/brands/spotify.svg";
import icon207 from "../assets/img/icons/brands/intercom.svg";
import icon208 from "../assets/img/icons/brands/wolf-pack-battalion.svg";
import icon209 from "../assets/img/icons/brands/square-twitter.svg";
import icon210 from "../assets/img/icons/brands/flipboard.svg";
import icon211 from "../assets/img/icons/brands/google-wallet.svg";
import icon212 from "../assets/img/icons/brands/shopware.svg";
import icon213 from "../assets/img/icons/brands/phoenix-framework.svg";
import icon214 from "../assets/img/icons/brands/wirsindhandwerk.svg";
import icon215 from "../assets/img/icons/brands/kickstarter-k.svg";
import icon216 from "../assets/img/icons/brands/cc-jcb.svg";
import icon217 from "../assets/img/icons/brands/researchgate.svg";
import icon218 from "../assets/img/icons/brands/lyft.svg";
import icon219 from "../assets/img/icons/brands/wpressr.svg";
import icon220 from "../assets/img/icons/brands/octopus-deploy.svg";
import icon221 from "../assets/img/icons/brands/periscope.svg";
import icon222 from "../assets/img/icons/brands/modx.svg";
import icon223 from "../assets/img/icons/brands/kickstarter.svg";
import icon224 from "../assets/img/icons/brands/slideshare.svg";
import icon225 from "../assets/img/icons/brands/snapchat.svg";
import icon226 from "../assets/img/icons/brands/font-awesome.svg";
import icon227 from "../assets/img/icons/brands/buy-n-large.svg";
import icon228 from "../assets/img/icons/brands/readme.svg";
import icon229 from "../assets/img/icons/brands/creative-commons-nc-jp.svg";
import icon230 from "../assets/img/icons/brands/themeisle.svg";
import icon231 from "../assets/img/icons/brands/html5.svg";
import icon232 from "../assets/img/icons/brands/replyd.svg";
import icon233 from "../assets/img/icons/brands/cuttlefish.svg";
import icon234 from "../assets/img/icons/brands/rockrms.svg";
import icon235 from "../assets/img/icons/brands/ravelry.svg";
import icon236 from "../assets/img/icons/brands/quora.svg";
import icon237 from "../assets/img/icons/brands/monero.svg";
import icon238 from "../assets/img/icons/brands/hire-a-helper.svg";
import icon239 from "../assets/img/icons/brands/github-alt.svg";
import icon240 from "../assets/img/icons/brands/creative-commons-share.svg";
import icon241 from "../assets/img/icons/brands/yarn.svg";
import icon242 from "../assets/img/icons/brands/cc-visa.svg";
import icon243 from "../assets/img/icons/brands/dochub.svg";
import icon244 from "../assets/img/icons/brands/laravel.svg";
import icon245 from "../assets/img/icons/brands/digital-ocean.svg";
import icon246 from "../assets/img/icons/brands/earlybirds.svg";
import icon247 from "../assets/img/icons/brands/centos.svg";
import icon248 from "../assets/img/icons/brands/bluetooth-b.svg";
import icon249 from "../assets/img/icons/brands/teamspeak.svg";
import icon250 from "../assets/img/icons/brands/square-google-plus.svg";
import icon251 from "../assets/img/icons/brands/wpexplorer.svg";
import icon252 from "../assets/img/icons/brands/first-order-alt.svg";
import icon253 from "../assets/img/icons/brands/ubuntu.svg";
import icon254 from "../assets/img/icons/brands/opencart.svg";
import icon255 from "../assets/img/icons/brands/pied-piper.svg";
import icon256 from "../assets/img/icons/brands/paypal.svg";
import icon257 from "../assets/img/icons/brands/suse.svg";
import icon258 from "../assets/img/icons/brands/wpbeginner.svg";
import icon259 from "../assets/img/icons/brands/deezer.svg";
import icon260 from "../assets/img/icons/brands/blogger-b.svg";
import icon261 from "../assets/img/icons/brands/rocketchat.svg";
import icon262 from "../assets/img/icons/brands/unsplash.svg";
import icon263 from "../assets/img/icons/brands/draft2digital.svg";
import icon264 from "../assets/img/icons/brands/playstation.svg";
import icon265 from "../assets/img/icons/brands/facebook.svg";
import icon266 from "../assets/img/icons/brands/amazon-pay.svg";
import icon267 from "../assets/img/icons/brands/square-font-awesome-stroke.svg";
import icon268 from "../assets/img/icons/brands/amazon.svg";
import icon269 from "../assets/img/icons/brands/quinscape.svg";
import icon270 from "../assets/img/icons/brands/free-code-camp.svg";
import icon271 from "../assets/img/icons/brands/vine.svg";
import icon272 from "../assets/img/icons/brands/pinterest.svg";
import icon273 from "../assets/img/icons/brands/creative-commons-sa.svg";
import icon274 from "../assets/img/icons/brands/creative-commons-pd-alt.svg";
import icon275 from "../assets/img/icons/brands/stack-overflow.svg";
import icon276 from "../assets/img/icons/brands/page4.svg";
import icon277 from "../assets/img/icons/brands/mdb.svg";
import icon278 from "../assets/img/icons/brands/osi.svg";
import icon279 from "../assets/img/icons/brands/yandex.svg";
import icon280 from "../assets/img/icons/brands/whatsapp.svg";
import icon281 from "../assets/img/icons/brands/google.svg";
import icon282 from "../assets/img/icons/brands/chromecast.svg";
import icon283 from "../assets/img/icons/brands/line.svg";
import icon284 from "../assets/img/icons/brands/battle-net.svg";
import icon285 from "../assets/img/icons/brands/affiliatetheme.svg";
import icon286 from "../assets/img/icons/brands/atlassian.svg";
import icon287 from "../assets/img/icons/brands/aviato.svg";
import icon288 from "../assets/img/icons/brands/bity.svg";
import icon289 from "../assets/img/icons/brands/php.svg";
import icon290 from "../assets/img/icons/brands/stumbleupon-circle.svg";
import icon291 from "../assets/img/icons/brands/stripe.svg";
import icon292 from "../assets/img/icons/brands/jedi-order.svg";
import icon293 from "../assets/img/icons/brands/think-peaks.svg";
import icon294 from "../assets/img/icons/brands/buromobelexperte.svg";
import icon295 from "../assets/img/icons/brands/phabricator.svg";
import icon296 from "../assets/img/icons/brands/apple-pay.svg";
import icon297 from "../assets/img/icons/brands/artstation.svg";
import icon298 from "../assets/img/icons/brands/scribd.svg";
import icon299 from "../assets/img/icons/brands/stack-exchange.svg";
import icon300 from "../assets/img/icons/brands/fedora.svg";
import icon301 from "../assets/img/icons/brands/jenkins.svg";
import icon302 from "../assets/img/icons/brands/studiovinari.svg";
import icon303 from "../assets/img/icons/brands/glide.svg";
import icon304 from "../assets/img/icons/brands/adn.svg";
import icon305 from "../assets/img/icons/brands/css3-alt.svg";
import icon306 from "../assets/img/icons/brands/xing.svg";
import icon307 from "../assets/img/icons/brands/medapps.svg";
import icon308 from "../assets/img/icons/brands/redhat.svg";
import icon309 from "../assets/img/icons/brands/uncharted.svg";
import icon310 from "../assets/img/icons/brands/fantasy-flight-games.svg";
import icon311 from "../assets/img/icons/brands/facebook-f.svg";
import icon312 from "../assets/img/icons/brands/perbyte.svg";
import icon313 from "../assets/img/icons/brands/hips.svg";
import icon314 from "../assets/img/icons/brands/google-pay.svg";
import icon315 from "../assets/img/icons/brands/twitch.svg";
import icon316 from "../assets/img/icons/brands/cc-diners-club.svg";
import icon317 from "../assets/img/icons/brands/windows.svg";
import icon318 from "../assets/img/icons/brands/rev.svg";
import icon319 from "../assets/img/icons/brands/btc.svg";
import icon320 from "../assets/img/icons/brands/creative-commons-zero.svg";
import icon321 from "../assets/img/icons/brands/elementor.svg";
import icon322 from "../assets/img/icons/brands/square-pied-piper.svg";
import icon323 from "../assets/img/icons/brands/youtube.svg";
import icon324 from "../assets/img/icons/brands/accessible-icon.svg";
import icon325 from "../assets/img/icons/brands/gg-circle.svg";
import icon326 from "../assets/img/icons/brands/app-store.svg";
import icon327 from "../assets/img/icons/brands/codiepie.svg";
import icon328 from "../assets/img/icons/brands/stumbleupon.svg";
import icon329 from "../assets/img/icons/brands/nutritionix.svg";
import icon330 from "../assets/img/icons/brands/linkedin-in.svg";
import icon331 from "../assets/img/icons/brands/jsfiddle.svg";
import icon332 from "../assets/img/icons/brands/yahoo.svg";
import icon333 from "../assets/img/icons/brands/grav.svg";
import icon334 from "../assets/img/icons/brands/firefox-browser.svg";
import icon335 from "../assets/img/icons/brands/sticker-mule.svg";
import icon336 from "../assets/img/icons/brands/goodreads-g.svg";
import icon337 from "../assets/img/icons/brands/uber.svg";
import icon338 from "../assets/img/icons/brands/sketch.svg";
import icon339 from "../assets/img/icons/brands/creative-commons-remix.svg";
import icon340 from "../assets/img/icons/brands/itunes-note.svg";
import icon341 from "../assets/img/icons/brands/gulp.svg";
import icon342 from "../assets/img/icons/brands/keycdn.svg";
import icon343 from "../assets/img/icons/brands/diaspora.svg";
import icon344 from "../assets/img/icons/brands/cotton-bureau.svg";
import icon345 from "../assets/img/icons/brands/reddit.svg";
import icon346 from "../assets/img/icons/brands/alipay.svg";
import icon347 from "../assets/img/icons/brands/cloudscale.svg";
import icon348 from "../assets/img/icons/brands/buysellads.svg";
import icon349 from "../assets/img/icons/brands/steam-symbol.svg";
import icon350 from "../assets/img/icons/brands/asymmetrik.svg";
import icon351 from "../assets/img/icons/brands/angellist.svg";
import icon352 from "../assets/img/icons/brands/wpforms.svg";
import icon353 from "../assets/img/icons/brands/linode.svg";
import icon354 from "../assets/img/icons/brands/guilded.svg";
import icon355 from "../assets/img/icons/brands/avianex.svg";
import icon356 from "../assets/img/icons/brands/evernote.svg";
import icon357 from "../assets/img/icons/brands/codepen.svg";
import icon358 from "../assets/img/icons/brands/dribbble.svg";
import icon359 from "../assets/img/icons/brands/cc-amazon-pay.svg";
import icon360 from "../assets/img/icons/brands/xbox.svg";
import icon361 from "../assets/img/icons/brands/trello.svg";
import icon362 from "../assets/img/icons/brands/adversal.svg";
import icon363 from "../assets/img/icons/brands/dhl.svg";
import icon364 from "../assets/img/icons/brands/hornbill.svg";
import icon365 from "../assets/img/icons/brands/themeco.svg";
import icon366 from "../assets/img/icons/brands/google-plus.svg";
import icon367 from "../assets/img/icons/brands/contao.svg";
import icon368 from "../assets/img/icons/brands/linkedin.svg";
import icon369 from "../assets/img/icons/brands/ideal.svg";
import icon370 from "../assets/img/icons/brands/mandalorian.svg";
import icon371 from "../assets/img/icons/brands/hashnode.svg";
import icon372 from "../assets/img/icons/brands/old-republic.svg";
import icon373 from "../assets/img/icons/brands/qq.svg";
import icon374 from "../assets/img/icons/brands/vaadin.svg";
import icon375 from "../assets/img/icons/brands/uikit.svg";
import icon376 from "../assets/img/icons/brands/react.svg";
import icon377 from "../assets/img/icons/brands/creative-commons-by.svg";
import icon378 from "../assets/img/icons/brands/square-pinterest.svg";
import icon379 from "../assets/img/icons/brands/watchman-monitoring.svg";
import icon380 from "../assets/img/icons/brands/optin-monster.svg";
import icon381 from "../assets/img/icons/brands/twitter.svg";
import icon382 from "../assets/img/icons/brands/less.svg";
import icon383 from "../assets/img/icons/brands/dailymotion.svg";
import icon384 from "../assets/img/icons/brands/patreon.svg";
import icon385 from "../assets/img/icons/brands/swift.svg";
import icon386 from "../assets/img/icons/brands/discord.svg";
import icon387 from "../assets/img/icons/brands/opera.svg";
import icon388 from "../assets/img/icons/brands/gitter.svg";
import icon389 from "../assets/img/icons/brands/buffer.svg";
import icon390 from "../assets/img/icons/brands/css3.svg";
import icon391 from "../assets/img/icons/brands/renren.svg";
import icon392 from "../assets/img/icons/brands/square-reddit.svg";
import icon393 from "../assets/img/icons/brands/ns8.svg";
import icon394 from "../assets/img/icons/brands/sistrix.svg";
import icon395 from "../assets/img/icons/brands/vuejs.svg";
import icon396 from "../assets/img/icons/brands/mixcloud.svg";
import icon397 from "../assets/img/icons/brands/firstdraft.svg";
import icon398 from "../assets/img/icons/brands/slack.svg";
import icon399 from "../assets/img/icons/brands/zhihu.svg";
import icon400 from "../assets/img/icons/brands/soundcloud.svg";
import icon401 from "../assets/img/icons/brands/product-hunt.svg";
import icon402 from "../assets/img/icons/brands/wikipedia-w.svg";
import icon403 from "../assets/img/icons/brands/space-awesome.svg";
import icon404 from "../assets/img/icons/brands/pied-piper-pp.svg";
import icon405 from "../assets/img/icons/brands/creative-commons-sampling.svg";
import icon406 from "../assets/img/icons/brands/hooli.svg";
import icon407 from "../assets/img/icons/brands/first-order.svg";
import icon408 from "../assets/img/icons/brands/glide-g.svg";
import icon409 from "../assets/img/icons/brands/ember.svg";
import icon410 from "../assets/img/icons/brands/gitkraken.svg";
import icon411 from "../assets/img/icons/brands/skype.svg";
import icon412 from "../assets/img/icons/brands/gofore.svg";
import icon413 from "../assets/img/icons/brands/ebay.svg";
import icon414 from "../assets/img/icons/brands/cpanel.svg";
import icon415 from "../assets/img/icons/brands/wordpress.svg";
import icon416 from "../assets/img/icons/brands/korvue.svg";
import icon417 from "../assets/img/icons/brands/cloudversify.svg";
import icon418 from "../assets/img/icons/brands/grunt.svg";
import icon419 from "../assets/img/icons/brands/viadeo.svg";
import icon420 from "../assets/img/icons/brands/wordpress-simple.svg";
import icon421 from "../assets/img/icons/brands/blackberry.svg";
import icon422 from "../assets/img/icons/brands/facebook-messenger.svg";
import icon423 from "../assets/img/icons/brands/pushed.svg";
import icon424 from "../assets/img/icons/brands/hive.svg";
import icon425 from "../assets/img/icons/brands/amilia.svg";
import icon426 from "../assets/img/icons/brands/pied-piper-alt.svg";
import icon427 from "../assets/img/icons/brands/nfc-directional.svg";
import icon428 from "../assets/img/icons/brands/cloudsmith.svg";
import icon429 from "../assets/img/icons/brands/chrome.svg";
import icon430 from "../assets/img/icons/brands/forumbee.svg";
import icon431 from "../assets/img/icons/brands/node-js.svg";
import icon432 from "../assets/img/icons/brands/maxcdn.svg";
import icon433 from "../assets/img/icons/brands/apple.svg";
import icon434 from "../assets/img/icons/brands/napster.svg";
import icon435 from "../assets/img/icons/brands/nimblr.svg";
import icon436 from "../assets/img/icons/brands/vnv.svg";
import icon437 from "../assets/img/icons/brands/waze.svg";
import icon438 from "../assets/img/icons/brands/google-plus-g.svg";
import icon439 from "../assets/img/icons/brands/palfed.svg";
import icon440 from "../assets/img/icons/brands/screenpal.svg";
import icon441 from "../assets/img/icons/brands/weebly.svg";
import icon442 from "../assets/img/icons/brands/autoprefixer.svg";
import icon443 from "../assets/img/icons/brands/searchengin.svg";
import icon444 from "../assets/img/icons/brands/square-viadeo.svg";
import icon445 from "../assets/img/icons/brands/megaport.svg";
import icon446 from "../assets/img/icons/brands/typo3.svg";
import icon447 from "../assets/img/icons/brands/deploydog.svg";
import icon448 from "../assets/img/icons/brands/node.svg";
import icon449 from "../assets/img/icons/brands/cc-mastercard.svg";
import icon450 from "../assets/img/icons/brands/tiktok.svg";
import icon451 from "../assets/img/icons/brands/dashcube.svg";
import icon452 from "../assets/img/icons/brands/get-pocket.svg";
import icon453 from "../assets/img/icons/brands/golang.svg";
import icon454 from "../assets/img/icons/brands/ups.svg";
import icon455 from "../assets/img/icons/brands/behance.svg";
import icon456 from "../assets/img/icons/brands/resolving.svg";
import icon457 from "../assets/img/icons/brands/internet-explorer.svg";
import icon458 from "../assets/img/icons/brands/staylinked.svg";
import icon459 from "../assets/img/icons/brands/cmplid.svg";
import icon460 from "../assets/img/icons/brands/firefox.svg";
import icon461 from "../assets/img/icons/brands/flickr.svg";
import icon462 from "../assets/img/icons/brands/mixer.svg";
import icon463 from "../assets/img/icons/brands/square-js.svg";
import icon464 from "../assets/img/icons/brands/itunes.svg";
import icon465 from "../assets/img/icons/solid/comment-dots.svg";
import icon466 from "../assets/img/icons/solid/passport.svg";
import icon467 from "../assets/img/icons/solid/socks.svg";
import icon468 from "../assets/img/icons/solid/underline.svg";
import icon469 from "../assets/img/icons/solid/file-arrow-up.svg";
import icon470 from "../assets/img/icons/solid/headphones-simple.svg";
import icon471 from "../assets/img/icons/solid/cloud-meatball.svg";
import icon472 from "../assets/img/icons/solid/caret-down.svg";
import icon473 from "../assets/img/icons/solid/dice-three.svg";
import icon474 from "../assets/img/icons/solid/file-zipper.svg";
import icon475 from "../assets/img/icons/solid/hand.svg";
import icon476 from "../assets/img/icons/solid/toggle-on.svg";
import icon477 from "../assets/img/icons/solid/clone.svg";
import icon478 from "../assets/img/icons/solid/arrow-rotate-right.svg";
import icon479 from "../assets/img/icons/solid/person-digging.svg";
import icon480 from "../assets/img/icons/solid/crow.svg";
import icon481 from "../assets/img/icons/solid/bars-progress.svg";
import icon482 from "../assets/img/icons/solid/right-from-bracket.svg";
import icon483 from "../assets/img/icons/solid/plug-circle-check.svg";
import icon484 from "../assets/img/icons/solid/bottle-droplet.svg";
import icon485 from "../assets/img/icons/solid/pallet.svg";
import icon486 from "../assets/img/icons/solid/person-drowning.svg";
import icon487 from "../assets/img/icons/solid/eraser.svg";
import icon488 from "../assets/img/icons/solid/list-ul.svg";
import icon489 from "../assets/img/icons/solid/chart-line.svg";
import icon490 from "../assets/img/icons/solid/person-chalkboard.svg";
import icon491 from "../assets/img/icons/solid/receipt.svg";
import icon492 from "../assets/img/icons/solid/diagram-successor.svg";
import icon493 from "../assets/img/icons/solid/money-bill-transfer.svg";
import icon494 from "../assets/img/icons/solid/briefcase-medical.svg";
import icon495 from "../assets/img/icons/solid/file-circle-exclamation.svg";
import icon496 from "../assets/img/icons/solid/right-left.svg";
import icon497 from "../assets/img/icons/solid/cash-register.svg";
import icon498 from "../assets/img/icons/solid/arrow-down.svg";
import icon499 from "../assets/img/icons/solid/money-bill-1-wave.svg";
import icon500 from "../assets/img/icons/solid/arrows-turn-right.svg";
import icon501 from "../assets/img/icons/solid/campground.svg";
import icon502 from "../assets/img/icons/solid/poo.svg";
import icon503 from "../assets/img/icons/solid/file-pen.svg";
import icon504 from "../assets/img/icons/solid/ghost.svg";
import icon505 from "../assets/img/icons/solid/business-time.svg";
import icon506 from "../assets/img/icons/solid/list.svg";
import icon507 from "../assets/img/icons/solid/volume-off.svg";
import icon508 from "../assets/img/icons/solid/house-chimney-window.svg";
import icon509 from "../assets/img/icons/solid/users-gear.svg";
import icon510 from "../assets/img/icons/solid/house-circle-exclamation.svg";
import icon511 from "../assets/img/icons/solid/diagram-next.svg";
import icon512 from "../assets/img/icons/solid/neuter.svg";
import icon513 from "../assets/img/icons/solid/book-bookmark.svg";
import icon514 from "../assets/img/icons/solid/file-excel.svg";
import icon515 from "../assets/img/icons/solid/virus.svg";
import icon516 from "../assets/img/icons/solid/map-location-dot.svg";
import icon517 from "../assets/img/icons/solid/house.svg";
import icon518 from "../assets/img/icons/solid/stapler.svg";
import icon519 from "../assets/img/icons/solid/prescription.svg";
import icon520 from "../assets/img/icons/solid/arrow-down-short-wide.svg";
import icon521 from "../assets/img/icons/solid/bed.svg";
import icon522 from "../assets/img/icons/solid/stopwatch-20.svg";
import icon523 from "../assets/img/icons/solid/mars-and-venus.svg";
import icon524 from "../assets/img/icons/solid/dumpster.svg";
import icon525 from "../assets/img/icons/solid/building-shield.svg";
import icon526 from "../assets/img/icons/solid/flask-vial.svg";
import icon527 from "../assets/img/icons/solid/wind.svg";
import icon528 from "../assets/img/icons/solid/person-walking-with-cane.svg";
import icon529 from "../assets/img/icons/solid/text-slash.svg";
import icon530 from "../assets/img/icons/solid/clapperboard.svg";
import icon531 from "../assets/img/icons/solid/subscript.svg";
import icon532 from "../assets/img/icons/solid/viruses.svg";
import icon533 from "../assets/img/icons/solid/drum-steelpan.svg";
import icon534 from "../assets/img/icons/solid/trash-can.svg";
import icon535 from "../assets/img/icons/solid/sack-xmark.svg";
import icon536 from "../assets/img/icons/solid/face-grin-tongue-wink.svg";
import icon537 from "../assets/img/icons/solid/rotate-left.svg";
import icon538 from "../assets/img/icons/solid/handshake-slash.svg";
import icon539 from "../assets/img/icons/solid/car-rear.svg";
import icon540 from "../assets/img/icons/solid/scissors.svg";
import icon541 from "../assets/img/icons/solid/plug-circle-exclamation.svg";
import icon542 from "../assets/img/icons/solid/section.svg";
import icon543 from "../assets/img/icons/solid/earth-americas.svg";
import icon544 from "../assets/img/icons/solid/forward-fast.svg";
import icon545 from "../assets/img/icons/solid/chalkboard-user.svg";
import icon546 from "../assets/img/icons/solid/repeat.svg";
import icon547 from "../assets/img/icons/solid/mitten.svg";
import icon548 from "../assets/img/icons/solid/pen-nib.svg";
import icon549 from "../assets/img/icons/solid/hands-bound.svg";
import icon550 from "../assets/img/icons/solid/grip.svg";
import icon551 from "../assets/img/icons/solid/money-bills.svg";
import icon552 from "../assets/img/icons/solid/house-laptop.svg";
import icon553 from "../assets/img/icons/solid/hourglass.svg";
import icon554 from "../assets/img/icons/solid/circle-chevron-right.svg";
import icon555 from "../assets/img/icons/solid/circle-exclamation.svg";
import icon556 from "../assets/img/icons/solid/chess-rook.svg";
import icon557 from "../assets/img/icons/solid/up-down.svg";
import icon558 from "../assets/img/icons/solid/dice-one.svg";
import icon559 from "../assets/img/icons/solid/ban-smoking.svg";
import icon560 from "../assets/img/icons/solid/volcano.svg";
import icon561 from "../assets/img/icons/solid/reply.svg";
import icon562 from "../assets/img/icons/solid/grip-lines.svg";
import icon563 from "../assets/img/icons/solid/camera-retro.svg";
import icon564 from "../assets/img/icons/solid/toolbox.svg";
import icon565 from "../assets/img/icons/solid/envelope-open-text.svg";
import icon566 from "../assets/img/icons/solid/mobile-screen.svg";
import icon567 from "../assets/img/icons/solid/handshake-simple.svg";
import icon568 from "../assets/img/icons/solid/children.svg";
import icon569 from "../assets/img/icons/solid/face-grimace.svg";
import icon570 from "../assets/img/icons/solid/house-flood-water-circle-arrow-right.svg";
import icon571 from "../assets/img/icons/solid/ankh.svg";
import icon572 from "../assets/img/icons/solid/cruzeiro-sign.svg";
import icon573 from "../assets/img/icons/solid/chess-knight.svg";
import icon574 from "../assets/img/icons/solid/mars-stroke-up.svg";
import icon575 from "../assets/img/icons/solid/comments-dollar.svg";
import icon576 from "../assets/img/icons/solid/spray-can.svg";
import icon577 from "../assets/img/icons/solid/user-secret.svg";
import icon578 from "../assets/img/icons/solid/person-swimming.svg";
import icon579 from "../assets/img/icons/solid/cloud-bolt.svg";
import icon580 from "../assets/img/icons/solid/head-side-cough.svg";
import icon581 from "../assets/img/icons/solid/umbrella.svg";
import icon582 from "../assets/img/icons/solid/user.svg";
import icon583 from "../assets/img/icons/solid/earth-europe.svg";
import icon584 from "../assets/img/icons/solid/fire-flame-simple.svg";
import icon585 from "../assets/img/icons/solid/cart-plus.svg";
import icon586 from "../assets/img/icons/solid/id-badge.svg";
import icon587 from "../assets/img/icons/solid/temperature-three-quarters.svg";
import icon588 from "../assets/img/icons/solid/tenge-sign.svg";
import icon589 from "../assets/img/icons/solid/network-wired.svg";
import icon590 from "../assets/img/icons/solid/cannabis.svg";
import icon591 from "../assets/img/icons/solid/gun.svg";
import icon592 from "../assets/img/icons/solid/tape.svg";
import icon593 from "../assets/img/icons/solid/hand-holding.svg";
import icon594 from "../assets/img/icons/solid/laptop-code.svg";
import icon595 from "../assets/img/icons/solid/panorama.svg";
import icon596 from "../assets/img/icons/solid/temperature-full.svg";
import icon597 from "../assets/img/icons/solid/arrow-right-to-bracket.svg";
import icon598 from "../assets/img/icons/solid/arrows-down-to-line.svg";
import icon599 from "../assets/img/icons/solid/circle.svg";
import icon600 from "../assets/img/icons/solid/temperature-high.svg";
import icon601 from "../assets/img/icons/solid/box-tissue.svg";
import icon602 from "../assets/img/icons/solid/earth-asia.svg";
import icon603 from "../assets/img/icons/solid/arrow-down-up-across-line.svg";
import icon604 from "../assets/img/icons/solid/hippo.svg";
import icon605 from "../assets/img/icons/solid/person.svg";
import icon606 from "../assets/img/icons/solid/arrow-down-up-lock.svg";
import icon607 from "../assets/img/icons/solid/table-list.svg";
import icon608 from "../assets/img/icons/solid/brazilian-real-sign.svg";
import icon609 from "../assets/img/icons/solid/house-medical-flag.svg";
import icon610 from "../assets/img/icons/solid/shop.svg";
import icon611 from "../assets/img/icons/solid/car-tunnel.svg";
import icon612 from "../assets/img/icons/solid/copyright.svg";
import icon613 from "../assets/img/icons/solid/vault.svg";
import icon614 from "../assets/img/icons/solid/backward-fast.svg";
import icon615 from "../assets/img/icons/solid/square-caret-down.svg";
import icon616 from "../assets/img/icons/solid/battery-half.svg";
import icon617 from "../assets/img/icons/solid/bolt-lightning.svg";
import icon618 from "../assets/img/icons/solid/arrows-split-up-and-left.svg";
import icon619 from "../assets/img/icons/solid/music.svg";
import icon620 from "../assets/img/icons/solid/burst.svg";
import icon621 from "../assets/img/icons/solid/wave-square.svg";
import icon622 from "../assets/img/icons/solid/sliders.svg";
import icon623 from "../assets/img/icons/solid/quote-right.svg";
import icon624 from "../assets/img/icons/solid/folder-tree.svg";
import icon625 from "../assets/img/icons/solid/chart-gantt.svg";
import icon626 from "../assets/img/icons/solid/cow.svg";
import icon627 from "../assets/img/icons/solid/user-large.svg";
import icon628 from "../assets/img/icons/solid/code-fork.svg";
import icon629 from "../assets/img/icons/solid/igloo.svg";
import icon630 from "../assets/img/icons/solid/recycle.svg";
import icon631 from "../assets/img/icons/solid/temperature-arrow-down.svg";
import icon632 from "../assets/img/icons/solid/eject.svg";
import icon633 from "../assets/img/icons/solid/cart-shopping.svg";
import icon634 from "../assets/img/icons/solid/hand-point-right.svg";
import icon635 from "../assets/img/icons/solid/archway.svg";
import icon636 from "../assets/img/icons/solid/jug-detergent.svg";
import icon637 from "../assets/img/icons/solid/clock-rotate-left.svg";
import icon638 from "../assets/img/icons/solid/earth-africa.svg";
import icon639 from "../assets/img/icons/solid/chevron-down.svg";
import icon640 from "../assets/img/icons/solid/face-laugh.svg";
import icon641 from "../assets/img/icons/solid/heading.svg";
import icon642 from "../assets/img/icons/solid/money-bill-1.svg";
import icon643 from "../assets/img/icons/solid/hill-avalanche.svg";
import icon644 from "../assets/img/icons/solid/strikethrough.svg";
import icon645 from "../assets/img/icons/solid/tents.svg";
import icon646 from "../assets/img/icons/solid/file-word.svg";
import icon647 from "../assets/img/icons/solid/square-virus.svg";
import icon648 from "../assets/img/icons/solid/photo-film.svg";
import icon649 from "../assets/img/icons/solid/i-cursor.svg";
import icon650 from "../assets/img/icons/solid/money-check.svg";
import icon651 from "../assets/img/icons/solid/shirt.svg";
import icon652 from "../assets/img/icons/solid/door-closed.svg";
import icon653 from "../assets/img/icons/solid/compact-disc.svg";
import icon654 from "../assets/img/icons/solid/mosquito.svg";
import icon655 from "../assets/img/icons/solid/tower-observation.svg";
import icon656 from "../assets/img/icons/solid/rotate.svg";
import icon657 from "../assets/img/icons/solid/mug-saucer.svg";
import icon658 from "../assets/img/icons/solid/ticket.svg";
import icon659 from "../assets/img/icons/solid/boxes-packing.svg";
import icon660 from "../assets/img/icons/solid/gas-pump.svg";
import icon661 from "../assets/img/icons/solid/building-user.svg";
import icon662 from "../assets/img/icons/solid/volume-xmark.svg";
import icon663 from "../assets/img/icons/solid/arrows-up-down.svg";
import icon664 from "../assets/img/icons/solid/weight-hanging.svg";
import icon665 from "../assets/img/icons/solid/face-grin-tongue.svg";
import icon666 from "../assets/img/icons/solid/shrimp.svg";
import icon667 from "../assets/img/icons/solid/couch.svg";
import icon668 from "../assets/img/icons/solid/church.svg";
import icon669 from "../assets/img/icons/solid/crop.svg";
import icon670 from "../assets/img/icons/solid/restroom.svg";
import icon671 from "../assets/img/icons/solid/object-group.svg";
import icon672 from "../assets/img/icons/solid/pen-fancy.svg";
import icon673 from "../assets/img/icons/solid/cookie.svg";
import icon674 from "../assets/img/icons/solid/tag.svg";
import icon675 from "../assets/img/icons/solid/user-tag.svg";
import icon676 from "../assets/img/icons/solid/anchor-circle-exclamation.svg";
import icon677 from "../assets/img/icons/solid/tablet-screen-button.svg";
import icon678 from "../assets/img/icons/solid/file-export.svg";
import icon679 from "../assets/img/icons/solid/briefcase.svg";
import icon680 from "../assets/img/icons/solid/circle-chevron-down.svg";
import icon681 from "../assets/img/icons/solid/circle-left.svg";
import icon682 from "../assets/img/icons/solid/dragon.svg";
import icon683 from "../assets/img/icons/solid/file-audio.svg";
import icon684 from "../assets/img/icons/solid/ellipsis-vertical.svg";
import icon685 from "../assets/img/icons/solid/bag-shopping.svg";
import icon686 from "../assets/img/icons/solid/hands-holding.svg";
import icon687 from "../assets/img/icons/solid/right-to-bracket.svg";
import icon688 from "../assets/img/icons/solid/champagne-glasses.svg";
import icon689 from "../assets/img/icons/solid/splotch.svg";
import icon690 from "../assets/img/icons/solid/location-pin.svg";
import icon691 from "../assets/img/icons/solid/shield-halved.svg";
import icon692 from "../assets/img/icons/solid/anchor-circle-check.svg";
import icon693 from "../assets/img/icons/solid/shower.svg";
import icon694 from "../assets/img/icons/solid/square-poll-horizontal.svg";
import icon695 from "../assets/img/icons/solid/virus-covid.svg";
import icon696 from "../assets/img/icons/solid/teeth-open.svg";
import icon697 from "../assets/img/icons/solid/road-spikes.svg";
import icon698 from "../assets/img/icons/solid/phone-volume.svg";
import icon699 from "../assets/img/icons/solid/mosquito-net.svg";
import icon700 from "../assets/img/icons/solid/file-circle-minus.svg";
import icon701 from "../assets/img/icons/solid/plane-circle-xmark.svg";
import icon702 from "../assets/img/icons/solid/person-skiing-nordic.svg";
import icon703 from "../assets/img/icons/solid/headset.svg";
import icon704 from "../assets/img/icons/solid/face-meh.svg";
import icon705 from "../assets/img/icons/solid/id-card-clip.svg";
import icon706 from "../assets/img/icons/solid/carrot.svg";
import icon707 from "../assets/img/icons/solid/prescription-bottle.svg";
import icon708 from "../assets/img/icons/solid/arrows-to-circle.svg";
import icon709 from "../assets/img/icons/solid/map.svg";
import icon710 from "../assets/img/icons/solid/inbox.svg";
import icon711 from "../assets/img/icons/solid/trowel.svg";
import icon712 from "../assets/img/icons/solid/comments.svg";
import icon713 from "../assets/img/icons/solid/align-justify.svg";
import icon714 from "../assets/img/icons/solid/anchor-lock.svg";
import icon715 from "../assets/img/icons/solid/microphone.svg";
import icon716 from "../assets/img/icons/solid/feather-pointed.svg";
import icon717 from "../assets/img/icons/solid/database.svg";
import icon718 from "../assets/img/icons/solid/calendar-day.svg";
import icon719 from "../assets/img/icons/solid/angle-down.svg";
import icon720 from "../assets/img/icons/solid/pump-soap.svg";
import icon721 from "../assets/img/icons/solid/dice-five.svg";
import icon722 from "../assets/img/icons/solid/truck-moving.svg";
import icon723 from "../assets/img/icons/solid/people-line.svg";
import icon724 from "../assets/img/icons/solid/satellite-dish.svg";
import icon725 from "../assets/img/icons/solid/text-width.svg";
import icon726 from "../assets/img/icons/solid/delete-left.svg";
import icon727 from "../assets/img/icons/solid/location-dot.svg";
import icon728 from "../assets/img/icons/solid/arrow-down-z-a.svg";
import icon729 from "../assets/img/icons/solid/person-falling-burst.svg";
import icon730 from "../assets/img/icons/solid/user-xmark.svg";
import icon731 from "../assets/img/icons/solid/share-nodes.svg";
import icon732 from "../assets/img/icons/solid/user-astronaut.svg";
import icon733 from "../assets/img/icons/solid/tornado.svg";
import icon734 from "../assets/img/icons/solid/helmet-un.svg";
import icon735 from "../assets/img/icons/solid/file-invoice-dollar.svg";
import icon736 from "../assets/img/icons/solid/square-caret-right.svg";
import icon737 from "../assets/img/icons/solid/truck-field.svg";
import icon738 from "../assets/img/icons/solid/file.svg";
import icon739 from "../assets/img/icons/solid/heart-circle-exclamation.svg";
import icon740 from "../assets/img/icons/solid/building-ngo.svg";
import icon741 from "../assets/img/icons/solid/signal.svg";
import icon742 from "../assets/img/icons/solid/trash-can-arrow-up.svg";
import icon743 from "../assets/img/icons/solid/share-from-square.svg";
import icon744 from "../assets/img/icons/solid/glass-water.svg";
import icon745 from "../assets/img/icons/solid/handshake-angle.svg";
import icon746 from "../assets/img/icons/solid/brain.svg";
import icon747 from "../assets/img/icons/solid/wheat-awn-circle-exclamation.svg";
import icon748 from "../assets/img/icons/solid/helicopter.svg";
import icon749 from "../assets/img/icons/solid/angles-down.svg";
import icon750 from "../assets/img/icons/solid/ruler-horizontal.svg";
import icon751 from "../assets/img/icons/solid/paw.svg";
import icon752 from "../assets/img/icons/solid/square-root-variable.svg";
import icon753 from "../assets/img/icons/solid/chess.svg";
import icon754 from "../assets/img/icons/solid/filter-circle-xmark.svg";
import icon755 from "../assets/img/icons/solid/voicemail.svg";
import icon756 from "../assets/img/icons/solid/trowel-bricks.svg";
import icon757 from "../assets/img/icons/solid/rug.svg";
import icon758 from "../assets/img/icons/solid/road.svg";
import icon759 from "../assets/img/icons/solid/truck-field-un.svg";
import icon760 from "../assets/img/icons/solid/text-height.svg";
import icon761 from "../assets/img/icons/solid/pen-to-square.svg";
import icon762 from "../assets/img/icons/solid/box-open.svg";
import icon763 from "../assets/img/icons/solid/terminal.svg";
import icon764 from "../assets/img/icons/solid/plant-wilt.svg";
import icon765 from "../assets/img/icons/solid/person-harassing.svg";
import icon766 from "../assets/img/icons/solid/ranking-star.svg";
import icon767 from "../assets/img/icons/solid/person-half-dress.svg";
import icon768 from "../assets/img/icons/solid/child-dress.svg";
import icon769 from "../assets/img/icons/solid/plus-minus.svg";
import icon770 from "../assets/img/icons/solid/maximize.svg";
import icon771 from "../assets/img/icons/solid/book-skull.svg";
import icon772 from "../assets/img/icons/solid/sack-dollar.svg";
import icon773 from "../assets/img/icons/solid/city.svg";
import icon774 from "../assets/img/icons/solid/comment-dollar.svg";
import icon775 from "../assets/img/icons/solid/chevron-up.svg";
import icon776 from "../assets/img/icons/solid/lira-sign.svg";
import icon777 from "../assets/img/icons/solid/square-full.svg";
import icon778 from "../assets/img/icons/solid/bridge.svg";
import icon779 from "../assets/img/icons/solid/person-skiing.svg";
import icon780 from "../assets/img/icons/solid/table-cells.svg";
import icon781 from "../assets/img/icons/solid/file-video.svg";
import icon782 from "../assets/img/icons/solid/mortar-pestle.svg";
import icon783 from "../assets/img/icons/solid/folder-open.svg";
import icon784 from "../assets/img/icons/solid/droplet.svg";
import icon785 from "../assets/img/icons/solid/plane-departure.svg";
import icon786 from "../assets/img/icons/solid/cent-sign.svg";
import icon787 from "../assets/img/icons/solid/fish-fins.svg";
import icon788 from "../assets/img/icons/solid/user-large-slash.svg";
import icon789 from "../assets/img/icons/solid/paint-roller.svg";
import icon790 from "../assets/img/icons/solid/baht-sign.svg";
import icon791 from "../assets/img/icons/solid/keyboard.svg";
import icon792 from "../assets/img/icons/solid/turn-up.svg";
import icon793 from "../assets/img/icons/solid/x.svg";
import icon794 from "../assets/img/icons/solid/9.svg";
import icon795 from "../assets/img/icons/solid/wine-glass-empty.svg";
import icon796 from "../assets/img/icons/solid/staff-snake.svg";
import icon797 from "../assets/img/icons/solid/less-than.svg";
import icon798 from "../assets/img/icons/solid/angle-up.svg";
import icon799 from "../assets/img/icons/solid/bath.svg";
import icon800 from "../assets/img/icons/solid/o.svg";
import icon801 from "../assets/img/icons/solid/record-vinyl.svg";
import icon802 from "../assets/img/icons/solid/tablet-button.svg";
import icon803 from "../assets/img/icons/solid/face-grin-squint-tears.svg";
import icon804 from "../assets/img/icons/solid/up-right-and-down-left-from-center.svg";
import icon805 from "../assets/img/icons/solid/laptop-file.svg";
import icon806 from "../assets/img/icons/solid/poo-storm.svg";
import icon807 from "../assets/img/icons/solid/question.svg";
import icon808 from "../assets/img/icons/solid/face-laugh-wink.svg";
import icon809 from "../assets/img/icons/solid/cable-car.svg";
import icon810 from "../assets/img/icons/solid/lock.svg";
import icon811 from "../assets/img/icons/solid/face-grin-wink.svg";
import icon812 from "../assets/img/icons/solid/toilets-portable.svg";
import icon813 from "../assets/img/icons/solid/face-rolling-eyes.svg";
import icon814 from "../assets/img/icons/solid/person-military-to-person.svg";
import icon815 from "../assets/img/icons/solid/x-ray.svg";
import icon816 from "../assets/img/icons/solid/fire.svg";
import icon817 from "../assets/img/icons/solid/square-person-confined.svg";
import icon818 from "../assets/img/icons/solid/ban.svg";
import icon819 from "../assets/img/icons/solid/heart-circle-check.svg";
import icon820 from "../assets/img/icons/solid/person-dress.svg";
import icon821 from "../assets/img/icons/solid/divide.svg";
import icon822 from "../assets/img/icons/solid/piggy-bank.svg";
import icon823 from "../assets/img/icons/solid/martini-glass.svg";
import icon824 from "../assets/img/icons/solid/file-contract.svg";
import icon825 from "../assets/img/icons/solid/person-circle-check.svg";
import icon826 from "../assets/img/icons/solid/square-parking.svg";
import icon827 from "../assets/img/icons/solid/sd-card.svg";
import icon828 from "../assets/img/icons/solid/plane-circle-exclamation.svg";
import icon829 from "../assets/img/icons/solid/mask-ventilator.svg";
import icon830 from "../assets/img/icons/solid/tent-arrow-down-to-line.svg";
import icon831 from "../assets/img/icons/solid/bacterium.svg";
import icon832 from "../assets/img/icons/solid/temperature-half.svg";
import icon833 from "../assets/img/icons/solid/money-bill-wheat.svg";
import icon834 from "../assets/img/icons/solid/pen.svg";
import icon835 from "../assets/img/icons/solid/user-pen.svg";
import icon836 from "../assets/img/icons/solid/arrow-up-long.svg";
import icon837 from "../assets/img/icons/solid/drumstick-bite.svg";
import icon838 from "../assets/img/icons/solid/plane.svg";
import icon839 from "../assets/img/icons/solid/chevron-right.svg";
import icon840 from "../assets/img/icons/solid/republican.svg";
import icon841 from "../assets/img/icons/solid/arrow-up-wide-short.svg";
import icon842 from "../assets/img/icons/solid/tarp-droplet.svg";
import icon843 from "../assets/img/icons/solid/clipboard.svg";
import icon844 from "../assets/img/icons/solid/mars.svg";
import icon845 from "../assets/img/icons/solid/circle-question.svg";
import icon846 from "../assets/img/icons/solid/venus-mars.svg";
import icon847 from "../assets/img/icons/solid/truck-pickup.svg";
import icon848 from "../assets/img/icons/solid/life-ring.svg";
import icon849 from "../assets/img/icons/solid/link.svg";
import icon850 from "../assets/img/icons/solid/tent-arrow-left-right.svg";
import icon851 from "../assets/img/icons/solid/certificate.svg";
import icon852 from "../assets/img/icons/solid/basket-shopping.svg";
import icon853 from "../assets/img/icons/solid/toggle-off.svg";
import icon854 from "../assets/img/icons/solid/person-circle-plus.svg";
import icon855 from "../assets/img/icons/solid/key.svg";
import icon856 from "../assets/img/icons/solid/ferry.svg";
import icon857 from "../assets/img/icons/solid/sailboat.svg";
import icon858 from "../assets/img/icons/solid/gifts.svg";
import icon859 from "../assets/img/icons/solid/code-pull-request.svg";
import icon860 from "../assets/img/icons/solid/child-reaching.svg";
import icon861 from "../assets/img/icons/solid/kitchen-set.svg";
import icon862 from "../assets/img/icons/solid/road-circle-exclamation.svg";
import icon863 from "../assets/img/icons/solid/door-open.svg";
import icon864 from "../assets/img/icons/solid/locust.svg";
import icon865 from "../assets/img/icons/solid/n.svg";
import icon866 from "../assets/img/icons/solid/check-to-slot.svg";
import icon867 from "../assets/img/icons/solid/book-quran.svg";
import icon868 from "../assets/img/icons/solid/cloud-sun.svg";
import icon869 from "../assets/img/icons/solid/arrow-right.svg";
import icon870 from "../assets/img/icons/solid/store.svg";
import icon871 from "../assets/img/icons/solid/greater-than-equal.svg";
import icon872 from "../assets/img/icons/solid/8.svg";
import icon873 from "../assets/img/icons/solid/y.svg";
import icon874 from "../assets/img/icons/solid/hamsa.svg";
import icon875 from "../assets/img/icons/solid/calendar-plus.svg";
import icon876 from "../assets/img/icons/solid/brush.svg";
import icon877 from "../assets/img/icons/solid/at.svg";
import icon878 from "../assets/img/icons/solid/clover.svg";
import icon879 from "../assets/img/icons/solid/hurricane.svg";
import icon880 from "../assets/img/icons/solid/phone-slash.svg";
import icon881 from "../assets/img/icons/solid/cubes.svg";
import icon882 from "../assets/img/icons/solid/user-ninja.svg";
import icon883 from "../assets/img/icons/solid/circle-play.svg";
import icon884 from "../assets/img/icons/solid/bowl-rice.svg";
import icon885 from "../assets/img/icons/solid/arrow-rotate-left.svg";
import icon886 from "../assets/img/icons/solid/notdef.svg";
import icon887 from "../assets/img/icons/solid/ship.svg";
import icon888 from "../assets/img/icons/solid/qrcode.svg";
import icon889 from "../assets/img/icons/solid/person-arrow-down-to-line.svg";
import icon890 from "../assets/img/icons/solid/worm.svg";
import icon891 from "../assets/img/icons/solid/notes-medical.svg";
import icon892 from "../assets/img/icons/solid/glass-water-droplet.svg";
import icon893 from "../assets/img/icons/solid/user-clock.svg";
import icon894 from "../assets/img/icons/solid/calendar-check.svg";
import icon895 from "../assets/img/icons/solid/l.svg";
import icon896 from "../assets/img/icons/solid/square-pen.svg";
import icon897 from "../assets/img/icons/solid/table-cells-large.svg";
import icon898 from "../assets/img/icons/solid/stopwatch.svg";
import icon899 from "../assets/img/icons/solid/minimize.svg";
import icon900 from "../assets/img/icons/solid/border-all.svg";
import icon901 from "../assets/img/icons/solid/arrow-up-from-water-pump.svg";
import icon902 from "../assets/img/icons/solid/yen-sign.svg";
import icon903 from "../assets/img/icons/solid/spray-can-sparkles.svg";
import icon904 from "../assets/img/icons/solid/heart-circle-plus.svg";
import icon905 from "../assets/img/icons/solid/calendar-week.svg";
import icon906 from "../assets/img/icons/solid/jet-fighter.svg";
import icon907 from "../assets/img/icons/solid/pizza-slice.svg";
import icon908 from "../assets/img/icons/solid/thumbs-down.svg";
import icon909 from "../assets/img/icons/solid/user-gear.svg";
import icon910 from "../assets/img/icons/solid/comment-medical.svg";
import icon911 from "../assets/img/icons/solid/compress.svg";
import icon912 from "../assets/img/icons/solid/arrow-down-long.svg";
import icon913 from "../assets/img/icons/solid/person-skating.svg";
import icon914 from "../assets/img/icons/solid/envelope.svg";
import icon915 from "../assets/img/icons/solid/microphone-lines-slash.svg";
import icon916 from "../assets/img/icons/solid/utensils.svg";
import icon917 from "../assets/img/icons/solid/person-booth.svg";
import icon918 from "../assets/img/icons/solid/droplet-slash.svg";
import icon919 from "../assets/img/icons/solid/colon-sign.svg";
import icon920 from "../assets/img/icons/solid/caret-up.svg";
import icon921 from "../assets/img/icons/solid/mobile-retro.svg";
import icon922 from "../assets/img/icons/solid/square-h.svg";
import icon923 from "../assets/img/icons/solid/hand-point-left.svg";
import icon924 from "../assets/img/icons/solid/building-circle-arrow-right.svg";
import icon925 from "../assets/img/icons/solid/map-location.svg";
import icon926 from "../assets/img/icons/solid/location-arrow.svg";
import icon927 from "../assets/img/icons/solid/face-grin-beam-sweat.svg";
import icon928 from "../assets/img/icons/solid/magnet.svg";
import icon929 from "../assets/img/icons/solid/school.svg";
import icon930 from "../assets/img/icons/solid/table-tennis-paddle-ball.svg";
import icon931 from "../assets/img/icons/solid/clipboard-question.svg";
import icon932 from "../assets/img/icons/solid/ruler-vertical.svg";
import icon933 from "../assets/img/icons/solid/shield.svg";
import icon934 from "../assets/img/icons/solid/desktop.svg";
import icon935 from "../assets/img/icons/solid/download.svg";
import icon936 from "../assets/img/icons/solid/helicopter-symbol.svg";
import icon937 from "../assets/img/icons/solid/hand-lizard.svg";
import icon938 from "../assets/img/icons/solid/jet-fighter-up.svg";
import icon939 from "../assets/img/icons/solid/expand.svg";
import icon940 from "../assets/img/icons/solid/building-flag.svg";
import icon941 from "../assets/img/icons/solid/bugs.svg";
import icon942 from "../assets/img/icons/solid/weight-scale.svg";
import icon943 from "../assets/img/icons/solid/arrow-up-a-z.svg";
import icon944 from "../assets/img/icons/solid/bandage.svg";
import icon945 from "../assets/img/icons/solid/khanda.svg";
import icon946 from "../assets/img/icons/solid/square-check.svg";
import icon947 from "../assets/img/icons/solid/shield-cat.svg";
import icon948 from "../assets/img/icons/solid/teeth.svg";
import icon949 from "../assets/img/icons/solid/face-frown-open.svg";
import icon950 from "../assets/img/icons/solid/hand-scissors.svg";
import icon951 from "../assets/img/icons/solid/vihara.svg";
import icon952 from "../assets/img/icons/solid/franc-sign.svg";
import icon953 from "../assets/img/icons/solid/battery-three-quarters.svg";
import icon954 from "../assets/img/icons/solid/book-open.svg";
import icon955 from "../assets/img/icons/solid/spaghetti-monster-flying.svg";
import icon956 from "../assets/img/icons/solid/hourglass-start.svg";
import icon957 from "../assets/img/icons/solid/comment-slash.svg";
import icon958 from "../assets/img/icons/solid/hand-holding-droplet.svg";
import icon959 from "../assets/img/icons/solid/jar.svg";
import icon960 from "../assets/img/icons/solid/bug-slash.svg";
import icon961 from "../assets/img/icons/solid/file-import.svg";
import icon962 from "../assets/img/icons/solid/face-meh-blank.svg";
import icon963 from "../assets/img/icons/solid/litecoin-sign.svg";
import icon964 from "../assets/img/icons/solid/disease.svg";
import icon965 from "../assets/img/icons/solid/hand-point-up.svg";
import icon966 from "../assets/img/icons/solid/server.svg";
import icon967 from "../assets/img/icons/solid/bolt.svg";
import icon968 from "../assets/img/icons/solid/tv.svg";
import icon969 from "../assets/img/icons/solid/person-praying.svg";
import icon970 from "../assets/img/icons/solid/m.svg";
import icon971 from "../assets/img/icons/solid/democrat.svg";
import icon972 from "../assets/img/icons/solid/house-medical.svg";
import icon973 from "../assets/img/icons/solid/hand-holding-medical.svg";
import icon974 from "../assets/img/icons/solid/z.svg";
import icon975 from "../assets/img/icons/solid/user-plus.svg";
import icon976 from "../assets/img/icons/solid/plug-circle-plus.svg";
import icon977 from "../assets/img/icons/solid/file-shield.svg";
import icon978 from "../assets/img/icons/solid/hands-clapping.svg";
import icon979 from "../assets/img/icons/solid/square-rss.svg";
import icon980 from "../assets/img/icons/solid/star-and-crescent.svg";
import icon981 from "../assets/img/icons/solid/shapes.svg";
import icon982 from "../assets/img/icons/solid/solar-panel.svg";
import icon983 from "../assets/img/icons/solid/mosque.svg";
import icon984 from "../assets/img/icons/solid/satellite.svg";
import icon985 from "../assets/img/icons/solid/file-circle-xmark.svg";
import icon986 from "../assets/img/icons/solid/arrow-down-9-1.svg";
import icon987 from "../assets/img/icons/solid/i.svg";
import icon988 from "../assets/img/icons/solid/indent.svg";
import icon989 from "../assets/img/icons/solid/circle-arrow-left.svg";
import icon990 from "../assets/img/icons/solid/link-slash.svg";
import icon991 from "../assets/img/icons/solid/cloud-arrow-up.svg";
import icon992 from "../assets/img/icons/solid/users-rectangle.svg";
import icon993 from "../assets/img/icons/solid/dna.svg";
import icon994 from "../assets/img/icons/solid/slash.svg";
import icon995 from "../assets/img/icons/solid/hands-holding-child.svg";
import icon996 from "../assets/img/icons/solid/radio.svg";
import icon997 from "../assets/img/icons/solid/taxi.svg";
import icon998 from "../assets/img/icons/solid/book.svg";
import icon999 from "../assets/img/icons/solid/camera-rotate.svg";
import icon1000 from "../assets/img/icons/solid/filter-circle-dollar.svg";
import icon1001 from "../assets/img/icons/solid/hotdog.svg";
import icon1002 from "../assets/img/icons/solid/house-medical-circle-xmark.svg";
import icon1003 from "../assets/img/icons/solid/hotel.svg";
import icon1004 from "../assets/img/icons/solid/comment-sms.svg";
import icon1005 from "../assets/img/icons/solid/plane-slash.svg";
import icon1006 from "../assets/img/icons/solid/object-ungroup.svg";
import icon1007 from "../assets/img/icons/solid/skull.svg";
import icon1008 from "../assets/img/icons/solid/paragraph.svg";
import icon1009 from "../assets/img/icons/solid/plug.svg";
import icon1010 from "../assets/img/icons/solid/road-circle-check.svg";
import icon1011 from "../assets/img/icons/solid/train.svg";
import icon1012 from "../assets/img/icons/solid/house-circle-check.svg";
import icon1013 from "../assets/img/icons/solid/chess-board.svg";
import icon1014 from "../assets/img/icons/solid/user-minus.svg";
import icon1015 from "../assets/img/icons/solid/wallet.svg";
import icon1016 from "../assets/img/icons/solid/person-shelter.svg";
import icon1017 from "../assets/img/icons/solid/circle-check.svg";
import icon1018 from "../assets/img/icons/solid/house-chimney-user.svg";
import icon1019 from "../assets/img/icons/solid/file-image.svg";
import icon1020 from "../assets/img/icons/solid/building-circle-check.svg";
import icon1021 from "../assets/img/icons/solid/bell.svg";
import icon1022 from "../assets/img/icons/solid/earth-oceania.svg";
import icon1023 from "../assets/img/icons/solid/arrow-down-1-9.svg";
import icon1024 from "../assets/img/icons/solid/code.svg";
import icon1025 from "../assets/img/icons/solid/dolly.svg";
import icon1026 from "../assets/img/icons/solid/user-lock.svg";
import icon1027 from "../assets/img/icons/solid/oil-well.svg";
import icon1028 from "../assets/img/icons/solid/turn-down.svg";
import icon1029 from "../assets/img/icons/solid/truck-plane.svg";
import icon1030 from "../assets/img/icons/solid/cloud-moon-rain.svg";
import icon1031 from "../assets/img/icons/solid/users-between-lines.svg";
import icon1032 from "../assets/img/icons/solid/thermometer.svg";
import icon1033 from "../assets/img/icons/solid/spider.svg";
import icon1034 from "../assets/img/icons/solid/gears.svg";
import icon1035 from "../assets/img/icons/solid/hands-bubbles.svg";
import icon1036 from "../assets/img/icons/solid/hand-sparkles.svg";
import icon1037 from "../assets/img/icons/solid/flag.svg";
import icon1038 from "../assets/img/icons/solid/gauge-high.svg";
import icon1039 from "../assets/img/icons/solid/magnifying-glass-minus.svg";
import icon1040 from "../assets/img/icons/solid/podcast.svg";
import icon1041 from "../assets/img/icons/solid/hand-peace.svg";
import icon1042 from "../assets/img/icons/solid/people-roof.svg";
import icon1043 from "../assets/img/icons/solid/up-right-from-square.svg";
import icon1044 from "../assets/img/icons/solid/chess-king.svg";
import icon1045 from "../assets/img/icons/solid/stop.svg";
import icon1046 from "../assets/img/icons/solid/newspaper.svg";
import icon1047 from "../assets/img/icons/solid/snowflake.svg";
import icon1048 from "../assets/img/icons/solid/cube.svg";
import icon1049 from "../assets/img/icons/solid/bomb.svg";
import icon1050 from "../assets/img/icons/solid/tower-broadcast.svg";
import icon1051 from "../assets/img/icons/solid/file-medical.svg";
import icon1052 from "../assets/img/icons/solid/crop-simple.svg";
import icon1053 from "../assets/img/icons/solid/right-long.svg";
import icon1054 from "../assets/img/icons/solid/plane-lock.svg";
import icon1055 from "../assets/img/icons/solid/clipboard-user.svg";
import icon1056 from "../assets/img/icons/solid/h.svg";
import icon1057 from "../assets/img/icons/solid/star-half.svg";
import icon1058 from "../assets/img/icons/solid/bridge-lock.svg";
import icon1059 from "../assets/img/icons/solid/house-chimney.svg";
import icon1060 from "../assets/img/icons/solid/arrows-up-to-line.svg";
import icon1061 from "../assets/img/icons/solid/ruler.svg";
import icon1062 from "../assets/img/icons/solid/truck-monster.svg";
import icon1063 from "../assets/img/icons/solid/money-bill-trend-up.svg";
import icon1064 from "../assets/img/icons/solid/building-lock.svg";
import icon1065 from "../assets/img/icons/solid/outdent.svg";
import icon1066 from "../assets/img/icons/solid/person-pregnant.svg";
import icon1067 from "../assets/img/icons/solid/clipboard-list.svg";
import icon1068 from "../assets/img/icons/solid/fax.svg";
import icon1069 from "../assets/img/icons/solid/biohazard.svg";
import icon1070 from "../assets/img/icons/solid/money-check-dollar.svg";
import icon1071 from "../assets/img/icons/solid/j.svg";
import icon1072 from "../assets/img/icons/solid/diagram-predecessor.svg";
import icon1073 from "../assets/img/icons/solid/caret-right.svg";
import icon1074 from "../assets/img/icons/solid/user-graduate.svg";
import icon1075 from "../assets/img/icons/solid/smog.svg";
import icon1076 from "../assets/img/icons/solid/bold.svg";
import icon1077 from "../assets/img/icons/solid/square-share-nodes.svg";
import icon1078 from "../assets/img/icons/solid/temperature-empty.svg";
import icon1079 from "../assets/img/icons/solid/bridge-circle-xmark.svg";
import icon1080 from "../assets/img/icons/solid/arrows-to-eye.svg";
import icon1081 from "../assets/img/icons/solid/tablets.svg";
import icon1082 from "../assets/img/icons/solid/bridge-water.svg";
import icon1083 from "../assets/img/icons/solid/envelope-open.svg";
import icon1084 from "../assets/img/icons/solid/trophy.svg";
import icon1085 from "../assets/img/icons/solid/battery-full.svg";
import icon1086 from "../assets/img/icons/solid/equals.svg";
import icon1087 from "../assets/img/icons/solid/arrow-up-from-ground-water.svg";
import icon1088 from "../assets/img/icons/solid/plus.svg";
import icon1089 from "../assets/img/icons/solid/wand-magic.svg";
import icon1090 from "../assets/img/icons/solid/scale-balanced.svg";
import icon1091 from "../assets/img/icons/solid/check.svg";
import icon1092 from "../assets/img/icons/solid/seedling.svg";
import icon1093 from "../assets/img/icons/solid/face-frown.svg";
import icon1094 from "../assets/img/icons/solid/left-long.svg";
import icon1095 from "../assets/img/icons/solid/star-of-david.svg";
import icon1096 from "../assets/img/icons/solid/ruler-combined.svg";
import icon1097 from "../assets/img/icons/solid/beer-mug-empty.svg";
import icon1098 from "../assets/img/icons/solid/heart-crack.svg";
import icon1099 from "../assets/img/icons/solid/hard-drive.svg";
import icon1100 from "../assets/img/icons/solid/tent.svg";
import icon1101 from "../assets/img/icons/solid/trademark.svg";
import icon1102 from "../assets/img/icons/solid/kaaba.svg";
import icon1103 from "../assets/img/icons/solid/bridge-circle-exclamation.svg";
import icon1104 from "../assets/img/icons/solid/smoking.svg";
import icon1105 from "../assets/img/icons/solid/scale-unbalanced-flip.svg";
import icon1106 from "../assets/img/icons/solid/plug-circle-bolt.svg";
import icon1107 from "../assets/img/icons/solid/hospital.svg";
import icon1108 from "../assets/img/icons/solid/arrow-right-to-city.svg";
import icon1109 from "../assets/img/icons/solid/flask.svg";
import icon1110 from "../assets/img/icons/solid/headphones.svg";
import icon1111 from "../assets/img/icons/solid/hand-holding-hand.svg";
import icon1112 from "../assets/img/icons/solid/people-carry-box.svg";
import icon1113 from "../assets/img/icons/solid/arrow-right-arrow-left.svg";
import icon1114 from "../assets/img/icons/solid/horse-head.svg";
import icon1115 from "../assets/img/icons/solid/mobile-button.svg";
import icon1116 from "../assets/img/icons/solid/rss.svg";
import icon1117 from "../assets/img/icons/solid/horse.svg";
import icon1118 from "../assets/img/icons/solid/person-walking-arrow-loop-left.svg";
import icon1119 from "../assets/img/icons/solid/landmark.svg";
import icon1120 from "../assets/img/icons/solid/wifi.svg";
import icon1121 from "../assets/img/icons/solid/arrow-right-long.svg";
import icon1122 from "../assets/img/icons/solid/face-grin-tongue-squint.svg";
import icon1123 from "../assets/img/icons/solid/heart-circle-bolt.svg";
import icon1124 from "../assets/img/icons/solid/shield-heart.svg";
import icon1125 from "../assets/img/icons/solid/building-un.svg";
import icon1126 from "../assets/img/icons/solid/face-smile-wink.svg";
import icon1127 from "../assets/img/icons/solid/person-through-window.svg";
import icon1128 from "../assets/img/icons/solid/angle-right.svg";
import icon1129 from "../assets/img/icons/solid/peso-sign.svg";
import icon1130 from "../assets/img/icons/solid/venus.svg";
import icon1131 from "../assets/img/icons/solid/mug-hot.svg";
import icon1132 from "../assets/img/icons/solid/info.svg";
import icon1133 from "../assets/img/icons/solid/face-dizzy.svg";
import icon1134 from "../assets/img/icons/solid/bowl-food.svg";
import icon1135 from "../assets/img/icons/solid/school-circle-xmark.svg";
import icon1136 from "../assets/img/icons/solid/mound.svg";
import icon1137 from "../assets/img/icons/solid/bullhorn.svg";
import icon1138 from "../assets/img/icons/solid/arrows-turn-to-dots.svg";
import icon1139 from "../assets/img/icons/solid/bread-slice.svg";
import icon1140 from "../assets/img/icons/solid/dog.svg";
import icon1141 from "../assets/img/icons/solid/user-nurse.svg";
import icon1142 from "../assets/img/icons/solid/fan.svg";
import icon1143 from "../assets/img/icons/solid/palette.svg";
import icon1144 from "../assets/img/icons/solid/spoon.svg";
import icon1145 from "../assets/img/icons/solid/k.svg";
import icon1146 from "../assets/img/icons/solid/cart-arrow-down.svg";
import icon1147 from "../assets/img/icons/solid/gear.svg";
import icon1148 from "../assets/img/icons/solid/truck-fast.svg";
import icon1149 from "../assets/img/icons/solid/om.svg";
import icon1150 from "../assets/img/icons/solid/hourglass-half.svg";
import icon1151 from "../assets/img/icons/solid/lungs-virus.svg";
import icon1152 from "../assets/img/icons/solid/manat-sign.svg";
import icon1153 from "../assets/img/icons/solid/face-sad-tear.svg";
import icon1154 from "../assets/img/icons/solid/holly-berry.svg";
import icon1155 from "../assets/img/icons/solid/volleyball.svg";
import icon1156 from "../assets/img/icons/solid/code-commit.svg";
import icon1157 from "../assets/img/icons/solid/prescription-bottle-medical.svg";
import icon1158 from "../assets/img/icons/solid/flag-usa.svg";
import icon1159 from "../assets/img/icons/solid/handcuffs.svg";
import icon1160 from "../assets/img/icons/solid/plug-circle-minus.svg";
import icon1161 from "../assets/img/icons/solid/kip-sign.svg";
import icon1162 from "../assets/img/icons/solid/arrows-up-down-left-right.svg";
import icon1163 from "../assets/img/icons/solid/f.svg";
import icon1164 from "../assets/img/icons/solid/q.svg";
import icon1165 from "../assets/img/icons/solid/0.svg";
import icon1166 from "../assets/img/icons/solid/cubes-stacked.svg";
import icon1167 from "../assets/img/icons/solid/folder-plus.svg";
import icon1168 from "../assets/img/icons/solid/pen-ruler.svg";
import icon1169 from "../assets/img/icons/solid/hat-cowboy.svg";
import icon1170 from "../assets/img/icons/solid/sign-hanging.svg";
import icon1171 from "../assets/img/icons/solid/calendar-xmark.svg";
import icon1172 from "../assets/img/icons/solid/virus-slash.svg";
import icon1173 from "../assets/img/icons/solid/user-doctor.svg";
import icon1174 from "../assets/img/icons/solid/hat-cowboy-side.svg";
import icon1175 from "../assets/img/icons/solid/rainbow.svg";
import icon1176 from "../assets/img/icons/solid/cookie-bite.svg";
import icon1177 from "../assets/img/icons/solid/sheet-plastic.svg";
import icon1178 from "../assets/img/icons/solid/suitcase-medical.svg";
import icon1179 from "../assets/img/icons/solid/copy.svg";
import icon1180 from "../assets/img/icons/solid/masks-theater.svg";
import icon1181 from "../assets/img/icons/solid/fish.svg";
import icon1182 from "../assets/img/icons/solid/martini-glass-citrus.svg";
import icon1183 from "../assets/img/icons/solid/circle-chevron-up.svg";
import icon1184 from "../assets/img/icons/solid/file-lines.svg";
import icon1185 from "../assets/img/icons/solid/signature.svg";
import icon1186 from "../assets/img/icons/solid/not-equal.svg";
import icon1187 from "../assets/img/icons/solid/burger.svg";
import icon1188 from "../assets/img/icons/solid/square-poll-vertical.svg";
import icon1189 from "../assets/img/icons/solid/glasses.svg";
import icon1190 from "../assets/img/icons/solid/coins.svg";
import icon1191 from "../assets/img/icons/solid/closed-captioning.svg";
import icon1192 from "../assets/img/icons/solid/sink.svg";
import icon1193 from "../assets/img/icons/solid/monument.svg";
import icon1194 from "../assets/img/icons/solid/hat-wizard.svg";
import icon1195 from "../assets/img/icons/solid/person-hiking.svg";
import icon1196 from "../assets/img/icons/solid/unlock-keyhole.svg";
import icon1197 from "../assets/img/icons/solid/arrow-right-from-bracket.svg";
import icon1198 from "../assets/img/icons/solid/less-than-equal.svg";
import icon1199 from "../assets/img/icons/solid/xmarks-lines.svg";
import icon1200 from "../assets/img/icons/solid/code-compare.svg";
import icon1201 from "../assets/img/icons/solid/wand-sparkles.svg";
import icon1202 from "../assets/img/icons/solid/caravan.svg";
import icon1203 from "../assets/img/icons/solid/industry.svg";
import icon1204 from "../assets/img/icons/solid/square-xmark.svg";
import icon1205 from "../assets/img/icons/solid/child.svg";
import icon1206 from "../assets/img/icons/solid/font-awesome.svg";
import icon1207 from "../assets/img/icons/solid/fire-extinguisher.svg";
import icon1208 from "../assets/img/icons/solid/marker.svg";
import icon1209 from "../assets/img/icons/solid/align-right.svg";
import icon1210 from "../assets/img/icons/solid/image.svg";
import icon1211 from "../assets/img/icons/solid/hand-point-down.svg";
import icon1212 from "../assets/img/icons/solid/vector-square.svg";
import icon1213 from "../assets/img/icons/solid/lightbulb.svg";
import icon1214 from "../assets/img/icons/solid/person-circle-question.svg";
import icon1215 from "../assets/img/icons/solid/face-grin-stars.svg";
import icon1216 from "../assets/img/icons/solid/images.svg";
import icon1217 from "../assets/img/icons/solid/house-chimney-crack.svg";
import icon1218 from "../assets/img/icons/solid/pager.svg";
import icon1219 from "../assets/img/icons/solid/turkish-lira-sign.svg";
import icon1220 from "../assets/img/icons/solid/shield-virus.svg";
import icon1221 from "../assets/img/icons/solid/bus-simple.svg";
import icon1222 from "../assets/img/icons/solid/mountain-sun.svg";
import icon1223 from "../assets/img/icons/solid/landmark-flag.svg";
import icon1224 from "../assets/img/icons/solid/magnifying-glass-chart.svg";
import icon1225 from "../assets/img/icons/solid/1.svg";
import icon1226 from "../assets/img/icons/solid/users-rays.svg";
import icon1227 from "../assets/img/icons/solid/p.svg";
import icon1228 from "../assets/img/icons/solid/rectangle-xmark.svg";
import icon1229 from "../assets/img/icons/solid/face-grin-squint.svg";
import icon1230 from "../assets/img/icons/solid/circle-up.svg";
import icon1231 from "../assets/img/icons/solid/code-merge.svg";
import icon1232 from "../assets/img/icons/solid/g.svg";
import icon1233 from "../assets/img/icons/solid/code-branch.svg";
import icon1234 from "../assets/img/icons/solid/house-chimney-medical.svg";
import icon1235 from "../assets/img/icons/solid/vr-cardboard.svg";
import icon1236 from "../assets/img/icons/solid/eye-dropper.svg";
import icon1237 from "../assets/img/icons/solid/envelopes-bulk.svg";
import icon1238 from "../assets/img/icons/solid/person-biking.svg";
import icon1239 from "../assets/img/icons/solid/arrows-left-right-to-line.svg";
import icon1240 from "../assets/img/icons/solid/dice-d6.svg";
import icon1241 from "../assets/img/icons/solid/person-rays.svg";
import icon1242 from "../assets/img/icons/solid/gauge-simple.svg";
import icon1243 from "../assets/img/icons/solid/tachograph-digital.svg";
import icon1244 from "../assets/img/icons/solid/e.svg";
import icon1245 from "../assets/img/icons/solid/paperclip.svg";
import icon1246 from "../assets/img/icons/solid/handshake-simple-slash.svg";
import icon1247 from "../assets/img/icons/solid/hand-back-fist.svg";
import icon1248 from "../assets/img/icons/solid/computer-mouse.svg";
import icon1249 from "../assets/img/icons/solid/r.svg";
import icon1250 from "../assets/img/icons/solid/3.svg";
import icon1251 from "../assets/img/icons/solid/backward-step.svg";
import icon1252 from "../assets/img/icons/solid/location-crosshairs.svg";
import icon1253 from "../assets/img/icons/solid/square-up-right.svg";
import icon1254 from "../assets/img/icons/solid/street-view.svg";
import icon1255 from "../assets/img/icons/solid/location-pin-lock.svg";
import icon1256 from "../assets/img/icons/solid/temperature-quarter.svg";
import icon1257 from "../assets/img/icons/solid/trailer.svg";
import icon1258 from "../assets/img/icons/solid/syringe.svg";
import icon1259 from "../assets/img/icons/solid/trash-arrow-up.svg";
import icon1260 from "../assets/img/icons/solid/message.svg";
import icon1261 from "../assets/img/icons/solid/car-battery.svg";
import icon1262 from "../assets/img/icons/solid/award.svg";
import icon1263 from "../assets/img/icons/solid/toilet-portable.svg";
import icon1264 from "../assets/img/icons/solid/baby-carriage.svg";
import icon1265 from "../assets/img/icons/solid/box.svg";
import icon1266 from "../assets/img/icons/solid/thumbs-up.svg";
import icon1267 from "../assets/img/icons/solid/superscript.svg";
import icon1268 from "../assets/img/icons/solid/person-circle-xmark.svg";
import icon1269 from "../assets/img/icons/solid/mask.svg";
import icon1270 from "../assets/img/icons/solid/peace.svg";
import icon1271 from "../assets/img/icons/solid/percent.svg";
import icon1272 from "../assets/img/icons/solid/heart-circle-xmark.svg";
import icon1273 from "../assets/img/icons/solid/guarani-sign.svg";
import icon1274 from "../assets/img/icons/solid/face-smile.svg";
import icon1275 from "../assets/img/icons/solid/dong-sign.svg";
import icon1276 from "../assets/img/icons/solid/tree-city.svg";
import icon1277 from "../assets/img/icons/solid/square.svg";
import icon1278 from "../assets/img/icons/solid/crown.svg";
import icon1279 from "../assets/img/icons/solid/broom.svg";
import icon1280 from "../assets/img/icons/solid/stethoscope.svg";
import icon1281 from "../assets/img/icons/solid/virus-covid-slash.svg";
import icon1282 from "../assets/img/icons/solid/book-atlas.svg";
import icon1283 from "../assets/img/icons/solid/eye-slash.svg";
import icon1284 from "../assets/img/icons/solid/candy-cane.svg";
import icon1285 from "../assets/img/icons/solid/play.svg";
import icon1286 from "../assets/img/icons/solid/backward.svg";
import icon1287 from "../assets/img/icons/solid/paper-plane.svg";
import icon1288 from "../assets/img/icons/solid/hands-praying.svg";
import icon1289 from "../assets/img/icons/solid/cake-candles.svg";
import icon1290 from "../assets/img/icons/solid/greater-than.svg";
import icon1291 from "../assets/img/icons/solid/face-grin-hearts.svg";
import icon1292 from "../assets/img/icons/solid/users-viewfinder.svg";
import icon1293 from "../assets/img/icons/solid/explosion.svg";
import icon1294 from "../assets/img/icons/solid/martini-glass-empty.svg";
import icon1295 from "../assets/img/icons/solid/dungeon.svg";
import icon1296 from "../assets/img/icons/solid/helmet-safety.svg";
import icon1297 from "../assets/img/icons/solid/table.svg";
import icon1298 from "../assets/img/icons/solid/person-falling.svg";
import icon1299 from "../assets/img/icons/solid/barcode.svg";
import icon1300 from "../assets/img/icons/solid/thumbtack.svg";
import icon1301 from "../assets/img/icons/solid/temperature-arrow-up.svg";
import icon1302 from "../assets/img/icons/solid/house-fire.svg";
import icon1303 from "../assets/img/icons/solid/hourglass-end.svg";
import icon1304 from "../assets/img/icons/solid/scroll.svg";
import icon1305 from "../assets/img/icons/solid/flag-checkered.svg";
import icon1306 from "../assets/img/icons/solid/mars-stroke-right.svg";
import icon1307 from "../assets/img/icons/solid/people-pulling.svg";
import icon1308 from "../assets/img/icons/solid/box-archive.svg";
import icon1309 from "../assets/img/icons/solid/cloud-showers-water.svg";
import icon1310 from "../assets/img/icons/solid/chalkboard.svg";
import icon1311 from "../assets/img/icons/solid/indian-rupee-sign.svg";
import icon1312 from "../assets/img/icons/solid/radiation.svg";
import icon1313 from "../assets/img/icons/solid/house-lock.svg";
import icon1314 from "../assets/img/icons/solid/plane-up.svg";
import icon1315 from "../assets/img/icons/solid/baby.svg";
import icon1316 from "../assets/img/icons/solid/2.svg";
import icon1317 from "../assets/img/icons/solid/s.svg";
import icon1318 from "../assets/img/icons/solid/shekel-sign.svg";
import icon1319 from "../assets/img/icons/solid/d.svg";
import icon1320 from "../assets/img/icons/solid/battery-empty.svg";
import icon1321 from "../assets/img/icons/solid/star-of-life.svg";
import icon1322 from "../assets/img/icons/solid/camera.svg";
import icon1323 from "../assets/img/icons/solid/square-phone-flip.svg";
import icon1324 from "../assets/img/icons/solid/baseball-bat-ball.svg";
import icon1325 from "../assets/img/icons/solid/circle-right.svg";
import icon1326 from "../assets/img/icons/solid/users-slash.svg";
import icon1327 from "../assets/img/icons/solid/floppy-disk.svg";
import icon1328 from "../assets/img/icons/solid/circle-nodes.svg";
import icon1329 from "../assets/img/icons/solid/file-powerpoint.svg";
import icon1330 from "../assets/img/icons/solid/folder-minus.svg";
import icon1331 from "../assets/img/icons/solid/otter.svg";
import icon1332 from "../assets/img/icons/solid/sun-plant-wilt.svg";
import icon1333 from "../assets/img/icons/solid/sim-card.svg";
import icon1334 from "../assets/img/icons/solid/truck.svg";
import icon1335 from "../assets/img/icons/solid/book-open-reader.svg";
import icon1336 from "../assets/img/icons/solid/note-sticky.svg";
import icon1337 from "../assets/img/icons/solid/fire-flame-curved.svg";
import icon1338 from "../assets/img/icons/solid/oil-can.svg";
import icon1339 from "../assets/img/icons/solid/w.svg";
import icon1340 from "../assets/img/icons/solid/6.svg";
import icon1341 from "../assets/img/icons/solid/fire-burner.svg";
import icon1342 from "../assets/img/icons/solid/blender.svg";
import icon1343 from "../assets/img/icons/solid/bridge-circle-check.svg";
import icon1344 from "../assets/img/icons/solid/person-cane.svg";
import icon1345 from "../assets/img/icons/solid/magnifying-glass-dollar.svg";
import icon1346 from "../assets/img/icons/solid/bottle-water.svg";
import icon1347 from "../assets/img/icons/solid/wine-glass.svg";
import icon1348 from "../assets/img/icons/solid/hand-spock.svg";
import icon1349 from "../assets/img/icons/solid/hands-holding-circle.svg";
import icon1350 from "../assets/img/icons/solid/hand-holding-dollar.svg";
import icon1351 from "../assets/img/icons/solid/faucet-drip.svg";
import icon1352 from "../assets/img/icons/solid/arrow-up-right-from-square.svg";
import icon1353 from "../assets/img/icons/solid/circle-arrow-right.svg";
import icon1354 from "../assets/img/icons/solid/toilet-paper.svg";
import icon1355 from "../assets/img/icons/solid/magnifying-glass-location.svg";
import icon1356 from "../assets/img/icons/solid/angles-right.svg";
import icon1357 from "../assets/img/icons/solid/bucket.svg";
import icon1358 from "../assets/img/icons/solid/circle-info.svg";
import icon1359 from "../assets/img/icons/solid/head-side-virus.svg";
import icon1360 from "../assets/img/icons/solid/egg.svg";
import icon1361 from "../assets/img/icons/solid/up-long.svg";
import icon1362 from "../assets/img/icons/solid/scroll-torah.svg";
import icon1363 from "../assets/img/icons/solid/hand-fist.svg";
import icon1364 from "../assets/img/icons/solid/circle-radiation.svg";
import icon1365 from "../assets/img/icons/solid/bullseye.svg";
import icon1366 from "../assets/img/icons/solid/bus.svg";
import icon1367 from "../assets/img/icons/solid/person-dress-burst.svg";
import icon1368 from "../assets/img/icons/solid/mercury.svg";
import icon1369 from "../assets/img/icons/solid/infinity.svg";
import icon1370 from "../assets/img/icons/solid/xmark.svg";
import icon1371 from "../assets/img/icons/solid/bacteria.svg";
import icon1372 from "../assets/img/icons/solid/gem.svg";
import icon1373 from "../assets/img/icons/solid/table-columns.svg";
import icon1374 from "../assets/img/icons/solid/audio-description.svg";
import icon1375 from "../assets/img/icons/solid/skull-crossbones.svg";
import icon1376 from "../assets/img/icons/solid/feather.svg";
import icon1377 from "../assets/img/icons/solid/trash.svg";
import icon1378 from "../assets/img/icons/solid/chart-bar.svg";
import icon1379 from "../assets/img/icons/solid/transgender.svg";
import icon1380 from "../assets/img/icons/solid/person-running.svg";
import icon1381 from "../assets/img/icons/solid/dumpster-fire.svg";
import icon1382 from "../assets/img/icons/solid/toilet.svg";
import icon1383 from "../assets/img/icons/solid/leaf.svg";
import icon1384 from "../assets/img/icons/solid/paste.svg";
import icon1385 from "../assets/img/icons/solid/window-maximize.svg";
import icon1386 from "../assets/img/icons/solid/magnifying-glass-plus.svg";
import icon1387 from "../assets/img/icons/solid/arrow-up-z-a.svg";
import icon1388 from "../assets/img/icons/solid/gopuram.svg";
import icon1389 from "../assets/img/icons/solid/sterling-sign.svg";
import icon1390 from "../assets/img/icons/solid/address-book.svg";
import icon1391 from "../assets/img/icons/solid/memory.svg";
import icon1392 from "../assets/img/icons/solid/stairs.svg";
import icon1393 from "../assets/img/icons/solid/dollar-sign.svg";
import icon1394 from "../assets/img/icons/solid/truck-front.svg";
import icon1395 from "../assets/img/icons/solid/circle-plus.svg";
import icon1396 from "../assets/img/icons/solid/arrow-turn-up.svg";
import icon1397 from "../assets/img/icons/solid/graduation-cap.svg";
import icon1398 from "../assets/img/icons/solid/kiwi-bird.svg";
import icon1399 from "../assets/img/icons/solid/train-tram.svg";
import icon1400 from "../assets/img/icons/solid/house-tsunami.svg";
import icon1401 from "../assets/img/icons/solid/fill-drip.svg";
import icon1402 from "../assets/img/icons/solid/hashtag.svg";
import icon1403 from "../assets/img/icons/solid/rectangle-list.svg";
import icon1404 from "../assets/img/icons/solid/guitar.svg";
import icon1405 from "../assets/img/icons/solid/bong.svg";
import icon1406 from "../assets/img/icons/solid/star.svg";
import icon1407 from "../assets/img/icons/solid/border-top-left.svg";
import icon1408 from "../assets/img/icons/solid/a.svg";
import icon1409 from "../assets/img/icons/solid/school-circle-check.svg";
import icon1410 from "../assets/img/icons/solid/person-military-rifle.svg";
import icon1411 from "../assets/img/icons/solid/7.svg";
import icon1412 from "../assets/img/icons/solid/euro-sign.svg";
import icon1413 from "../assets/img/icons/solid/v.svg";
import icon1414 from "../assets/img/icons/solid/sun.svg";
import icon1415 from "../assets/img/icons/solid/wrench.svg";
import icon1416 from "../assets/img/icons/solid/lines-leaning.svg";
import icon1417 from "../assets/img/icons/solid/arrow-up-from-bracket.svg";
import icon1418 from "../assets/img/icons/solid/gauge.svg";
import icon1419 from "../assets/img/icons/solid/house-medical-circle-check.svg";
import icon1420 from "../assets/img/icons/solid/circle-notch.svg";
import icon1421 from "../assets/img/icons/solid/chart-column.svg";
import icon1422 from "../assets/img/icons/solid/van-shuttle.svg";
import icon1423 from "../assets/img/icons/solid/shoe-prints.svg";
import icon1424 from "../assets/img/icons/solid/t.svg";
import icon1425 from "../assets/img/icons/solid/5.svg";
import icon1426 from "../assets/img/icons/solid/c.svg";
import icon1427 from "../assets/img/icons/solid/soap.svg";
import icon1428 from "../assets/img/icons/solid/bacon.svg";
import icon1429 from "../assets/img/icons/solid/rectangle-ad.svg";
import icon1430 from "../assets/img/icons/solid/plane-arrival.svg";
import icon1431 from "../assets/img/icons/solid/bone.svg";
import icon1432 from "../assets/img/icons/solid/florin-sign.svg";
import icon1433 from "../assets/img/icons/solid/book-journal-whills.svg";
import icon1434 from "../assets/img/icons/solid/crutch.svg";
import icon1435 from "../assets/img/icons/solid/anchor.svg";
import icon1436 from "../assets/img/icons/solid/jedi.svg";
import icon1437 from "../assets/img/icons/solid/group-arrows-rotate.svg";
import icon1438 from "../assets/img/icons/solid/head-side-cough-slash.svg";
import icon1439 from "../assets/img/icons/solid/hammer.svg";
import icon1440 from "../assets/img/icons/solid/highlighter.svg";
import icon1441 from "../assets/img/icons/solid/water-ladder.svg";
import icon1442 from "../assets/img/icons/solid/square-arrow-up-right.svg";
import icon1443 from "../assets/img/icons/solid/file-circle-check.svg";
import icon1444 from "../assets/img/icons/solid/head-side-mask.svg";
import icon1445 from "../assets/img/icons/solid/address-card.svg";
import icon1446 from "../assets/img/icons/solid/wheelchair.svg";
import icon1447 from "../assets/img/icons/solid/scale-unbalanced.svg";
import icon1448 from "../assets/img/icons/solid/circle-stop.svg";
import icon1449 from "../assets/img/icons/solid/chart-pie.svg";
import icon1450 from "../assets/img/icons/solid/boxes-stacked.svg";
import icon1451 from "../assets/img/icons/solid/arrows-left-right.svg";
import icon1452 from "../assets/img/icons/solid/cloud-arrow-down.svg";
import icon1453 from "../assets/img/icons/solid/broom-ball.svg";
import icon1454 from "../assets/img/icons/solid/magnifying-glass-arrow-right.svg";
import icon1455 from "../assets/img/icons/solid/wand-magic-sparkles.svg";
import icon1456 from "../assets/img/icons/solid/circle-xmark.svg";
import icon1457 from "../assets/img/icons/solid/lemon.svg";
import icon1458 from "../assets/img/icons/solid/house-flag.svg";
import icon1459 from "../assets/img/icons/solid/building-circle-xmark.svg";
import icon1460 from "../assets/img/icons/solid/place-of-worship.svg";
import icon1461 from "../assets/img/icons/solid/road-circle-xmark.svg";
import icon1462 from "../assets/img/icons/solid/house-circle-xmark.svg";
import icon1463 from "../assets/img/icons/solid/file-circle-question.svg";
import icon1464 from "../assets/img/icons/solid/bug.svg";
import icon1465 from "../assets/img/icons/solid/volume-high.svg";
import icon1466 from "../assets/img/icons/solid/road-barrier.svg";
import icon1467 from "../assets/img/icons/solid/cedi-sign.svg";
import icon1468 from "../assets/img/icons/solid/spinner.svg";
import icon1469 from "../assets/img/icons/solid/lari-sign.svg";
import icon1470 from "../assets/img/icons/solid/unlock.svg";
import icon1471 from "../assets/img/icons/solid/jar-wheat.svg";
import icon1472 from "../assets/img/icons/solid/wheelchair-move.svg";
import icon1473 from "../assets/img/icons/solid/person-military-pointing.svg";
import icon1474 from "../assets/img/icons/solid/file-arrow-down.svg";
import icon1475 from "../assets/img/icons/solid/hand-holding-heart.svg";
import icon1476 from "../assets/img/icons/solid/compass.svg";
import icon1477 from "../assets/img/icons/solid/meteor.svg";
import icon1478 from "../assets/img/icons/solid/faucet.svg";
import icon1479 from "../assets/img/icons/solid/face-surprise.svg";
import icon1480 from "../assets/img/icons/solid/traffic-light.svg";
import icon1481 from "../assets/img/icons/solid/bicycle.svg";
import icon1482 from "../assets/img/icons/solid/person-dots-from-line.svg";
import icon1483 from "../assets/img/icons/solid/menorah.svg";
import icon1484 from "../assets/img/icons/solid/hospital-user.svg";
import icon1485 from "../assets/img/icons/solid/cloud-moon.svg";
import icon1486 from "../assets/img/icons/solid/warehouse.svg";
import icon1487 from "../assets/img/icons/solid/arrow-up-short-wide.svg";
import icon1488 from "../assets/img/icons/solid/hryvnia-sign.svg";
import icon1489 from "../assets/img/icons/solid/credit-card.svg";
import icon1490 from "../assets/img/icons/solid/circle-arrow-down.svg";
import icon1491 from "../assets/img/icons/solid/joint.svg";
import icon1492 from "../assets/img/icons/solid/user-tie.svg";
import icon1493 from "../assets/img/icons/solid/snowman.svg";
import icon1494 from "../assets/img/icons/solid/truck-droplet.svg";
import icon1495 from "../assets/img/icons/solid/computer.svg";
import icon1496 from "../assets/img/icons/solid/b.svg";
import icon1497 from "../assets/img/icons/solid/forward-step.svg";
import icon1498 from "../assets/img/icons/solid/person-rifle.svg";
import icon1499 from "../assets/img/icons/solid/circle-half-stroke.svg";
import icon1500 from "../assets/img/icons/solid/vial.svg";
import icon1501 from "../assets/img/icons/solid/4.svg";
import icon1502 from "../assets/img/icons/solid/u.svg";
import icon1503 from "../assets/img/icons/solid/shop-slash.svg";
import icon1504 from "../assets/img/icons/solid/reply-all.svg";
import icon1505 from "../assets/img/icons/solid/truck-medical.svg";
import icon1506 from "../assets/img/icons/solid/pencil.svg";
import icon1507 from "../assets/img/icons/solid/phone-flip.svg";
import icon1508 from "../assets/img/icons/solid/diagram-project.svg";
import icon1509 from "../assets/img/icons/solid/language.svg";
import icon1510 from "../assets/img/icons/solid/cloud-rain.svg";
import icon1511 from "../assets/img/icons/solid/mars-stroke.svg";
import icon1512 from "../assets/img/icons/solid/child-rifle.svg";
import icon1513 from "../assets/img/icons/solid/venus-double.svg";
import icon1514 from "../assets/img/icons/solid/naira-sign.svg";
import icon1515 from "../assets/img/icons/solid/user-group.svg";
import icon1516 from "../assets/img/icons/solid/person-walking-dashed-line-arrow-right.svg";
import icon1517 from "../assets/img/icons/solid/pen-clip.svg";
import icon1518 from "../assets/img/icons/solid/vest.svg";
import icon1519 from "../assets/img/icons/solid/person-snowboarding.svg";
import icon1520 from "../assets/img/icons/solid/golf-ball-tee.svg";
import icon1521 from "../assets/img/icons/solid/circle-user.svg";
import icon1522 from "../assets/img/icons/solid/vest-patches.svg";
import icon1523 from "../assets/img/icons/solid/sitemap.svg";
import icon1524 from "../assets/img/icons/solid/crosshairs.svg";
import icon1525 from "../assets/img/icons/solid/mill-sign.svg";
import icon1526 from "../assets/img/icons/solid/basketball.svg";
import icon1527 from "../assets/img/icons/solid/poop.svg";
import icon1528 from "../assets/img/icons/solid/land-mine-on.svg";
import icon1529 from "../assets/img/icons/solid/diamond.svg";
import icon1530 from "../assets/img/icons/solid/clock.svg";
import icon1531 from "../assets/img/icons/solid/window-minimize.svg";
import icon1532 from "../assets/img/icons/solid/retweet.svg";
import icon1533 from "../assets/img/icons/solid/phone.svg";
import icon1534 from "../assets/img/icons/solid/football.svg";
import icon1535 from "../assets/img/icons/solid/toilet-paper-slash.svg";
import icon1536 from "../assets/img/icons/solid/person-circle-minus.svg";
import icon1537 from "../assets/img/icons/solid/person-circle-exclamation.svg";
import icon1538 from "../assets/img/icons/solid/people-robbery.svg";
import icon1539 from "../assets/img/icons/solid/lungs.svg";
import icon1540 from "../assets/img/icons/solid/house-user.svg";
import icon1541 from "../assets/img/icons/solid/heart-circle-minus.svg";
import icon1542 from "../assets/img/icons/solid/arrow-down-a-z.svg";
import icon1543 from "../assets/img/icons/solid/dumbbell.svg";
import icon1544 from "../assets/img/icons/solid/wine-bottle.svg";
import icon1545 from "../assets/img/icons/solid/tractor.svg";
import icon1546 from "../assets/img/icons/solid/folder-closed.svg";
import icon1547 from "../assets/img/icons/solid/eye.svg";
import icon1548 from "../assets/img/icons/solid/car-burst.svg";
import icon1549 from "../assets/img/icons/solid/mobile.svg";
import icon1550 from "../assets/img/icons/solid/person-arrow-up-from-line.svg";
import icon1551 from "../assets/img/icons/solid/universal-access.svg";
import icon1552 from "../assets/img/icons/solid/arrow-turn-down.svg";
import icon1553 from "../assets/img/icons/solid/car-on.svg";
import icon1554 from "../assets/img/icons/solid/user-injured.svg";
import icon1555 from "../assets/img/icons/solid/book-medical.svg";
import icon1556 from "../assets/img/icons/solid/square-caret-up.svg";
import icon1557 from "../assets/img/icons/solid/vial-virus.svg";
import icon1558 from "../assets/img/icons/solid/square-envelope.svg";
import icon1559 from "../assets/img/icons/solid/austral-sign.svg";
import icon1560 from "../assets/img/icons/solid/dove.svg";
import icon1561 from "../assets/img/icons/solid/car-side.svg";
import icon1562 from "../assets/img/icons/solid/shield-dog.svg";
import icon1563 from "../assets/img/icons/solid/square-plus.svg";
import icon1564 from "../assets/img/icons/solid/bahai.svg";
import icon1565 from "../assets/img/icons/solid/truck-ramp-box.svg";
import icon1566 from "../assets/img/icons/solid/gift.svg";
import icon1567 from "../assets/img/icons/solid/person-breastfeeding.svg";
import icon1568 from "../assets/img/icons/solid/gauge-simple-high.svg";
import icon1569 from "../assets/img/icons/solid/car.svg";
import icon1570 from "../assets/img/icons/solid/tarp.svg";
import icon1571 from "../assets/img/icons/solid/kit-medical.svg";
import icon1572 from "../assets/img/icons/solid/registered.svg";
import icon1573 from "../assets/img/icons/solid/face-kiss-wink-heart.svg";
import icon1574 from "../assets/img/icons/solid/file-invoice.svg";
import icon1575 from "../assets/img/icons/solid/truck-arrow-right.svg";
import icon1576 from "../assets/img/icons/solid/ear-listen.svg";
import icon1577 from "../assets/img/icons/solid/draw-polygon.svg";
import icon1578 from "../assets/img/icons/solid/battery-quarter.svg";
import icon1579 from "../assets/img/icons/solid/mountain-city.svg";
import icon1580 from "../assets/img/icons/solid/layer-group.svg";
import icon1581 from "../assets/img/icons/solid/baseball.svg";
import icon1582 from "../assets/img/icons/solid/share.svg";
import icon1583 from "../assets/img/icons/solid/ticket-simple.svg";
import icon1584 from "../assets/img/icons/solid/tags.svg";
import icon1585 from "../assets/img/icons/solid/people-arrows.svg";
import icon1586 from "../assets/img/icons/solid/plug-circle-xmark.svg";
import icon1587 from "../assets/img/icons/solid/vials.svg";
import icon1588 from "../assets/img/icons/solid/signs-post.svg";
import icon1589 from "../assets/img/icons/solid/arrow-up.svg";
import icon1590 from "../assets/img/icons/solid/down-left-and-up-right-to-center.svg";
import icon1591 from "../assets/img/icons/solid/microscope.svg";
import icon1592 from "../assets/img/icons/solid/face-sad-cry.svg";
import icon1593 from "../assets/img/icons/solid/drum.svg";
import icon1594 from "../assets/img/icons/solid/sort.svg";
import icon1595 from "../assets/img/icons/solid/school-flag.svg";
import icon1596 from "../assets/img/icons/solid/angles-up.svg";
import icon1597 from "../assets/img/icons/solid/calendar-minus.svg";
import icon1598 from "../assets/img/icons/solid/ethernet.svg";
import icon1599 from "../assets/img/icons/solid/tent-arrows-down.svg";
import icon1600 from "../assets/img/icons/solid/people-group.svg";
import icon1601 from "../assets/img/icons/solid/video.svg";
import icon1602 from "../assets/img/icons/solid/users-line.svg";
import icon1603 from "../assets/img/icons/solid/compass-drafting.svg";
import icon1604 from "../assets/img/icons/solid/arrow-up-right-dots.svg";
import icon1605 from "../assets/img/icons/solid/bitcoin-sign.svg";
import icon1606 from "../assets/img/icons/solid/robot.svg";
import icon1607 from "../assets/img/icons/solid/pepper-hot.svg";
import icon1608 from "../assets/img/icons/solid/circle-dollar-to-slot.svg";
import icon1609 from "../assets/img/icons/solid/tree.svg";
import icon1610 from "../assets/img/icons/solid/id-card.svg";
import icon1611 from "../assets/img/icons/solid/arrow-left-long.svg";
import icon1612 from "../assets/img/icons/solid/pills.svg";
import icon1613 from "../assets/img/icons/solid/triangle-exclamation.svg";
import icon1614 from "../assets/img/icons/solid/puzzle-piece.svg";
import icon1615 from "../assets/img/icons/solid/map-pin.svg";
import icon1616 from "../assets/img/icons/solid/shop-lock.svg";
import icon1617 from "../assets/img/icons/solid/filter.svg";
import icon1618 from "../assets/img/icons/solid/suitcase-rolling.svg";
import icon1619 from "../assets/img/icons/solid/water.svg";
import icon1620 from "../assets/img/icons/solid/eye-low-vision.svg";
import icon1621 from "../assets/img/icons/solid/house-flood-water.svg";
import icon1622 from "../assets/img/icons/solid/cloud-showers-heavy.svg";
import icon1623 from "../assets/img/icons/solid/chess-pawn.svg";
import icon1624 from "../assets/img/icons/solid/umbrella-beach.svg";
import icon1625 from "../assets/img/icons/solid/check-double.svg";
import icon1626 from "../assets/img/icons/solid/file-signature.svg";
import icon1627 from "../assets/img/icons/solid/italic.svg";
import icon1628 from "../assets/img/icons/solid/face-smile-beam.svg";
import icon1629 from "../assets/img/icons/solid/bell-slash.svg";
import icon1630 from "../assets/img/icons/solid/handshake.svg";
import icon1631 from "../assets/img/icons/solid/money-bill.svg";
import icon1632 from "../assets/img/icons/solid/medal.svg";
import icon1633 from "../assets/img/icons/solid/mars-double.svg";
import icon1634 from "../assets/img/icons/solid/won-sign.svg";
import icon1635 from "../assets/img/icons/solid/microphone-lines.svg";
import icon1636 from "../assets/img/icons/solid/comment.svg";
import icon1637 from "../assets/img/icons/solid/timeline.svg";
import icon1638 from "../assets/img/icons/solid/torii-gate.svg";
import icon1639 from "../assets/img/icons/solid/video-slash.svg";
import icon1640 from "../assets/img/icons/solid/calendar.svg";
import icon1641 from "../assets/img/icons/solid/arrow-trend-up.svg";
import icon1642 from "../assets/img/icons/solid/arrows-rotate.svg";
import icon1643 from "../assets/img/icons/solid/globe.svg";
import icon1644 from "../assets/img/icons/solid/arrow-left.svg";
import icon1645 from "../assets/img/icons/solid/paintbrush.svg";
import icon1646 from "../assets/img/icons/solid/align-center.svg";
import icon1647 from "../assets/img/icons/solid/cross.svg";
import icon1648 from "../assets/img/icons/solid/square-minus.svg";
import icon1649 from "../assets/img/icons/solid/blog.svg";
import icon1650 from "../assets/img/icons/solid/route.svg";
import icon1651 from "../assets/img/icons/solid/circle-arrow-up.svg";
import icon1652 from "../assets/img/icons/solid/magnifying-glass.svg";
import icon1653 from "../assets/img/icons/solid/bowling-ball.svg";
import icon1654 from "../assets/img/icons/solid/ellipsis.svg";
import icon1655 from "../assets/img/icons/solid/face-tired.svg";
import icon1656 from "../assets/img/icons/solid/grip-lines-vertical.svg";
import icon1657 from "../assets/img/icons/solid/microchip.svg";
import icon1658 from "../assets/img/icons/solid/frog.svg";
import icon1659 from "../assets/img/icons/solid/hands-asl-interpreting.svg";
import icon1660 from "../assets/img/icons/solid/laptop.svg";
import icon1661 from "../assets/img/icons/solid/power-off.svg";
import icon1662 from "../assets/img/icons/solid/chart-area.svg";
import icon1663 from "../assets/img/icons/solid/display.svg";
import icon1664 from "../assets/img/icons/solid/quote-left.svg";
import icon1665 from "../assets/img/icons/solid/file-prescription.svg";
import icon1666 from "../assets/img/icons/solid/face-grin.svg";
import icon1667 from "../assets/img/icons/solid/mountain.svg";
import icon1668 from "../assets/img/icons/solid/microphone-slash.svg";
import icon1669 from "../assets/img/icons/solid/mars-and-venus-burst.svg";
import icon1670 from "../assets/img/icons/solid/caret-left.svg";
import icon1671 from "../assets/img/icons/solid/dice-two.svg";
import icon1672 from "../assets/img/icons/solid/file-circle-plus.svg";
import icon1673 from "../assets/img/icons/solid/tooth.svg";
import icon1674 from "../assets/img/icons/solid/volume-low.svg";
import icon1675 from "../assets/img/icons/solid/chess-queen.svg";
import icon1676 from "../assets/img/icons/solid/file-code.svg";
import icon1677 from "../assets/img/icons/solid/rupiah-sign.svg";
import icon1678 from "../assets/img/icons/solid/file-csv.svg";
import icon1679 from "../assets/img/icons/solid/building-wheat.svg";
import icon1680 from "../assets/img/icons/solid/tent-arrow-turn-left.svg";
import icon1681 from "../assets/img/icons/solid/road-bridge.svg";
import icon1682 from "../assets/img/icons/solid/dice-six.svg";
import icon1683 from "../assets/img/icons/solid/plate-wheat.svg";
import icon1684 from "../assets/img/icons/solid/house-crack.svg";
import icon1685 from "../assets/img/icons/solid/cloud.svg";
import icon1686 from "../assets/img/icons/solid/hockey-puck.svg";
import icon1687 from "../assets/img/icons/solid/tty.svg";
import icon1688 from "../assets/img/icons/solid/shuffle.svg";
import icon1689 from "../assets/img/icons/solid/square-nfi.svg";
import icon1690 from "../assets/img/icons/solid/building-circle-exclamation.svg";
import icon1691 from "../assets/img/icons/solid/grip-vertical.svg";
import icon1692 from "../assets/img/icons/solid/arrows-spin.svg";
import icon1693 from "../assets/img/icons/solid/futbol.svg";
import icon1694 from "../assets/img/icons/solid/cheese.svg";
import icon1695 from "../assets/img/icons/solid/dice.svg";
import icon1696 from "../assets/img/icons/solid/bars.svg";
import icon1697 from "../assets/img/icons/solid/mask-face.svg";
import icon1698 from "../assets/img/icons/solid/bezier-curve.svg";
import icon1699 from "../assets/img/icons/solid/chair.svg";
import icon1700 from "../assets/img/icons/solid/dice-d20.svg";
import icon1701 from "../assets/img/icons/solid/road-lock.svg";
import icon1702 from "../assets/img/icons/solid/suitcase.svg";
import icon1703 from "../assets/img/icons/solid/list-check.svg";
import icon1704 from "../assets/img/icons/solid/motorcycle.svg";
import icon1705 from "../assets/img/icons/solid/upload.svg";
import icon1706 from "../assets/img/icons/solid/store-slash.svg";
import icon1707 from "../assets/img/icons/solid/face-flushed.svg";
import icon1708 from "../assets/img/icons/solid/mobile-screen-button.svg";
import icon1709 from "../assets/img/icons/solid/cart-flatbed.svg";
import icon1710 from "../assets/img/icons/solid/pause.svg";
import icon1711 from "../assets/img/icons/solid/plane-circle-check.svg";
import icon1712 from "../assets/img/icons/solid/face-kiss.svg";
import icon1713 from "../assets/img/icons/solid/forward.svg";
import icon1714 from "../assets/img/icons/solid/icons.svg";
import icon1715 from "../assets/img/icons/solid/dice-four.svg";
import icon1716 from "../assets/img/icons/solid/circle-minus.svg";
import icon1717 from "../assets/img/icons/solid/bookmark.svg";
import icon1718 from "../assets/img/icons/solid/braille.svg";
import icon1719 from "../assets/img/icons/solid/angles-left.svg";
import icon1720 from "../assets/img/icons/solid/rocket.svg";
import icon1721 from "../assets/img/icons/solid/icicles.svg";
import icon1722 from "../assets/img/icons/solid/circle-dot.svg";
import icon1723 from "../assets/img/icons/solid/blender-phone.svg";
import icon1724 from "../assets/img/icons/solid/asterisk.svg";
import icon1725 from "../assets/img/icons/solid/rotate-right.svg";
import icon1726 from "../assets/img/icons/solid/user-check.svg";
import icon1727 from "../assets/img/icons/solid/image-portrait.svg";
import icon1728 from "../assets/img/icons/solid/hand-pointer.svg";
import icon1729 from "../assets/img/icons/solid/cloud-sun-rain.svg";
import icon1730 from "../assets/img/icons/solid/rupee-sign.svg";
import icon1731 from "../assets/img/icons/solid/sleigh.svg";
import icon1732 from "../assets/img/icons/solid/tablet.svg";
import icon1733 from "../assets/img/icons/solid/peseta-sign.svg";
import icon1734 from "../assets/img/icons/solid/book-tanakh.svg";
import icon1735 from "../assets/img/icons/solid/elevator.svg";
import icon1736 from "../assets/img/icons/solid/star-half-stroke.svg";
import icon1737 from "../assets/img/icons/solid/whiskey-glass.svg";
import icon1738 from "../assets/img/icons/solid/envelope-circle-check.svg";
import icon1739 from "../assets/img/icons/solid/list-ol.svg";
import icon1740 from "../assets/img/icons/solid/tower-cell.svg";
import icon1741 from "../assets/img/icons/solid/screwdriver-wrench.svg";
import icon1742 from "../assets/img/icons/solid/face-grin-beam.svg";
import icon1743 from "../assets/img/icons/solid/bars-staggered.svg";
import icon1744 from "../assets/img/icons/solid/diamond-turn-right.svg";
import icon1745 from "../assets/img/icons/solid/square-phone.svg";
import icon1746 from "../assets/img/icons/solid/spell-check.svg";
import icon1747 from "../assets/img/icons/solid/face-grin-wide.svg";
import icon1748 from "../assets/img/icons/solid/laptop-medical.svg";
import icon1749 from "../assets/img/icons/solid/shuttle-space.svg";
import icon1750 from "../assets/img/icons/solid/ruble-sign.svg";
import icon1751 from "../assets/img/icons/solid/circle-h.svg";
import icon1752 from "../assets/img/icons/solid/lock-open.svg";
import icon1753 from "../assets/img/icons/solid/atom.svg";
import icon1754 from "../assets/img/icons/solid/cat.svg";
import icon1755 from "../assets/img/icons/solid/yin-yang.svg";
import icon1756 from "../assets/img/icons/solid/train-subway.svg";
import icon1757 from "../assets/img/icons/solid/ice-cream.svg";
import icon1758 from "../assets/img/icons/solid/ring.svg";
import icon1759 from "../assets/img/icons/solid/building-columns.svg";
import icon1760 from "../assets/img/icons/solid/face-grin-tears.svg";
import icon1761 from "../assets/img/icons/solid/snowplow.svg";
import icon1762 from "../assets/img/icons/solid/face-laugh-beam.svg";
import icon1763 from "../assets/img/icons/solid/angle-left.svg";
import icon1764 from "../assets/img/icons/solid/face-kiss-beam.svg";
import icon1765 from "../assets/img/icons/solid/face-angry.svg";
import icon1766 from "../assets/img/icons/solid/arrows-down-to-people.svg";
import icon1767 from "../assets/img/icons/solid/parachute-box.svg";
import icon1768 from "../assets/img/icons/solid/spa.svg";
import icon1769 from "../assets/img/icons/solid/sort-down.svg";
import icon1770 from "../assets/img/icons/solid/calculator.svg";
import icon1771 from "../assets/img/icons/solid/window-restore.svg";
import icon1772 from "../assets/img/icons/solid/user-slash.svg";
import icon1773 from "../assets/img/icons/solid/circle-chevron-left.svg";
import icon1774 from "../assets/img/icons/solid/cart-flatbed-suitcase.svg";
import icon1775 from "../assets/img/icons/solid/screwdriver.svg";
import icon1776 from "../assets/img/icons/solid/circle-pause.svg";
import icon1777 from "../assets/img/icons/solid/money-bill-wave.svg";
import icon1778 from "../assets/img/icons/solid/hand-middle-finger.svg";
import icon1779 from "../assets/img/icons/solid/chess-bishop.svg";
import icon1780 from "../assets/img/icons/solid/dharmachakra.svg";
import icon1781 from "../assets/img/icons/solid/bed-pulse.svg";
import icon1782 from "../assets/img/icons/solid/bore-hole.svg";
import icon1783 from "../assets/img/icons/solid/person-walking.svg";
import icon1784 from "../assets/img/icons/solid/charging-station.svg";
import icon1785 from "../assets/img/icons/solid/circle-down.svg";
import icon1786 from "../assets/img/icons/solid/building.svg";
import icon1787 from "../assets/img/icons/solid/wheat-awn.svg";
import icon1788 from "../assets/img/icons/solid/house-medical-circle-exclamation.svg";
import icon1789 from "../assets/img/icons/solid/binoculars.svg";
import icon1790 from "../assets/img/icons/solid/genderless.svg";
import icon1791 from "../assets/img/icons/solid/gavel.svg";
import icon1792 from "../assets/img/icons/solid/folder.svg";
import icon1793 from "../assets/img/icons/solid/down-long.svg";
import icon1794 from "../assets/img/icons/solid/school-circle-exclamation.svg";
import icon1795 from "../assets/img/icons/solid/person-walking-luggage.svg";
import icon1796 from "../assets/img/icons/solid/pump-medical.svg";
import icon1797 from "../assets/img/icons/solid/hand-dots.svg";
import icon1798 from "../assets/img/icons/solid/users.svg";
import icon1799 from "../assets/img/icons/solid/chart-simple.svg";
import icon1800 from "../assets/img/icons/solid/hill-rockslide.svg";
import icon1801 from "../assets/img/icons/solid/hands.svg";
import icon1802 from "../assets/img/icons/solid/fingerprint.svg";
import icon1803 from "../assets/img/icons/solid/fill.svg";
import icon1804 from "../assets/img/icons/solid/hanukiah.svg";
import icon1805 from "../assets/img/icons/solid/synagogue.svg";
import icon1806 from "../assets/img/icons/solid/file-pdf.svg";
import icon1807 from "../assets/img/icons/solid/arrow-up-1-9.svg";
import icon1808 from "../assets/img/icons/solid/gamepad.svg";
import icon1809 from "../assets/img/icons/solid/clipboard-check.svg";
import icon1810 from "../assets/img/icons/solid/minus.svg";
import icon1811 from "../assets/img/icons/solid/heart-pulse.svg";
import icon1812 from "../assets/img/icons/solid/arrows-to-dot.svg";
import icon1813 from "../assets/img/icons/solid/up-down-left-right.svg";
import icon1814 from "../assets/img/icons/solid/ear-deaf.svg";
import icon1815 from "../assets/img/icons/solid/chevron-left.svg";
import icon1816 from "../assets/img/icons/solid/hot-tub-person.svg";
import icon1817 from "../assets/img/icons/solid/mattress-pillow.svg";
import icon1818 from "../assets/img/icons/solid/film.svg";
import icon1819 from "../assets/img/icons/solid/print.svg";
import icon1820 from "../assets/img/icons/solid/moon.svg";
import icon1821 from "../assets/img/icons/solid/stroopwafel.svg";
import icon1822 from "../assets/img/icons/solid/walkie-talkie.svg";
import icon1823 from "../assets/img/icons/solid/exclamation.svg";
import icon1824 from "../assets/img/icons/solid/bell-concierge.svg";
import icon1825 from "../assets/img/icons/solid/capsules.svg";
import icon1826 from "../assets/img/icons/solid/vial-circle-check.svg";
import icon1827 from "../assets/img/icons/solid/person-burst.svg";
import icon1828 from "../assets/img/icons/solid/school-lock.svg";
import icon1829 from "../assets/img/icons/solid/arrow-pointer.svg";
import icon1830 from "../assets/img/icons/solid/border-none.svg";
import icon1831 from "../assets/img/icons/solid/book-bible.svg";
import icon1832 from "../assets/img/icons/solid/user-shield.svg";
import icon1833 from "../assets/img/icons/solid/house-signal.svg";
import icon1834 from "../assets/img/icons/solid/face-laugh-squint.svg";
import icon1835 from "../assets/img/icons/solid/left-right.svg";
import icon1836 from "../assets/img/icons/solid/anchor-circle-xmark.svg";
import icon1837 from "../assets/img/icons/solid/ribbon.svg";
import icon1838 from "../assets/img/icons/solid/arrow-down-wide-short.svg";
import icon1839 from "../assets/img/icons/solid/swatchbook.svg";
import icon1840 from "../assets/img/icons/solid/apple-whole.svg";
import icon1841 from "../assets/img/icons/solid/stamp.svg";
import icon1842 from "../assets/img/icons/solid/landmark-dome.svg";
import icon1843 from "../assets/img/icons/solid/square-caret-left.svg";
import icon1844 from "../assets/img/icons/solid/sort-up.svg";
import icon1845 from "../assets/img/icons/solid/file-waveform.svg";
import icon1846 from "../assets/img/icons/solid/calendar-days.svg";
import icon1847 from "../assets/img/icons/solid/align-left.svg";
import icon1848 from "../assets/img/icons/solid/temperature-low.svg";
import icon1849 from "../assets/img/icons/solid/person-walking-arrow-right.svg";
import icon1850 from "../assets/img/icons/solid/heart.svg";
import icon1851 from "../assets/img/icons/solid/arrow-trend-down.svg";
import icon1852 from "../assets/img/icons/solid/font.svg";
import icon1853 from "../assets/img/icons/solid/arrow-up-9-1.svg";
import icon1854 from "../assets/img/icons/regular/comment-dots.svg";
import icon1855 from "../assets/img/icons/regular/file-zipper.svg";
import icon1856 from "../assets/img/icons/regular/hand.svg";
import icon1857 from "../assets/img/icons/regular/clone.svg";
import icon1858 from "../assets/img/icons/regular/file-excel.svg";
import icon1859 from "../assets/img/icons/regular/trash-can.svg";
import icon1860 from "../assets/img/icons/regular/face-grin-tongue-wink.svg";
import icon1861 from "../assets/img/icons/regular/hourglass.svg";
import icon1862 from "../assets/img/icons/regular/chess-rook.svg";
import icon1863 from "../assets/img/icons/regular/face-grimace.svg";
import icon1864 from "../assets/img/icons/regular/chess-knight.svg";
import icon1865 from "../assets/img/icons/regular/user.svg";
import icon1866 from "../assets/img/icons/regular/id-badge.svg";
import icon1867 from "../assets/img/icons/regular/circle.svg";
import icon1868 from "../assets/img/icons/regular/copyright.svg";
import icon1869 from "../assets/img/icons/regular/square-caret-down.svg";
import icon1870 from "../assets/img/icons/regular/hand-point-right.svg";
import icon1871 from "../assets/img/icons/regular/face-laugh.svg";
import icon1872 from "../assets/img/icons/regular/money-bill-1.svg";
import icon1873 from "../assets/img/icons/regular/file-word.svg";
import icon1874 from "../assets/img/icons/regular/face-grin-tongue.svg";
import icon1875 from "../assets/img/icons/regular/object-group.svg";
import icon1876 from "../assets/img/icons/regular/circle-left.svg";
import icon1877 from "../assets/img/icons/regular/file-audio.svg";
import icon1878 from "../assets/img/icons/regular/face-meh.svg";
import icon1879 from "../assets/img/icons/regular/map.svg";
import icon1880 from "../assets/img/icons/regular/comments.svg";
import icon1881 from "../assets/img/icons/regular/square-caret-right.svg";
import icon1882 from "../assets/img/icons/regular/file.svg";
import icon1883 from "../assets/img/icons/regular/share-from-square.svg";
import icon1884 from "../assets/img/icons/regular/pen-to-square.svg";
import icon1885 from "../assets/img/icons/regular/square-full.svg";
import icon1886 from "../assets/img/icons/regular/file-video.svg";
import icon1887 from "../assets/img/icons/regular/folder-open.svg";
import icon1888 from "../assets/img/icons/regular/keyboard.svg";
import icon1889 from "../assets/img/icons/regular/face-grin-squint-tears.svg";
import icon1890 from "../assets/img/icons/regular/face-laugh-wink.svg";
import icon1891 from "../assets/img/icons/regular/face-grin-wink.svg";
import icon1892 from "../assets/img/icons/regular/face-rolling-eyes.svg";
import icon1893 from "../assets/img/icons/regular/clipboard.svg";
import icon1894 from "../assets/img/icons/regular/circle-question.svg";
import icon1895 from "../assets/img/icons/regular/life-ring.svg";
import icon1896 from "../assets/img/icons/regular/calendar-plus.svg";
import icon1897 from "../assets/img/icons/regular/circle-play.svg";
import icon1898 from "../assets/img/icons/regular/notdef.svg";
import icon1899 from "../assets/img/icons/regular/calendar-check.svg";
import icon1900 from "../assets/img/icons/regular/thumbs-down.svg";
import icon1901 from "../assets/img/icons/regular/envelope.svg";
import icon1902 from "../assets/img/icons/regular/hand-point-left.svg";
import icon1903 from "../assets/img/icons/regular/face-grin-beam-sweat.svg";
import icon1904 from "../assets/img/icons/regular/hand-lizard.svg";
import icon1905 from "../assets/img/icons/regular/square-check.svg";
import icon1906 from "../assets/img/icons/regular/face-frown-open.svg";
import icon1907 from "../assets/img/icons/regular/hand-scissors.svg";
import icon1908 from "../assets/img/icons/regular/face-meh-blank.svg";
import icon1909 from "../assets/img/icons/regular/hand-point-up.svg";
import icon1910 from "../assets/img/icons/regular/object-ungroup.svg";
import icon1911 from "../assets/img/icons/regular/circle-check.svg";
import icon1912 from "../assets/img/icons/regular/file-image.svg";
import icon1913 from "../assets/img/icons/regular/bell.svg";
import icon1914 from "../assets/img/icons/regular/flag.svg";
import icon1915 from "../assets/img/icons/regular/hand-peace.svg";
import icon1916 from "../assets/img/icons/regular/chess-king.svg";
import icon1917 from "../assets/img/icons/regular/newspaper.svg";
import icon1918 from "../assets/img/icons/regular/snowflake.svg";
import icon1919 from "../assets/img/icons/regular/star-half.svg";
import icon1920 from "../assets/img/icons/regular/envelope-open.svg";
import icon1921 from "../assets/img/icons/regular/face-frown.svg";
import icon1922 from "../assets/img/icons/regular/hard-drive.svg";
import icon1923 from "../assets/img/icons/regular/hospital.svg";
import icon1924 from "../assets/img/icons/regular/face-grin-tongue-squint.svg";
import icon1925 from "../assets/img/icons/regular/face-smile-wink.svg";
import icon1926 from "../assets/img/icons/regular/face-dizzy.svg";
import icon1927 from "../assets/img/icons/regular/hourglass-half.svg";
import icon1928 from "../assets/img/icons/regular/face-sad-tear.svg";
import icon1929 from "../assets/img/icons/regular/calendar-xmark.svg";
import icon1930 from "../assets/img/icons/regular/copy.svg";
import icon1931 from "../assets/img/icons/regular/file-lines.svg";
import icon1932 from "../assets/img/icons/regular/closed-captioning.svg";
import icon1933 from "../assets/img/icons/regular/font-awesome.svg";
import icon1934 from "../assets/img/icons/regular/image.svg";
import icon1935 from "../assets/img/icons/regular/hand-point-down.svg";
import icon1936 from "../assets/img/icons/regular/lightbulb.svg";
import icon1937 from "../assets/img/icons/regular/face-grin-stars.svg";
import icon1938 from "../assets/img/icons/regular/images.svg";
import icon1939 from "../assets/img/icons/regular/rectangle-xmark.svg";
import icon1940 from "../assets/img/icons/regular/face-grin-squint.svg";
import icon1941 from "../assets/img/icons/regular/circle-up.svg";
import icon1942 from "../assets/img/icons/regular/hand-back-fist.svg";
import icon1943 from "../assets/img/icons/regular/message.svg";
import icon1944 from "../assets/img/icons/regular/thumbs-up.svg";
import icon1945 from "../assets/img/icons/regular/face-smile.svg";
import icon1946 from "../assets/img/icons/regular/square.svg";
import icon1947 from "../assets/img/icons/regular/eye-slash.svg";
import icon1948 from "../assets/img/icons/regular/paper-plane.svg";
import icon1949 from "../assets/img/icons/regular/face-grin-hearts.svg";
import icon1950 from "../assets/img/icons/regular/circle-right.svg";
import icon1951 from "../assets/img/icons/regular/floppy-disk.svg";
import icon1952 from "../assets/img/icons/regular/file-powerpoint.svg";
import icon1953 from "../assets/img/icons/regular/note-sticky.svg";
import icon1954 from "../assets/img/icons/regular/hand-spock.svg";
import icon1955 from "../assets/img/icons/regular/gem.svg";
import icon1956 from "../assets/img/icons/regular/chart-bar.svg";
import icon1957 from "../assets/img/icons/regular/paste.svg";
import icon1958 from "../assets/img/icons/regular/window-maximize.svg";
import icon1959 from "../assets/img/icons/regular/address-book.svg";
import icon1960 from "../assets/img/icons/regular/rectangle-list.svg";
import icon1961 from "../assets/img/icons/regular/star.svg";
import icon1962 from "../assets/img/icons/regular/sun.svg";
import icon1963 from "../assets/img/icons/regular/address-card.svg";
import icon1964 from "../assets/img/icons/regular/circle-stop.svg";
import icon1965 from "../assets/img/icons/regular/circle-xmark.svg";
import icon1966 from "../assets/img/icons/regular/lemon.svg";
import icon1967 from "../assets/img/icons/regular/compass.svg";
import icon1968 from "../assets/img/icons/regular/face-surprise.svg";
import icon1969 from "../assets/img/icons/regular/credit-card.svg";
import icon1970 from "../assets/img/icons/regular/circle-user.svg";
import icon1971 from "../assets/img/icons/regular/clock.svg";
import icon1972 from "../assets/img/icons/regular/window-minimize.svg";
import icon1973 from "../assets/img/icons/regular/folder-closed.svg";
import icon1974 from "../assets/img/icons/regular/eye.svg";
import icon1975 from "../assets/img/icons/regular/square-caret-up.svg";
import icon1976 from "../assets/img/icons/regular/square-plus.svg";
import icon1977 from "../assets/img/icons/regular/registered.svg";
import icon1978 from "../assets/img/icons/regular/face-kiss-wink-heart.svg";
import icon1979 from "../assets/img/icons/regular/face-sad-cry.svg";
import icon1980 from "../assets/img/icons/regular/calendar-minus.svg";
import icon1981 from "../assets/img/icons/regular/id-card.svg";
import icon1982 from "../assets/img/icons/regular/chess-pawn.svg";
import icon1983 from "../assets/img/icons/regular/face-smile-beam.svg";
import icon1984 from "../assets/img/icons/regular/bell-slash.svg";
import icon1985 from "../assets/img/icons/regular/handshake.svg";
import icon1986 from "../assets/img/icons/regular/comment.svg";
import icon1987 from "../assets/img/icons/regular/calendar.svg";
import icon1988 from "../assets/img/icons/regular/square-minus.svg";
import icon1989 from "../assets/img/icons/regular/face-tired.svg";
import icon1990 from "../assets/img/icons/regular/face-grin.svg";
import icon1991 from "../assets/img/icons/regular/chess-queen.svg";
import icon1992 from "../assets/img/icons/regular/file-code.svg";
import icon1993 from "../assets/img/icons/regular/futbol.svg";
import icon1994 from "../assets/img/icons/regular/face-flushed.svg";
import icon1995 from "../assets/img/icons/regular/face-kiss.svg";
import icon1996 from "../assets/img/icons/regular/bookmark.svg";
import icon1997 from "../assets/img/icons/regular/circle-dot.svg";
import icon1998 from "../assets/img/icons/regular/hand-pointer.svg";
import icon1999 from "../assets/img/icons/regular/star-half-stroke.svg";
import icon2000 from "../assets/img/icons/regular/face-grin-beam.svg";
import icon2001 from "../assets/img/icons/regular/face-grin-wide.svg";
import icon2002 from "../assets/img/icons/regular/face-grin-tears.svg";
import icon2003 from "../assets/img/icons/regular/face-laugh-beam.svg";
import icon2004 from "../assets/img/icons/regular/face-kiss-beam.svg";
import icon2005 from "../assets/img/icons/regular/face-angry.svg";
import icon2006 from "../assets/img/icons/regular/window-restore.svg";
import icon2007 from "../assets/img/icons/regular/circle-pause.svg";
import icon2008 from "../assets/img/icons/regular/chess-bishop.svg";
import icon2009 from "../assets/img/icons/regular/circle-down.svg";
import icon2010 from "../assets/img/icons/regular/building.svg";
import icon2011 from "../assets/img/icons/regular/folder.svg";
import icon2012 from "../assets/img/icons/regular/file-pdf.svg";
import icon2013 from "../assets/img/icons/regular/moon.svg";
import icon2014 from "../assets/img/icons/regular/face-laugh-squint.svg";
import icon2015 from "../assets/img/icons/regular/square-caret-left.svg";
import icon2016 from "../assets/img/icons/regular/calendar-days.svg";
import icon2017 from "../assets/img/icons/regular/heart.svg";

export let iconOptions = [
  {
    label: (
      <span>
        /icons/brands/supple.svg <img style={{ height: 20 }} src={icon0} />
      </span>
    ),
    value: "/icons/brands/supple.svg",
    key: 0,
  },
  {
    label: (
      <span>
        /icons/brands/bootstrap.svg <img style={{ height: 20 }} src={icon1} />
      </span>
    ),
    value: "/icons/brands/bootstrap.svg",
    key: 1,
  },
  {
    label: (
      <span>
        /icons/brands/weixin.svg <img style={{ height: 20 }} src={icon2} />
      </span>
    ),
    value: "/icons/brands/weixin.svg",
    key: 2,
  },
  {
    label: (
      <span>
        /icons/brands/connectdevelop.svg{" "}
        <img style={{ height: 20 }} src={icon3} />
      </span>
    ),
    value: "/icons/brands/connectdevelop.svg",
    key: 3,
  },
  {
    label: (
      <span>
        /icons/brands/medium.svg <img style={{ height: 20 }} src={icon4} />
      </span>
    ),
    value: "/icons/brands/medium.svg",
    key: 4,
  },
  {
    label: (
      <span>
        /icons/brands/square-facebook.svg{" "}
        <img style={{ height: 20 }} src={icon5} />
      </span>
    ),
    value: "/icons/brands/square-facebook.svg",
    key: 5,
  },
  {
    label: (
      <span>
        /icons/brands/ioxhost.svg <img style={{ height: 20 }} src={icon6} />
      </span>
    ),
    value: "/icons/brands/ioxhost.svg",
    key: 6,
  },
  {
    label: (
      <span>
        /icons/brands/fonticons.svg <img style={{ height: 20 }} src={icon7} />
      </span>
    ),
    value: "/icons/brands/fonticons.svg",
    key: 7,
  },
  {
    label: (
      <span>
        /icons/brands/figma.svg <img style={{ height: 20 }} src={icon8} />
      </span>
    ),
    value: "/icons/brands/figma.svg",
    key: 8,
  },
  {
    label: (
      <span>
        /icons/brands/reddit-alien.svg{" "}
        <img style={{ height: 20 }} src={icon9} />
      </span>
    ),
    value: "/icons/brands/reddit-alien.svg",
    key: 9,
  },
  {
    label: (
      <span>
        /icons/brands/angrycreative.svg{" "}
        <img style={{ height: 20 }} src={icon10} />
      </span>
    ),
    value: "/icons/brands/angrycreative.svg",
    key: 10,
  },
  {
    label: (
      <span>
        /icons/brands/imdb.svg <img style={{ height: 20 }} src={icon11} />
      </span>
    ),
    value: "/icons/brands/imdb.svg",
    key: 11,
  },
  {
    label: (
      <span>
        /icons/brands/steam.svg <img style={{ height: 20 }} src={icon12} />
      </span>
    ),
    value: "/icons/brands/steam.svg",
    key: 12,
  },
  {
    label: (
      <span>
        /icons/brands/square-xing.svg{" "}
        <img style={{ height: 20 }} src={icon13} />
      </span>
    ),
    value: "/icons/brands/square-xing.svg",
    key: 13,
  },
  {
    label: (
      <span>
        /icons/brands/d-and-d-beyond.svg{" "}
        <img style={{ height: 20 }} src={icon14} />
      </span>
    ),
    value: "/icons/brands/d-and-d-beyond.svg",
    key: 14,
  },
  {
    label: (
      <span>
        /icons/brands/tumblr.svg <img style={{ height: 20 }} src={icon15} />
      </span>
    ),
    value: "/icons/brands/tumblr.svg",
    key: 15,
  },
  {
    label: (
      <span>
        /icons/brands/bandcamp.svg <img style={{ height: 20 }} src={icon16} />
      </span>
    ),
    value: "/icons/brands/bandcamp.svg",
    key: 16,
  },
  {
    label: (
      <span>
        /icons/brands/red-river.svg <img style={{ height: 20 }} src={icon17} />
      </span>
    ),
    value: "/icons/brands/red-river.svg",
    key: 17,
  },
  {
    label: (
      <span>
        /icons/brands/square-lastfm.svg{" "}
        <img style={{ height: 20 }} src={icon18} />
      </span>
    ),
    value: "/icons/brands/square-lastfm.svg",
    key: 18,
  },
  {
    label: (
      <span>
        /icons/brands/stackpath.svg <img style={{ height: 20 }} src={icon19} />
      </span>
    ),
    value: "/icons/brands/stackpath.svg",
    key: 19,
  },
  {
    label: (
      <span>
        /icons/brands/square-hacker-news.svg{" "}
        <img style={{ height: 20 }} src={icon20} />
      </span>
    ),
    value: "/icons/brands/square-hacker-news.svg",
    key: 20,
  },
  {
    label: (
      <span>
        /icons/brands/strava.svg <img style={{ height: 20 }} src={icon21} />
      </span>
    ),
    value: "/icons/brands/strava.svg",
    key: 21,
  },
  {
    label: (
      <span>
        /icons/brands/dropbox.svg <img style={{ height: 20 }} src={icon22} />
      </span>
    ),
    value: "/icons/brands/dropbox.svg",
    key: 22,
  },
  {
    label: (
      <span>
        /icons/brands/meta.svg <img style={{ height: 20 }} src={icon23} />
      </span>
    ),
    value: "/icons/brands/meta.svg",
    key: 23,
  },
  {
    label: (
      <span>
        /icons/brands/microsoft.svg <img style={{ height: 20 }} src={icon24} />
      </span>
    ),
    value: "/icons/brands/microsoft.svg",
    key: 24,
  },
  {
    label: (
      <span>
        /icons/brands/creative-commons-nd.svg{" "}
        <img style={{ height: 20 }} src={icon25} />
      </span>
    ),
    value: "/icons/brands/creative-commons-nd.svg",
    key: 25,
  },
  {
    label: (
      <span>
        /icons/brands/square-tumblr.svg{" "}
        <img style={{ height: 20 }} src={icon26} />
      </span>
    ),
    value: "/icons/brands/square-tumblr.svg",
    key: 26,
  },
  {
    label: (
      <span>
        /icons/brands/dyalog.svg <img style={{ height: 20 }} src={icon27} />
      </span>
    ),
    value: "/icons/brands/dyalog.svg",
    key: 27,
  },
  {
    label: (
      <span>
        /icons/brands/squarespace.svg{" "}
        <img style={{ height: 20 }} src={icon28} />
      </span>
    ),
    value: "/icons/brands/squarespace.svg",
    key: 28,
  },
  {
    label: (
      <span>
        /icons/brands/accusoft.svg <img style={{ height: 20 }} src={icon29} />
      </span>
    ),
    value: "/icons/brands/accusoft.svg",
    key: 29,
  },
  {
    label: (
      <span>
        /icons/brands/lastfm.svg <img style={{ height: 20 }} src={icon30} />
      </span>
    ),
    value: "/icons/brands/lastfm.svg",
    key: 30,
  },
  {
    label: (
      <span>
        /icons/brands/phoenix-squadron.svg{" "}
        <img style={{ height: 20 }} src={icon31} />
      </span>
    ),
    value: "/icons/brands/phoenix-squadron.svg",
    key: 31,
  },
  {
    label: (
      <span>
        /icons/brands/square-snapchat.svg{" "}
        <img style={{ height: 20 }} src={icon32} />
      </span>
    ),
    value: "/icons/brands/square-snapchat.svg",
    key: 32,
  },
  {
    label: (
      <span>
        /icons/brands/yelp.svg <img style={{ height: 20 }} src={icon33} />
      </span>
    ),
    value: "/icons/brands/yelp.svg",
    key: 33,
  },
  {
    label: (
      <span>
        /icons/brands/cloudflare.svg <img style={{ height: 20 }} src={icon34} />
      </span>
    ),
    value: "/icons/brands/cloudflare.svg",
    key: 34,
  },
  {
    label: (
      <span>
        /icons/brands/deviantart.svg <img style={{ height: 20 }} src={icon35} />
      </span>
    ),
    value: "/icons/brands/deviantart.svg",
    key: 35,
  },
  {
    label: (
      <span>
        /icons/brands/airbnb.svg <img style={{ height: 20 }} src={icon36} />
      </span>
    ),
    value: "/icons/brands/airbnb.svg",
    key: 36,
  },
  {
    label: (
      <span>
        /icons/brands/usps.svg <img style={{ height: 20 }} src={icon37} />
      </span>
    ),
    value: "/icons/brands/usps.svg",
    key: 37,
  },
  {
    label: (
      <span>
        /icons/brands/envira.svg <img style={{ height: 20 }} src={icon38} />
      </span>
    ),
    value: "/icons/brands/envira.svg",
    key: 38,
  },
  {
    label: (
      <span>
        /icons/brands/fulcrum.svg <img style={{ height: 20 }} src={icon39} />
      </span>
    ),
    value: "/icons/brands/fulcrum.svg",
    key: 39,
  },
  {
    label: (
      <span>
        /icons/brands/linux.svg <img style={{ height: 20 }} src={icon40} />
      </span>
    ),
    value: "/icons/brands/linux.svg",
    key: 40,
  },
  {
    label: (
      <span>
        /icons/brands/foursquare.svg <img style={{ height: 20 }} src={icon41} />
      </span>
    ),
    value: "/icons/brands/foursquare.svg",
    key: 41,
  },
  {
    label: (
      <span>
        /icons/brands/bilibili.svg <img style={{ height: 20 }} src={icon42} />
      </span>
    ),
    value: "/icons/brands/bilibili.svg",
    key: 42,
  },
  {
    label: (
      <span>
        /icons/brands/drupal.svg <img style={{ height: 20 }} src={icon43} />
      </span>
    ),
    value: "/icons/brands/drupal.svg",
    key: 43,
  },
  {
    label: (
      <span>
        /icons/brands/cc-stripe.svg <img style={{ height: 20 }} src={icon44} />
      </span>
    ),
    value: "/icons/brands/cc-stripe.svg",
    key: 44,
  },
  {
    label: (
      <span>
        /icons/brands/square-youtube.svg{" "}
        <img style={{ height: 20 }} src={icon45} />
      </span>
    ),
    value: "/icons/brands/square-youtube.svg",
    key: 45,
  },
  {
    label: (
      <span>
        /icons/brands/edge-legacy.svg{" "}
        <img style={{ height: 20 }} src={icon46} />
      </span>
    ),
    value: "/icons/brands/edge-legacy.svg",
    key: 46,
  },
  {
    label: (
      <span>
        /icons/brands/dev.svg <img style={{ height: 20 }} src={icon47} />
      </span>
    ),
    value: "/icons/brands/dev.svg",
    key: 47,
  },
  {
    label: (
      <span>
        /icons/brands/speakap.svg <img style={{ height: 20 }} src={icon48} />
      </span>
    ),
    value: "/icons/brands/speakap.svg",
    key: 48,
  },
  {
    label: (
      <span>
        /icons/brands/android.svg <img style={{ height: 20 }} src={icon49} />
      </span>
    ),
    value: "/icons/brands/android.svg",
    key: 49,
  },
  {
    label: (
      <span>
        /icons/brands/ethereum.svg <img style={{ height: 20 }} src={icon50} />
      </span>
    ),
    value: "/icons/brands/ethereum.svg",
    key: 50,
  },
  {
    label: (
      <span>
        /icons/brands/shirtsinbulk.svg{" "}
        <img style={{ height: 20 }} src={icon51} />
      </span>
    ),
    value: "/icons/brands/shirtsinbulk.svg",
    key: 51,
  },
  {
    label: (
      <span>
        /icons/brands/black-tie.svg <img style={{ height: 20 }} src={icon52} />
      </span>
    ),
    value: "/icons/brands/black-tie.svg",
    key: 52,
  },
  {
    label: (
      <span>
        /icons/brands/odnoklassniki.svg{" "}
        <img style={{ height: 20 }} src={icon53} />
      </span>
    ),
    value: "/icons/brands/odnoklassniki.svg",
    key: 53,
  },
  {
    label: (
      <span>
        /icons/brands/fonticons-fi.svg{" "}
        <img style={{ height: 20 }} src={icon54} />
      </span>
    ),
    value: "/icons/brands/fonticons-fi.svg",
    key: 54,
  },
  {
    label: (
      <span>
        /icons/brands/cc-amex.svg <img style={{ height: 20 }} src={icon55} />
      </span>
    ),
    value: "/icons/brands/cc-amex.svg",
    key: 55,
  },
  {
    label: (
      <span>
        /icons/brands/js.svg <img style={{ height: 20 }} src={icon56} />
      </span>
    ),
    value: "/icons/brands/js.svg",
    key: 56,
  },
  {
    label: (
      <span>
        /icons/brands/galactic-senate.svg{" "}
        <img style={{ height: 20 }} src={icon57} />
      </span>
    ),
    value: "/icons/brands/galactic-senate.svg",
    key: 57,
  },
  {
    label: (
      <span>
        /icons/brands/reacteurope.svg{" "}
        <img style={{ height: 20 }} src={icon58} />
      </span>
    ),
    value: "/icons/brands/reacteurope.svg",
    key: 58,
  },
  {
    label: (
      <span>
        /icons/brands/openid.svg <img style={{ height: 20 }} src={icon59} />
      </span>
    ),
    value: "/icons/brands/openid.svg",
    key: 59,
  },
  {
    label: (
      <span>
        /icons/brands/gitlab.svg <img style={{ height: 20 }} src={icon60} />
      </span>
    ),
    value: "/icons/brands/gitlab.svg",
    key: 60,
  },
  {
    label: (
      <span>
        /icons/brands/sourcetree.svg <img style={{ height: 20 }} src={icon61} />
      </span>
    ),
    value: "/icons/brands/sourcetree.svg",
    key: 61,
  },
  {
    label: (
      <span>
        /icons/brands/gg.svg <img style={{ height: 20 }} src={icon62} />
      </span>
    ),
    value: "/icons/brands/gg.svg",
    key: 62,
  },
  {
    label: (
      <span>
        /icons/brands/joget.svg <img style={{ height: 20 }} src={icon63} />
      </span>
    ),
    value: "/icons/brands/joget.svg",
    key: 63,
  },
  {
    label: (
      <span>
        /icons/brands/weibo.svg <img style={{ height: 20 }} src={icon64} />
      </span>
    ),
    value: "/icons/brands/weibo.svg",
    key: 64,
  },
  {
    label: (
      <span>
        /icons/brands/aws.svg <img style={{ height: 20 }} src={icon65} />
      </span>
    ),
    value: "/icons/brands/aws.svg",
    key: 65,
  },
  {
    label: (
      <span>
        /icons/brands/sass.svg <img style={{ height: 20 }} src={icon66} />
      </span>
    ),
    value: "/icons/brands/sass.svg",
    key: 66,
  },
  {
    label: (
      <span>
        /icons/brands/cc-paypal.svg <img style={{ height: 20 }} src={icon67} />
      </span>
    ),
    value: "/icons/brands/cc-paypal.svg",
    key: 67,
  },
  {
    label: (
      <span>
        /icons/brands/superpowers.svg{" "}
        <img style={{ height: 20 }} src={icon68} />
      </span>
    ),
    value: "/icons/brands/superpowers.svg",
    key: 68,
  },
  {
    label: (
      <span>
        /icons/brands/rust.svg <img style={{ height: 20 }} src={icon69} />
      </span>
    ),
    value: "/icons/brands/rust.svg",
    key: 69,
  },
  {
    label: (
      <span>
        /icons/brands/sellcast.svg <img style={{ height: 20 }} src={icon70} />
      </span>
    ),
    value: "/icons/brands/sellcast.svg",
    key: 70,
  },
  {
    label: (
      <span>
        /icons/brands/java.svg <img style={{ height: 20 }} src={icon71} />
      </span>
    ),
    value: "/icons/brands/java.svg",
    key: 71,
  },
  {
    label: (
      <span>
        /icons/brands/orcid.svg <img style={{ height: 20 }} src={icon72} />
      </span>
    ),
    value: "/icons/brands/orcid.svg",
    key: 72,
  },
  {
    label: (
      <span>
        /icons/brands/tencent-weibo.svg{" "}
        <img style={{ height: 20 }} src={icon73} />
      </span>
    ),
    value: "/icons/brands/tencent-weibo.svg",
    key: 73,
  },
  {
    label: (
      <span>
        /icons/brands/trade-federation.svg{" "}
        <img style={{ height: 20 }} src={icon74} />
      </span>
    ),
    value: "/icons/brands/trade-federation.svg",
    key: 74,
  },
  {
    label: (
      <span>
        /icons/brands/viber.svg <img style={{ height: 20 }} src={icon75} />
      </span>
    ),
    value: "/icons/brands/viber.svg",
    key: 75,
  },
  {
    label: (
      <span>
        /icons/brands/ello.svg <img style={{ height: 20 }} src={icon76} />
      </span>
    ),
    value: "/icons/brands/ello.svg",
    key: 76,
  },
  {
    label: (
      <span>
        /icons/brands/wix.svg <img style={{ height: 20 }} src={icon77} />
      </span>
    ),
    value: "/icons/brands/wix.svg",
    key: 77,
  },
  {
    label: (
      <span>
        /icons/brands/mizuni.svg <img style={{ height: 20 }} src={icon78} />
      </span>
    ),
    value: "/icons/brands/mizuni.svg",
    key: 78,
  },
  {
    label: (
      <span>
        /icons/brands/leanpub.svg <img style={{ height: 20 }} src={icon79} />
      </span>
    ),
    value: "/icons/brands/leanpub.svg",
    key: 79,
  },
  {
    label: (
      <span>
        /icons/brands/bitbucket.svg <img style={{ height: 20 }} src={icon80} />
      </span>
    ),
    value: "/icons/brands/bitbucket.svg",
    key: 80,
  },
  {
    label: (
      <span>
        /icons/brands/pagelines.svg <img style={{ height: 20 }} src={icon81} />
      </span>
    ),
    value: "/icons/brands/pagelines.svg",
    key: 81,
  },
  {
    label: (
      <span>
        /icons/brands/creative-commons.svg{" "}
        <img style={{ height: 20 }} src={icon82} />
      </span>
    ),
    value: "/icons/brands/creative-commons.svg",
    key: 82,
  },
  {
    label: (
      <span>
        /icons/brands/github.svg <img style={{ height: 20 }} src={icon83} />
      </span>
    ),
    value: "/icons/brands/github.svg",
    key: 83,
  },
  {
    label: (
      <span>
        /icons/brands/viacoin.svg <img style={{ height: 20 }} src={icon84} />
      </span>
    ),
    value: "/icons/brands/viacoin.svg",
    key: 84,
  },
  {
    label: (
      <span>
        /icons/brands/freebsd.svg <img style={{ height: 20 }} src={icon85} />
      </span>
    ),
    value: "/icons/brands/freebsd.svg",
    key: 85,
  },
  {
    label: (
      <span>
        /icons/brands/rebel.svg <img style={{ height: 20 }} src={icon86} />
      </span>
    ),
    value: "/icons/brands/rebel.svg",
    key: 86,
  },
  {
    label: (
      <span>
        /icons/brands/square-steam.svg{" "}
        <img style={{ height: 20 }} src={icon87} />
      </span>
    ),
    value: "/icons/brands/square-steam.svg",
    key: 87,
  },
  {
    label: (
      <span>
        /icons/brands/fedex.svg <img style={{ height: 20 }} src={icon88} />
      </span>
    ),
    value: "/icons/brands/fedex.svg",
    key: 88,
  },
  {
    label: (
      <span>
        /icons/brands/usb.svg <img style={{ height: 20 }} src={icon89} />
      </span>
    ),
    value: "/icons/brands/usb.svg",
    key: 89,
  },
  {
    label: (
      <span>
        /icons/brands/empire.svg <img style={{ height: 20 }} src={icon90} />
      </span>
    ),
    value: "/icons/brands/empire.svg",
    key: 90,
  },
  {
    label: (
      <span>
        /icons/brands/simplybuilt.svg{" "}
        <img style={{ height: 20 }} src={icon91} />
      </span>
    ),
    value: "/icons/brands/simplybuilt.svg",
    key: 91,
  },
  {
    label: (
      <span>
        /icons/brands/google-drive.svg{" "}
        <img style={{ height: 20 }} src={icon92} />
      </span>
    ),
    value: "/icons/brands/google-drive.svg",
    key: 92,
  },
  {
    label: (
      <span>
        /icons/brands/microblog.svg <img style={{ height: 20 }} src={icon93} />
      </span>
    ),
    value: "/icons/brands/microblog.svg",
    key: 93,
  },
  {
    label: (
      <span>
        /icons/brands/etsy.svg <img style={{ height: 20 }} src={icon94} />
      </span>
    ),
    value: "/icons/brands/etsy.svg",
    key: 94,
  },
  {
    label: (
      <span>
        /icons/brands/bimobject.svg <img style={{ height: 20 }} src={icon95} />
      </span>
    ),
    value: "/icons/brands/bimobject.svg",
    key: 95,
  },
  {
    label: (
      <span>
        /icons/brands/app-store-ios.svg{" "}
        <img style={{ height: 20 }} src={icon96} />
      </span>
    ),
    value: "/icons/brands/app-store-ios.svg",
    key: 96,
  },
  {
    label: (
      <span>
        /icons/brands/gripfire.svg <img style={{ height: 20 }} src={icon97} />
      </span>
    ),
    value: "/icons/brands/gripfire.svg",
    key: 97,
  },
  {
    label: (
      <span>
        /icons/brands/500px.svg <img style={{ height: 20 }} src={icon98} />
      </span>
    ),
    value: "/icons/brands/500px.svg",
    key: 98,
  },
  {
    label: (
      <span>
        /icons/brands/speaker-deck.svg{" "}
        <img style={{ height: 20 }} src={icon99} />
      </span>
    ),
    value: "/icons/brands/speaker-deck.svg",
    key: 99,
  },
  {
    label: (
      <span>
        /icons/brands/deskpro.svg <img style={{ height: 20 }} src={icon100} />
      </span>
    ),
    value: "/icons/brands/deskpro.svg",
    key: 100,
  },
  {
    label: (
      <span>
        /icons/brands/critical-role.svg{" "}
        <img style={{ height: 20 }} src={icon101} />
      </span>
    ),
    value: "/icons/brands/critical-role.svg",
    key: 101,
  },
  {
    label: (
      <span>
        /icons/brands/square-github.svg{" "}
        <img style={{ height: 20 }} src={icon102} />
      </span>
    ),
    value: "/icons/brands/square-github.svg",
    key: 102,
  },
  {
    label: (
      <span>
        /icons/brands/vimeo-v.svg <img style={{ height: 20 }} src={icon103} />
      </span>
    ),
    value: "/icons/brands/vimeo-v.svg",
    key: 103,
  },
  {
    label: (
      <span>
        /icons/brands/stripe-s.svg <img style={{ height: 20 }} src={icon104} />
      </span>
    ),
    value: "/icons/brands/stripe-s.svg",
    key: 104,
  },
  {
    label: (
      <span>
        /icons/brands/keybase.svg <img style={{ height: 20 }} src={icon105} />
      </span>
    ),
    value: "/icons/brands/keybase.svg",
    key: 105,
  },
  {
    label: (
      <span>
        /icons/brands/yandex-international.svg{" "}
        <img style={{ height: 20 }} src={icon106} />
      </span>
    ),
    value: "/icons/brands/yandex-international.svg",
    key: 106,
  },
  {
    label: (
      <span>
        /icons/brands/umbraco.svg <img style={{ height: 20 }} src={icon107} />
      </span>
    ),
    value: "/icons/brands/umbraco.svg",
    key: 107,
  },
  {
    label: (
      <span>
        /icons/brands/joomla.svg <img style={{ height: 20 }} src={icon108} />
      </span>
    ),
    value: "/icons/brands/joomla.svg",
    key: 108,
  },
  {
    label: (
      <span>
        /icons/brands/google-play.svg{" "}
        <img style={{ height: 20 }} src={icon109} />
      </span>
    ),
    value: "/icons/brands/google-play.svg",
    key: 109,
  },
  {
    label: (
      <span>
        /icons/brands/uniregistry.svg{" "}
        <img style={{ height: 20 }} src={icon110} />
      </span>
    ),
    value: "/icons/brands/uniregistry.svg",
    key: 110,
  },
  {
    label: (
      <span>
        /icons/brands/d-and-d.svg <img style={{ height: 20 }} src={icon111} />
      </span>
    ),
    value: "/icons/brands/d-and-d.svg",
    key: 111,
  },
  {
    label: (
      <span>
        /icons/brands/kaggle.svg <img style={{ height: 20 }} src={icon112} />
      </span>
    ),
    value: "/icons/brands/kaggle.svg",
    key: 112,
  },
  {
    label: (
      <span>
        /icons/brands/hubspot.svg <img style={{ height: 20 }} src={icon113} />
      </span>
    ),
    value: "/icons/brands/hubspot.svg",
    key: 113,
  },
  {
    label: (
      <span>
        /icons/brands/confluence.svg{" "}
        <img style={{ height: 20 }} src={icon114} />
      </span>
    ),
    value: "/icons/brands/confluence.svg",
    key: 114,
  },
  {
    label: (
      <span>
        /icons/brands/square-gitlab.svg{" "}
        <img style={{ height: 20 }} src={icon115} />
      </span>
    ),
    value: "/icons/brands/square-gitlab.svg",
    key: 115,
  },
  {
    label: (
      <span>
        /icons/brands/servicestack.svg{" "}
        <img style={{ height: 20 }} src={icon116} />
      </span>
    ),
    value: "/icons/brands/servicestack.svg",
    key: 116,
  },
  {
    label: (
      <span>
        /icons/brands/npm.svg <img style={{ height: 20 }} src={icon117} />
      </span>
    ),
    value: "/icons/brands/npm.svg",
    key: 117,
  },
  {
    label: (
      <span>
        /icons/brands/square-font-awesome.svg{" "}
        <img style={{ height: 20 }} src={icon118} />
      </span>
    ),
    value: "/icons/brands/square-font-awesome.svg",
    key: 118,
  },
  {
    label: (
      <span>
        /icons/brands/square-whatsapp.svg{" "}
        <img style={{ height: 20 }} src={icon119} />
      </span>
    ),
    value: "/icons/brands/square-whatsapp.svg",
    key: 119,
  },
  {
    label: (
      <span>
        /icons/brands/creative-commons-nc.svg{" "}
        <img style={{ height: 20 }} src={icon120} />
      </span>
    ),
    value: "/icons/brands/creative-commons-nc.svg",
    key: 120,
  },
  {
    label: (
      <span>
        /icons/brands/galactic-republic.svg{" "}
        <img style={{ height: 20 }} src={icon121} />
      </span>
    ),
    value: "/icons/brands/galactic-republic.svg",
    key: 121,
  },
  {
    label: (
      <span>
        /icons/brands/square-instagram.svg{" "}
        <img style={{ height: 20 }} src={icon122} />
      </span>
    ),
    value: "/icons/brands/square-instagram.svg",
    key: 122,
  },
  {
    label: (
      <span>
        /icons/brands/raspberry-pi.svg{" "}
        <img style={{ height: 20 }} src={icon123} />
      </span>
    ),
    value: "/icons/brands/raspberry-pi.svg",
    key: 123,
  },
  {
    label: (
      <span>
        /icons/brands/mastodon.svg <img style={{ height: 20 }} src={icon124} />
      </span>
    ),
    value: "/icons/brands/mastodon.svg",
    key: 124,
  },
  {
    label: (
      <span>
        /icons/brands/square-git.svg{" "}
        <img style={{ height: 20 }} src={icon125} />
      </span>
    ),
    value: "/icons/brands/square-git.svg",
    key: 125,
  },
  {
    label: (
      <span>
        /icons/brands/42-group.svg <img style={{ height: 20 }} src={icon126} />
      </span>
    ),
    value: "/icons/brands/42-group.svg",
    key: 126,
  },
  {
    label: (
      <span>
        /icons/brands/angular.svg <img style={{ height: 20 }} src={icon127} />
      </span>
    ),
    value: "/icons/brands/angular.svg",
    key: 127,
  },
  {
    label: (
      <span>
        /icons/brands/untappd.svg <img style={{ height: 20 }} src={icon128} />
      </span>
    ),
    value: "/icons/brands/untappd.svg",
    key: 128,
  },
  {
    label: (
      <span>
        /icons/brands/apper.svg <img style={{ height: 20 }} src={icon129} />
      </span>
    ),
    value: "/icons/brands/apper.svg",
    key: 129,
  },
  {
    label: (
      <span>
        /icons/brands/git-alt.svg <img style={{ height: 20 }} src={icon130} />
      </span>
    ),
    value: "/icons/brands/git-alt.svg",
    key: 130,
  },
  {
    label: (
      <span>
        /icons/brands/sitrox.svg <img style={{ height: 20 }} src={icon131} />
      </span>
    ),
    value: "/icons/brands/sitrox.svg",
    key: 131,
  },
  {
    label: (
      <span>
        /icons/brands/vk.svg <img style={{ height: 20 }} src={icon132} />
      </span>
    ),
    value: "/icons/brands/vk.svg",
    key: 132,
  },
  {
    label: (
      <span>
        /icons/brands/vimeo.svg <img style={{ height: 20 }} src={icon133} />
      </span>
    ),
    value: "/icons/brands/vimeo.svg",
    key: 133,
  },
  {
    label: (
      <span>
        /icons/brands/discourse.svg <img style={{ height: 20 }} src={icon134} />
      </span>
    ),
    value: "/icons/brands/discourse.svg",
    key: 134,
  },
  {
    label: (
      <span>
        /icons/brands/hotjar.svg <img style={{ height: 20 }} src={icon135} />
      </span>
    ),
    value: "/icons/brands/hotjar.svg",
    key: 135,
  },
  {
    label: (
      <span>
        /icons/brands/symfony.svg <img style={{ height: 20 }} src={icon136} />
      </span>
    ),
    value: "/icons/brands/symfony.svg",
    key: 136,
  },
  {
    label: (
      <span>
        /icons/brands/square-odnoklassniki.svg{" "}
        <img style={{ height: 20 }} src={icon137} />
      </span>
    ),
    value: "/icons/brands/square-odnoklassniki.svg",
    key: 137,
  },
  {
    label: (
      <span>
        /icons/brands/skyatlas.svg <img style={{ height: 20 }} src={icon138} />
      </span>
    ),
    value: "/icons/brands/skyatlas.svg",
    key: 138,
  },
  {
    label: (
      <span>
        /icons/brands/hacker-news.svg{" "}
        <img style={{ height: 20 }} src={icon139} />
      </span>
    ),
    value: "/icons/brands/hacker-news.svg",
    key: 139,
  },
  {
    label: (
      <span>
        /icons/brands/instagram.svg <img style={{ height: 20 }} src={icon140} />
      </span>
    ),
    value: "/icons/brands/instagram.svg",
    key: 140,
  },
  {
    label: (
      <span>
        /icons/brands/y-combinator.svg{" "}
        <img style={{ height: 20 }} src={icon141} />
      </span>
    ),
    value: "/icons/brands/y-combinator.svg",
    key: 141,
  },
  {
    label: (
      <span>
        /icons/brands/shopify.svg <img style={{ height: 20 }} src={icon142} />
      </span>
    ),
    value: "/icons/brands/shopify.svg",
    key: 142,
  },
  {
    label: (
      <span>
        /icons/brands/mendeley.svg <img style={{ height: 20 }} src={icon143} />
      </span>
    ),
    value: "/icons/brands/mendeley.svg",
    key: 143,
  },
  {
    label: (
      <span>
        /icons/brands/wodu.svg <img style={{ height: 20 }} src={icon144} />
      </span>
    ),
    value: "/icons/brands/wodu.svg",
    key: 144,
  },
  {
    label: (
      <span>
        /icons/brands/fort-awesome-alt.svg{" "}
        <img style={{ height: 20 }} src={icon145} />
      </span>
    ),
    value: "/icons/brands/fort-awesome-alt.svg",
    key: 145,
  },
  {
    label: (
      <span>
        /icons/brands/r-project.svg <img style={{ height: 20 }} src={icon146} />
      </span>
    ),
    value: "/icons/brands/r-project.svg",
    key: 146,
  },
  {
    label: (
      <span>
        /icons/brands/edge.svg <img style={{ height: 20 }} src={icon147} />
      </span>
    ),
    value: "/icons/brands/edge.svg",
    key: 147,
  },
  {
    label: (
      <span>
        /icons/brands/yammer.svg <img style={{ height: 20 }} src={icon148} />
      </span>
    ),
    value: "/icons/brands/yammer.svg",
    key: 148,
  },
  {
    label: (
      <span>
        /icons/brands/bots.svg <img style={{ height: 20 }} src={icon149} />
      </span>
    ),
    value: "/icons/brands/bots.svg",
    key: 149,
  },
  {
    label: (
      <span>
        /icons/brands/fly.svg <img style={{ height: 20 }} src={icon150} />
      </span>
    ),
    value: "/icons/brands/fly.svg",
    key: 150,
  },
  {
    label: (
      <span>
        /icons/brands/safari.svg <img style={{ height: 20 }} src={icon151} />
      </span>
    ),
    value: "/icons/brands/safari.svg",
    key: 151,
  },
  {
    label: (
      <span>
        /icons/brands/square-behance.svg{" "}
        <img style={{ height: 20 }} src={icon152} />
      </span>
    ),
    value: "/icons/brands/square-behance.svg",
    key: 152,
  },
  {
    label: (
      <span>
        /icons/brands/pied-piper-hat.svg{" "}
        <img style={{ height: 20 }} src={icon153} />
      </span>
    ),
    value: "/icons/brands/pied-piper-hat.svg",
    key: 153,
  },
  {
    label: (
      <span>
        /icons/brands/audible.svg <img style={{ height: 20 }} src={icon154} />
      </span>
    ),
    value: "/icons/brands/audible.svg",
    key: 154,
  },
  {
    label: (
      <span>
        /icons/brands/yoast.svg <img style={{ height: 20 }} src={icon155} />
      </span>
    ),
    value: "/icons/brands/yoast.svg",
    key: 155,
  },
  {
    label: (
      <span>
        /icons/brands/fort-awesome.svg{" "}
        <img style={{ height: 20 }} src={icon156} />
      </span>
    ),
    value: "/icons/brands/fort-awesome.svg",
    key: 156,
  },
  {
    label: (
      <span>
        /icons/brands/square-dribbble.svg{" "}
        <img style={{ height: 20 }} src={icon157} />
      </span>
    ),
    value: "/icons/brands/square-dribbble.svg",
    key: 157,
  },
  {
    label: (
      <span>
        /icons/brands/python.svg <img style={{ height: 20 }} src={icon158} />
      </span>
    ),
    value: "/icons/brands/python.svg",
    key: 158,
  },
  {
    label: (
      <span>
        /icons/brands/pix.svg <img style={{ height: 20 }} src={icon159} />
      </span>
    ),
    value: "/icons/brands/pix.svg",
    key: 159,
  },
  {
    label: (
      <span>
        /icons/brands/cc-apple-pay.svg{" "}
        <img style={{ height: 20 }} src={icon160} />
      </span>
    ),
    value: "/icons/brands/cc-apple-pay.svg",
    key: 160,
  },
  {
    label: (
      <span>
        /icons/brands/docker.svg <img style={{ height: 20 }} src={icon161} />
      </span>
    ),
    value: "/icons/brands/docker.svg",
    key: 161,
  },
  {
    label: (
      <span>
        /icons/brands/git.svg <img style={{ height: 20 }} src={icon162} />
      </span>
    ),
    value: "/icons/brands/git.svg",
    key: 162,
  },
  {
    label: (
      <span>
        /icons/brands/blogger.svg <img style={{ height: 20 }} src={icon163} />
      </span>
    ),
    value: "/icons/brands/blogger.svg",
    key: 163,
  },
  {
    label: (
      <span>
        /icons/brands/itch-io.svg <img style={{ height: 20 }} src={icon164} />
      </span>
    ),
    value: "/icons/brands/itch-io.svg",
    key: 164,
  },
  {
    label: (
      <span>
        /icons/brands/nfc-symbol.svg{" "}
        <img style={{ height: 20 }} src={icon165} />
      </span>
    ),
    value: "/icons/brands/nfc-symbol.svg",
    key: 165,
  },
  {
    label: (
      <span>
        /icons/brands/creative-commons-nc-eu.svg{" "}
        <img style={{ height: 20 }} src={icon166} />
      </span>
    ),
    value: "/icons/brands/creative-commons-nc-eu.svg",
    key: 166,
  },
  {
    label: (
      <span>
        /icons/brands/schlix.svg <img style={{ height: 20 }} src={icon167} />
      </span>
    ),
    value: "/icons/brands/schlix.svg",
    key: 167,
  },
  {
    label: (
      <span>
        /icons/brands/mailchimp.svg <img style={{ height: 20 }} src={icon168} />
      </span>
    ),
    value: "/icons/brands/mailchimp.svg",
    key: 168,
  },
  {
    label: (
      <span>
        /icons/brands/neos.svg <img style={{ height: 20 }} src={icon169} />
      </span>
    ),
    value: "/icons/brands/neos.svg",
    key: 169,
  },
  {
    label: (
      <span>
        /icons/brands/creative-commons-pd.svg{" "}
        <img style={{ height: 20 }} src={icon170} />
      </span>
    ),
    value: "/icons/brands/creative-commons-pd.svg",
    key: 170,
  },
  {
    label: (
      <span>
        /icons/brands/pinterest-p.svg{" "}
        <img style={{ height: 20 }} src={icon171} />
      </span>
    ),
    value: "/icons/brands/pinterest-p.svg",
    key: 171,
  },
  {
    label: (
      <span>
        /icons/brands/digg.svg <img style={{ height: 20 }} src={icon172} />
      </span>
    ),
    value: "/icons/brands/digg.svg",
    key: 172,
  },
  {
    label: (
      <span>
        /icons/brands/bitcoin.svg <img style={{ height: 20 }} src={icon173} />
      </span>
    ),
    value: "/icons/brands/bitcoin.svg",
    key: 173,
  },
  {
    label: (
      <span>
        /icons/brands/whmcs.svg <img style={{ height: 20 }} src={icon174} />
      </span>
    ),
    value: "/icons/brands/whmcs.svg",
    key: 174,
  },
  {
    label: (
      <span>
        /icons/brands/delicious.svg <img style={{ height: 20 }} src={icon175} />
      </span>
    ),
    value: "/icons/brands/delicious.svg",
    key: 175,
  },
  {
    label: (
      <span>
        /icons/brands/hackerrank.svg{" "}
        <img style={{ height: 20 }} src={icon176} />
      </span>
    ),
    value: "/icons/brands/hackerrank.svg",
    key: 176,
  },
  {
    label: (
      <span>
        /icons/brands/telegram.svg <img style={{ height: 20 }} src={icon177} />
      </span>
    ),
    value: "/icons/brands/telegram.svg",
    key: 177,
  },
  {
    label: (
      <span>
        /icons/brands/cc-discover.svg{" "}
        <img style={{ height: 20 }} src={icon178} />
      </span>
    ),
    value: "/icons/brands/cc-discover.svg",
    key: 178,
  },
  {
    label: (
      <span>
        /icons/brands/centercode.svg{" "}
        <img style={{ height: 20 }} src={icon179} />
      </span>
    ),
    value: "/icons/brands/centercode.svg",
    key: 179,
  },
  {
    label: (
      <span>
        /icons/brands/erlang.svg <img style={{ height: 20 }} src={icon180} />
      </span>
    ),
    value: "/icons/brands/erlang.svg",
    key: 180,
  },
  {
    label: (
      <span>
        /icons/brands/medrt.svg <img style={{ height: 20 }} src={icon181} />
      </span>
    ),
    value: "/icons/brands/medrt.svg",
    key: 181,
  },
  {
    label: (
      <span>
        /icons/brands/salesforce.svg{" "}
        <img style={{ height: 20 }} src={icon182} />
      </span>
    ),
    value: "/icons/brands/salesforce.svg",
    key: 182,
  },
  {
    label: (
      <span>
        /icons/brands/meetup.svg <img style={{ height: 20 }} src={icon183} />
      </span>
    ),
    value: "/icons/brands/meetup.svg",
    key: 183,
  },
  {
    label: (
      <span>
        /icons/brands/square-vimeo.svg{" "}
        <img style={{ height: 20 }} src={icon184} />
      </span>
    ),
    value: "/icons/brands/square-vimeo.svg",
    key: 184,
  },
  {
    label: (
      <span>
        /icons/brands/wizards-of-the-coast.svg{" "}
        <img style={{ height: 20 }} src={icon185} />
      </span>
    ),
    value: "/icons/brands/wizards-of-the-coast.svg",
    key: 185,
  },
  {
    label: (
      <span>
        /icons/brands/markdown.svg <img style={{ height: 20 }} src={icon186} />
      </span>
    ),
    value: "/icons/brands/markdown.svg",
    key: 186,
  },
  {
    label: (
      <span>
        /icons/brands/instalod.svg <img style={{ height: 20 }} src={icon187} />
      </span>
    ),
    value: "/icons/brands/instalod.svg",
    key: 187,
  },
  {
    label: (
      <span>
        /icons/brands/padlet.svg <img style={{ height: 20 }} src={icon188} />
      </span>
    ),
    value: "/icons/brands/padlet.svg",
    key: 188,
  },
  {
    label: (
      <span>
        /icons/brands/goodreads.svg <img style={{ height: 20 }} src={icon189} />
      </span>
    ),
    value: "/icons/brands/goodreads.svg",
    key: 189,
  },
  {
    label: (
      <span>
        /icons/brands/unity.svg <img style={{ height: 20 }} src={icon190} />
      </span>
    ),
    value: "/icons/brands/unity.svg",
    key: 190,
  },
  {
    label: (
      <span>
        /icons/brands/creative-commons-sampling-plus.svg{" "}
        <img style={{ height: 20 }} src={icon191} />
      </span>
    ),
    value: "/icons/brands/creative-commons-sampling-plus.svg",
    key: 191,
  },
  {
    label: (
      <span>
        /icons/brands/invision.svg <img style={{ height: 20 }} src={icon192} />
      </span>
    ),
    value: "/icons/brands/invision.svg",
    key: 192,
  },
  {
    label: (
      <span>
        /icons/brands/mix.svg <img style={{ height: 20 }} src={icon193} />
      </span>
    ),
    value: "/icons/brands/mix.svg",
    key: 193,
  },
  {
    label: (
      <span>
        /icons/brands/gratipay.svg <img style={{ height: 20 }} src={icon194} />
      </span>
    ),
    value: "/icons/brands/gratipay.svg",
    key: 194,
  },
  {
    label: (
      <span>
        /icons/brands/magento.svg <img style={{ height: 20 }} src={icon195} />
      </span>
    ),
    value: "/icons/brands/magento.svg",
    key: 195,
  },
  {
    label: (
      <span>
        /icons/brands/bluetooth.svg <img style={{ height: 20 }} src={icon196} />
      </span>
    ),
    value: "/icons/brands/bluetooth.svg",
    key: 196,
  },
  {
    label: (
      <span>
        /icons/brands/houzz.svg <img style={{ height: 20 }} src={icon197} />
      </span>
    ),
    value: "/icons/brands/houzz.svg",
    key: 197,
  },
  {
    label: (
      <span>
        /icons/brands/expeditedssl.svg{" "}
        <img style={{ height: 20 }} src={icon198} />
      </span>
    ),
    value: "/icons/brands/expeditedssl.svg",
    key: 198,
  },
  {
    label: (
      <span>
        /icons/brands/the-red-yeti.svg{" "}
        <img style={{ height: 20 }} src={icon199} />
      </span>
    ),
    value: "/icons/brands/the-red-yeti.svg",
    key: 199,
  },
  {
    label: (
      <span>
        /icons/brands/canadian-maple-leaf.svg{" "}
        <img style={{ height: 20 }} src={icon200} />
      </span>
    ),
    value: "/icons/brands/canadian-maple-leaf.svg",
    key: 200,
  },
  {
    label: (
      <span>
        /icons/brands/sith.svg <img style={{ height: 20 }} src={icon201} />
      </span>
    ),
    value: "/icons/brands/sith.svg",
    key: 201,
  },
  {
    label: (
      <span>
        /icons/brands/algolia.svg <img style={{ height: 20 }} src={icon202} />
      </span>
    ),
    value: "/icons/brands/algolia.svg",
    key: 202,
  },
  {
    label: (
      <span>
        /icons/brands/ussunnah.svg <img style={{ height: 20 }} src={icon203} />
      </span>
    ),
    value: "/icons/brands/ussunnah.svg",
    key: 203,
  },
  {
    label: (
      <span>
        /icons/brands/sellsy.svg <img style={{ height: 20 }} src={icon204} />
      </span>
    ),
    value: "/icons/brands/sellsy.svg",
    key: 204,
  },
  {
    label: (
      <span>
        /icons/brands/jira.svg <img style={{ height: 20 }} src={icon205} />
      </span>
    ),
    value: "/icons/brands/jira.svg",
    key: 205,
  },
  {
    label: (
      <span>
        /icons/brands/spotify.svg <img style={{ height: 20 }} src={icon206} />
      </span>
    ),
    value: "/icons/brands/spotify.svg",
    key: 206,
  },
  {
    label: (
      <span>
        /icons/brands/intercom.svg <img style={{ height: 20 }} src={icon207} />
      </span>
    ),
    value: "/icons/brands/intercom.svg",
    key: 207,
  },
  {
    label: (
      <span>
        /icons/brands/wolf-pack-battalion.svg{" "}
        <img style={{ height: 20 }} src={icon208} />
      </span>
    ),
    value: "/icons/brands/wolf-pack-battalion.svg",
    key: 208,
  },
  {
    label: (
      <span>
        /icons/brands/square-twitter.svg{" "}
        <img style={{ height: 20 }} src={icon209} />
      </span>
    ),
    value: "/icons/brands/square-twitter.svg",
    key: 209,
  },
  {
    label: (
      <span>
        /icons/brands/flipboard.svg <img style={{ height: 20 }} src={icon210} />
      </span>
    ),
    value: "/icons/brands/flipboard.svg",
    key: 210,
  },
  {
    label: (
      <span>
        /icons/brands/google-wallet.svg{" "}
        <img style={{ height: 20 }} src={icon211} />
      </span>
    ),
    value: "/icons/brands/google-wallet.svg",
    key: 211,
  },
  {
    label: (
      <span>
        /icons/brands/shopware.svg <img style={{ height: 20 }} src={icon212} />
      </span>
    ),
    value: "/icons/brands/shopware.svg",
    key: 212,
  },
  {
    label: (
      <span>
        /icons/brands/phoenix-framework.svg{" "}
        <img style={{ height: 20 }} src={icon213} />
      </span>
    ),
    value: "/icons/brands/phoenix-framework.svg",
    key: 213,
  },
  {
    label: (
      <span>
        /icons/brands/wirsindhandwerk.svg{" "}
        <img style={{ height: 20 }} src={icon214} />
      </span>
    ),
    value: "/icons/brands/wirsindhandwerk.svg",
    key: 214,
  },
  {
    label: (
      <span>
        /icons/brands/kickstarter-k.svg{" "}
        <img style={{ height: 20 }} src={icon215} />
      </span>
    ),
    value: "/icons/brands/kickstarter-k.svg",
    key: 215,
  },
  {
    label: (
      <span>
        /icons/brands/cc-jcb.svg <img style={{ height: 20 }} src={icon216} />
      </span>
    ),
    value: "/icons/brands/cc-jcb.svg",
    key: 216,
  },
  {
    label: (
      <span>
        /icons/brands/researchgate.svg{" "}
        <img style={{ height: 20 }} src={icon217} />
      </span>
    ),
    value: "/icons/brands/researchgate.svg",
    key: 217,
  },
  {
    label: (
      <span>
        /icons/brands/lyft.svg <img style={{ height: 20 }} src={icon218} />
      </span>
    ),
    value: "/icons/brands/lyft.svg",
    key: 218,
  },
  {
    label: (
      <span>
        /icons/brands/wpressr.svg <img style={{ height: 20 }} src={icon219} />
      </span>
    ),
    value: "/icons/brands/wpressr.svg",
    key: 219,
  },
  {
    label: (
      <span>
        /icons/brands/octopus-deploy.svg{" "}
        <img style={{ height: 20 }} src={icon220} />
      </span>
    ),
    value: "/icons/brands/octopus-deploy.svg",
    key: 220,
  },
  {
    label: (
      <span>
        /icons/brands/periscope.svg <img style={{ height: 20 }} src={icon221} />
      </span>
    ),
    value: "/icons/brands/periscope.svg",
    key: 221,
  },
  {
    label: (
      <span>
        /icons/brands/modx.svg <img style={{ height: 20 }} src={icon222} />
      </span>
    ),
    value: "/icons/brands/modx.svg",
    key: 222,
  },
  {
    label: (
      <span>
        /icons/brands/kickstarter.svg{" "}
        <img style={{ height: 20 }} src={icon223} />
      </span>
    ),
    value: "/icons/brands/kickstarter.svg",
    key: 223,
  },
  {
    label: (
      <span>
        /icons/brands/slideshare.svg{" "}
        <img style={{ height: 20 }} src={icon224} />
      </span>
    ),
    value: "/icons/brands/slideshare.svg",
    key: 224,
  },
  {
    label: (
      <span>
        /icons/brands/snapchat.svg <img style={{ height: 20 }} src={icon225} />
      </span>
    ),
    value: "/icons/brands/snapchat.svg",
    key: 225,
  },
  {
    label: (
      <span>
        /icons/brands/font-awesome.svg{" "}
        <img style={{ height: 20 }} src={icon226} />
      </span>
    ),
    value: "/icons/brands/font-awesome.svg",
    key: 226,
  },
  {
    label: (
      <span>
        /icons/brands/buy-n-large.svg{" "}
        <img style={{ height: 20 }} src={icon227} />
      </span>
    ),
    value: "/icons/brands/buy-n-large.svg",
    key: 227,
  },
  {
    label: (
      <span>
        /icons/brands/readme.svg <img style={{ height: 20 }} src={icon228} />
      </span>
    ),
    value: "/icons/brands/readme.svg",
    key: 228,
  },
  {
    label: (
      <span>
        /icons/brands/creative-commons-nc-jp.svg{" "}
        <img style={{ height: 20 }} src={icon229} />
      </span>
    ),
    value: "/icons/brands/creative-commons-nc-jp.svg",
    key: 229,
  },
  {
    label: (
      <span>
        /icons/brands/themeisle.svg <img style={{ height: 20 }} src={icon230} />
      </span>
    ),
    value: "/icons/brands/themeisle.svg",
    key: 230,
  },
  {
    label: (
      <span>
        /icons/brands/html5.svg <img style={{ height: 20 }} src={icon231} />
      </span>
    ),
    value: "/icons/brands/html5.svg",
    key: 231,
  },
  {
    label: (
      <span>
        /icons/brands/replyd.svg <img style={{ height: 20 }} src={icon232} />
      </span>
    ),
    value: "/icons/brands/replyd.svg",
    key: 232,
  },
  {
    label: (
      <span>
        /icons/brands/cuttlefish.svg{" "}
        <img style={{ height: 20 }} src={icon233} />
      </span>
    ),
    value: "/icons/brands/cuttlefish.svg",
    key: 233,
  },
  {
    label: (
      <span>
        /icons/brands/rockrms.svg <img style={{ height: 20 }} src={icon234} />
      </span>
    ),
    value: "/icons/brands/rockrms.svg",
    key: 234,
  },
  {
    label: (
      <span>
        /icons/brands/ravelry.svg <img style={{ height: 20 }} src={icon235} />
      </span>
    ),
    value: "/icons/brands/ravelry.svg",
    key: 235,
  },
  {
    label: (
      <span>
        /icons/brands/quora.svg <img style={{ height: 20 }} src={icon236} />
      </span>
    ),
    value: "/icons/brands/quora.svg",
    key: 236,
  },
  {
    label: (
      <span>
        /icons/brands/monero.svg <img style={{ height: 20 }} src={icon237} />
      </span>
    ),
    value: "/icons/brands/monero.svg",
    key: 237,
  },
  {
    label: (
      <span>
        /icons/brands/hire-a-helper.svg{" "}
        <img style={{ height: 20 }} src={icon238} />
      </span>
    ),
    value: "/icons/brands/hire-a-helper.svg",
    key: 238,
  },
  {
    label: (
      <span>
        /icons/brands/github-alt.svg{" "}
        <img style={{ height: 20 }} src={icon239} />
      </span>
    ),
    value: "/icons/brands/github-alt.svg",
    key: 239,
  },
  {
    label: (
      <span>
        /icons/brands/creative-commons-share.svg{" "}
        <img style={{ height: 20 }} src={icon240} />
      </span>
    ),
    value: "/icons/brands/creative-commons-share.svg",
    key: 240,
  },
  {
    label: (
      <span>
        /icons/brands/yarn.svg <img style={{ height: 20 }} src={icon241} />
      </span>
    ),
    value: "/icons/brands/yarn.svg",
    key: 241,
  },
  {
    label: (
      <span>
        /icons/brands/cc-visa.svg <img style={{ height: 20 }} src={icon242} />
      </span>
    ),
    value: "/icons/brands/cc-visa.svg",
    key: 242,
  },
  {
    label: (
      <span>
        /icons/brands/dochub.svg <img style={{ height: 20 }} src={icon243} />
      </span>
    ),
    value: "/icons/brands/dochub.svg",
    key: 243,
  },
  {
    label: (
      <span>
        /icons/brands/laravel.svg <img style={{ height: 20 }} src={icon244} />
      </span>
    ),
    value: "/icons/brands/laravel.svg",
    key: 244,
  },
  {
    label: (
      <span>
        /icons/brands/digital-ocean.svg{" "}
        <img style={{ height: 20 }} src={icon245} />
      </span>
    ),
    value: "/icons/brands/digital-ocean.svg",
    key: 245,
  },
  {
    label: (
      <span>
        /icons/brands/earlybirds.svg{" "}
        <img style={{ height: 20 }} src={icon246} />
      </span>
    ),
    value: "/icons/brands/earlybirds.svg",
    key: 246,
  },
  {
    label: (
      <span>
        /icons/brands/centos.svg <img style={{ height: 20 }} src={icon247} />
      </span>
    ),
    value: "/icons/brands/centos.svg",
    key: 247,
  },
  {
    label: (
      <span>
        /icons/brands/bluetooth-b.svg{" "}
        <img style={{ height: 20 }} src={icon248} />
      </span>
    ),
    value: "/icons/brands/bluetooth-b.svg",
    key: 248,
  },
  {
    label: (
      <span>
        /icons/brands/teamspeak.svg <img style={{ height: 20 }} src={icon249} />
      </span>
    ),
    value: "/icons/brands/teamspeak.svg",
    key: 249,
  },
  {
    label: (
      <span>
        /icons/brands/square-google-plus.svg{" "}
        <img style={{ height: 20 }} src={icon250} />
      </span>
    ),
    value: "/icons/brands/square-google-plus.svg",
    key: 250,
  },
  {
    label: (
      <span>
        /icons/brands/wpexplorer.svg{" "}
        <img style={{ height: 20 }} src={icon251} />
      </span>
    ),
    value: "/icons/brands/wpexplorer.svg",
    key: 251,
  },
  {
    label: (
      <span>
        /icons/brands/first-order-alt.svg{" "}
        <img style={{ height: 20 }} src={icon252} />
      </span>
    ),
    value: "/icons/brands/first-order-alt.svg",
    key: 252,
  },
  {
    label: (
      <span>
        /icons/brands/ubuntu.svg <img style={{ height: 20 }} src={icon253} />
      </span>
    ),
    value: "/icons/brands/ubuntu.svg",
    key: 253,
  },
  {
    label: (
      <span>
        /icons/brands/opencart.svg <img style={{ height: 20 }} src={icon254} />
      </span>
    ),
    value: "/icons/brands/opencart.svg",
    key: 254,
  },
  {
    label: (
      <span>
        /icons/brands/pied-piper.svg{" "}
        <img style={{ height: 20 }} src={icon255} />
      </span>
    ),
    value: "/icons/brands/pied-piper.svg",
    key: 255,
  },
  {
    label: (
      <span>
        /icons/brands/paypal.svg <img style={{ height: 20 }} src={icon256} />
      </span>
    ),
    value: "/icons/brands/paypal.svg",
    key: 256,
  },
  {
    label: (
      <span>
        /icons/brands/suse.svg <img style={{ height: 20 }} src={icon257} />
      </span>
    ),
    value: "/icons/brands/suse.svg",
    key: 257,
  },
  {
    label: (
      <span>
        /icons/brands/wpbeginner.svg{" "}
        <img style={{ height: 20 }} src={icon258} />
      </span>
    ),
    value: "/icons/brands/wpbeginner.svg",
    key: 258,
  },
  {
    label: (
      <span>
        /icons/brands/deezer.svg <img style={{ height: 20 }} src={icon259} />
      </span>
    ),
    value: "/icons/brands/deezer.svg",
    key: 259,
  },
  {
    label: (
      <span>
        /icons/brands/blogger-b.svg <img style={{ height: 20 }} src={icon260} />
      </span>
    ),
    value: "/icons/brands/blogger-b.svg",
    key: 260,
  },
  {
    label: (
      <span>
        /icons/brands/rocketchat.svg{" "}
        <img style={{ height: 20 }} src={icon261} />
      </span>
    ),
    value: "/icons/brands/rocketchat.svg",
    key: 261,
  },
  {
    label: (
      <span>
        /icons/brands/unsplash.svg <img style={{ height: 20 }} src={icon262} />
      </span>
    ),
    value: "/icons/brands/unsplash.svg",
    key: 262,
  },
  {
    label: (
      <span>
        /icons/brands/draft2digital.svg{" "}
        <img style={{ height: 20 }} src={icon263} />
      </span>
    ),
    value: "/icons/brands/draft2digital.svg",
    key: 263,
  },
  {
    label: (
      <span>
        /icons/brands/playstation.svg{" "}
        <img style={{ height: 20 }} src={icon264} />
      </span>
    ),
    value: "/icons/brands/playstation.svg",
    key: 264,
  },
  {
    label: (
      <span>
        /icons/brands/facebook.svg <img style={{ height: 20 }} src={icon265} />
      </span>
    ),
    value: "/icons/brands/facebook.svg",
    key: 265,
  },
  {
    label: (
      <span>
        /icons/brands/amazon-pay.svg{" "}
        <img style={{ height: 20 }} src={icon266} />
      </span>
    ),
    value: "/icons/brands/amazon-pay.svg",
    key: 266,
  },
  {
    label: (
      <span>
        /icons/brands/square-font-awesome-stroke.svg{" "}
        <img style={{ height: 20 }} src={icon267} />
      </span>
    ),
    value: "/icons/brands/square-font-awesome-stroke.svg",
    key: 267,
  },
  {
    label: (
      <span>
        /icons/brands/amazon.svg <img style={{ height: 20 }} src={icon268} />
      </span>
    ),
    value: "/icons/brands/amazon.svg",
    key: 268,
  },
  {
    label: (
      <span>
        /icons/brands/quinscape.svg <img style={{ height: 20 }} src={icon269} />
      </span>
    ),
    value: "/icons/brands/quinscape.svg",
    key: 269,
  },
  {
    label: (
      <span>
        /icons/brands/free-code-camp.svg{" "}
        <img style={{ height: 20 }} src={icon270} />
      </span>
    ),
    value: "/icons/brands/free-code-camp.svg",
    key: 270,
  },
  {
    label: (
      <span>
        /icons/brands/vine.svg <img style={{ height: 20 }} src={icon271} />
      </span>
    ),
    value: "/icons/brands/vine.svg",
    key: 271,
  },
  {
    label: (
      <span>
        /icons/brands/pinterest.svg <img style={{ height: 20 }} src={icon272} />
      </span>
    ),
    value: "/icons/brands/pinterest.svg",
    key: 272,
  },
  {
    label: (
      <span>
        /icons/brands/creative-commons-sa.svg{" "}
        <img style={{ height: 20 }} src={icon273} />
      </span>
    ),
    value: "/icons/brands/creative-commons-sa.svg",
    key: 273,
  },
  {
    label: (
      <span>
        /icons/brands/creative-commons-pd-alt.svg{" "}
        <img style={{ height: 20 }} src={icon274} />
      </span>
    ),
    value: "/icons/brands/creative-commons-pd-alt.svg",
    key: 274,
  },
  {
    label: (
      <span>
        /icons/brands/stack-overflow.svg{" "}
        <img style={{ height: 20 }} src={icon275} />
      </span>
    ),
    value: "/icons/brands/stack-overflow.svg",
    key: 275,
  },
  {
    label: (
      <span>
        /icons/brands/page4.svg <img style={{ height: 20 }} src={icon276} />
      </span>
    ),
    value: "/icons/brands/page4.svg",
    key: 276,
  },
  {
    label: (
      <span>
        /icons/brands/mdb.svg <img style={{ height: 20 }} src={icon277} />
      </span>
    ),
    value: "/icons/brands/mdb.svg",
    key: 277,
  },
  {
    label: (
      <span>
        /icons/brands/osi.svg <img style={{ height: 20 }} src={icon278} />
      </span>
    ),
    value: "/icons/brands/osi.svg",
    key: 278,
  },
  {
    label: (
      <span>
        /icons/brands/yandex.svg <img style={{ height: 20 }} src={icon279} />
      </span>
    ),
    value: "/icons/brands/yandex.svg",
    key: 279,
  },
  {
    label: (
      <span>
        /icons/brands/whatsapp.svg <img style={{ height: 20 }} src={icon280} />
      </span>
    ),
    value: "/icons/brands/whatsapp.svg",
    key: 280,
  },
  {
    label: (
      <span>
        /icons/brands/google.svg <img style={{ height: 20 }} src={icon281} />
      </span>
    ),
    value: "/icons/brands/google.svg",
    key: 281,
  },
  {
    label: (
      <span>
        /icons/brands/chromecast.svg{" "}
        <img style={{ height: 20 }} src={icon282} />
      </span>
    ),
    value: "/icons/brands/chromecast.svg",
    key: 282,
  },
  {
    label: (
      <span>
        /icons/brands/line.svg <img style={{ height: 20 }} src={icon283} />
      </span>
    ),
    value: "/icons/brands/line.svg",
    key: 283,
  },
  {
    label: (
      <span>
        /icons/brands/battle-net.svg{" "}
        <img style={{ height: 20 }} src={icon284} />
      </span>
    ),
    value: "/icons/brands/battle-net.svg",
    key: 284,
  },
  {
    label: (
      <span>
        /icons/brands/affiliatetheme.svg{" "}
        <img style={{ height: 20 }} src={icon285} />
      </span>
    ),
    value: "/icons/brands/affiliatetheme.svg",
    key: 285,
  },
  {
    label: (
      <span>
        /icons/brands/atlassian.svg <img style={{ height: 20 }} src={icon286} />
      </span>
    ),
    value: "/icons/brands/atlassian.svg",
    key: 286,
  },
  {
    label: (
      <span>
        /icons/brands/aviato.svg <img style={{ height: 20 }} src={icon287} />
      </span>
    ),
    value: "/icons/brands/aviato.svg",
    key: 287,
  },
  {
    label: (
      <span>
        /icons/brands/bity.svg <img style={{ height: 20 }} src={icon288} />
      </span>
    ),
    value: "/icons/brands/bity.svg",
    key: 288,
  },
  {
    label: (
      <span>
        /icons/brands/php.svg <img style={{ height: 20 }} src={icon289} />
      </span>
    ),
    value: "/icons/brands/php.svg",
    key: 289,
  },
  {
    label: (
      <span>
        /icons/brands/stumbleupon-circle.svg{" "}
        <img style={{ height: 20 }} src={icon290} />
      </span>
    ),
    value: "/icons/brands/stumbleupon-circle.svg",
    key: 290,
  },
  {
    label: (
      <span>
        /icons/brands/stripe.svg <img style={{ height: 20 }} src={icon291} />
      </span>
    ),
    value: "/icons/brands/stripe.svg",
    key: 291,
  },
  {
    label: (
      <span>
        /icons/brands/jedi-order.svg{" "}
        <img style={{ height: 20 }} src={icon292} />
      </span>
    ),
    value: "/icons/brands/jedi-order.svg",
    key: 292,
  },
  {
    label: (
      <span>
        /icons/brands/think-peaks.svg{" "}
        <img style={{ height: 20 }} src={icon293} />
      </span>
    ),
    value: "/icons/brands/think-peaks.svg",
    key: 293,
  },
  {
    label: (
      <span>
        /icons/brands/buromobelexperte.svg{" "}
        <img style={{ height: 20 }} src={icon294} />
      </span>
    ),
    value: "/icons/brands/buromobelexperte.svg",
    key: 294,
  },
  {
    label: (
      <span>
        /icons/brands/phabricator.svg{" "}
        <img style={{ height: 20 }} src={icon295} />
      </span>
    ),
    value: "/icons/brands/phabricator.svg",
    key: 295,
  },
  {
    label: (
      <span>
        /icons/brands/apple-pay.svg <img style={{ height: 20 }} src={icon296} />
      </span>
    ),
    value: "/icons/brands/apple-pay.svg",
    key: 296,
  },
  {
    label: (
      <span>
        /icons/brands/artstation.svg{" "}
        <img style={{ height: 20 }} src={icon297} />
      </span>
    ),
    value: "/icons/brands/artstation.svg",
    key: 297,
  },
  {
    label: (
      <span>
        /icons/brands/scribd.svg <img style={{ height: 20 }} src={icon298} />
      </span>
    ),
    value: "/icons/brands/scribd.svg",
    key: 298,
  },
  {
    label: (
      <span>
        /icons/brands/stack-exchange.svg{" "}
        <img style={{ height: 20 }} src={icon299} />
      </span>
    ),
    value: "/icons/brands/stack-exchange.svg",
    key: 299,
  },
  {
    label: (
      <span>
        /icons/brands/fedora.svg <img style={{ height: 20 }} src={icon300} />
      </span>
    ),
    value: "/icons/brands/fedora.svg",
    key: 300,
  },
  {
    label: (
      <span>
        /icons/brands/jenkins.svg <img style={{ height: 20 }} src={icon301} />
      </span>
    ),
    value: "/icons/brands/jenkins.svg",
    key: 301,
  },
  {
    label: (
      <span>
        /icons/brands/studiovinari.svg{" "}
        <img style={{ height: 20 }} src={icon302} />
      </span>
    ),
    value: "/icons/brands/studiovinari.svg",
    key: 302,
  },
  {
    label: (
      <span>
        /icons/brands/glide.svg <img style={{ height: 20 }} src={icon303} />
      </span>
    ),
    value: "/icons/brands/glide.svg",
    key: 303,
  },
  {
    label: (
      <span>
        /icons/brands/adn.svg <img style={{ height: 20 }} src={icon304} />
      </span>
    ),
    value: "/icons/brands/adn.svg",
    key: 304,
  },
  {
    label: (
      <span>
        /icons/brands/css3-alt.svg <img style={{ height: 20 }} src={icon305} />
      </span>
    ),
    value: "/icons/brands/css3-alt.svg",
    key: 305,
  },
  {
    label: (
      <span>
        /icons/brands/xing.svg <img style={{ height: 20 }} src={icon306} />
      </span>
    ),
    value: "/icons/brands/xing.svg",
    key: 306,
  },
  {
    label: (
      <span>
        /icons/brands/medapps.svg <img style={{ height: 20 }} src={icon307} />
      </span>
    ),
    value: "/icons/brands/medapps.svg",
    key: 307,
  },
  {
    label: (
      <span>
        /icons/brands/redhat.svg <img style={{ height: 20 }} src={icon308} />
      </span>
    ),
    value: "/icons/brands/redhat.svg",
    key: 308,
  },
  {
    label: (
      <span>
        /icons/brands/uncharted.svg <img style={{ height: 20 }} src={icon309} />
      </span>
    ),
    value: "/icons/brands/uncharted.svg",
    key: 309,
  },
  {
    label: (
      <span>
        /icons/brands/fantasy-flight-games.svg{" "}
        <img style={{ height: 20 }} src={icon310} />
      </span>
    ),
    value: "/icons/brands/fantasy-flight-games.svg",
    key: 310,
  },
  {
    label: (
      <span>
        /icons/brands/facebook-f.svg{" "}
        <img style={{ height: 20 }} src={icon311} />
      </span>
    ),
    value: "/icons/brands/facebook-f.svg",
    key: 311,
  },
  {
    label: (
      <span>
        /icons/brands/perbyte.svg <img style={{ height: 20 }} src={icon312} />
      </span>
    ),
    value: "/icons/brands/perbyte.svg",
    key: 312,
  },
  {
    label: (
      <span>
        /icons/brands/hips.svg <img style={{ height: 20 }} src={icon313} />
      </span>
    ),
    value: "/icons/brands/hips.svg",
    key: 313,
  },
  {
    label: (
      <span>
        /icons/brands/google-pay.svg{" "}
        <img style={{ height: 20 }} src={icon314} />
      </span>
    ),
    value: "/icons/brands/google-pay.svg",
    key: 314,
  },
  {
    label: (
      <span>
        /icons/brands/twitch.svg <img style={{ height: 20 }} src={icon315} />
      </span>
    ),
    value: "/icons/brands/twitch.svg",
    key: 315,
  },
  {
    label: (
      <span>
        /icons/brands/cc-diners-club.svg{" "}
        <img style={{ height: 20 }} src={icon316} />
      </span>
    ),
    value: "/icons/brands/cc-diners-club.svg",
    key: 316,
  },
  {
    label: (
      <span>
        /icons/brands/windows.svg <img style={{ height: 20 }} src={icon317} />
      </span>
    ),
    value: "/icons/brands/windows.svg",
    key: 317,
  },
  {
    label: (
      <span>
        /icons/brands/rev.svg <img style={{ height: 20 }} src={icon318} />
      </span>
    ),
    value: "/icons/brands/rev.svg",
    key: 318,
  },
  {
    label: (
      <span>
        /icons/brands/btc.svg <img style={{ height: 20 }} src={icon319} />
      </span>
    ),
    value: "/icons/brands/btc.svg",
    key: 319,
  },
  {
    label: (
      <span>
        /icons/brands/creative-commons-zero.svg{" "}
        <img style={{ height: 20 }} src={icon320} />
      </span>
    ),
    value: "/icons/brands/creative-commons-zero.svg",
    key: 320,
  },
  {
    label: (
      <span>
        /icons/brands/elementor.svg <img style={{ height: 20 }} src={icon321} />
      </span>
    ),
    value: "/icons/brands/elementor.svg",
    key: 321,
  },
  {
    label: (
      <span>
        /icons/brands/square-pied-piper.svg{" "}
        <img style={{ height: 20 }} src={icon322} />
      </span>
    ),
    value: "/icons/brands/square-pied-piper.svg",
    key: 322,
  },
  {
    label: (
      <span>
        /icons/brands/youtube.svg <img style={{ height: 20 }} src={icon323} />
      </span>
    ),
    value: "/icons/brands/youtube.svg",
    key: 323,
  },
  {
    label: (
      <span>
        /icons/brands/accessible-icon.svg{" "}
        <img style={{ height: 20 }} src={icon324} />
      </span>
    ),
    value: "/icons/brands/accessible-icon.svg",
    key: 324,
  },
  {
    label: (
      <span>
        /icons/brands/gg-circle.svg <img style={{ height: 20 }} src={icon325} />
      </span>
    ),
    value: "/icons/brands/gg-circle.svg",
    key: 325,
  },
  {
    label: (
      <span>
        /icons/brands/app-store.svg <img style={{ height: 20 }} src={icon326} />
      </span>
    ),
    value: "/icons/brands/app-store.svg",
    key: 326,
  },
  {
    label: (
      <span>
        /icons/brands/codiepie.svg <img style={{ height: 20 }} src={icon327} />
      </span>
    ),
    value: "/icons/brands/codiepie.svg",
    key: 327,
  },
  {
    label: (
      <span>
        /icons/brands/stumbleupon.svg{" "}
        <img style={{ height: 20 }} src={icon328} />
      </span>
    ),
    value: "/icons/brands/stumbleupon.svg",
    key: 328,
  },
  {
    label: (
      <span>
        /icons/brands/nutritionix.svg{" "}
        <img style={{ height: 20 }} src={icon329} />
      </span>
    ),
    value: "/icons/brands/nutritionix.svg",
    key: 329,
  },
  {
    label: (
      <span>
        /icons/brands/linkedin-in.svg{" "}
        <img style={{ height: 20 }} src={icon330} />
      </span>
    ),
    value: "/icons/brands/linkedin-in.svg",
    key: 330,
  },
  {
    label: (
      <span>
        /icons/brands/jsfiddle.svg <img style={{ height: 20 }} src={icon331} />
      </span>
    ),
    value: "/icons/brands/jsfiddle.svg",
    key: 331,
  },
  {
    label: (
      <span>
        /icons/brands/yahoo.svg <img style={{ height: 20 }} src={icon332} />
      </span>
    ),
    value: "/icons/brands/yahoo.svg",
    key: 332,
  },
  {
    label: (
      <span>
        /icons/brands/grav.svg <img style={{ height: 20 }} src={icon333} />
      </span>
    ),
    value: "/icons/brands/grav.svg",
    key: 333,
  },
  {
    label: (
      <span>
        /icons/brands/firefox-browser.svg{" "}
        <img style={{ height: 20 }} src={icon334} />
      </span>
    ),
    value: "/icons/brands/firefox-browser.svg",
    key: 334,
  },
  {
    label: (
      <span>
        /icons/brands/sticker-mule.svg{" "}
        <img style={{ height: 20 }} src={icon335} />
      </span>
    ),
    value: "/icons/brands/sticker-mule.svg",
    key: 335,
  },
  {
    label: (
      <span>
        /icons/brands/goodreads-g.svg{" "}
        <img style={{ height: 20 }} src={icon336} />
      </span>
    ),
    value: "/icons/brands/goodreads-g.svg",
    key: 336,
  },
  {
    label: (
      <span>
        /icons/brands/uber.svg <img style={{ height: 20 }} src={icon337} />
      </span>
    ),
    value: "/icons/brands/uber.svg",
    key: 337,
  },
  {
    label: (
      <span>
        /icons/brands/sketch.svg <img style={{ height: 20 }} src={icon338} />
      </span>
    ),
    value: "/icons/brands/sketch.svg",
    key: 338,
  },
  {
    label: (
      <span>
        /icons/brands/creative-commons-remix.svg{" "}
        <img style={{ height: 20 }} src={icon339} />
      </span>
    ),
    value: "/icons/brands/creative-commons-remix.svg",
    key: 339,
  },
  {
    label: (
      <span>
        /icons/brands/itunes-note.svg{" "}
        <img style={{ height: 20 }} src={icon340} />
      </span>
    ),
    value: "/icons/brands/itunes-note.svg",
    key: 340,
  },
  {
    label: (
      <span>
        /icons/brands/gulp.svg <img style={{ height: 20 }} src={icon341} />
      </span>
    ),
    value: "/icons/brands/gulp.svg",
    key: 341,
  },
  {
    label: (
      <span>
        /icons/brands/keycdn.svg <img style={{ height: 20 }} src={icon342} />
      </span>
    ),
    value: "/icons/brands/keycdn.svg",
    key: 342,
  },
  {
    label: (
      <span>
        /icons/brands/diaspora.svg <img style={{ height: 20 }} src={icon343} />
      </span>
    ),
    value: "/icons/brands/diaspora.svg",
    key: 343,
  },
  {
    label: (
      <span>
        /icons/brands/cotton-bureau.svg{" "}
        <img style={{ height: 20 }} src={icon344} />
      </span>
    ),
    value: "/icons/brands/cotton-bureau.svg",
    key: 344,
  },
  {
    label: (
      <span>
        /icons/brands/reddit.svg <img style={{ height: 20 }} src={icon345} />
      </span>
    ),
    value: "/icons/brands/reddit.svg",
    key: 345,
  },
  {
    label: (
      <span>
        /icons/brands/alipay.svg <img style={{ height: 20 }} src={icon346} />
      </span>
    ),
    value: "/icons/brands/alipay.svg",
    key: 346,
  },
  {
    label: (
      <span>
        /icons/brands/cloudscale.svg{" "}
        <img style={{ height: 20 }} src={icon347} />
      </span>
    ),
    value: "/icons/brands/cloudscale.svg",
    key: 347,
  },
  {
    label: (
      <span>
        /icons/brands/buysellads.svg{" "}
        <img style={{ height: 20 }} src={icon348} />
      </span>
    ),
    value: "/icons/brands/buysellads.svg",
    key: 348,
  },
  {
    label: (
      <span>
        /icons/brands/steam-symbol.svg{" "}
        <img style={{ height: 20 }} src={icon349} />
      </span>
    ),
    value: "/icons/brands/steam-symbol.svg",
    key: 349,
  },
  {
    label: (
      <span>
        /icons/brands/asymmetrik.svg{" "}
        <img style={{ height: 20 }} src={icon350} />
      </span>
    ),
    value: "/icons/brands/asymmetrik.svg",
    key: 350,
  },
  {
    label: (
      <span>
        /icons/brands/angellist.svg <img style={{ height: 20 }} src={icon351} />
      </span>
    ),
    value: "/icons/brands/angellist.svg",
    key: 351,
  },
  {
    label: (
      <span>
        /icons/brands/wpforms.svg <img style={{ height: 20 }} src={icon352} />
      </span>
    ),
    value: "/icons/brands/wpforms.svg",
    key: 352,
  },
  {
    label: (
      <span>
        /icons/brands/linode.svg <img style={{ height: 20 }} src={icon353} />
      </span>
    ),
    value: "/icons/brands/linode.svg",
    key: 353,
  },
  {
    label: (
      <span>
        /icons/brands/guilded.svg <img style={{ height: 20 }} src={icon354} />
      </span>
    ),
    value: "/icons/brands/guilded.svg",
    key: 354,
  },
  {
    label: (
      <span>
        /icons/brands/avianex.svg <img style={{ height: 20 }} src={icon355} />
      </span>
    ),
    value: "/icons/brands/avianex.svg",
    key: 355,
  },
  {
    label: (
      <span>
        /icons/brands/evernote.svg <img style={{ height: 20 }} src={icon356} />
      </span>
    ),
    value: "/icons/brands/evernote.svg",
    key: 356,
  },
  {
    label: (
      <span>
        /icons/brands/codepen.svg <img style={{ height: 20 }} src={icon357} />
      </span>
    ),
    value: "/icons/brands/codepen.svg",
    key: 357,
  },
  {
    label: (
      <span>
        /icons/brands/dribbble.svg <img style={{ height: 20 }} src={icon358} />
      </span>
    ),
    value: "/icons/brands/dribbble.svg",
    key: 358,
  },
  {
    label: (
      <span>
        /icons/brands/cc-amazon-pay.svg{" "}
        <img style={{ height: 20 }} src={icon359} />
      </span>
    ),
    value: "/icons/brands/cc-amazon-pay.svg",
    key: 359,
  },
  {
    label: (
      <span>
        /icons/brands/xbox.svg <img style={{ height: 20 }} src={icon360} />
      </span>
    ),
    value: "/icons/brands/xbox.svg",
    key: 360,
  },
  {
    label: (
      <span>
        /icons/brands/trello.svg <img style={{ height: 20 }} src={icon361} />
      </span>
    ),
    value: "/icons/brands/trello.svg",
    key: 361,
  },
  {
    label: (
      <span>
        /icons/brands/adversal.svg <img style={{ height: 20 }} src={icon362} />
      </span>
    ),
    value: "/icons/brands/adversal.svg",
    key: 362,
  },
  {
    label: (
      <span>
        /icons/brands/dhl.svg <img style={{ height: 20 }} src={icon363} />
      </span>
    ),
    value: "/icons/brands/dhl.svg",
    key: 363,
  },
  {
    label: (
      <span>
        /icons/brands/hornbill.svg <img style={{ height: 20 }} src={icon364} />
      </span>
    ),
    value: "/icons/brands/hornbill.svg",
    key: 364,
  },
  {
    label: (
      <span>
        /icons/brands/themeco.svg <img style={{ height: 20 }} src={icon365} />
      </span>
    ),
    value: "/icons/brands/themeco.svg",
    key: 365,
  },
  {
    label: (
      <span>
        /icons/brands/google-plus.svg{" "}
        <img style={{ height: 20 }} src={icon366} />
      </span>
    ),
    value: "/icons/brands/google-plus.svg",
    key: 366,
  },
  {
    label: (
      <span>
        /icons/brands/contao.svg <img style={{ height: 20 }} src={icon367} />
      </span>
    ),
    value: "/icons/brands/contao.svg",
    key: 367,
  },
  {
    label: (
      <span>
        /icons/brands/linkedin.svg <img style={{ height: 20 }} src={icon368} />
      </span>
    ),
    value: "/icons/brands/linkedin.svg",
    key: 368,
  },
  {
    label: (
      <span>
        /icons/brands/ideal.svg <img style={{ height: 20 }} src={icon369} />
      </span>
    ),
    value: "/icons/brands/ideal.svg",
    key: 369,
  },
  {
    label: (
      <span>
        /icons/brands/mandalorian.svg{" "}
        <img style={{ height: 20 }} src={icon370} />
      </span>
    ),
    value: "/icons/brands/mandalorian.svg",
    key: 370,
  },
  {
    label: (
      <span>
        /icons/brands/hashnode.svg <img style={{ height: 20 }} src={icon371} />
      </span>
    ),
    value: "/icons/brands/hashnode.svg",
    key: 371,
  },
  {
    label: (
      <span>
        /icons/brands/old-republic.svg{" "}
        <img style={{ height: 20 }} src={icon372} />
      </span>
    ),
    value: "/icons/brands/old-republic.svg",
    key: 372,
  },
  {
    label: (
      <span>
        /icons/brands/qq.svg <img style={{ height: 20 }} src={icon373} />
      </span>
    ),
    value: "/icons/brands/qq.svg",
    key: 373,
  },
  {
    label: (
      <span>
        /icons/brands/vaadin.svg <img style={{ height: 20 }} src={icon374} />
      </span>
    ),
    value: "/icons/brands/vaadin.svg",
    key: 374,
  },
  {
    label: (
      <span>
        /icons/brands/uikit.svg <img style={{ height: 20 }} src={icon375} />
      </span>
    ),
    value: "/icons/brands/uikit.svg",
    key: 375,
  },
  {
    label: (
      <span>
        /icons/brands/react.svg <img style={{ height: 20 }} src={icon376} />
      </span>
    ),
    value: "/icons/brands/react.svg",
    key: 376,
  },
  {
    label: (
      <span>
        /icons/brands/creative-commons-by.svg{" "}
        <img style={{ height: 20 }} src={icon377} />
      </span>
    ),
    value: "/icons/brands/creative-commons-by.svg",
    key: 377,
  },
  {
    label: (
      <span>
        /icons/brands/square-pinterest.svg{" "}
        <img style={{ height: 20 }} src={icon378} />
      </span>
    ),
    value: "/icons/brands/square-pinterest.svg",
    key: 378,
  },
  {
    label: (
      <span>
        /icons/brands/watchman-monitoring.svg{" "}
        <img style={{ height: 20 }} src={icon379} />
      </span>
    ),
    value: "/icons/brands/watchman-monitoring.svg",
    key: 379,
  },
  {
    label: (
      <span>
        /icons/brands/optin-monster.svg{" "}
        <img style={{ height: 20 }} src={icon380} />
      </span>
    ),
    value: "/icons/brands/optin-monster.svg",
    key: 380,
  },
  {
    label: (
      <span>
        /icons/brands/twitter.svg <img style={{ height: 20 }} src={icon381} />
      </span>
    ),
    value: "/icons/brands/twitter.svg",
    key: 381,
  },
  {
    label: (
      <span>
        /icons/brands/less.svg <img style={{ height: 20 }} src={icon382} />
      </span>
    ),
    value: "/icons/brands/less.svg",
    key: 382,
  },
  {
    label: (
      <span>
        /icons/brands/dailymotion.svg{" "}
        <img style={{ height: 20 }} src={icon383} />
      </span>
    ),
    value: "/icons/brands/dailymotion.svg",
    key: 383,
  },
  {
    label: (
      <span>
        /icons/brands/patreon.svg <img style={{ height: 20 }} src={icon384} />
      </span>
    ),
    value: "/icons/brands/patreon.svg",
    key: 384,
  },
  {
    label: (
      <span>
        /icons/brands/swift.svg <img style={{ height: 20 }} src={icon385} />
      </span>
    ),
    value: "/icons/brands/swift.svg",
    key: 385,
  },
  {
    label: (
      <span>
        /icons/brands/discord.svg <img style={{ height: 20 }} src={icon386} />
      </span>
    ),
    value: "/icons/brands/discord.svg",
    key: 386,
  },
  {
    label: (
      <span>
        /icons/brands/opera.svg <img style={{ height: 20 }} src={icon387} />
      </span>
    ),
    value: "/icons/brands/opera.svg",
    key: 387,
  },
  {
    label: (
      <span>
        /icons/brands/gitter.svg <img style={{ height: 20 }} src={icon388} />
      </span>
    ),
    value: "/icons/brands/gitter.svg",
    key: 388,
  },
  {
    label: (
      <span>
        /icons/brands/buffer.svg <img style={{ height: 20 }} src={icon389} />
      </span>
    ),
    value: "/icons/brands/buffer.svg",
    key: 389,
  },
  {
    label: (
      <span>
        /icons/brands/css3.svg <img style={{ height: 20 }} src={icon390} />
      </span>
    ),
    value: "/icons/brands/css3.svg",
    key: 390,
  },
  {
    label: (
      <span>
        /icons/brands/renren.svg <img style={{ height: 20 }} src={icon391} />
      </span>
    ),
    value: "/icons/brands/renren.svg",
    key: 391,
  },
  {
    label: (
      <span>
        /icons/brands/square-reddit.svg{" "}
        <img style={{ height: 20 }} src={icon392} />
      </span>
    ),
    value: "/icons/brands/square-reddit.svg",
    key: 392,
  },
  {
    label: (
      <span>
        /icons/brands/ns8.svg <img style={{ height: 20 }} src={icon393} />
      </span>
    ),
    value: "/icons/brands/ns8.svg",
    key: 393,
  },
  {
    label: (
      <span>
        /icons/brands/sistrix.svg <img style={{ height: 20 }} src={icon394} />
      </span>
    ),
    value: "/icons/brands/sistrix.svg",
    key: 394,
  },
  {
    label: (
      <span>
        /icons/brands/vuejs.svg <img style={{ height: 20 }} src={icon395} />
      </span>
    ),
    value: "/icons/brands/vuejs.svg",
    key: 395,
  },
  {
    label: (
      <span>
        /icons/brands/mixcloud.svg <img style={{ height: 20 }} src={icon396} />
      </span>
    ),
    value: "/icons/brands/mixcloud.svg",
    key: 396,
  },
  {
    label: (
      <span>
        /icons/brands/firstdraft.svg{" "}
        <img style={{ height: 20 }} src={icon397} />
      </span>
    ),
    value: "/icons/brands/firstdraft.svg",
    key: 397,
  },
  {
    label: (
      <span>
        /icons/brands/slack.svg <img style={{ height: 20 }} src={icon398} />
      </span>
    ),
    value: "/icons/brands/slack.svg",
    key: 398,
  },
  {
    label: (
      <span>
        /icons/brands/zhihu.svg <img style={{ height: 20 }} src={icon399} />
      </span>
    ),
    value: "/icons/brands/zhihu.svg",
    key: 399,
  },
  {
    label: (
      <span>
        /icons/brands/soundcloud.svg{" "}
        <img style={{ height: 20 }} src={icon400} />
      </span>
    ),
    value: "/icons/brands/soundcloud.svg",
    key: 400,
  },
  {
    label: (
      <span>
        /icons/brands/product-hunt.svg{" "}
        <img style={{ height: 20 }} src={icon401} />
      </span>
    ),
    value: "/icons/brands/product-hunt.svg",
    key: 401,
  },
  {
    label: (
      <span>
        /icons/brands/wikipedia-w.svg{" "}
        <img style={{ height: 20 }} src={icon402} />
      </span>
    ),
    value: "/icons/brands/wikipedia-w.svg",
    key: 402,
  },
  {
    label: (
      <span>
        /icons/brands/space-awesome.svg{" "}
        <img style={{ height: 20 }} src={icon403} />
      </span>
    ),
    value: "/icons/brands/space-awesome.svg",
    key: 403,
  },
  {
    label: (
      <span>
        /icons/brands/pied-piper-pp.svg{" "}
        <img style={{ height: 20 }} src={icon404} />
      </span>
    ),
    value: "/icons/brands/pied-piper-pp.svg",
    key: 404,
  },
  {
    label: (
      <span>
        /icons/brands/creative-commons-sampling.svg{" "}
        <img style={{ height: 20 }} src={icon405} />
      </span>
    ),
    value: "/icons/brands/creative-commons-sampling.svg",
    key: 405,
  },
  {
    label: (
      <span>
        /icons/brands/hooli.svg <img style={{ height: 20 }} src={icon406} />
      </span>
    ),
    value: "/icons/brands/hooli.svg",
    key: 406,
  },
  {
    label: (
      <span>
        /icons/brands/first-order.svg{" "}
        <img style={{ height: 20 }} src={icon407} />
      </span>
    ),
    value: "/icons/brands/first-order.svg",
    key: 407,
  },
  {
    label: (
      <span>
        /icons/brands/glide-g.svg <img style={{ height: 20 }} src={icon408} />
      </span>
    ),
    value: "/icons/brands/glide-g.svg",
    key: 408,
  },
  {
    label: (
      <span>
        /icons/brands/ember.svg <img style={{ height: 20 }} src={icon409} />
      </span>
    ),
    value: "/icons/brands/ember.svg",
    key: 409,
  },
  {
    label: (
      <span>
        /icons/brands/gitkraken.svg <img style={{ height: 20 }} src={icon410} />
      </span>
    ),
    value: "/icons/brands/gitkraken.svg",
    key: 410,
  },
  {
    label: (
      <span>
        /icons/brands/skype.svg <img style={{ height: 20 }} src={icon411} />
      </span>
    ),
    value: "/icons/brands/skype.svg",
    key: 411,
  },
  {
    label: (
      <span>
        /icons/brands/gofore.svg <img style={{ height: 20 }} src={icon412} />
      </span>
    ),
    value: "/icons/brands/gofore.svg",
    key: 412,
  },
  {
    label: (
      <span>
        /icons/brands/ebay.svg <img style={{ height: 20 }} src={icon413} />
      </span>
    ),
    value: "/icons/brands/ebay.svg",
    key: 413,
  },
  {
    label: (
      <span>
        /icons/brands/cpanel.svg <img style={{ height: 20 }} src={icon414} />
      </span>
    ),
    value: "/icons/brands/cpanel.svg",
    key: 414,
  },
  {
    label: (
      <span>
        /icons/brands/wordpress.svg <img style={{ height: 20 }} src={icon415} />
      </span>
    ),
    value: "/icons/brands/wordpress.svg",
    key: 415,
  },
  {
    label: (
      <span>
        /icons/brands/korvue.svg <img style={{ height: 20 }} src={icon416} />
      </span>
    ),
    value: "/icons/brands/korvue.svg",
    key: 416,
  },
  {
    label: (
      <span>
        /icons/brands/cloudversify.svg{" "}
        <img style={{ height: 20 }} src={icon417} />
      </span>
    ),
    value: "/icons/brands/cloudversify.svg",
    key: 417,
  },
  {
    label: (
      <span>
        /icons/brands/grunt.svg <img style={{ height: 20 }} src={icon418} />
      </span>
    ),
    value: "/icons/brands/grunt.svg",
    key: 418,
  },
  {
    label: (
      <span>
        /icons/brands/viadeo.svg <img style={{ height: 20 }} src={icon419} />
      </span>
    ),
    value: "/icons/brands/viadeo.svg",
    key: 419,
  },
  {
    label: (
      <span>
        /icons/brands/wordpress-simple.svg{" "}
        <img style={{ height: 20 }} src={icon420} />
      </span>
    ),
    value: "/icons/brands/wordpress-simple.svg",
    key: 420,
  },
  {
    label: (
      <span>
        /icons/brands/blackberry.svg{" "}
        <img style={{ height: 20 }} src={icon421} />
      </span>
    ),
    value: "/icons/brands/blackberry.svg",
    key: 421,
  },
  {
    label: (
      <span>
        /icons/brands/facebook-messenger.svg{" "}
        <img style={{ height: 20 }} src={icon422} />
      </span>
    ),
    value: "/icons/brands/facebook-messenger.svg",
    key: 422,
  },
  {
    label: (
      <span>
        /icons/brands/pushed.svg <img style={{ height: 20 }} src={icon423} />
      </span>
    ),
    value: "/icons/brands/pushed.svg",
    key: 423,
  },
  {
    label: (
      <span>
        /icons/brands/hive.svg <img style={{ height: 20 }} src={icon424} />
      </span>
    ),
    value: "/icons/brands/hive.svg",
    key: 424,
  },
  {
    label: (
      <span>
        /icons/brands/amilia.svg <img style={{ height: 20 }} src={icon425} />
      </span>
    ),
    value: "/icons/brands/amilia.svg",
    key: 425,
  },
  {
    label: (
      <span>
        /icons/brands/pied-piper-alt.svg{" "}
        <img style={{ height: 20 }} src={icon426} />
      </span>
    ),
    value: "/icons/brands/pied-piper-alt.svg",
    key: 426,
  },
  {
    label: (
      <span>
        /icons/brands/nfc-directional.svg{" "}
        <img style={{ height: 20 }} src={icon427} />
      </span>
    ),
    value: "/icons/brands/nfc-directional.svg",
    key: 427,
  },
  {
    label: (
      <span>
        /icons/brands/cloudsmith.svg{" "}
        <img style={{ height: 20 }} src={icon428} />
      </span>
    ),
    value: "/icons/brands/cloudsmith.svg",
    key: 428,
  },
  {
    label: (
      <span>
        /icons/brands/chrome.svg <img style={{ height: 20 }} src={icon429} />
      </span>
    ),
    value: "/icons/brands/chrome.svg",
    key: 429,
  },
  {
    label: (
      <span>
        /icons/brands/forumbee.svg <img style={{ height: 20 }} src={icon430} />
      </span>
    ),
    value: "/icons/brands/forumbee.svg",
    key: 430,
  },
  {
    label: (
      <span>
        /icons/brands/node-js.svg <img style={{ height: 20 }} src={icon431} />
      </span>
    ),
    value: "/icons/brands/node-js.svg",
    key: 431,
  },
  {
    label: (
      <span>
        /icons/brands/maxcdn.svg <img style={{ height: 20 }} src={icon432} />
      </span>
    ),
    value: "/icons/brands/maxcdn.svg",
    key: 432,
  },
  {
    label: (
      <span>
        /icons/brands/apple.svg <img style={{ height: 20 }} src={icon433} />
      </span>
    ),
    value: "/icons/brands/apple.svg",
    key: 433,
  },
  {
    label: (
      <span>
        /icons/brands/napster.svg <img style={{ height: 20 }} src={icon434} />
      </span>
    ),
    value: "/icons/brands/napster.svg",
    key: 434,
  },
  {
    label: (
      <span>
        /icons/brands/nimblr.svg <img style={{ height: 20 }} src={icon435} />
      </span>
    ),
    value: "/icons/brands/nimblr.svg",
    key: 435,
  },
  {
    label: (
      <span>
        /icons/brands/vnv.svg <img style={{ height: 20 }} src={icon436} />
      </span>
    ),
    value: "/icons/brands/vnv.svg",
    key: 436,
  },
  {
    label: (
      <span>
        /icons/brands/waze.svg <img style={{ height: 20 }} src={icon437} />
      </span>
    ),
    value: "/icons/brands/waze.svg",
    key: 437,
  },
  {
    label: (
      <span>
        /icons/brands/google-plus-g.svg{" "}
        <img style={{ height: 20 }} src={icon438} />
      </span>
    ),
    value: "/icons/brands/google-plus-g.svg",
    key: 438,
  },
  {
    label: (
      <span>
        /icons/brands/palfed.svg <img style={{ height: 20 }} src={icon439} />
      </span>
    ),
    value: "/icons/brands/palfed.svg",
    key: 439,
  },
  {
    label: (
      <span>
        /icons/brands/screenpal.svg <img style={{ height: 20 }} src={icon440} />
      </span>
    ),
    value: "/icons/brands/screenpal.svg",
    key: 440,
  },
  {
    label: (
      <span>
        /icons/brands/weebly.svg <img style={{ height: 20 }} src={icon441} />
      </span>
    ),
    value: "/icons/brands/weebly.svg",
    key: 441,
  },
  {
    label: (
      <span>
        /icons/brands/autoprefixer.svg{" "}
        <img style={{ height: 20 }} src={icon442} />
      </span>
    ),
    value: "/icons/brands/autoprefixer.svg",
    key: 442,
  },
  {
    label: (
      <span>
        /icons/brands/searchengin.svg{" "}
        <img style={{ height: 20 }} src={icon443} />
      </span>
    ),
    value: "/icons/brands/searchengin.svg",
    key: 443,
  },
  {
    label: (
      <span>
        /icons/brands/square-viadeo.svg{" "}
        <img style={{ height: 20 }} src={icon444} />
      </span>
    ),
    value: "/icons/brands/square-viadeo.svg",
    key: 444,
  },
  {
    label: (
      <span>
        /icons/brands/megaport.svg <img style={{ height: 20 }} src={icon445} />
      </span>
    ),
    value: "/icons/brands/megaport.svg",
    key: 445,
  },
  {
    label: (
      <span>
        /icons/brands/typo3.svg <img style={{ height: 20 }} src={icon446} />
      </span>
    ),
    value: "/icons/brands/typo3.svg",
    key: 446,
  },
  {
    label: (
      <span>
        /icons/brands/deploydog.svg <img style={{ height: 20 }} src={icon447} />
      </span>
    ),
    value: "/icons/brands/deploydog.svg",
    key: 447,
  },
  {
    label: (
      <span>
        /icons/brands/node.svg <img style={{ height: 20 }} src={icon448} />
      </span>
    ),
    value: "/icons/brands/node.svg",
    key: 448,
  },
  {
    label: (
      <span>
        /icons/brands/cc-mastercard.svg{" "}
        <img style={{ height: 20 }} src={icon449} />
      </span>
    ),
    value: "/icons/brands/cc-mastercard.svg",
    key: 449,
  },
  {
    label: (
      <span>
        /icons/brands/tiktok.svg <img style={{ height: 20 }} src={icon450} />
      </span>
    ),
    value: "/icons/brands/tiktok.svg",
    key: 450,
  },
  {
    label: (
      <span>
        /icons/brands/dashcube.svg <img style={{ height: 20 }} src={icon451} />
      </span>
    ),
    value: "/icons/brands/dashcube.svg",
    key: 451,
  },
  {
    label: (
      <span>
        /icons/brands/get-pocket.svg{" "}
        <img style={{ height: 20 }} src={icon452} />
      </span>
    ),
    value: "/icons/brands/get-pocket.svg",
    key: 452,
  },
  {
    label: (
      <span>
        /icons/brands/golang.svg <img style={{ height: 20 }} src={icon453} />
      </span>
    ),
    value: "/icons/brands/golang.svg",
    key: 453,
  },
  {
    label: (
      <span>
        /icons/brands/ups.svg <img style={{ height: 20 }} src={icon454} />
      </span>
    ),
    value: "/icons/brands/ups.svg",
    key: 454,
  },
  {
    label: (
      <span>
        /icons/brands/behance.svg <img style={{ height: 20 }} src={icon455} />
      </span>
    ),
    value: "/icons/brands/behance.svg",
    key: 455,
  },
  {
    label: (
      <span>
        /icons/brands/resolving.svg <img style={{ height: 20 }} src={icon456} />
      </span>
    ),
    value: "/icons/brands/resolving.svg",
    key: 456,
  },
  {
    label: (
      <span>
        /icons/brands/internet-explorer.svg{" "}
        <img style={{ height: 20 }} src={icon457} />
      </span>
    ),
    value: "/icons/brands/internet-explorer.svg",
    key: 457,
  },
  {
    label: (
      <span>
        /icons/brands/staylinked.svg{" "}
        <img style={{ height: 20 }} src={icon458} />
      </span>
    ),
    value: "/icons/brands/staylinked.svg",
    key: 458,
  },
  {
    label: (
      <span>
        /icons/brands/cmplid.svg <img style={{ height: 20 }} src={icon459} />
      </span>
    ),
    value: "/icons/brands/cmplid.svg",
    key: 459,
  },
  {
    label: (
      <span>
        /icons/brands/firefox.svg <img style={{ height: 20 }} src={icon460} />
      </span>
    ),
    value: "/icons/brands/firefox.svg",
    key: 460,
  },
  {
    label: (
      <span>
        /icons/brands/flickr.svg <img style={{ height: 20 }} src={icon461} />
      </span>
    ),
    value: "/icons/brands/flickr.svg",
    key: 461,
  },
  {
    label: (
      <span>
        /icons/brands/mixer.svg <img style={{ height: 20 }} src={icon462} />
      </span>
    ),
    value: "/icons/brands/mixer.svg",
    key: 462,
  },
  {
    label: (
      <span>
        /icons/brands/square-js.svg <img style={{ height: 20 }} src={icon463} />
      </span>
    ),
    value: "/icons/brands/square-js.svg",
    key: 463,
  },
  {
    label: (
      <span>
        /icons/brands/itunes.svg <img style={{ height: 20 }} src={icon464} />
      </span>
    ),
    value: "/icons/brands/itunes.svg",
    key: 464,
  },
  {
    label: (
      <span>
        /icons/solid/comment-dots.svg{" "}
        <img style={{ height: 20 }} src={icon465} />
      </span>
    ),
    value: "/icons/solid/comment-dots.svg",
    key: 465,
  },
  {
    label: (
      <span>
        /icons/solid/passport.svg <img style={{ height: 20 }} src={icon466} />
      </span>
    ),
    value: "/icons/solid/passport.svg",
    key: 466,
  },
  {
    label: (
      <span>
        /icons/solid/socks.svg <img style={{ height: 20 }} src={icon467} />
      </span>
    ),
    value: "/icons/solid/socks.svg",
    key: 467,
  },
  {
    label: (
      <span>
        /icons/solid/underline.svg <img style={{ height: 20 }} src={icon468} />
      </span>
    ),
    value: "/icons/solid/underline.svg",
    key: 468,
  },
  {
    label: (
      <span>
        /icons/solid/file-arrow-up.svg{" "}
        <img style={{ height: 20 }} src={icon469} />
      </span>
    ),
    value: "/icons/solid/file-arrow-up.svg",
    key: 469,
  },
  {
    label: (
      <span>
        /icons/solid/headphones-simple.svg{" "}
        <img style={{ height: 20 }} src={icon470} />
      </span>
    ),
    value: "/icons/solid/headphones-simple.svg",
    key: 470,
  },
  {
    label: (
      <span>
        /icons/solid/cloud-meatball.svg{" "}
        <img style={{ height: 20 }} src={icon471} />
      </span>
    ),
    value: "/icons/solid/cloud-meatball.svg",
    key: 471,
  },
  {
    label: (
      <span>
        /icons/solid/caret-down.svg <img style={{ height: 20 }} src={icon472} />
      </span>
    ),
    value: "/icons/solid/caret-down.svg",
    key: 472,
  },
  {
    label: (
      <span>
        /icons/solid/dice-three.svg <img style={{ height: 20 }} src={icon473} />
      </span>
    ),
    value: "/icons/solid/dice-three.svg",
    key: 473,
  },
  {
    label: (
      <span>
        /icons/solid/file-zipper.svg{" "}
        <img style={{ height: 20 }} src={icon474} />
      </span>
    ),
    value: "/icons/solid/file-zipper.svg",
    key: 474,
  },
  {
    label: (
      <span>
        /icons/solid/hand.svg <img style={{ height: 20 }} src={icon475} />
      </span>
    ),
    value: "/icons/solid/hand.svg",
    key: 475,
  },
  {
    label: (
      <span>
        /icons/solid/toggle-on.svg <img style={{ height: 20 }} src={icon476} />
      </span>
    ),
    value: "/icons/solid/toggle-on.svg",
    key: 476,
  },
  {
    label: (
      <span>
        /icons/solid/clone.svg <img style={{ height: 20 }} src={icon477} />
      </span>
    ),
    value: "/icons/solid/clone.svg",
    key: 477,
  },
  {
    label: (
      <span>
        /icons/solid/arrow-rotate-right.svg{" "}
        <img style={{ height: 20 }} src={icon478} />
      </span>
    ),
    value: "/icons/solid/arrow-rotate-right.svg",
    key: 478,
  },
  {
    label: (
      <span>
        /icons/solid/person-digging.svg{" "}
        <img style={{ height: 20 }} src={icon479} />
      </span>
    ),
    value: "/icons/solid/person-digging.svg",
    key: 479,
  },
  {
    label: (
      <span>
        /icons/solid/crow.svg <img style={{ height: 20 }} src={icon480} />
      </span>
    ),
    value: "/icons/solid/crow.svg",
    key: 480,
  },
  {
    label: (
      <span>
        /icons/solid/bars-progress.svg{" "}
        <img style={{ height: 20 }} src={icon481} />
      </span>
    ),
    value: "/icons/solid/bars-progress.svg",
    key: 481,
  },
  {
    label: (
      <span>
        /icons/solid/right-from-bracket.svg{" "}
        <img style={{ height: 20 }} src={icon482} />
      </span>
    ),
    value: "/icons/solid/right-from-bracket.svg",
    key: 482,
  },
  {
    label: (
      <span>
        /icons/solid/plug-circle-check.svg{" "}
        <img style={{ height: 20 }} src={icon483} />
      </span>
    ),
    value: "/icons/solid/plug-circle-check.svg",
    key: 483,
  },
  {
    label: (
      <span>
        /icons/solid/bottle-droplet.svg{" "}
        <img style={{ height: 20 }} src={icon484} />
      </span>
    ),
    value: "/icons/solid/bottle-droplet.svg",
    key: 484,
  },
  {
    label: (
      <span>
        /icons/solid/pallet.svg <img style={{ height: 20 }} src={icon485} />
      </span>
    ),
    value: "/icons/solid/pallet.svg",
    key: 485,
  },
  {
    label: (
      <span>
        /icons/solid/person-drowning.svg{" "}
        <img style={{ height: 20 }} src={icon486} />
      </span>
    ),
    value: "/icons/solid/person-drowning.svg",
    key: 486,
  },
  {
    label: (
      <span>
        /icons/solid/eraser.svg <img style={{ height: 20 }} src={icon487} />
      </span>
    ),
    value: "/icons/solid/eraser.svg",
    key: 487,
  },
  {
    label: (
      <span>
        /icons/solid/list-ul.svg <img style={{ height: 20 }} src={icon488} />
      </span>
    ),
    value: "/icons/solid/list-ul.svg",
    key: 488,
  },
  {
    label: (
      <span>
        /icons/solid/chart-line.svg <img style={{ height: 20 }} src={icon489} />
      </span>
    ),
    value: "/icons/solid/chart-line.svg",
    key: 489,
  },
  {
    label: (
      <span>
        /icons/solid/person-chalkboard.svg{" "}
        <img style={{ height: 20 }} src={icon490} />
      </span>
    ),
    value: "/icons/solid/person-chalkboard.svg",
    key: 490,
  },
  {
    label: (
      <span>
        /icons/solid/receipt.svg <img style={{ height: 20 }} src={icon491} />
      </span>
    ),
    value: "/icons/solid/receipt.svg",
    key: 491,
  },
  {
    label: (
      <span>
        /icons/solid/diagram-successor.svg{" "}
        <img style={{ height: 20 }} src={icon492} />
      </span>
    ),
    value: "/icons/solid/diagram-successor.svg",
    key: 492,
  },
  {
    label: (
      <span>
        /icons/solid/money-bill-transfer.svg{" "}
        <img style={{ height: 20 }} src={icon493} />
      </span>
    ),
    value: "/icons/solid/money-bill-transfer.svg",
    key: 493,
  },
  {
    label: (
      <span>
        /icons/solid/briefcase-medical.svg{" "}
        <img style={{ height: 20 }} src={icon494} />
      </span>
    ),
    value: "/icons/solid/briefcase-medical.svg",
    key: 494,
  },
  {
    label: (
      <span>
        /icons/solid/file-circle-exclamation.svg{" "}
        <img style={{ height: 20 }} src={icon495} />
      </span>
    ),
    value: "/icons/solid/file-circle-exclamation.svg",
    key: 495,
  },
  {
    label: (
      <span>
        /icons/solid/right-left.svg <img style={{ height: 20 }} src={icon496} />
      </span>
    ),
    value: "/icons/solid/right-left.svg",
    key: 496,
  },
  {
    label: (
      <span>
        /icons/solid/cash-register.svg{" "}
        <img style={{ height: 20 }} src={icon497} />
      </span>
    ),
    value: "/icons/solid/cash-register.svg",
    key: 497,
  },
  {
    label: (
      <span>
        /icons/solid/arrow-down.svg <img style={{ height: 20 }} src={icon498} />
      </span>
    ),
    value: "/icons/solid/arrow-down.svg",
    key: 498,
  },
  {
    label: (
      <span>
        /icons/solid/money-bill-1-wave.svg{" "}
        <img style={{ height: 20 }} src={icon499} />
      </span>
    ),
    value: "/icons/solid/money-bill-1-wave.svg",
    key: 499,
  },
  {
    label: (
      <span>
        /icons/solid/arrows-turn-right.svg{" "}
        <img style={{ height: 20 }} src={icon500} />
      </span>
    ),
    value: "/icons/solid/arrows-turn-right.svg",
    key: 500,
  },
  {
    label: (
      <span>
        /icons/solid/campground.svg <img style={{ height: 20 }} src={icon501} />
      </span>
    ),
    value: "/icons/solid/campground.svg",
    key: 501,
  },
  {
    label: (
      <span>
        /icons/solid/poo.svg <img style={{ height: 20 }} src={icon502} />
      </span>
    ),
    value: "/icons/solid/poo.svg",
    key: 502,
  },
  {
    label: (
      <span>
        /icons/solid/file-pen.svg <img style={{ height: 20 }} src={icon503} />
      </span>
    ),
    value: "/icons/solid/file-pen.svg",
    key: 503,
  },
  {
    label: (
      <span>
        /icons/solid/ghost.svg <img style={{ height: 20 }} src={icon504} />
      </span>
    ),
    value: "/icons/solid/ghost.svg",
    key: 504,
  },
  {
    label: (
      <span>
        /icons/solid/business-time.svg{" "}
        <img style={{ height: 20 }} src={icon505} />
      </span>
    ),
    value: "/icons/solid/business-time.svg",
    key: 505,
  },
  {
    label: (
      <span>
        /icons/solid/list.svg <img style={{ height: 20 }} src={icon506} />
      </span>
    ),
    value: "/icons/solid/list.svg",
    key: 506,
  },
  {
    label: (
      <span>
        /icons/solid/volume-off.svg <img style={{ height: 20 }} src={icon507} />
      </span>
    ),
    value: "/icons/solid/volume-off.svg",
    key: 507,
  },
  {
    label: (
      <span>
        /icons/solid/house-chimney-window.svg{" "}
        <img style={{ height: 20 }} src={icon508} />
      </span>
    ),
    value: "/icons/solid/house-chimney-window.svg",
    key: 508,
  },
  {
    label: (
      <span>
        /icons/solid/users-gear.svg <img style={{ height: 20 }} src={icon509} />
      </span>
    ),
    value: "/icons/solid/users-gear.svg",
    key: 509,
  },
  {
    label: (
      <span>
        /icons/solid/house-circle-exclamation.svg{" "}
        <img style={{ height: 20 }} src={icon510} />
      </span>
    ),
    value: "/icons/solid/house-circle-exclamation.svg",
    key: 510,
  },
  {
    label: (
      <span>
        /icons/solid/diagram-next.svg{" "}
        <img style={{ height: 20 }} src={icon511} />
      </span>
    ),
    value: "/icons/solid/diagram-next.svg",
    key: 511,
  },
  {
    label: (
      <span>
        /icons/solid/neuter.svg <img style={{ height: 20 }} src={icon512} />
      </span>
    ),
    value: "/icons/solid/neuter.svg",
    key: 512,
  },
  {
    label: (
      <span>
        /icons/solid/book-bookmark.svg{" "}
        <img style={{ height: 20 }} src={icon513} />
      </span>
    ),
    value: "/icons/solid/book-bookmark.svg",
    key: 513,
  },
  {
    label: (
      <span>
        /icons/solid/file-excel.svg <img style={{ height: 20 }} src={icon514} />
      </span>
    ),
    value: "/icons/solid/file-excel.svg",
    key: 514,
  },
  {
    label: (
      <span>
        /icons/solid/virus.svg <img style={{ height: 20 }} src={icon515} />
      </span>
    ),
    value: "/icons/solid/virus.svg",
    key: 515,
  },
  {
    label: (
      <span>
        /icons/solid/map-location-dot.svg{" "}
        <img style={{ height: 20 }} src={icon516} />
      </span>
    ),
    value: "/icons/solid/map-location-dot.svg",
    key: 516,
  },
  {
    label: (
      <span>
        /icons/solid/house.svg <img style={{ height: 20 }} src={icon517} />
      </span>
    ),
    value: "/icons/solid/house.svg",
    key: 517,
  },
  {
    label: (
      <span>
        /icons/solid/stapler.svg <img style={{ height: 20 }} src={icon518} />
      </span>
    ),
    value: "/icons/solid/stapler.svg",
    key: 518,
  },
  {
    label: (
      <span>
        /icons/solid/prescription.svg{" "}
        <img style={{ height: 20 }} src={icon519} />
      </span>
    ),
    value: "/icons/solid/prescription.svg",
    key: 519,
  },
  {
    label: (
      <span>
        /icons/solid/arrow-down-short-wide.svg{" "}
        <img style={{ height: 20 }} src={icon520} />
      </span>
    ),
    value: "/icons/solid/arrow-down-short-wide.svg",
    key: 520,
  },
  {
    label: (
      <span>
        /icons/solid/bed.svg <img style={{ height: 20 }} src={icon521} />
      </span>
    ),
    value: "/icons/solid/bed.svg",
    key: 521,
  },
  {
    label: (
      <span>
        /icons/solid/stopwatch-20.svg{" "}
        <img style={{ height: 20 }} src={icon522} />
      </span>
    ),
    value: "/icons/solid/stopwatch-20.svg",
    key: 522,
  },
  {
    label: (
      <span>
        /icons/solid/mars-and-venus.svg{" "}
        <img style={{ height: 20 }} src={icon523} />
      </span>
    ),
    value: "/icons/solid/mars-and-venus.svg",
    key: 523,
  },
  {
    label: (
      <span>
        /icons/solid/dumpster.svg <img style={{ height: 20 }} src={icon524} />
      </span>
    ),
    value: "/icons/solid/dumpster.svg",
    key: 524,
  },
  {
    label: (
      <span>
        /icons/solid/building-shield.svg{" "}
        <img style={{ height: 20 }} src={icon525} />
      </span>
    ),
    value: "/icons/solid/building-shield.svg",
    key: 525,
  },
  {
    label: (
      <span>
        /icons/solid/flask-vial.svg <img style={{ height: 20 }} src={icon526} />
      </span>
    ),
    value: "/icons/solid/flask-vial.svg",
    key: 526,
  },
  {
    label: (
      <span>
        /icons/solid/wind.svg <img style={{ height: 20 }} src={icon527} />
      </span>
    ),
    value: "/icons/solid/wind.svg",
    key: 527,
  },
  {
    label: (
      <span>
        /icons/solid/person-walking-with-cane.svg{" "}
        <img style={{ height: 20 }} src={icon528} />
      </span>
    ),
    value: "/icons/solid/person-walking-with-cane.svg",
    key: 528,
  },
  {
    label: (
      <span>
        /icons/solid/text-slash.svg <img style={{ height: 20 }} src={icon529} />
      </span>
    ),
    value: "/icons/solid/text-slash.svg",
    key: 529,
  },
  {
    label: (
      <span>
        /icons/solid/clapperboard.svg{" "}
        <img style={{ height: 20 }} src={icon530} />
      </span>
    ),
    value: "/icons/solid/clapperboard.svg",
    key: 530,
  },
  {
    label: (
      <span>
        /icons/solid/subscript.svg <img style={{ height: 20 }} src={icon531} />
      </span>
    ),
    value: "/icons/solid/subscript.svg",
    key: 531,
  },
  {
    label: (
      <span>
        /icons/solid/viruses.svg <img style={{ height: 20 }} src={icon532} />
      </span>
    ),
    value: "/icons/solid/viruses.svg",
    key: 532,
  },
  {
    label: (
      <span>
        /icons/solid/drum-steelpan.svg{" "}
        <img style={{ height: 20 }} src={icon533} />
      </span>
    ),
    value: "/icons/solid/drum-steelpan.svg",
    key: 533,
  },
  {
    label: (
      <span>
        /icons/solid/trash-can.svg <img style={{ height: 20 }} src={icon534} />
      </span>
    ),
    value: "/icons/solid/trash-can.svg",
    key: 534,
  },
  {
    label: (
      <span>
        /icons/solid/sack-xmark.svg <img style={{ height: 20 }} src={icon535} />
      </span>
    ),
    value: "/icons/solid/sack-xmark.svg",
    key: 535,
  },
  {
    label: (
      <span>
        /icons/solid/face-grin-tongue-wink.svg{" "}
        <img style={{ height: 20 }} src={icon536} />
      </span>
    ),
    value: "/icons/solid/face-grin-tongue-wink.svg",
    key: 536,
  },
  {
    label: (
      <span>
        /icons/solid/rotate-left.svg{" "}
        <img style={{ height: 20 }} src={icon537} />
      </span>
    ),
    value: "/icons/solid/rotate-left.svg",
    key: 537,
  },
  {
    label: (
      <span>
        /icons/solid/handshake-slash.svg{" "}
        <img style={{ height: 20 }} src={icon538} />
      </span>
    ),
    value: "/icons/solid/handshake-slash.svg",
    key: 538,
  },
  {
    label: (
      <span>
        /icons/solid/car-rear.svg <img style={{ height: 20 }} src={icon539} />
      </span>
    ),
    value: "/icons/solid/car-rear.svg",
    key: 539,
  },
  {
    label: (
      <span>
        /icons/solid/scissors.svg <img style={{ height: 20 }} src={icon540} />
      </span>
    ),
    value: "/icons/solid/scissors.svg",
    key: 540,
  },
  {
    label: (
      <span>
        /icons/solid/plug-circle-exclamation.svg{" "}
        <img style={{ height: 20 }} src={icon541} />
      </span>
    ),
    value: "/icons/solid/plug-circle-exclamation.svg",
    key: 541,
  },
  {
    label: (
      <span>
        /icons/solid/section.svg <img style={{ height: 20 }} src={icon542} />
      </span>
    ),
    value: "/icons/solid/section.svg",
    key: 542,
  },
  {
    label: (
      <span>
        /icons/solid/earth-americas.svg{" "}
        <img style={{ height: 20 }} src={icon543} />
      </span>
    ),
    value: "/icons/solid/earth-americas.svg",
    key: 543,
  },
  {
    label: (
      <span>
        /icons/solid/forward-fast.svg{" "}
        <img style={{ height: 20 }} src={icon544} />
      </span>
    ),
    value: "/icons/solid/forward-fast.svg",
    key: 544,
  },
  {
    label: (
      <span>
        /icons/solid/chalkboard-user.svg{" "}
        <img style={{ height: 20 }} src={icon545} />
      </span>
    ),
    value: "/icons/solid/chalkboard-user.svg",
    key: 545,
  },
  {
    label: (
      <span>
        /icons/solid/repeat.svg <img style={{ height: 20 }} src={icon546} />
      </span>
    ),
    value: "/icons/solid/repeat.svg",
    key: 546,
  },
  {
    label: (
      <span>
        /icons/solid/mitten.svg <img style={{ height: 20 }} src={icon547} />
      </span>
    ),
    value: "/icons/solid/mitten.svg",
    key: 547,
  },
  {
    label: (
      <span>
        /icons/solid/pen-nib.svg <img style={{ height: 20 }} src={icon548} />
      </span>
    ),
    value: "/icons/solid/pen-nib.svg",
    key: 548,
  },
  {
    label: (
      <span>
        /icons/solid/hands-bound.svg{" "}
        <img style={{ height: 20 }} src={icon549} />
      </span>
    ),
    value: "/icons/solid/hands-bound.svg",
    key: 549,
  },
  {
    label: (
      <span>
        /icons/solid/grip.svg <img style={{ height: 20 }} src={icon550} />
      </span>
    ),
    value: "/icons/solid/grip.svg",
    key: 550,
  },
  {
    label: (
      <span>
        /icons/solid/money-bills.svg{" "}
        <img style={{ height: 20 }} src={icon551} />
      </span>
    ),
    value: "/icons/solid/money-bills.svg",
    key: 551,
  },
  {
    label: (
      <span>
        /icons/solid/house-laptop.svg{" "}
        <img style={{ height: 20 }} src={icon552} />
      </span>
    ),
    value: "/icons/solid/house-laptop.svg",
    key: 552,
  },
  {
    label: (
      <span>
        /icons/solid/hourglass.svg <img style={{ height: 20 }} src={icon553} />
      </span>
    ),
    value: "/icons/solid/hourglass.svg",
    key: 553,
  },
  {
    label: (
      <span>
        /icons/solid/circle-chevron-right.svg{" "}
        <img style={{ height: 20 }} src={icon554} />
      </span>
    ),
    value: "/icons/solid/circle-chevron-right.svg",
    key: 554,
  },
  {
    label: (
      <span>
        /icons/solid/circle-exclamation.svg{" "}
        <img style={{ height: 20 }} src={icon555} />
      </span>
    ),
    value: "/icons/solid/circle-exclamation.svg",
    key: 555,
  },
  {
    label: (
      <span>
        /icons/solid/chess-rook.svg <img style={{ height: 20 }} src={icon556} />
      </span>
    ),
    value: "/icons/solid/chess-rook.svg",
    key: 556,
  },
  {
    label: (
      <span>
        /icons/solid/up-down.svg <img style={{ height: 20 }} src={icon557} />
      </span>
    ),
    value: "/icons/solid/up-down.svg",
    key: 557,
  },
  {
    label: (
      <span>
        /icons/solid/dice-one.svg <img style={{ height: 20 }} src={icon558} />
      </span>
    ),
    value: "/icons/solid/dice-one.svg",
    key: 558,
  },
  {
    label: (
      <span>
        /icons/solid/ban-smoking.svg{" "}
        <img style={{ height: 20 }} src={icon559} />
      </span>
    ),
    value: "/icons/solid/ban-smoking.svg",
    key: 559,
  },
  {
    label: (
      <span>
        /icons/solid/volcano.svg <img style={{ height: 20 }} src={icon560} />
      </span>
    ),
    value: "/icons/solid/volcano.svg",
    key: 560,
  },
  {
    label: (
      <span>
        /icons/solid/reply.svg <img style={{ height: 20 }} src={icon561} />
      </span>
    ),
    value: "/icons/solid/reply.svg",
    key: 561,
  },
  {
    label: (
      <span>
        /icons/solid/grip-lines.svg <img style={{ height: 20 }} src={icon562} />
      </span>
    ),
    value: "/icons/solid/grip-lines.svg",
    key: 562,
  },
  {
    label: (
      <span>
        /icons/solid/camera-retro.svg{" "}
        <img style={{ height: 20 }} src={icon563} />
      </span>
    ),
    value: "/icons/solid/camera-retro.svg",
    key: 563,
  },
  {
    label: (
      <span>
        /icons/solid/toolbox.svg <img style={{ height: 20 }} src={icon564} />
      </span>
    ),
    value: "/icons/solid/toolbox.svg",
    key: 564,
  },
  {
    label: (
      <span>
        /icons/solid/envelope-open-text.svg{" "}
        <img style={{ height: 20 }} src={icon565} />
      </span>
    ),
    value: "/icons/solid/envelope-open-text.svg",
    key: 565,
  },
  {
    label: (
      <span>
        /icons/solid/mobile-screen.svg{" "}
        <img style={{ height: 20 }} src={icon566} />
      </span>
    ),
    value: "/icons/solid/mobile-screen.svg",
    key: 566,
  },
  {
    label: (
      <span>
        /icons/solid/handshake-simple.svg{" "}
        <img style={{ height: 20 }} src={icon567} />
      </span>
    ),
    value: "/icons/solid/handshake-simple.svg",
    key: 567,
  },
  {
    label: (
      <span>
        /icons/solid/children.svg <img style={{ height: 20 }} src={icon568} />
      </span>
    ),
    value: "/icons/solid/children.svg",
    key: 568,
  },
  {
    label: (
      <span>
        /icons/solid/face-grimace.svg{" "}
        <img style={{ height: 20 }} src={icon569} />
      </span>
    ),
    value: "/icons/solid/face-grimace.svg",
    key: 569,
  },
  {
    label: (
      <span>
        /icons/solid/house-flood-water-circle-arrow-right.svg{" "}
        <img style={{ height: 20 }} src={icon570} />
      </span>
    ),
    value: "/icons/solid/house-flood-water-circle-arrow-right.svg",
    key: 570,
  },
  {
    label: (
      <span>
        /icons/solid/ankh.svg <img style={{ height: 20 }} src={icon571} />
      </span>
    ),
    value: "/icons/solid/ankh.svg",
    key: 571,
  },
  {
    label: (
      <span>
        /icons/solid/cruzeiro-sign.svg{" "}
        <img style={{ height: 20 }} src={icon572} />
      </span>
    ),
    value: "/icons/solid/cruzeiro-sign.svg",
    key: 572,
  },
  {
    label: (
      <span>
        /icons/solid/chess-knight.svg{" "}
        <img style={{ height: 20 }} src={icon573} />
      </span>
    ),
    value: "/icons/solid/chess-knight.svg",
    key: 573,
  },
  {
    label: (
      <span>
        /icons/solid/mars-stroke-up.svg{" "}
        <img style={{ height: 20 }} src={icon574} />
      </span>
    ),
    value: "/icons/solid/mars-stroke-up.svg",
    key: 574,
  },
  {
    label: (
      <span>
        /icons/solid/comments-dollar.svg{" "}
        <img style={{ height: 20 }} src={icon575} />
      </span>
    ),
    value: "/icons/solid/comments-dollar.svg",
    key: 575,
  },
  {
    label: (
      <span>
        /icons/solid/spray-can.svg <img style={{ height: 20 }} src={icon576} />
      </span>
    ),
    value: "/icons/solid/spray-can.svg",
    key: 576,
  },
  {
    label: (
      <span>
        /icons/solid/user-secret.svg{" "}
        <img style={{ height: 20 }} src={icon577} />
      </span>
    ),
    value: "/icons/solid/user-secret.svg",
    key: 577,
  },
  {
    label: (
      <span>
        /icons/solid/person-swimming.svg{" "}
        <img style={{ height: 20 }} src={icon578} />
      </span>
    ),
    value: "/icons/solid/person-swimming.svg",
    key: 578,
  },
  {
    label: (
      <span>
        /icons/solid/cloud-bolt.svg <img style={{ height: 20 }} src={icon579} />
      </span>
    ),
    value: "/icons/solid/cloud-bolt.svg",
    key: 579,
  },
  {
    label: (
      <span>
        /icons/solid/head-side-cough.svg{" "}
        <img style={{ height: 20 }} src={icon580} />
      </span>
    ),
    value: "/icons/solid/head-side-cough.svg",
    key: 580,
  },
  {
    label: (
      <span>
        /icons/solid/umbrella.svg <img style={{ height: 20 }} src={icon581} />
      </span>
    ),
    value: "/icons/solid/umbrella.svg",
    key: 581,
  },
  {
    label: (
      <span>
        /icons/solid/user.svg <img style={{ height: 20 }} src={icon582} />
      </span>
    ),
    value: "/icons/solid/user.svg",
    key: 582,
  },
  {
    label: (
      <span>
        /icons/solid/earth-europe.svg{" "}
        <img style={{ height: 20 }} src={icon583} />
      </span>
    ),
    value: "/icons/solid/earth-europe.svg",
    key: 583,
  },
  {
    label: (
      <span>
        /icons/solid/fire-flame-simple.svg{" "}
        <img style={{ height: 20 }} src={icon584} />
      </span>
    ),
    value: "/icons/solid/fire-flame-simple.svg",
    key: 584,
  },
  {
    label: (
      <span>
        /icons/solid/cart-plus.svg <img style={{ height: 20 }} src={icon585} />
      </span>
    ),
    value: "/icons/solid/cart-plus.svg",
    key: 585,
  },
  {
    label: (
      <span>
        /icons/solid/id-badge.svg <img style={{ height: 20 }} src={icon586} />
      </span>
    ),
    value: "/icons/solid/id-badge.svg",
    key: 586,
  },
  {
    label: (
      <span>
        /icons/solid/temperature-three-quarters.svg{" "}
        <img style={{ height: 20 }} src={icon587} />
      </span>
    ),
    value: "/icons/solid/temperature-three-quarters.svg",
    key: 587,
  },
  {
    label: (
      <span>
        /icons/solid/tenge-sign.svg <img style={{ height: 20 }} src={icon588} />
      </span>
    ),
    value: "/icons/solid/tenge-sign.svg",
    key: 588,
  },
  {
    label: (
      <span>
        /icons/solid/network-wired.svg{" "}
        <img style={{ height: 20 }} src={icon589} />
      </span>
    ),
    value: "/icons/solid/network-wired.svg",
    key: 589,
  },
  {
    label: (
      <span>
        /icons/solid/cannabis.svg <img style={{ height: 20 }} src={icon590} />
      </span>
    ),
    value: "/icons/solid/cannabis.svg",
    key: 590,
  },
  {
    label: (
      <span>
        /icons/solid/gun.svg <img style={{ height: 20 }} src={icon591} />
      </span>
    ),
    value: "/icons/solid/gun.svg",
    key: 591,
  },
  {
    label: (
      <span>
        /icons/solid/tape.svg <img style={{ height: 20 }} src={icon592} />
      </span>
    ),
    value: "/icons/solid/tape.svg",
    key: 592,
  },
  {
    label: (
      <span>
        /icons/solid/hand-holding.svg{" "}
        <img style={{ height: 20 }} src={icon593} />
      </span>
    ),
    value: "/icons/solid/hand-holding.svg",
    key: 593,
  },
  {
    label: (
      <span>
        /icons/solid/laptop-code.svg{" "}
        <img style={{ height: 20 }} src={icon594} />
      </span>
    ),
    value: "/icons/solid/laptop-code.svg",
    key: 594,
  },
  {
    label: (
      <span>
        /icons/solid/panorama.svg <img style={{ height: 20 }} src={icon595} />
      </span>
    ),
    value: "/icons/solid/panorama.svg",
    key: 595,
  },
  {
    label: (
      <span>
        /icons/solid/temperature-full.svg{" "}
        <img style={{ height: 20 }} src={icon596} />
      </span>
    ),
    value: "/icons/solid/temperature-full.svg",
    key: 596,
  },
  {
    label: (
      <span>
        /icons/solid/arrow-right-to-bracket.svg{" "}
        <img style={{ height: 20 }} src={icon597} />
      </span>
    ),
    value: "/icons/solid/arrow-right-to-bracket.svg",
    key: 597,
  },
  {
    label: (
      <span>
        /icons/solid/arrows-down-to-line.svg{" "}
        <img style={{ height: 20 }} src={icon598} />
      </span>
    ),
    value: "/icons/solid/arrows-down-to-line.svg",
    key: 598,
  },
  {
    label: (
      <span>
        /icons/solid/circle.svg <img style={{ height: 20 }} src={icon599} />
      </span>
    ),
    value: "/icons/solid/circle.svg",
    key: 599,
  },
  {
    label: (
      <span>
        /icons/solid/temperature-high.svg{" "}
        <img style={{ height: 20 }} src={icon600} />
      </span>
    ),
    value: "/icons/solid/temperature-high.svg",
    key: 600,
  },
  {
    label: (
      <span>
        /icons/solid/box-tissue.svg <img style={{ height: 20 }} src={icon601} />
      </span>
    ),
    value: "/icons/solid/box-tissue.svg",
    key: 601,
  },
  {
    label: (
      <span>
        /icons/solid/earth-asia.svg <img style={{ height: 20 }} src={icon602} />
      </span>
    ),
    value: "/icons/solid/earth-asia.svg",
    key: 602,
  },
  {
    label: (
      <span>
        /icons/solid/arrow-down-up-across-line.svg{" "}
        <img style={{ height: 20 }} src={icon603} />
      </span>
    ),
    value: "/icons/solid/arrow-down-up-across-line.svg",
    key: 603,
  },
  {
    label: (
      <span>
        /icons/solid/hippo.svg <img style={{ height: 20 }} src={icon604} />
      </span>
    ),
    value: "/icons/solid/hippo.svg",
    key: 604,
  },
  {
    label: (
      <span>
        /icons/solid/person.svg <img style={{ height: 20 }} src={icon605} />
      </span>
    ),
    value: "/icons/solid/person.svg",
    key: 605,
  },
  {
    label: (
      <span>
        /icons/solid/arrow-down-up-lock.svg{" "}
        <img style={{ height: 20 }} src={icon606} />
      </span>
    ),
    value: "/icons/solid/arrow-down-up-lock.svg",
    key: 606,
  },
  {
    label: (
      <span>
        /icons/solid/table-list.svg <img style={{ height: 20 }} src={icon607} />
      </span>
    ),
    value: "/icons/solid/table-list.svg",
    key: 607,
  },
  {
    label: (
      <span>
        /icons/solid/brazilian-real-sign.svg{" "}
        <img style={{ height: 20 }} src={icon608} />
      </span>
    ),
    value: "/icons/solid/brazilian-real-sign.svg",
    key: 608,
  },
  {
    label: (
      <span>
        /icons/solid/house-medical-flag.svg{" "}
        <img style={{ height: 20 }} src={icon609} />
      </span>
    ),
    value: "/icons/solid/house-medical-flag.svg",
    key: 609,
  },
  {
    label: (
      <span>
        /icons/solid/shop.svg <img style={{ height: 20 }} src={icon610} />
      </span>
    ),
    value: "/icons/solid/shop.svg",
    key: 610,
  },
  {
    label: (
      <span>
        /icons/solid/car-tunnel.svg <img style={{ height: 20 }} src={icon611} />
      </span>
    ),
    value: "/icons/solid/car-tunnel.svg",
    key: 611,
  },
  {
    label: (
      <span>
        /icons/solid/copyright.svg <img style={{ height: 20 }} src={icon612} />
      </span>
    ),
    value: "/icons/solid/copyright.svg",
    key: 612,
  },
  {
    label: (
      <span>
        /icons/solid/vault.svg <img style={{ height: 20 }} src={icon613} />
      </span>
    ),
    value: "/icons/solid/vault.svg",
    key: 613,
  },
  {
    label: (
      <span>
        /icons/solid/backward-fast.svg{" "}
        <img style={{ height: 20 }} src={icon614} />
      </span>
    ),
    value: "/icons/solid/backward-fast.svg",
    key: 614,
  },
  {
    label: (
      <span>
        /icons/solid/square-caret-down.svg{" "}
        <img style={{ height: 20 }} src={icon615} />
      </span>
    ),
    value: "/icons/solid/square-caret-down.svg",
    key: 615,
  },
  {
    label: (
      <span>
        /icons/solid/battery-half.svg{" "}
        <img style={{ height: 20 }} src={icon616} />
      </span>
    ),
    value: "/icons/solid/battery-half.svg",
    key: 616,
  },
  {
    label: (
      <span>
        /icons/solid/bolt-lightning.svg{" "}
        <img style={{ height: 20 }} src={icon617} />
      </span>
    ),
    value: "/icons/solid/bolt-lightning.svg",
    key: 617,
  },
  {
    label: (
      <span>
        /icons/solid/arrows-split-up-and-left.svg{" "}
        <img style={{ height: 20 }} src={icon618} />
      </span>
    ),
    value: "/icons/solid/arrows-split-up-and-left.svg",
    key: 618,
  },
  {
    label: (
      <span>
        /icons/solid/music.svg <img style={{ height: 20 }} src={icon619} />
      </span>
    ),
    value: "/icons/solid/music.svg",
    key: 619,
  },
  {
    label: (
      <span>
        /icons/solid/burst.svg <img style={{ height: 20 }} src={icon620} />
      </span>
    ),
    value: "/icons/solid/burst.svg",
    key: 620,
  },
  {
    label: (
      <span>
        /icons/solid/wave-square.svg{" "}
        <img style={{ height: 20 }} src={icon621} />
      </span>
    ),
    value: "/icons/solid/wave-square.svg",
    key: 621,
  },
  {
    label: (
      <span>
        /icons/solid/sliders.svg <img style={{ height: 20 }} src={icon622} />
      </span>
    ),
    value: "/icons/solid/sliders.svg",
    key: 622,
  },
  {
    label: (
      <span>
        /icons/solid/quote-right.svg{" "}
        <img style={{ height: 20 }} src={icon623} />
      </span>
    ),
    value: "/icons/solid/quote-right.svg",
    key: 623,
  },
  {
    label: (
      <span>
        /icons/solid/folder-tree.svg{" "}
        <img style={{ height: 20 }} src={icon624} />
      </span>
    ),
    value: "/icons/solid/folder-tree.svg",
    key: 624,
  },
  {
    label: (
      <span>
        /icons/solid/chart-gantt.svg{" "}
        <img style={{ height: 20 }} src={icon625} />
      </span>
    ),
    value: "/icons/solid/chart-gantt.svg",
    key: 625,
  },
  {
    label: (
      <span>
        /icons/solid/cow.svg <img style={{ height: 20 }} src={icon626} />
      </span>
    ),
    value: "/icons/solid/cow.svg",
    key: 626,
  },
  {
    label: (
      <span>
        /icons/solid/user-large.svg <img style={{ height: 20 }} src={icon627} />
      </span>
    ),
    value: "/icons/solid/user-large.svg",
    key: 627,
  },
  {
    label: (
      <span>
        /icons/solid/code-fork.svg <img style={{ height: 20 }} src={icon628} />
      </span>
    ),
    value: "/icons/solid/code-fork.svg",
    key: 628,
  },
  {
    label: (
      <span>
        /icons/solid/igloo.svg <img style={{ height: 20 }} src={icon629} />
      </span>
    ),
    value: "/icons/solid/igloo.svg",
    key: 629,
  },
  {
    label: (
      <span>
        /icons/solid/recycle.svg <img style={{ height: 20 }} src={icon630} />
      </span>
    ),
    value: "/icons/solid/recycle.svg",
    key: 630,
  },
  {
    label: (
      <span>
        /icons/solid/temperature-arrow-down.svg{" "}
        <img style={{ height: 20 }} src={icon631} />
      </span>
    ),
    value: "/icons/solid/temperature-arrow-down.svg",
    key: 631,
  },
  {
    label: (
      <span>
        /icons/solid/eject.svg <img style={{ height: 20 }} src={icon632} />
      </span>
    ),
    value: "/icons/solid/eject.svg",
    key: 632,
  },
  {
    label: (
      <span>
        /icons/solid/cart-shopping.svg{" "}
        <img style={{ height: 20 }} src={icon633} />
      </span>
    ),
    value: "/icons/solid/cart-shopping.svg",
    key: 633,
  },
  {
    label: (
      <span>
        /icons/solid/hand-point-right.svg{" "}
        <img style={{ height: 20 }} src={icon634} />
      </span>
    ),
    value: "/icons/solid/hand-point-right.svg",
    key: 634,
  },
  {
    label: (
      <span>
        /icons/solid/archway.svg <img style={{ height: 20 }} src={icon635} />
      </span>
    ),
    value: "/icons/solid/archway.svg",
    key: 635,
  },
  {
    label: (
      <span>
        /icons/solid/jug-detergent.svg{" "}
        <img style={{ height: 20 }} src={icon636} />
      </span>
    ),
    value: "/icons/solid/jug-detergent.svg",
    key: 636,
  },
  {
    label: (
      <span>
        /icons/solid/clock-rotate-left.svg{" "}
        <img style={{ height: 20 }} src={icon637} />
      </span>
    ),
    value: "/icons/solid/clock-rotate-left.svg",
    key: 637,
  },
  {
    label: (
      <span>
        /icons/solid/earth-africa.svg{" "}
        <img style={{ height: 20 }} src={icon638} />
      </span>
    ),
    value: "/icons/solid/earth-africa.svg",
    key: 638,
  },
  {
    label: (
      <span>
        /icons/solid/chevron-down.svg{" "}
        <img style={{ height: 20 }} src={icon639} />
      </span>
    ),
    value: "/icons/solid/chevron-down.svg",
    key: 639,
  },
  {
    label: (
      <span>
        /icons/solid/face-laugh.svg <img style={{ height: 20 }} src={icon640} />
      </span>
    ),
    value: "/icons/solid/face-laugh.svg",
    key: 640,
  },
  {
    label: (
      <span>
        /icons/solid/heading.svg <img style={{ height: 20 }} src={icon641} />
      </span>
    ),
    value: "/icons/solid/heading.svg",
    key: 641,
  },
  {
    label: (
      <span>
        /icons/solid/money-bill-1.svg{" "}
        <img style={{ height: 20 }} src={icon642} />
      </span>
    ),
    value: "/icons/solid/money-bill-1.svg",
    key: 642,
  },
  {
    label: (
      <span>
        /icons/solid/hill-avalanche.svg{" "}
        <img style={{ height: 20 }} src={icon643} />
      </span>
    ),
    value: "/icons/solid/hill-avalanche.svg",
    key: 643,
  },
  {
    label: (
      <span>
        /icons/solid/strikethrough.svg{" "}
        <img style={{ height: 20 }} src={icon644} />
      </span>
    ),
    value: "/icons/solid/strikethrough.svg",
    key: 644,
  },
  {
    label: (
      <span>
        /icons/solid/tents.svg <img style={{ height: 20 }} src={icon645} />
      </span>
    ),
    value: "/icons/solid/tents.svg",
    key: 645,
  },
  {
    label: (
      <span>
        /icons/solid/file-word.svg <img style={{ height: 20 }} src={icon646} />
      </span>
    ),
    value: "/icons/solid/file-word.svg",
    key: 646,
  },
  {
    label: (
      <span>
        /icons/solid/square-virus.svg{" "}
        <img style={{ height: 20 }} src={icon647} />
      </span>
    ),
    value: "/icons/solid/square-virus.svg",
    key: 647,
  },
  {
    label: (
      <span>
        /icons/solid/photo-film.svg <img style={{ height: 20 }} src={icon648} />
      </span>
    ),
    value: "/icons/solid/photo-film.svg",
    key: 648,
  },
  {
    label: (
      <span>
        /icons/solid/i-cursor.svg <img style={{ height: 20 }} src={icon649} />
      </span>
    ),
    value: "/icons/solid/i-cursor.svg",
    key: 649,
  },
  {
    label: (
      <span>
        /icons/solid/money-check.svg{" "}
        <img style={{ height: 20 }} src={icon650} />
      </span>
    ),
    value: "/icons/solid/money-check.svg",
    key: 650,
  },
  {
    label: (
      <span>
        /icons/solid/shirt.svg <img style={{ height: 20 }} src={icon651} />
      </span>
    ),
    value: "/icons/solid/shirt.svg",
    key: 651,
  },
  {
    label: (
      <span>
        /icons/solid/door-closed.svg{" "}
        <img style={{ height: 20 }} src={icon652} />
      </span>
    ),
    value: "/icons/solid/door-closed.svg",
    key: 652,
  },
  {
    label: (
      <span>
        /icons/solid/compact-disc.svg{" "}
        <img style={{ height: 20 }} src={icon653} />
      </span>
    ),
    value: "/icons/solid/compact-disc.svg",
    key: 653,
  },
  {
    label: (
      <span>
        /icons/solid/mosquito.svg <img style={{ height: 20 }} src={icon654} />
      </span>
    ),
    value: "/icons/solid/mosquito.svg",
    key: 654,
  },
  {
    label: (
      <span>
        /icons/solid/tower-observation.svg{" "}
        <img style={{ height: 20 }} src={icon655} />
      </span>
    ),
    value: "/icons/solid/tower-observation.svg",
    key: 655,
  },
  {
    label: (
      <span>
        /icons/solid/rotate.svg <img style={{ height: 20 }} src={icon656} />
      </span>
    ),
    value: "/icons/solid/rotate.svg",
    key: 656,
  },
  {
    label: (
      <span>
        /icons/solid/mug-saucer.svg <img style={{ height: 20 }} src={icon657} />
      </span>
    ),
    value: "/icons/solid/mug-saucer.svg",
    key: 657,
  },
  {
    label: (
      <span>
        /icons/solid/ticket.svg <img style={{ height: 20 }} src={icon658} />
      </span>
    ),
    value: "/icons/solid/ticket.svg",
    key: 658,
  },
  {
    label: (
      <span>
        /icons/solid/boxes-packing.svg{" "}
        <img style={{ height: 20 }} src={icon659} />
      </span>
    ),
    value: "/icons/solid/boxes-packing.svg",
    key: 659,
  },
  {
    label: (
      <span>
        /icons/solid/gas-pump.svg <img style={{ height: 20 }} src={icon660} />
      </span>
    ),
    value: "/icons/solid/gas-pump.svg",
    key: 660,
  },
  {
    label: (
      <span>
        /icons/solid/building-user.svg{" "}
        <img style={{ height: 20 }} src={icon661} />
      </span>
    ),
    value: "/icons/solid/building-user.svg",
    key: 661,
  },
  {
    label: (
      <span>
        /icons/solid/volume-xmark.svg{" "}
        <img style={{ height: 20 }} src={icon662} />
      </span>
    ),
    value: "/icons/solid/volume-xmark.svg",
    key: 662,
  },
  {
    label: (
      <span>
        /icons/solid/arrows-up-down.svg{" "}
        <img style={{ height: 20 }} src={icon663} />
      </span>
    ),
    value: "/icons/solid/arrows-up-down.svg",
    key: 663,
  },
  {
    label: (
      <span>
        /icons/solid/weight-hanging.svg{" "}
        <img style={{ height: 20 }} src={icon664} />
      </span>
    ),
    value: "/icons/solid/weight-hanging.svg",
    key: 664,
  },
  {
    label: (
      <span>
        /icons/solid/face-grin-tongue.svg{" "}
        <img style={{ height: 20 }} src={icon665} />
      </span>
    ),
    value: "/icons/solid/face-grin-tongue.svg",
    key: 665,
  },
  {
    label: (
      <span>
        /icons/solid/shrimp.svg <img style={{ height: 20 }} src={icon666} />
      </span>
    ),
    value: "/icons/solid/shrimp.svg",
    key: 666,
  },
  {
    label: (
      <span>
        /icons/solid/couch.svg <img style={{ height: 20 }} src={icon667} />
      </span>
    ),
    value: "/icons/solid/couch.svg",
    key: 667,
  },
  {
    label: (
      <span>
        /icons/solid/church.svg <img style={{ height: 20 }} src={icon668} />
      </span>
    ),
    value: "/icons/solid/church.svg",
    key: 668,
  },
  {
    label: (
      <span>
        /icons/solid/crop.svg <img style={{ height: 20 }} src={icon669} />
      </span>
    ),
    value: "/icons/solid/crop.svg",
    key: 669,
  },
  {
    label: (
      <span>
        /icons/solid/restroom.svg <img style={{ height: 20 }} src={icon670} />
      </span>
    ),
    value: "/icons/solid/restroom.svg",
    key: 670,
  },
  {
    label: (
      <span>
        /icons/solid/object-group.svg{" "}
        <img style={{ height: 20 }} src={icon671} />
      </span>
    ),
    value: "/icons/solid/object-group.svg",
    key: 671,
  },
  {
    label: (
      <span>
        /icons/solid/pen-fancy.svg <img style={{ height: 20 }} src={icon672} />
      </span>
    ),
    value: "/icons/solid/pen-fancy.svg",
    key: 672,
  },
  {
    label: (
      <span>
        /icons/solid/cookie.svg <img style={{ height: 20 }} src={icon673} />
      </span>
    ),
    value: "/icons/solid/cookie.svg",
    key: 673,
  },
  {
    label: (
      <span>
        /icons/solid/tag.svg <img style={{ height: 20 }} src={icon674} />
      </span>
    ),
    value: "/icons/solid/tag.svg",
    key: 674,
  },
  {
    label: (
      <span>
        /icons/solid/user-tag.svg <img style={{ height: 20 }} src={icon675} />
      </span>
    ),
    value: "/icons/solid/user-tag.svg",
    key: 675,
  },
  {
    label: (
      <span>
        /icons/solid/anchor-circle-exclamation.svg{" "}
        <img style={{ height: 20 }} src={icon676} />
      </span>
    ),
    value: "/icons/solid/anchor-circle-exclamation.svg",
    key: 676,
  },
  {
    label: (
      <span>
        /icons/solid/tablet-screen-button.svg{" "}
        <img style={{ height: 20 }} src={icon677} />
      </span>
    ),
    value: "/icons/solid/tablet-screen-button.svg",
    key: 677,
  },
  {
    label: (
      <span>
        /icons/solid/file-export.svg{" "}
        <img style={{ height: 20 }} src={icon678} />
      </span>
    ),
    value: "/icons/solid/file-export.svg",
    key: 678,
  },
  {
    label: (
      <span>
        /icons/solid/briefcase.svg <img style={{ height: 20 }} src={icon679} />
      </span>
    ),
    value: "/icons/solid/briefcase.svg",
    key: 679,
  },
  {
    label: (
      <span>
        /icons/solid/circle-chevron-down.svg{" "}
        <img style={{ height: 20 }} src={icon680} />
      </span>
    ),
    value: "/icons/solid/circle-chevron-down.svg",
    key: 680,
  },
  {
    label: (
      <span>
        /icons/solid/circle-left.svg{" "}
        <img style={{ height: 20 }} src={icon681} />
      </span>
    ),
    value: "/icons/solid/circle-left.svg",
    key: 681,
  },
  {
    label: (
      <span>
        /icons/solid/dragon.svg <img style={{ height: 20 }} src={icon682} />
      </span>
    ),
    value: "/icons/solid/dragon.svg",
    key: 682,
  },
  {
    label: (
      <span>
        /icons/solid/file-audio.svg <img style={{ height: 20 }} src={icon683} />
      </span>
    ),
    value: "/icons/solid/file-audio.svg",
    key: 683,
  },
  {
    label: (
      <span>
        /icons/solid/ellipsis-vertical.svg{" "}
        <img style={{ height: 20 }} src={icon684} />
      </span>
    ),
    value: "/icons/solid/ellipsis-vertical.svg",
    key: 684,
  },
  {
    label: (
      <span>
        /icons/solid/bag-shopping.svg{" "}
        <img style={{ height: 20 }} src={icon685} />
      </span>
    ),
    value: "/icons/solid/bag-shopping.svg",
    key: 685,
  },
  {
    label: (
      <span>
        /icons/solid/hands-holding.svg{" "}
        <img style={{ height: 20 }} src={icon686} />
      </span>
    ),
    value: "/icons/solid/hands-holding.svg",
    key: 686,
  },
  {
    label: (
      <span>
        /icons/solid/right-to-bracket.svg{" "}
        <img style={{ height: 20 }} src={icon687} />
      </span>
    ),
    value: "/icons/solid/right-to-bracket.svg",
    key: 687,
  },
  {
    label: (
      <span>
        /icons/solid/champagne-glasses.svg{" "}
        <img style={{ height: 20 }} src={icon688} />
      </span>
    ),
    value: "/icons/solid/champagne-glasses.svg",
    key: 688,
  },
  {
    label: (
      <span>
        /icons/solid/splotch.svg <img style={{ height: 20 }} src={icon689} />
      </span>
    ),
    value: "/icons/solid/splotch.svg",
    key: 689,
  },
  {
    label: (
      <span>
        /icons/solid/location-pin.svg{" "}
        <img style={{ height: 20 }} src={icon690} />
      </span>
    ),
    value: "/icons/solid/location-pin.svg",
    key: 690,
  },
  {
    label: (
      <span>
        /icons/solid/shield-halved.svg{" "}
        <img style={{ height: 20 }} src={icon691} />
      </span>
    ),
    value: "/icons/solid/shield-halved.svg",
    key: 691,
  },
  {
    label: (
      <span>
        /icons/solid/anchor-circle-check.svg{" "}
        <img style={{ height: 20 }} src={icon692} />
      </span>
    ),
    value: "/icons/solid/anchor-circle-check.svg",
    key: 692,
  },
  {
    label: (
      <span>
        /icons/solid/shower.svg <img style={{ height: 20 }} src={icon693} />
      </span>
    ),
    value: "/icons/solid/shower.svg",
    key: 693,
  },
  {
    label: (
      <span>
        /icons/solid/square-poll-horizontal.svg{" "}
        <img style={{ height: 20 }} src={icon694} />
      </span>
    ),
    value: "/icons/solid/square-poll-horizontal.svg",
    key: 694,
  },
  {
    label: (
      <span>
        /icons/solid/virus-covid.svg{" "}
        <img style={{ height: 20 }} src={icon695} />
      </span>
    ),
    value: "/icons/solid/virus-covid.svg",
    key: 695,
  },
  {
    label: (
      <span>
        /icons/solid/teeth-open.svg <img style={{ height: 20 }} src={icon696} />
      </span>
    ),
    value: "/icons/solid/teeth-open.svg",
    key: 696,
  },
  {
    label: (
      <span>
        /icons/solid/road-spikes.svg{" "}
        <img style={{ height: 20 }} src={icon697} />
      </span>
    ),
    value: "/icons/solid/road-spikes.svg",
    key: 697,
  },
  {
    label: (
      <span>
        /icons/solid/phone-volume.svg{" "}
        <img style={{ height: 20 }} src={icon698} />
      </span>
    ),
    value: "/icons/solid/phone-volume.svg",
    key: 698,
  },
  {
    label: (
      <span>
        /icons/solid/mosquito-net.svg{" "}
        <img style={{ height: 20 }} src={icon699} />
      </span>
    ),
    value: "/icons/solid/mosquito-net.svg",
    key: 699,
  },
  {
    label: (
      <span>
        /icons/solid/file-circle-minus.svg{" "}
        <img style={{ height: 20 }} src={icon700} />
      </span>
    ),
    value: "/icons/solid/file-circle-minus.svg",
    key: 700,
  },
  {
    label: (
      <span>
        /icons/solid/plane-circle-xmark.svg{" "}
        <img style={{ height: 20 }} src={icon701} />
      </span>
    ),
    value: "/icons/solid/plane-circle-xmark.svg",
    key: 701,
  },
  {
    label: (
      <span>
        /icons/solid/person-skiing-nordic.svg{" "}
        <img style={{ height: 20 }} src={icon702} />
      </span>
    ),
    value: "/icons/solid/person-skiing-nordic.svg",
    key: 702,
  },
  {
    label: (
      <span>
        /icons/solid/headset.svg <img style={{ height: 20 }} src={icon703} />
      </span>
    ),
    value: "/icons/solid/headset.svg",
    key: 703,
  },
  {
    label: (
      <span>
        /icons/solid/face-meh.svg <img style={{ height: 20 }} src={icon704} />
      </span>
    ),
    value: "/icons/solid/face-meh.svg",
    key: 704,
  },
  {
    label: (
      <span>
        /icons/solid/id-card-clip.svg{" "}
        <img style={{ height: 20 }} src={icon705} />
      </span>
    ),
    value: "/icons/solid/id-card-clip.svg",
    key: 705,
  },
  {
    label: (
      <span>
        /icons/solid/carrot.svg <img style={{ height: 20 }} src={icon706} />
      </span>
    ),
    value: "/icons/solid/carrot.svg",
    key: 706,
  },
  {
    label: (
      <span>
        /icons/solid/prescription-bottle.svg{" "}
        <img style={{ height: 20 }} src={icon707} />
      </span>
    ),
    value: "/icons/solid/prescription-bottle.svg",
    key: 707,
  },
  {
    label: (
      <span>
        /icons/solid/arrows-to-circle.svg{" "}
        <img style={{ height: 20 }} src={icon708} />
      </span>
    ),
    value: "/icons/solid/arrows-to-circle.svg",
    key: 708,
  },
  {
    label: (
      <span>
        /icons/solid/map.svg <img style={{ height: 20 }} src={icon709} />
      </span>
    ),
    value: "/icons/solid/map.svg",
    key: 709,
  },
  {
    label: (
      <span>
        /icons/solid/inbox.svg <img style={{ height: 20 }} src={icon710} />
      </span>
    ),
    value: "/icons/solid/inbox.svg",
    key: 710,
  },
  {
    label: (
      <span>
        /icons/solid/trowel.svg <img style={{ height: 20 }} src={icon711} />
      </span>
    ),
    value: "/icons/solid/trowel.svg",
    key: 711,
  },
  {
    label: (
      <span>
        /icons/solid/comments.svg <img style={{ height: 20 }} src={icon712} />
      </span>
    ),
    value: "/icons/solid/comments.svg",
    key: 712,
  },
  {
    label: (
      <span>
        /icons/solid/align-justify.svg{" "}
        <img style={{ height: 20 }} src={icon713} />
      </span>
    ),
    value: "/icons/solid/align-justify.svg",
    key: 713,
  },
  {
    label: (
      <span>
        /icons/solid/anchor-lock.svg{" "}
        <img style={{ height: 20 }} src={icon714} />
      </span>
    ),
    value: "/icons/solid/anchor-lock.svg",
    key: 714,
  },
  {
    label: (
      <span>
        /icons/solid/microphone.svg <img style={{ height: 20 }} src={icon715} />
      </span>
    ),
    value: "/icons/solid/microphone.svg",
    key: 715,
  },
  {
    label: (
      <span>
        /icons/solid/feather-pointed.svg{" "}
        <img style={{ height: 20 }} src={icon716} />
      </span>
    ),
    value: "/icons/solid/feather-pointed.svg",
    key: 716,
  },
  {
    label: (
      <span>
        /icons/solid/database.svg <img style={{ height: 20 }} src={icon717} />
      </span>
    ),
    value: "/icons/solid/database.svg",
    key: 717,
  },
  {
    label: (
      <span>
        /icons/solid/calendar-day.svg{" "}
        <img style={{ height: 20 }} src={icon718} />
      </span>
    ),
    value: "/icons/solid/calendar-day.svg",
    key: 718,
  },
  {
    label: (
      <span>
        /icons/solid/angle-down.svg <img style={{ height: 20 }} src={icon719} />
      </span>
    ),
    value: "/icons/solid/angle-down.svg",
    key: 719,
  },
  {
    label: (
      <span>
        /icons/solid/pump-soap.svg <img style={{ height: 20 }} src={icon720} />
      </span>
    ),
    value: "/icons/solid/pump-soap.svg",
    key: 720,
  },
  {
    label: (
      <span>
        /icons/solid/dice-five.svg <img style={{ height: 20 }} src={icon721} />
      </span>
    ),
    value: "/icons/solid/dice-five.svg",
    key: 721,
  },
  {
    label: (
      <span>
        /icons/solid/truck-moving.svg{" "}
        <img style={{ height: 20 }} src={icon722} />
      </span>
    ),
    value: "/icons/solid/truck-moving.svg",
    key: 722,
  },
  {
    label: (
      <span>
        /icons/solid/people-line.svg{" "}
        <img style={{ height: 20 }} src={icon723} />
      </span>
    ),
    value: "/icons/solid/people-line.svg",
    key: 723,
  },
  {
    label: (
      <span>
        /icons/solid/satellite-dish.svg{" "}
        <img style={{ height: 20 }} src={icon724} />
      </span>
    ),
    value: "/icons/solid/satellite-dish.svg",
    key: 724,
  },
  {
    label: (
      <span>
        /icons/solid/text-width.svg <img style={{ height: 20 }} src={icon725} />
      </span>
    ),
    value: "/icons/solid/text-width.svg",
    key: 725,
  },
  {
    label: (
      <span>
        /icons/solid/delete-left.svg{" "}
        <img style={{ height: 20 }} src={icon726} />
      </span>
    ),
    value: "/icons/solid/delete-left.svg",
    key: 726,
  },
  {
    label: (
      <span>
        /icons/solid/location-dot.svg{" "}
        <img style={{ height: 20 }} src={icon727} />
      </span>
    ),
    value: "/icons/solid/location-dot.svg",
    key: 727,
  },
  {
    label: (
      <span>
        /icons/solid/arrow-down-z-a.svg{" "}
        <img style={{ height: 20 }} src={icon728} />
      </span>
    ),
    value: "/icons/solid/arrow-down-z-a.svg",
    key: 728,
  },
  {
    label: (
      <span>
        /icons/solid/person-falling-burst.svg{" "}
        <img style={{ height: 20 }} src={icon729} />
      </span>
    ),
    value: "/icons/solid/person-falling-burst.svg",
    key: 729,
  },
  {
    label: (
      <span>
        /icons/solid/user-xmark.svg <img style={{ height: 20 }} src={icon730} />
      </span>
    ),
    value: "/icons/solid/user-xmark.svg",
    key: 730,
  },
  {
    label: (
      <span>
        /icons/solid/share-nodes.svg{" "}
        <img style={{ height: 20 }} src={icon731} />
      </span>
    ),
    value: "/icons/solid/share-nodes.svg",
    key: 731,
  },
  {
    label: (
      <span>
        /icons/solid/user-astronaut.svg{" "}
        <img style={{ height: 20 }} src={icon732} />
      </span>
    ),
    value: "/icons/solid/user-astronaut.svg",
    key: 732,
  },
  {
    label: (
      <span>
        /icons/solid/tornado.svg <img style={{ height: 20 }} src={icon733} />
      </span>
    ),
    value: "/icons/solid/tornado.svg",
    key: 733,
  },
  {
    label: (
      <span>
        /icons/solid/helmet-un.svg <img style={{ height: 20 }} src={icon734} />
      </span>
    ),
    value: "/icons/solid/helmet-un.svg",
    key: 734,
  },
  {
    label: (
      <span>
        /icons/solid/file-invoice-dollar.svg{" "}
        <img style={{ height: 20 }} src={icon735} />
      </span>
    ),
    value: "/icons/solid/file-invoice-dollar.svg",
    key: 735,
  },
  {
    label: (
      <span>
        /icons/solid/square-caret-right.svg{" "}
        <img style={{ height: 20 }} src={icon736} />
      </span>
    ),
    value: "/icons/solid/square-caret-right.svg",
    key: 736,
  },
  {
    label: (
      <span>
        /icons/solid/truck-field.svg{" "}
        <img style={{ height: 20 }} src={icon737} />
      </span>
    ),
    value: "/icons/solid/truck-field.svg",
    key: 737,
  },
  {
    label: (
      <span>
        /icons/solid/file.svg <img style={{ height: 20 }} src={icon738} />
      </span>
    ),
    value: "/icons/solid/file.svg",
    key: 738,
  },
  {
    label: (
      <span>
        /icons/solid/heart-circle-exclamation.svg{" "}
        <img style={{ height: 20 }} src={icon739} />
      </span>
    ),
    value: "/icons/solid/heart-circle-exclamation.svg",
    key: 739,
  },
  {
    label: (
      <span>
        /icons/solid/building-ngo.svg{" "}
        <img style={{ height: 20 }} src={icon740} />
      </span>
    ),
    value: "/icons/solid/building-ngo.svg",
    key: 740,
  },
  {
    label: (
      <span>
        /icons/solid/signal.svg <img style={{ height: 20 }} src={icon741} />
      </span>
    ),
    value: "/icons/solid/signal.svg",
    key: 741,
  },
  {
    label: (
      <span>
        /icons/solid/trash-can-arrow-up.svg{" "}
        <img style={{ height: 20 }} src={icon742} />
      </span>
    ),
    value: "/icons/solid/trash-can-arrow-up.svg",
    key: 742,
  },
  {
    label: (
      <span>
        /icons/solid/share-from-square.svg{" "}
        <img style={{ height: 20 }} src={icon743} />
      </span>
    ),
    value: "/icons/solid/share-from-square.svg",
    key: 743,
  },
  {
    label: (
      <span>
        /icons/solid/glass-water.svg{" "}
        <img style={{ height: 20 }} src={icon744} />
      </span>
    ),
    value: "/icons/solid/glass-water.svg",
    key: 744,
  },
  {
    label: (
      <span>
        /icons/solid/handshake-angle.svg{" "}
        <img style={{ height: 20 }} src={icon745} />
      </span>
    ),
    value: "/icons/solid/handshake-angle.svg",
    key: 745,
  },
  {
    label: (
      <span>
        /icons/solid/brain.svg <img style={{ height: 20 }} src={icon746} />
      </span>
    ),
    value: "/icons/solid/brain.svg",
    key: 746,
  },
  {
    label: (
      <span>
        /icons/solid/wheat-awn-circle-exclamation.svg{" "}
        <img style={{ height: 20 }} src={icon747} />
      </span>
    ),
    value: "/icons/solid/wheat-awn-circle-exclamation.svg",
    key: 747,
  },
  {
    label: (
      <span>
        /icons/solid/helicopter.svg <img style={{ height: 20 }} src={icon748} />
      </span>
    ),
    value: "/icons/solid/helicopter.svg",
    key: 748,
  },
  {
    label: (
      <span>
        /icons/solid/angles-down.svg{" "}
        <img style={{ height: 20 }} src={icon749} />
      </span>
    ),
    value: "/icons/solid/angles-down.svg",
    key: 749,
  },
  {
    label: (
      <span>
        /icons/solid/ruler-horizontal.svg{" "}
        <img style={{ height: 20 }} src={icon750} />
      </span>
    ),
    value: "/icons/solid/ruler-horizontal.svg",
    key: 750,
  },
  {
    label: (
      <span>
        /icons/solid/paw.svg <img style={{ height: 20 }} src={icon751} />
      </span>
    ),
    value: "/icons/solid/paw.svg",
    key: 751,
  },
  {
    label: (
      <span>
        /icons/solid/square-root-variable.svg{" "}
        <img style={{ height: 20 }} src={icon752} />
      </span>
    ),
    value: "/icons/solid/square-root-variable.svg",
    key: 752,
  },
  {
    label: (
      <span>
        /icons/solid/chess.svg <img style={{ height: 20 }} src={icon753} />
      </span>
    ),
    value: "/icons/solid/chess.svg",
    key: 753,
  },
  {
    label: (
      <span>
        /icons/solid/filter-circle-xmark.svg{" "}
        <img style={{ height: 20 }} src={icon754} />
      </span>
    ),
    value: "/icons/solid/filter-circle-xmark.svg",
    key: 754,
  },
  {
    label: (
      <span>
        /icons/solid/voicemail.svg <img style={{ height: 20 }} src={icon755} />
      </span>
    ),
    value: "/icons/solid/voicemail.svg",
    key: 755,
  },
  {
    label: (
      <span>
        /icons/solid/trowel-bricks.svg{" "}
        <img style={{ height: 20 }} src={icon756} />
      </span>
    ),
    value: "/icons/solid/trowel-bricks.svg",
    key: 756,
  },
  {
    label: (
      <span>
        /icons/solid/rug.svg <img style={{ height: 20 }} src={icon757} />
      </span>
    ),
    value: "/icons/solid/rug.svg",
    key: 757,
  },
  {
    label: (
      <span>
        /icons/solid/road.svg <img style={{ height: 20 }} src={icon758} />
      </span>
    ),
    value: "/icons/solid/road.svg",
    key: 758,
  },
  {
    label: (
      <span>
        /icons/solid/truck-field-un.svg{" "}
        <img style={{ height: 20 }} src={icon759} />
      </span>
    ),
    value: "/icons/solid/truck-field-un.svg",
    key: 759,
  },
  {
    label: (
      <span>
        /icons/solid/text-height.svg{" "}
        <img style={{ height: 20 }} src={icon760} />
      </span>
    ),
    value: "/icons/solid/text-height.svg",
    key: 760,
  },
  {
    label: (
      <span>
        /icons/solid/pen-to-square.svg{" "}
        <img style={{ height: 20 }} src={icon761} />
      </span>
    ),
    value: "/icons/solid/pen-to-square.svg",
    key: 761,
  },
  {
    label: (
      <span>
        /icons/solid/box-open.svg <img style={{ height: 20 }} src={icon762} />
      </span>
    ),
    value: "/icons/solid/box-open.svg",
    key: 762,
  },
  {
    label: (
      <span>
        /icons/solid/terminal.svg <img style={{ height: 20 }} src={icon763} />
      </span>
    ),
    value: "/icons/solid/terminal.svg",
    key: 763,
  },
  {
    label: (
      <span>
        /icons/solid/plant-wilt.svg <img style={{ height: 20 }} src={icon764} />
      </span>
    ),
    value: "/icons/solid/plant-wilt.svg",
    key: 764,
  },
  {
    label: (
      <span>
        /icons/solid/person-harassing.svg{" "}
        <img style={{ height: 20 }} src={icon765} />
      </span>
    ),
    value: "/icons/solid/person-harassing.svg",
    key: 765,
  },
  {
    label: (
      <span>
        /icons/solid/ranking-star.svg{" "}
        <img style={{ height: 20 }} src={icon766} />
      </span>
    ),
    value: "/icons/solid/ranking-star.svg",
    key: 766,
  },
  {
    label: (
      <span>
        /icons/solid/person-half-dress.svg{" "}
        <img style={{ height: 20 }} src={icon767} />
      </span>
    ),
    value: "/icons/solid/person-half-dress.svg",
    key: 767,
  },
  {
    label: (
      <span>
        /icons/solid/child-dress.svg{" "}
        <img style={{ height: 20 }} src={icon768} />
      </span>
    ),
    value: "/icons/solid/child-dress.svg",
    key: 768,
  },
  {
    label: (
      <span>
        /icons/solid/plus-minus.svg <img style={{ height: 20 }} src={icon769} />
      </span>
    ),
    value: "/icons/solid/plus-minus.svg",
    key: 769,
  },
  {
    label: (
      <span>
        /icons/solid/maximize.svg <img style={{ height: 20 }} src={icon770} />
      </span>
    ),
    value: "/icons/solid/maximize.svg",
    key: 770,
  },
  {
    label: (
      <span>
        /icons/solid/book-skull.svg <img style={{ height: 20 }} src={icon771} />
      </span>
    ),
    value: "/icons/solid/book-skull.svg",
    key: 771,
  },
  {
    label: (
      <span>
        /icons/solid/sack-dollar.svg{" "}
        <img style={{ height: 20 }} src={icon772} />
      </span>
    ),
    value: "/icons/solid/sack-dollar.svg",
    key: 772,
  },
  {
    label: (
      <span>
        /icons/solid/city.svg <img style={{ height: 20 }} src={icon773} />
      </span>
    ),
    value: "/icons/solid/city.svg",
    key: 773,
  },
  {
    label: (
      <span>
        /icons/solid/comment-dollar.svg{" "}
        <img style={{ height: 20 }} src={icon774} />
      </span>
    ),
    value: "/icons/solid/comment-dollar.svg",
    key: 774,
  },
  {
    label: (
      <span>
        /icons/solid/chevron-up.svg <img style={{ height: 20 }} src={icon775} />
      </span>
    ),
    value: "/icons/solid/chevron-up.svg",
    key: 775,
  },
  {
    label: (
      <span>
        /icons/solid/lira-sign.svg <img style={{ height: 20 }} src={icon776} />
      </span>
    ),
    value: "/icons/solid/lira-sign.svg",
    key: 776,
  },
  {
    label: (
      <span>
        /icons/solid/square-full.svg{" "}
        <img style={{ height: 20 }} src={icon777} />
      </span>
    ),
    value: "/icons/solid/square-full.svg",
    key: 777,
  },
  {
    label: (
      <span>
        /icons/solid/bridge.svg <img style={{ height: 20 }} src={icon778} />
      </span>
    ),
    value: "/icons/solid/bridge.svg",
    key: 778,
  },
  {
    label: (
      <span>
        /icons/solid/person-skiing.svg{" "}
        <img style={{ height: 20 }} src={icon779} />
      </span>
    ),
    value: "/icons/solid/person-skiing.svg",
    key: 779,
  },
  {
    label: (
      <span>
        /icons/solid/table-cells.svg{" "}
        <img style={{ height: 20 }} src={icon780} />
      </span>
    ),
    value: "/icons/solid/table-cells.svg",
    key: 780,
  },
  {
    label: (
      <span>
        /icons/solid/file-video.svg <img style={{ height: 20 }} src={icon781} />
      </span>
    ),
    value: "/icons/solid/file-video.svg",
    key: 781,
  },
  {
    label: (
      <span>
        /icons/solid/mortar-pestle.svg{" "}
        <img style={{ height: 20 }} src={icon782} />
      </span>
    ),
    value: "/icons/solid/mortar-pestle.svg",
    key: 782,
  },
  {
    label: (
      <span>
        /icons/solid/folder-open.svg{" "}
        <img style={{ height: 20 }} src={icon783} />
      </span>
    ),
    value: "/icons/solid/folder-open.svg",
    key: 783,
  },
  {
    label: (
      <span>
        /icons/solid/droplet.svg <img style={{ height: 20 }} src={icon784} />
      </span>
    ),
    value: "/icons/solid/droplet.svg",
    key: 784,
  },
  {
    label: (
      <span>
        /icons/solid/plane-departure.svg{" "}
        <img style={{ height: 20 }} src={icon785} />
      </span>
    ),
    value: "/icons/solid/plane-departure.svg",
    key: 785,
  },
  {
    label: (
      <span>
        /icons/solid/cent-sign.svg <img style={{ height: 20 }} src={icon786} />
      </span>
    ),
    value: "/icons/solid/cent-sign.svg",
    key: 786,
  },
  {
    label: (
      <span>
        /icons/solid/fish-fins.svg <img style={{ height: 20 }} src={icon787} />
      </span>
    ),
    value: "/icons/solid/fish-fins.svg",
    key: 787,
  },
  {
    label: (
      <span>
        /icons/solid/user-large-slash.svg{" "}
        <img style={{ height: 20 }} src={icon788} />
      </span>
    ),
    value: "/icons/solid/user-large-slash.svg",
    key: 788,
  },
  {
    label: (
      <span>
        /icons/solid/paint-roller.svg{" "}
        <img style={{ height: 20 }} src={icon789} />
      </span>
    ),
    value: "/icons/solid/paint-roller.svg",
    key: 789,
  },
  {
    label: (
      <span>
        /icons/solid/baht-sign.svg <img style={{ height: 20 }} src={icon790} />
      </span>
    ),
    value: "/icons/solid/baht-sign.svg",
    key: 790,
  },
  {
    label: (
      <span>
        /icons/solid/keyboard.svg <img style={{ height: 20 }} src={icon791} />
      </span>
    ),
    value: "/icons/solid/keyboard.svg",
    key: 791,
  },
  {
    label: (
      <span>
        /icons/solid/turn-up.svg <img style={{ height: 20 }} src={icon792} />
      </span>
    ),
    value: "/icons/solid/turn-up.svg",
    key: 792,
  },
  {
    label: (
      <span>
        /icons/solid/x.svg <img style={{ height: 20 }} src={icon793} />
      </span>
    ),
    value: "/icons/solid/x.svg",
    key: 793,
  },
  {
    label: (
      <span>
        /icons/solid/9.svg <img style={{ height: 20 }} src={icon794} />
      </span>
    ),
    value: "/icons/solid/9.svg",
    key: 794,
  },
  {
    label: (
      <span>
        /icons/solid/wine-glass-empty.svg{" "}
        <img style={{ height: 20 }} src={icon795} />
      </span>
    ),
    value: "/icons/solid/wine-glass-empty.svg",
    key: 795,
  },
  {
    label: (
      <span>
        /icons/solid/staff-snake.svg{" "}
        <img style={{ height: 20 }} src={icon796} />
      </span>
    ),
    value: "/icons/solid/staff-snake.svg",
    key: 796,
  },
  {
    label: (
      <span>
        /icons/solid/less-than.svg <img style={{ height: 20 }} src={icon797} />
      </span>
    ),
    value: "/icons/solid/less-than.svg",
    key: 797,
  },
  {
    label: (
      <span>
        /icons/solid/angle-up.svg <img style={{ height: 20 }} src={icon798} />
      </span>
    ),
    value: "/icons/solid/angle-up.svg",
    key: 798,
  },
  {
    label: (
      <span>
        /icons/solid/bath.svg <img style={{ height: 20 }} src={icon799} />
      </span>
    ),
    value: "/icons/solid/bath.svg",
    key: 799,
  },
  {
    label: (
      <span>
        /icons/solid/o.svg <img style={{ height: 20 }} src={icon800} />
      </span>
    ),
    value: "/icons/solid/o.svg",
    key: 800,
  },
  {
    label: (
      <span>
        /icons/solid/record-vinyl.svg{" "}
        <img style={{ height: 20 }} src={icon801} />
      </span>
    ),
    value: "/icons/solid/record-vinyl.svg",
    key: 801,
  },
  {
    label: (
      <span>
        /icons/solid/tablet-button.svg{" "}
        <img style={{ height: 20 }} src={icon802} />
      </span>
    ),
    value: "/icons/solid/tablet-button.svg",
    key: 802,
  },
  {
    label: (
      <span>
        /icons/solid/face-grin-squint-tears.svg{" "}
        <img style={{ height: 20 }} src={icon803} />
      </span>
    ),
    value: "/icons/solid/face-grin-squint-tears.svg",
    key: 803,
  },
  {
    label: (
      <span>
        /icons/solid/up-right-and-down-left-from-center.svg{" "}
        <img style={{ height: 20 }} src={icon804} />
      </span>
    ),
    value: "/icons/solid/up-right-and-down-left-from-center.svg",
    key: 804,
  },
  {
    label: (
      <span>
        /icons/solid/laptop-file.svg{" "}
        <img style={{ height: 20 }} src={icon805} />
      </span>
    ),
    value: "/icons/solid/laptop-file.svg",
    key: 805,
  },
  {
    label: (
      <span>
        /icons/solid/poo-storm.svg <img style={{ height: 20 }} src={icon806} />
      </span>
    ),
    value: "/icons/solid/poo-storm.svg",
    key: 806,
  },
  {
    label: (
      <span>
        /icons/solid/question.svg <img style={{ height: 20 }} src={icon807} />
      </span>
    ),
    value: "/icons/solid/question.svg",
    key: 807,
  },
  {
    label: (
      <span>
        /icons/solid/face-laugh-wink.svg{" "}
        <img style={{ height: 20 }} src={icon808} />
      </span>
    ),
    value: "/icons/solid/face-laugh-wink.svg",
    key: 808,
  },
  {
    label: (
      <span>
        /icons/solid/cable-car.svg <img style={{ height: 20 }} src={icon809} />
      </span>
    ),
    value: "/icons/solid/cable-car.svg",
    key: 809,
  },
  {
    label: (
      <span>
        /icons/solid/lock.svg <img style={{ height: 20 }} src={icon810} />
      </span>
    ),
    value: "/icons/solid/lock.svg",
    key: 810,
  },
  {
    label: (
      <span>
        /icons/solid/face-grin-wink.svg{" "}
        <img style={{ height: 20 }} src={icon811} />
      </span>
    ),
    value: "/icons/solid/face-grin-wink.svg",
    key: 811,
  },
  {
    label: (
      <span>
        /icons/solid/toilets-portable.svg{" "}
        <img style={{ height: 20 }} src={icon812} />
      </span>
    ),
    value: "/icons/solid/toilets-portable.svg",
    key: 812,
  },
  {
    label: (
      <span>
        /icons/solid/face-rolling-eyes.svg{" "}
        <img style={{ height: 20 }} src={icon813} />
      </span>
    ),
    value: "/icons/solid/face-rolling-eyes.svg",
    key: 813,
  },
  {
    label: (
      <span>
        /icons/solid/person-military-to-person.svg{" "}
        <img style={{ height: 20 }} src={icon814} />
      </span>
    ),
    value: "/icons/solid/person-military-to-person.svg",
    key: 814,
  },
  {
    label: (
      <span>
        /icons/solid/x-ray.svg <img style={{ height: 20 }} src={icon815} />
      </span>
    ),
    value: "/icons/solid/x-ray.svg",
    key: 815,
  },
  {
    label: (
      <span>
        /icons/solid/fire.svg <img style={{ height: 20 }} src={icon816} />
      </span>
    ),
    value: "/icons/solid/fire.svg",
    key: 816,
  },
  {
    label: (
      <span>
        /icons/solid/square-person-confined.svg{" "}
        <img style={{ height: 20 }} src={icon817} />
      </span>
    ),
    value: "/icons/solid/square-person-confined.svg",
    key: 817,
  },
  {
    label: (
      <span>
        /icons/solid/ban.svg <img style={{ height: 20 }} src={icon818} />
      </span>
    ),
    value: "/icons/solid/ban.svg",
    key: 818,
  },
  {
    label: (
      <span>
        /icons/solid/heart-circle-check.svg{" "}
        <img style={{ height: 20 }} src={icon819} />
      </span>
    ),
    value: "/icons/solid/heart-circle-check.svg",
    key: 819,
  },
  {
    label: (
      <span>
        /icons/solid/person-dress.svg{" "}
        <img style={{ height: 20 }} src={icon820} />
      </span>
    ),
    value: "/icons/solid/person-dress.svg",
    key: 820,
  },
  {
    label: (
      <span>
        /icons/solid/divide.svg <img style={{ height: 20 }} src={icon821} />
      </span>
    ),
    value: "/icons/solid/divide.svg",
    key: 821,
  },
  {
    label: (
      <span>
        /icons/solid/piggy-bank.svg <img style={{ height: 20 }} src={icon822} />
      </span>
    ),
    value: "/icons/solid/piggy-bank.svg",
    key: 822,
  },
  {
    label: (
      <span>
        /icons/solid/martini-glass.svg{" "}
        <img style={{ height: 20 }} src={icon823} />
      </span>
    ),
    value: "/icons/solid/martini-glass.svg",
    key: 823,
  },
  {
    label: (
      <span>
        /icons/solid/file-contract.svg{" "}
        <img style={{ height: 20 }} src={icon824} />
      </span>
    ),
    value: "/icons/solid/file-contract.svg",
    key: 824,
  },
  {
    label: (
      <span>
        /icons/solid/person-circle-check.svg{" "}
        <img style={{ height: 20 }} src={icon825} />
      </span>
    ),
    value: "/icons/solid/person-circle-check.svg",
    key: 825,
  },
  {
    label: (
      <span>
        /icons/solid/square-parking.svg{" "}
        <img style={{ height: 20 }} src={icon826} />
      </span>
    ),
    value: "/icons/solid/square-parking.svg",
    key: 826,
  },
  {
    label: (
      <span>
        /icons/solid/sd-card.svg <img style={{ height: 20 }} src={icon827} />
      </span>
    ),
    value: "/icons/solid/sd-card.svg",
    key: 827,
  },
  {
    label: (
      <span>
        /icons/solid/plane-circle-exclamation.svg{" "}
        <img style={{ height: 20 }} src={icon828} />
      </span>
    ),
    value: "/icons/solid/plane-circle-exclamation.svg",
    key: 828,
  },
  {
    label: (
      <span>
        /icons/solid/mask-ventilator.svg{" "}
        <img style={{ height: 20 }} src={icon829} />
      </span>
    ),
    value: "/icons/solid/mask-ventilator.svg",
    key: 829,
  },
  {
    label: (
      <span>
        /icons/solid/tent-arrow-down-to-line.svg{" "}
        <img style={{ height: 20 }} src={icon830} />
      </span>
    ),
    value: "/icons/solid/tent-arrow-down-to-line.svg",
    key: 830,
  },
  {
    label: (
      <span>
        /icons/solid/bacterium.svg <img style={{ height: 20 }} src={icon831} />
      </span>
    ),
    value: "/icons/solid/bacterium.svg",
    key: 831,
  },
  {
    label: (
      <span>
        /icons/solid/temperature-half.svg{" "}
        <img style={{ height: 20 }} src={icon832} />
      </span>
    ),
    value: "/icons/solid/temperature-half.svg",
    key: 832,
  },
  {
    label: (
      <span>
        /icons/solid/money-bill-wheat.svg{" "}
        <img style={{ height: 20 }} src={icon833} />
      </span>
    ),
    value: "/icons/solid/money-bill-wheat.svg",
    key: 833,
  },
  {
    label: (
      <span>
        /icons/solid/pen.svg <img style={{ height: 20 }} src={icon834} />
      </span>
    ),
    value: "/icons/solid/pen.svg",
    key: 834,
  },
  {
    label: (
      <span>
        /icons/solid/user-pen.svg <img style={{ height: 20 }} src={icon835} />
      </span>
    ),
    value: "/icons/solid/user-pen.svg",
    key: 835,
  },
  {
    label: (
      <span>
        /icons/solid/arrow-up-long.svg{" "}
        <img style={{ height: 20 }} src={icon836} />
      </span>
    ),
    value: "/icons/solid/arrow-up-long.svg",
    key: 836,
  },
  {
    label: (
      <span>
        /icons/solid/drumstick-bite.svg{" "}
        <img style={{ height: 20 }} src={icon837} />
      </span>
    ),
    value: "/icons/solid/drumstick-bite.svg",
    key: 837,
  },
  {
    label: (
      <span>
        /icons/solid/plane.svg <img style={{ height: 20 }} src={icon838} />
      </span>
    ),
    value: "/icons/solid/plane.svg",
    key: 838,
  },
  {
    label: (
      <span>
        /icons/solid/chevron-right.svg{" "}
        <img style={{ height: 20 }} src={icon839} />
      </span>
    ),
    value: "/icons/solid/chevron-right.svg",
    key: 839,
  },
  {
    label: (
      <span>
        /icons/solid/republican.svg <img style={{ height: 20 }} src={icon840} />
      </span>
    ),
    value: "/icons/solid/republican.svg",
    key: 840,
  },
  {
    label: (
      <span>
        /icons/solid/arrow-up-wide-short.svg{" "}
        <img style={{ height: 20 }} src={icon841} />
      </span>
    ),
    value: "/icons/solid/arrow-up-wide-short.svg",
    key: 841,
  },
  {
    label: (
      <span>
        /icons/solid/tarp-droplet.svg{" "}
        <img style={{ height: 20 }} src={icon842} />
      </span>
    ),
    value: "/icons/solid/tarp-droplet.svg",
    key: 842,
  },
  {
    label: (
      <span>
        /icons/solid/clipboard.svg <img style={{ height: 20 }} src={icon843} />
      </span>
    ),
    value: "/icons/solid/clipboard.svg",
    key: 843,
  },
  {
    label: (
      <span>
        /icons/solid/mars.svg <img style={{ height: 20 }} src={icon844} />
      </span>
    ),
    value: "/icons/solid/mars.svg",
    key: 844,
  },
  {
    label: (
      <span>
        /icons/solid/circle-question.svg{" "}
        <img style={{ height: 20 }} src={icon845} />
      </span>
    ),
    value: "/icons/solid/circle-question.svg",
    key: 845,
  },
  {
    label: (
      <span>
        /icons/solid/venus-mars.svg <img style={{ height: 20 }} src={icon846} />
      </span>
    ),
    value: "/icons/solid/venus-mars.svg",
    key: 846,
  },
  {
    label: (
      <span>
        /icons/solid/truck-pickup.svg{" "}
        <img style={{ height: 20 }} src={icon847} />
      </span>
    ),
    value: "/icons/solid/truck-pickup.svg",
    key: 847,
  },
  {
    label: (
      <span>
        /icons/solid/life-ring.svg <img style={{ height: 20 }} src={icon848} />
      </span>
    ),
    value: "/icons/solid/life-ring.svg",
    key: 848,
  },
  {
    label: (
      <span>
        /icons/solid/link.svg <img style={{ height: 20 }} src={icon849} />
      </span>
    ),
    value: "/icons/solid/link.svg",
    key: 849,
  },
  {
    label: (
      <span>
        /icons/solid/tent-arrow-left-right.svg{" "}
        <img style={{ height: 20 }} src={icon850} />
      </span>
    ),
    value: "/icons/solid/tent-arrow-left-right.svg",
    key: 850,
  },
  {
    label: (
      <span>
        /icons/solid/certificate.svg{" "}
        <img style={{ height: 20 }} src={icon851} />
      </span>
    ),
    value: "/icons/solid/certificate.svg",
    key: 851,
  },
  {
    label: (
      <span>
        /icons/solid/basket-shopping.svg{" "}
        <img style={{ height: 20 }} src={icon852} />
      </span>
    ),
    value: "/icons/solid/basket-shopping.svg",
    key: 852,
  },
  {
    label: (
      <span>
        /icons/solid/toggle-off.svg <img style={{ height: 20 }} src={icon853} />
      </span>
    ),
    value: "/icons/solid/toggle-off.svg",
    key: 853,
  },
  {
    label: (
      <span>
        /icons/solid/person-circle-plus.svg{" "}
        <img style={{ height: 20 }} src={icon854} />
      </span>
    ),
    value: "/icons/solid/person-circle-plus.svg",
    key: 854,
  },
  {
    label: (
      <span>
        /icons/solid/key.svg <img style={{ height: 20 }} src={icon855} />
      </span>
    ),
    value: "/icons/solid/key.svg",
    key: 855,
  },
  {
    label: (
      <span>
        /icons/solid/ferry.svg <img style={{ height: 20 }} src={icon856} />
      </span>
    ),
    value: "/icons/solid/ferry.svg",
    key: 856,
  },
  {
    label: (
      <span>
        /icons/solid/sailboat.svg <img style={{ height: 20 }} src={icon857} />
      </span>
    ),
    value: "/icons/solid/sailboat.svg",
    key: 857,
  },
  {
    label: (
      <span>
        /icons/solid/gifts.svg <img style={{ height: 20 }} src={icon858} />
      </span>
    ),
    value: "/icons/solid/gifts.svg",
    key: 858,
  },
  {
    label: (
      <span>
        /icons/solid/code-pull-request.svg{" "}
        <img style={{ height: 20 }} src={icon859} />
      </span>
    ),
    value: "/icons/solid/code-pull-request.svg",
    key: 859,
  },
  {
    label: (
      <span>
        /icons/solid/child-reaching.svg{" "}
        <img style={{ height: 20 }} src={icon860} />
      </span>
    ),
    value: "/icons/solid/child-reaching.svg",
    key: 860,
  },
  {
    label: (
      <span>
        /icons/solid/kitchen-set.svg{" "}
        <img style={{ height: 20 }} src={icon861} />
      </span>
    ),
    value: "/icons/solid/kitchen-set.svg",
    key: 861,
  },
  {
    label: (
      <span>
        /icons/solid/road-circle-exclamation.svg{" "}
        <img style={{ height: 20 }} src={icon862} />
      </span>
    ),
    value: "/icons/solid/road-circle-exclamation.svg",
    key: 862,
  },
  {
    label: (
      <span>
        /icons/solid/door-open.svg <img style={{ height: 20 }} src={icon863} />
      </span>
    ),
    value: "/icons/solid/door-open.svg",
    key: 863,
  },
  {
    label: (
      <span>
        /icons/solid/locust.svg <img style={{ height: 20 }} src={icon864} />
      </span>
    ),
    value: "/icons/solid/locust.svg",
    key: 864,
  },
  {
    label: (
      <span>
        /icons/solid/n.svg <img style={{ height: 20 }} src={icon865} />
      </span>
    ),
    value: "/icons/solid/n.svg",
    key: 865,
  },
  {
    label: (
      <span>
        /icons/solid/check-to-slot.svg{" "}
        <img style={{ height: 20 }} src={icon866} />
      </span>
    ),
    value: "/icons/solid/check-to-slot.svg",
    key: 866,
  },
  {
    label: (
      <span>
        /icons/solid/book-quran.svg <img style={{ height: 20 }} src={icon867} />
      </span>
    ),
    value: "/icons/solid/book-quran.svg",
    key: 867,
  },
  {
    label: (
      <span>
        /icons/solid/cloud-sun.svg <img style={{ height: 20 }} src={icon868} />
      </span>
    ),
    value: "/icons/solid/cloud-sun.svg",
    key: 868,
  },
  {
    label: (
      <span>
        /icons/solid/arrow-right.svg{" "}
        <img style={{ height: 20 }} src={icon869} />
      </span>
    ),
    value: "/icons/solid/arrow-right.svg",
    key: 869,
  },
  {
    label: (
      <span>
        /icons/solid/store.svg <img style={{ height: 20 }} src={icon870} />
      </span>
    ),
    value: "/icons/solid/store.svg",
    key: 870,
  },
  {
    label: (
      <span>
        /icons/solid/greater-than-equal.svg{" "}
        <img style={{ height: 20 }} src={icon871} />
      </span>
    ),
    value: "/icons/solid/greater-than-equal.svg",
    key: 871,
  },
  {
    label: (
      <span>
        /icons/solid/8.svg <img style={{ height: 20 }} src={icon872} />
      </span>
    ),
    value: "/icons/solid/8.svg",
    key: 872,
  },
  {
    label: (
      <span>
        /icons/solid/y.svg <img style={{ height: 20 }} src={icon873} />
      </span>
    ),
    value: "/icons/solid/y.svg",
    key: 873,
  },
  {
    label: (
      <span>
        /icons/solid/hamsa.svg <img style={{ height: 20 }} src={icon874} />
      </span>
    ),
    value: "/icons/solid/hamsa.svg",
    key: 874,
  },
  {
    label: (
      <span>
        /icons/solid/calendar-plus.svg{" "}
        <img style={{ height: 20 }} src={icon875} />
      </span>
    ),
    value: "/icons/solid/calendar-plus.svg",
    key: 875,
  },
  {
    label: (
      <span>
        /icons/solid/brush.svg <img style={{ height: 20 }} src={icon876} />
      </span>
    ),
    value: "/icons/solid/brush.svg",
    key: 876,
  },
  {
    label: (
      <span>
        /icons/solid/at.svg <img style={{ height: 20 }} src={icon877} />
      </span>
    ),
    value: "/icons/solid/at.svg",
    key: 877,
  },
  {
    label: (
      <span>
        /icons/solid/clover.svg <img style={{ height: 20 }} src={icon878} />
      </span>
    ),
    value: "/icons/solid/clover.svg",
    key: 878,
  },
  {
    label: (
      <span>
        /icons/solid/hurricane.svg <img style={{ height: 20 }} src={icon879} />
      </span>
    ),
    value: "/icons/solid/hurricane.svg",
    key: 879,
  },
  {
    label: (
      <span>
        /icons/solid/phone-slash.svg{" "}
        <img style={{ height: 20 }} src={icon880} />
      </span>
    ),
    value: "/icons/solid/phone-slash.svg",
    key: 880,
  },
  {
    label: (
      <span>
        /icons/solid/cubes.svg <img style={{ height: 20 }} src={icon881} />
      </span>
    ),
    value: "/icons/solid/cubes.svg",
    key: 881,
  },
  {
    label: (
      <span>
        /icons/solid/user-ninja.svg <img style={{ height: 20 }} src={icon882} />
      </span>
    ),
    value: "/icons/solid/user-ninja.svg",
    key: 882,
  },
  {
    label: (
      <span>
        /icons/solid/circle-play.svg{" "}
        <img style={{ height: 20 }} src={icon883} />
      </span>
    ),
    value: "/icons/solid/circle-play.svg",
    key: 883,
  },
  {
    label: (
      <span>
        /icons/solid/bowl-rice.svg <img style={{ height: 20 }} src={icon884} />
      </span>
    ),
    value: "/icons/solid/bowl-rice.svg",
    key: 884,
  },
  {
    label: (
      <span>
        /icons/solid/arrow-rotate-left.svg{" "}
        <img style={{ height: 20 }} src={icon885} />
      </span>
    ),
    value: "/icons/solid/arrow-rotate-left.svg",
    key: 885,
  },
  {
    label: (
      <span>
        /icons/solid/notdef.svg <img style={{ height: 20 }} src={icon886} />
      </span>
    ),
    value: "/icons/solid/notdef.svg",
    key: 886,
  },
  {
    label: (
      <span>
        /icons/solid/ship.svg <img style={{ height: 20 }} src={icon887} />
      </span>
    ),
    value: "/icons/solid/ship.svg",
    key: 887,
  },
  {
    label: (
      <span>
        /icons/solid/qrcode.svg <img style={{ height: 20 }} src={icon888} />
      </span>
    ),
    value: "/icons/solid/qrcode.svg",
    key: 888,
  },
  {
    label: (
      <span>
        /icons/solid/person-arrow-down-to-line.svg{" "}
        <img style={{ height: 20 }} src={icon889} />
      </span>
    ),
    value: "/icons/solid/person-arrow-down-to-line.svg",
    key: 889,
  },
  {
    label: (
      <span>
        /icons/solid/worm.svg <img style={{ height: 20 }} src={icon890} />
      </span>
    ),
    value: "/icons/solid/worm.svg",
    key: 890,
  },
  {
    label: (
      <span>
        /icons/solid/notes-medical.svg{" "}
        <img style={{ height: 20 }} src={icon891} />
      </span>
    ),
    value: "/icons/solid/notes-medical.svg",
    key: 891,
  },
  {
    label: (
      <span>
        /icons/solid/glass-water-droplet.svg{" "}
        <img style={{ height: 20 }} src={icon892} />
      </span>
    ),
    value: "/icons/solid/glass-water-droplet.svg",
    key: 892,
  },
  {
    label: (
      <span>
        /icons/solid/user-clock.svg <img style={{ height: 20 }} src={icon893} />
      </span>
    ),
    value: "/icons/solid/user-clock.svg",
    key: 893,
  },
  {
    label: (
      <span>
        /icons/solid/calendar-check.svg{" "}
        <img style={{ height: 20 }} src={icon894} />
      </span>
    ),
    value: "/icons/solid/calendar-check.svg",
    key: 894,
  },
  {
    label: (
      <span>
        /icons/solid/l.svg <img style={{ height: 20 }} src={icon895} />
      </span>
    ),
    value: "/icons/solid/l.svg",
    key: 895,
  },
  {
    label: (
      <span>
        /icons/solid/square-pen.svg <img style={{ height: 20 }} src={icon896} />
      </span>
    ),
    value: "/icons/solid/square-pen.svg",
    key: 896,
  },
  {
    label: (
      <span>
        /icons/solid/table-cells-large.svg{" "}
        <img style={{ height: 20 }} src={icon897} />
      </span>
    ),
    value: "/icons/solid/table-cells-large.svg",
    key: 897,
  },
  {
    label: (
      <span>
        /icons/solid/stopwatch.svg <img style={{ height: 20 }} src={icon898} />
      </span>
    ),
    value: "/icons/solid/stopwatch.svg",
    key: 898,
  },
  {
    label: (
      <span>
        /icons/solid/minimize.svg <img style={{ height: 20 }} src={icon899} />
      </span>
    ),
    value: "/icons/solid/minimize.svg",
    key: 899,
  },
  {
    label: (
      <span>
        /icons/solid/border-all.svg <img style={{ height: 20 }} src={icon900} />
      </span>
    ),
    value: "/icons/solid/border-all.svg",
    key: 900,
  },
  {
    label: (
      <span>
        /icons/solid/arrow-up-from-water-pump.svg{" "}
        <img style={{ height: 20 }} src={icon901} />
      </span>
    ),
    value: "/icons/solid/arrow-up-from-water-pump.svg",
    key: 901,
  },
  {
    label: (
      <span>
        /icons/solid/yen-sign.svg <img style={{ height: 20 }} src={icon902} />
      </span>
    ),
    value: "/icons/solid/yen-sign.svg",
    key: 902,
  },
  {
    label: (
      <span>
        /icons/solid/spray-can-sparkles.svg{" "}
        <img style={{ height: 20 }} src={icon903} />
      </span>
    ),
    value: "/icons/solid/spray-can-sparkles.svg",
    key: 903,
  },
  {
    label: (
      <span>
        /icons/solid/heart-circle-plus.svg{" "}
        <img style={{ height: 20 }} src={icon904} />
      </span>
    ),
    value: "/icons/solid/heart-circle-plus.svg",
    key: 904,
  },
  {
    label: (
      <span>
        /icons/solid/calendar-week.svg{" "}
        <img style={{ height: 20 }} src={icon905} />
      </span>
    ),
    value: "/icons/solid/calendar-week.svg",
    key: 905,
  },
  {
    label: (
      <span>
        /icons/solid/jet-fighter.svg{" "}
        <img style={{ height: 20 }} src={icon906} />
      </span>
    ),
    value: "/icons/solid/jet-fighter.svg",
    key: 906,
  },
  {
    label: (
      <span>
        /icons/solid/pizza-slice.svg{" "}
        <img style={{ height: 20 }} src={icon907} />
      </span>
    ),
    value: "/icons/solid/pizza-slice.svg",
    key: 907,
  },
  {
    label: (
      <span>
        /icons/solid/thumbs-down.svg{" "}
        <img style={{ height: 20 }} src={icon908} />
      </span>
    ),
    value: "/icons/solid/thumbs-down.svg",
    key: 908,
  },
  {
    label: (
      <span>
        /icons/solid/user-gear.svg <img style={{ height: 20 }} src={icon909} />
      </span>
    ),
    value: "/icons/solid/user-gear.svg",
    key: 909,
  },
  {
    label: (
      <span>
        /icons/solid/comment-medical.svg{" "}
        <img style={{ height: 20 }} src={icon910} />
      </span>
    ),
    value: "/icons/solid/comment-medical.svg",
    key: 910,
  },
  {
    label: (
      <span>
        /icons/solid/compress.svg <img style={{ height: 20 }} src={icon911} />
      </span>
    ),
    value: "/icons/solid/compress.svg",
    key: 911,
  },
  {
    label: (
      <span>
        /icons/solid/arrow-down-long.svg{" "}
        <img style={{ height: 20 }} src={icon912} />
      </span>
    ),
    value: "/icons/solid/arrow-down-long.svg",
    key: 912,
  },
  {
    label: (
      <span>
        /icons/solid/person-skating.svg{" "}
        <img style={{ height: 20 }} src={icon913} />
      </span>
    ),
    value: "/icons/solid/person-skating.svg",
    key: 913,
  },
  {
    label: (
      <span>
        /icons/solid/envelope.svg <img style={{ height: 20 }} src={icon914} />
      </span>
    ),
    value: "/icons/solid/envelope.svg",
    key: 914,
  },
  {
    label: (
      <span>
        /icons/solid/microphone-lines-slash.svg{" "}
        <img style={{ height: 20 }} src={icon915} />
      </span>
    ),
    value: "/icons/solid/microphone-lines-slash.svg",
    key: 915,
  },
  {
    label: (
      <span>
        /icons/solid/utensils.svg <img style={{ height: 20 }} src={icon916} />
      </span>
    ),
    value: "/icons/solid/utensils.svg",
    key: 916,
  },
  {
    label: (
      <span>
        /icons/solid/person-booth.svg{" "}
        <img style={{ height: 20 }} src={icon917} />
      </span>
    ),
    value: "/icons/solid/person-booth.svg",
    key: 917,
  },
  {
    label: (
      <span>
        /icons/solid/droplet-slash.svg{" "}
        <img style={{ height: 20 }} src={icon918} />
      </span>
    ),
    value: "/icons/solid/droplet-slash.svg",
    key: 918,
  },
  {
    label: (
      <span>
        /icons/solid/colon-sign.svg <img style={{ height: 20 }} src={icon919} />
      </span>
    ),
    value: "/icons/solid/colon-sign.svg",
    key: 919,
  },
  {
    label: (
      <span>
        /icons/solid/caret-up.svg <img style={{ height: 20 }} src={icon920} />
      </span>
    ),
    value: "/icons/solid/caret-up.svg",
    key: 920,
  },
  {
    label: (
      <span>
        /icons/solid/mobile-retro.svg{" "}
        <img style={{ height: 20 }} src={icon921} />
      </span>
    ),
    value: "/icons/solid/mobile-retro.svg",
    key: 921,
  },
  {
    label: (
      <span>
        /icons/solid/square-h.svg <img style={{ height: 20 }} src={icon922} />
      </span>
    ),
    value: "/icons/solid/square-h.svg",
    key: 922,
  },
  {
    label: (
      <span>
        /icons/solid/hand-point-left.svg{" "}
        <img style={{ height: 20 }} src={icon923} />
      </span>
    ),
    value: "/icons/solid/hand-point-left.svg",
    key: 923,
  },
  {
    label: (
      <span>
        /icons/solid/building-circle-arrow-right.svg{" "}
        <img style={{ height: 20 }} src={icon924} />
      </span>
    ),
    value: "/icons/solid/building-circle-arrow-right.svg",
    key: 924,
  },
  {
    label: (
      <span>
        /icons/solid/map-location.svg{" "}
        <img style={{ height: 20 }} src={icon925} />
      </span>
    ),
    value: "/icons/solid/map-location.svg",
    key: 925,
  },
  {
    label: (
      <span>
        /icons/solid/location-arrow.svg{" "}
        <img style={{ height: 20 }} src={icon926} />
      </span>
    ),
    value: "/icons/solid/location-arrow.svg",
    key: 926,
  },
  {
    label: (
      <span>
        /icons/solid/face-grin-beam-sweat.svg{" "}
        <img style={{ height: 20 }} src={icon927} />
      </span>
    ),
    value: "/icons/solid/face-grin-beam-sweat.svg",
    key: 927,
  },
  {
    label: (
      <span>
        /icons/solid/magnet.svg <img style={{ height: 20 }} src={icon928} />
      </span>
    ),
    value: "/icons/solid/magnet.svg",
    key: 928,
  },
  {
    label: (
      <span>
        /icons/solid/school.svg <img style={{ height: 20 }} src={icon929} />
      </span>
    ),
    value: "/icons/solid/school.svg",
    key: 929,
  },
  {
    label: (
      <span>
        /icons/solid/table-tennis-paddle-ball.svg{" "}
        <img style={{ height: 20 }} src={icon930} />
      </span>
    ),
    value: "/icons/solid/table-tennis-paddle-ball.svg",
    key: 930,
  },
  {
    label: (
      <span>
        /icons/solid/clipboard-question.svg{" "}
        <img style={{ height: 20 }} src={icon931} />
      </span>
    ),
    value: "/icons/solid/clipboard-question.svg",
    key: 931,
  },
  {
    label: (
      <span>
        /icons/solid/ruler-vertical.svg{" "}
        <img style={{ height: 20 }} src={icon932} />
      </span>
    ),
    value: "/icons/solid/ruler-vertical.svg",
    key: 932,
  },
  {
    label: (
      <span>
        /icons/solid/shield.svg <img style={{ height: 20 }} src={icon933} />
      </span>
    ),
    value: "/icons/solid/shield.svg",
    key: 933,
  },
  {
    label: (
      <span>
        /icons/solid/desktop.svg <img style={{ height: 20 }} src={icon934} />
      </span>
    ),
    value: "/icons/solid/desktop.svg",
    key: 934,
  },
  {
    label: (
      <span>
        /icons/solid/download.svg <img style={{ height: 20 }} src={icon935} />
      </span>
    ),
    value: "/icons/solid/download.svg",
    key: 935,
  },
  {
    label: (
      <span>
        /icons/solid/helicopter-symbol.svg{" "}
        <img style={{ height: 20 }} src={icon936} />
      </span>
    ),
    value: "/icons/solid/helicopter-symbol.svg",
    key: 936,
  },
  {
    label: (
      <span>
        /icons/solid/hand-lizard.svg{" "}
        <img style={{ height: 20 }} src={icon937} />
      </span>
    ),
    value: "/icons/solid/hand-lizard.svg",
    key: 937,
  },
  {
    label: (
      <span>
        /icons/solid/jet-fighter-up.svg{" "}
        <img style={{ height: 20 }} src={icon938} />
      </span>
    ),
    value: "/icons/solid/jet-fighter-up.svg",
    key: 938,
  },
  {
    label: (
      <span>
        /icons/solid/expand.svg <img style={{ height: 20 }} src={icon939} />
      </span>
    ),
    value: "/icons/solid/expand.svg",
    key: 939,
  },
  {
    label: (
      <span>
        /icons/solid/building-flag.svg{" "}
        <img style={{ height: 20 }} src={icon940} />
      </span>
    ),
    value: "/icons/solid/building-flag.svg",
    key: 940,
  },
  {
    label: (
      <span>
        /icons/solid/bugs.svg <img style={{ height: 20 }} src={icon941} />
      </span>
    ),
    value: "/icons/solid/bugs.svg",
    key: 941,
  },
  {
    label: (
      <span>
        /icons/solid/weight-scale.svg{" "}
        <img style={{ height: 20 }} src={icon942} />
      </span>
    ),
    value: "/icons/solid/weight-scale.svg",
    key: 942,
  },
  {
    label: (
      <span>
        /icons/solid/arrow-up-a-z.svg{" "}
        <img style={{ height: 20 }} src={icon943} />
      </span>
    ),
    value: "/icons/solid/arrow-up-a-z.svg",
    key: 943,
  },
  {
    label: (
      <span>
        /icons/solid/bandage.svg <img style={{ height: 20 }} src={icon944} />
      </span>
    ),
    value: "/icons/solid/bandage.svg",
    key: 944,
  },
  {
    label: (
      <span>
        /icons/solid/khanda.svg <img style={{ height: 20 }} src={icon945} />
      </span>
    ),
    value: "/icons/solid/khanda.svg",
    key: 945,
  },
  {
    label: (
      <span>
        /icons/solid/square-check.svg{" "}
        <img style={{ height: 20 }} src={icon946} />
      </span>
    ),
    value: "/icons/solid/square-check.svg",
    key: 946,
  },
  {
    label: (
      <span>
        /icons/solid/shield-cat.svg <img style={{ height: 20 }} src={icon947} />
      </span>
    ),
    value: "/icons/solid/shield-cat.svg",
    key: 947,
  },
  {
    label: (
      <span>
        /icons/solid/teeth.svg <img style={{ height: 20 }} src={icon948} />
      </span>
    ),
    value: "/icons/solid/teeth.svg",
    key: 948,
  },
  {
    label: (
      <span>
        /icons/solid/face-frown-open.svg{" "}
        <img style={{ height: 20 }} src={icon949} />
      </span>
    ),
    value: "/icons/solid/face-frown-open.svg",
    key: 949,
  },
  {
    label: (
      <span>
        /icons/solid/hand-scissors.svg{" "}
        <img style={{ height: 20 }} src={icon950} />
      </span>
    ),
    value: "/icons/solid/hand-scissors.svg",
    key: 950,
  },
  {
    label: (
      <span>
        /icons/solid/vihara.svg <img style={{ height: 20 }} src={icon951} />
      </span>
    ),
    value: "/icons/solid/vihara.svg",
    key: 951,
  },
  {
    label: (
      <span>
        /icons/solid/franc-sign.svg <img style={{ height: 20 }} src={icon952} />
      </span>
    ),
    value: "/icons/solid/franc-sign.svg",
    key: 952,
  },
  {
    label: (
      <span>
        /icons/solid/battery-three-quarters.svg{" "}
        <img style={{ height: 20 }} src={icon953} />
      </span>
    ),
    value: "/icons/solid/battery-three-quarters.svg",
    key: 953,
  },
  {
    label: (
      <span>
        /icons/solid/book-open.svg <img style={{ height: 20 }} src={icon954} />
      </span>
    ),
    value: "/icons/solid/book-open.svg",
    key: 954,
  },
  {
    label: (
      <span>
        /icons/solid/spaghetti-monster-flying.svg{" "}
        <img style={{ height: 20 }} src={icon955} />
      </span>
    ),
    value: "/icons/solid/spaghetti-monster-flying.svg",
    key: 955,
  },
  {
    label: (
      <span>
        /icons/solid/hourglass-start.svg{" "}
        <img style={{ height: 20 }} src={icon956} />
      </span>
    ),
    value: "/icons/solid/hourglass-start.svg",
    key: 956,
  },
  {
    label: (
      <span>
        /icons/solid/comment-slash.svg{" "}
        <img style={{ height: 20 }} src={icon957} />
      </span>
    ),
    value: "/icons/solid/comment-slash.svg",
    key: 957,
  },
  {
    label: (
      <span>
        /icons/solid/hand-holding-droplet.svg{" "}
        <img style={{ height: 20 }} src={icon958} />
      </span>
    ),
    value: "/icons/solid/hand-holding-droplet.svg",
    key: 958,
  },
  {
    label: (
      <span>
        /icons/solid/jar.svg <img style={{ height: 20 }} src={icon959} />
      </span>
    ),
    value: "/icons/solid/jar.svg",
    key: 959,
  },
  {
    label: (
      <span>
        /icons/solid/bug-slash.svg <img style={{ height: 20 }} src={icon960} />
      </span>
    ),
    value: "/icons/solid/bug-slash.svg",
    key: 960,
  },
  {
    label: (
      <span>
        /icons/solid/file-import.svg{" "}
        <img style={{ height: 20 }} src={icon961} />
      </span>
    ),
    value: "/icons/solid/file-import.svg",
    key: 961,
  },
  {
    label: (
      <span>
        /icons/solid/face-meh-blank.svg{" "}
        <img style={{ height: 20 }} src={icon962} />
      </span>
    ),
    value: "/icons/solid/face-meh-blank.svg",
    key: 962,
  },
  {
    label: (
      <span>
        /icons/solid/litecoin-sign.svg{" "}
        <img style={{ height: 20 }} src={icon963} />
      </span>
    ),
    value: "/icons/solid/litecoin-sign.svg",
    key: 963,
  },
  {
    label: (
      <span>
        /icons/solid/disease.svg <img style={{ height: 20 }} src={icon964} />
      </span>
    ),
    value: "/icons/solid/disease.svg",
    key: 964,
  },
  {
    label: (
      <span>
        /icons/solid/hand-point-up.svg{" "}
        <img style={{ height: 20 }} src={icon965} />
      </span>
    ),
    value: "/icons/solid/hand-point-up.svg",
    key: 965,
  },
  {
    label: (
      <span>
        /icons/solid/server.svg <img style={{ height: 20 }} src={icon966} />
      </span>
    ),
    value: "/icons/solid/server.svg",
    key: 966,
  },
  {
    label: (
      <span>
        /icons/solid/bolt.svg <img style={{ height: 20 }} src={icon967} />
      </span>
    ),
    value: "/icons/solid/bolt.svg",
    key: 967,
  },
  {
    label: (
      <span>
        /icons/solid/tv.svg <img style={{ height: 20 }} src={icon968} />
      </span>
    ),
    value: "/icons/solid/tv.svg",
    key: 968,
  },
  {
    label: (
      <span>
        /icons/solid/person-praying.svg{" "}
        <img style={{ height: 20 }} src={icon969} />
      </span>
    ),
    value: "/icons/solid/person-praying.svg",
    key: 969,
  },
  {
    label: (
      <span>
        /icons/solid/m.svg <img style={{ height: 20 }} src={icon970} />
      </span>
    ),
    value: "/icons/solid/m.svg",
    key: 970,
  },
  {
    label: (
      <span>
        /icons/solid/democrat.svg <img style={{ height: 20 }} src={icon971} />
      </span>
    ),
    value: "/icons/solid/democrat.svg",
    key: 971,
  },
  {
    label: (
      <span>
        /icons/solid/house-medical.svg{" "}
        <img style={{ height: 20 }} src={icon972} />
      </span>
    ),
    value: "/icons/solid/house-medical.svg",
    key: 972,
  },
  {
    label: (
      <span>
        /icons/solid/hand-holding-medical.svg{" "}
        <img style={{ height: 20 }} src={icon973} />
      </span>
    ),
    value: "/icons/solid/hand-holding-medical.svg",
    key: 973,
  },
  {
    label: (
      <span>
        /icons/solid/z.svg <img style={{ height: 20 }} src={icon974} />
      </span>
    ),
    value: "/icons/solid/z.svg",
    key: 974,
  },
  {
    label: (
      <span>
        /icons/solid/user-plus.svg <img style={{ height: 20 }} src={icon975} />
      </span>
    ),
    value: "/icons/solid/user-plus.svg",
    key: 975,
  },
  {
    label: (
      <span>
        /icons/solid/plug-circle-plus.svg{" "}
        <img style={{ height: 20 }} src={icon976} />
      </span>
    ),
    value: "/icons/solid/plug-circle-plus.svg",
    key: 976,
  },
  {
    label: (
      <span>
        /icons/solid/file-shield.svg{" "}
        <img style={{ height: 20 }} src={icon977} />
      </span>
    ),
    value: "/icons/solid/file-shield.svg",
    key: 977,
  },
  {
    label: (
      <span>
        /icons/solid/hands-clapping.svg{" "}
        <img style={{ height: 20 }} src={icon978} />
      </span>
    ),
    value: "/icons/solid/hands-clapping.svg",
    key: 978,
  },
  {
    label: (
      <span>
        /icons/solid/square-rss.svg <img style={{ height: 20 }} src={icon979} />
      </span>
    ),
    value: "/icons/solid/square-rss.svg",
    key: 979,
  },
  {
    label: (
      <span>
        /icons/solid/star-and-crescent.svg{" "}
        <img style={{ height: 20 }} src={icon980} />
      </span>
    ),
    value: "/icons/solid/star-and-crescent.svg",
    key: 980,
  },
  {
    label: (
      <span>
        /icons/solid/shapes.svg <img style={{ height: 20 }} src={icon981} />
      </span>
    ),
    value: "/icons/solid/shapes.svg",
    key: 981,
  },
  {
    label: (
      <span>
        /icons/solid/solar-panel.svg{" "}
        <img style={{ height: 20 }} src={icon982} />
      </span>
    ),
    value: "/icons/solid/solar-panel.svg",
    key: 982,
  },
  {
    label: (
      <span>
        /icons/solid/mosque.svg <img style={{ height: 20 }} src={icon983} />
      </span>
    ),
    value: "/icons/solid/mosque.svg",
    key: 983,
  },
  {
    label: (
      <span>
        /icons/solid/satellite.svg <img style={{ height: 20 }} src={icon984} />
      </span>
    ),
    value: "/icons/solid/satellite.svg",
    key: 984,
  },
  {
    label: (
      <span>
        /icons/solid/file-circle-xmark.svg{" "}
        <img style={{ height: 20 }} src={icon985} />
      </span>
    ),
    value: "/icons/solid/file-circle-xmark.svg",
    key: 985,
  },
  {
    label: (
      <span>
        /icons/solid/arrow-down-9-1.svg{" "}
        <img style={{ height: 20 }} src={icon986} />
      </span>
    ),
    value: "/icons/solid/arrow-down-9-1.svg",
    key: 986,
  },
  {
    label: (
      <span>
        /icons/solid/i.svg <img style={{ height: 20 }} src={icon987} />
      </span>
    ),
    value: "/icons/solid/i.svg",
    key: 987,
  },
  {
    label: (
      <span>
        /icons/solid/indent.svg <img style={{ height: 20 }} src={icon988} />
      </span>
    ),
    value: "/icons/solid/indent.svg",
    key: 988,
  },
  {
    label: (
      <span>
        /icons/solid/circle-arrow-left.svg{" "}
        <img style={{ height: 20 }} src={icon989} />
      </span>
    ),
    value: "/icons/solid/circle-arrow-left.svg",
    key: 989,
  },
  {
    label: (
      <span>
        /icons/solid/link-slash.svg <img style={{ height: 20 }} src={icon990} />
      </span>
    ),
    value: "/icons/solid/link-slash.svg",
    key: 990,
  },
  {
    label: (
      <span>
        /icons/solid/cloud-arrow-up.svg{" "}
        <img style={{ height: 20 }} src={icon991} />
      </span>
    ),
    value: "/icons/solid/cloud-arrow-up.svg",
    key: 991,
  },
  {
    label: (
      <span>
        /icons/solid/users-rectangle.svg{" "}
        <img style={{ height: 20 }} src={icon992} />
      </span>
    ),
    value: "/icons/solid/users-rectangle.svg",
    key: 992,
  },
  {
    label: (
      <span>
        /icons/solid/dna.svg <img style={{ height: 20 }} src={icon993} />
      </span>
    ),
    value: "/icons/solid/dna.svg",
    key: 993,
  },
  {
    label: (
      <span>
        /icons/solid/slash.svg <img style={{ height: 20 }} src={icon994} />
      </span>
    ),
    value: "/icons/solid/slash.svg",
    key: 994,
  },
  {
    label: (
      <span>
        /icons/solid/hands-holding-child.svg{" "}
        <img style={{ height: 20 }} src={icon995} />
      </span>
    ),
    value: "/icons/solid/hands-holding-child.svg",
    key: 995,
  },
  {
    label: (
      <span>
        /icons/solid/radio.svg <img style={{ height: 20 }} src={icon996} />
      </span>
    ),
    value: "/icons/solid/radio.svg",
    key: 996,
  },
  {
    label: (
      <span>
        /icons/solid/taxi.svg <img style={{ height: 20 }} src={icon997} />
      </span>
    ),
    value: "/icons/solid/taxi.svg",
    key: 997,
  },
  {
    label: (
      <span>
        /icons/solid/book.svg <img style={{ height: 20 }} src={icon998} />
      </span>
    ),
    value: "/icons/solid/book.svg",
    key: 998,
  },
  {
    label: (
      <span>
        /icons/solid/camera-rotate.svg{" "}
        <img style={{ height: 20 }} src={icon999} />
      </span>
    ),
    value: "/icons/solid/camera-rotate.svg",
    key: 999,
  },
  {
    label: (
      <span>
        /icons/solid/filter-circle-dollar.svg{" "}
        <img style={{ height: 20 }} src={icon1000} />
      </span>
    ),
    value: "/icons/solid/filter-circle-dollar.svg",
    key: 1000,
  },
  {
    label: (
      <span>
        /icons/solid/hotdog.svg <img style={{ height: 20 }} src={icon1001} />
      </span>
    ),
    value: "/icons/solid/hotdog.svg",
    key: 1001,
  },
  {
    label: (
      <span>
        /icons/solid/house-medical-circle-xmark.svg{" "}
        <img style={{ height: 20 }} src={icon1002} />
      </span>
    ),
    value: "/icons/solid/house-medical-circle-xmark.svg",
    key: 1002,
  },
  {
    label: (
      <span>
        /icons/solid/hotel.svg <img style={{ height: 20 }} src={icon1003} />
      </span>
    ),
    value: "/icons/solid/hotel.svg",
    key: 1003,
  },
  {
    label: (
      <span>
        /icons/solid/comment-sms.svg{" "}
        <img style={{ height: 20 }} src={icon1004} />
      </span>
    ),
    value: "/icons/solid/comment-sms.svg",
    key: 1004,
  },
  {
    label: (
      <span>
        /icons/solid/plane-slash.svg{" "}
        <img style={{ height: 20 }} src={icon1005} />
      </span>
    ),
    value: "/icons/solid/plane-slash.svg",
    key: 1005,
  },
  {
    label: (
      <span>
        /icons/solid/object-ungroup.svg{" "}
        <img style={{ height: 20 }} src={icon1006} />
      </span>
    ),
    value: "/icons/solid/object-ungroup.svg",
    key: 1006,
  },
  {
    label: (
      <span>
        /icons/solid/skull.svg <img style={{ height: 20 }} src={icon1007} />
      </span>
    ),
    value: "/icons/solid/skull.svg",
    key: 1007,
  },
  {
    label: (
      <span>
        /icons/solid/paragraph.svg <img style={{ height: 20 }} src={icon1008} />
      </span>
    ),
    value: "/icons/solid/paragraph.svg",
    key: 1008,
  },
  {
    label: (
      <span>
        /icons/solid/plug.svg <img style={{ height: 20 }} src={icon1009} />
      </span>
    ),
    value: "/icons/solid/plug.svg",
    key: 1009,
  },
  {
    label: (
      <span>
        /icons/solid/road-circle-check.svg{" "}
        <img style={{ height: 20 }} src={icon1010} />
      </span>
    ),
    value: "/icons/solid/road-circle-check.svg",
    key: 1010,
  },
  {
    label: (
      <span>
        /icons/solid/train.svg <img style={{ height: 20 }} src={icon1011} />
      </span>
    ),
    value: "/icons/solid/train.svg",
    key: 1011,
  },
  {
    label: (
      <span>
        /icons/solid/house-circle-check.svg{" "}
        <img style={{ height: 20 }} src={icon1012} />
      </span>
    ),
    value: "/icons/solid/house-circle-check.svg",
    key: 1012,
  },
  {
    label: (
      <span>
        /icons/solid/chess-board.svg{" "}
        <img style={{ height: 20 }} src={icon1013} />
      </span>
    ),
    value: "/icons/solid/chess-board.svg",
    key: 1013,
  },
  {
    label: (
      <span>
        /icons/solid/user-minus.svg{" "}
        <img style={{ height: 20 }} src={icon1014} />
      </span>
    ),
    value: "/icons/solid/user-minus.svg",
    key: 1014,
  },
  {
    label: (
      <span>
        /icons/solid/wallet.svg <img style={{ height: 20 }} src={icon1015} />
      </span>
    ),
    value: "/icons/solid/wallet.svg",
    key: 1015,
  },
  {
    label: (
      <span>
        /icons/solid/person-shelter.svg{" "}
        <img style={{ height: 20 }} src={icon1016} />
      </span>
    ),
    value: "/icons/solid/person-shelter.svg",
    key: 1016,
  },
  {
    label: (
      <span>
        /icons/solid/circle-check.svg{" "}
        <img style={{ height: 20 }} src={icon1017} />
      </span>
    ),
    value: "/icons/solid/circle-check.svg",
    key: 1017,
  },
  {
    label: (
      <span>
        /icons/solid/house-chimney-user.svg{" "}
        <img style={{ height: 20 }} src={icon1018} />
      </span>
    ),
    value: "/icons/solid/house-chimney-user.svg",
    key: 1018,
  },
  {
    label: (
      <span>
        /icons/solid/file-image.svg{" "}
        <img style={{ height: 20 }} src={icon1019} />
      </span>
    ),
    value: "/icons/solid/file-image.svg",
    key: 1019,
  },
  {
    label: (
      <span>
        /icons/solid/building-circle-check.svg{" "}
        <img style={{ height: 20 }} src={icon1020} />
      </span>
    ),
    value: "/icons/solid/building-circle-check.svg",
    key: 1020,
  },
  {
    label: (
      <span>
        /icons/solid/bell.svg <img style={{ height: 20 }} src={icon1021} />
      </span>
    ),
    value: "/icons/solid/bell.svg",
    key: 1021,
  },
  {
    label: (
      <span>
        /icons/solid/earth-oceania.svg{" "}
        <img style={{ height: 20 }} src={icon1022} />
      </span>
    ),
    value: "/icons/solid/earth-oceania.svg",
    key: 1022,
  },
  {
    label: (
      <span>
        /icons/solid/arrow-down-1-9.svg{" "}
        <img style={{ height: 20 }} src={icon1023} />
      </span>
    ),
    value: "/icons/solid/arrow-down-1-9.svg",
    key: 1023,
  },
  {
    label: (
      <span>
        /icons/solid/code.svg <img style={{ height: 20 }} src={icon1024} />
      </span>
    ),
    value: "/icons/solid/code.svg",
    key: 1024,
  },
  {
    label: (
      <span>
        /icons/solid/dolly.svg <img style={{ height: 20 }} src={icon1025} />
      </span>
    ),
    value: "/icons/solid/dolly.svg",
    key: 1025,
  },
  {
    label: (
      <span>
        /icons/solid/user-lock.svg <img style={{ height: 20 }} src={icon1026} />
      </span>
    ),
    value: "/icons/solid/user-lock.svg",
    key: 1026,
  },
  {
    label: (
      <span>
        /icons/solid/oil-well.svg <img style={{ height: 20 }} src={icon1027} />
      </span>
    ),
    value: "/icons/solid/oil-well.svg",
    key: 1027,
  },
  {
    label: (
      <span>
        /icons/solid/turn-down.svg <img style={{ height: 20 }} src={icon1028} />
      </span>
    ),
    value: "/icons/solid/turn-down.svg",
    key: 1028,
  },
  {
    label: (
      <span>
        /icons/solid/truck-plane.svg{" "}
        <img style={{ height: 20 }} src={icon1029} />
      </span>
    ),
    value: "/icons/solid/truck-plane.svg",
    key: 1029,
  },
  {
    label: (
      <span>
        /icons/solid/cloud-moon-rain.svg{" "}
        <img style={{ height: 20 }} src={icon1030} />
      </span>
    ),
    value: "/icons/solid/cloud-moon-rain.svg",
    key: 1030,
  },
  {
    label: (
      <span>
        /icons/solid/users-between-lines.svg{" "}
        <img style={{ height: 20 }} src={icon1031} />
      </span>
    ),
    value: "/icons/solid/users-between-lines.svg",
    key: 1031,
  },
  {
    label: (
      <span>
        /icons/solid/thermometer.svg{" "}
        <img style={{ height: 20 }} src={icon1032} />
      </span>
    ),
    value: "/icons/solid/thermometer.svg",
    key: 1032,
  },
  {
    label: (
      <span>
        /icons/solid/spider.svg <img style={{ height: 20 }} src={icon1033} />
      </span>
    ),
    value: "/icons/solid/spider.svg",
    key: 1033,
  },
  {
    label: (
      <span>
        /icons/solid/gears.svg <img style={{ height: 20 }} src={icon1034} />
      </span>
    ),
    value: "/icons/solid/gears.svg",
    key: 1034,
  },
  {
    label: (
      <span>
        /icons/solid/hands-bubbles.svg{" "}
        <img style={{ height: 20 }} src={icon1035} />
      </span>
    ),
    value: "/icons/solid/hands-bubbles.svg",
    key: 1035,
  },
  {
    label: (
      <span>
        /icons/solid/hand-sparkles.svg{" "}
        <img style={{ height: 20 }} src={icon1036} />
      </span>
    ),
    value: "/icons/solid/hand-sparkles.svg",
    key: 1036,
  },
  {
    label: (
      <span>
        /icons/solid/flag.svg <img style={{ height: 20 }} src={icon1037} />
      </span>
    ),
    value: "/icons/solid/flag.svg",
    key: 1037,
  },
  {
    label: (
      <span>
        /icons/solid/gauge-high.svg{" "}
        <img style={{ height: 20 }} src={icon1038} />
      </span>
    ),
    value: "/icons/solid/gauge-high.svg",
    key: 1038,
  },
  {
    label: (
      <span>
        /icons/solid/magnifying-glass-minus.svg{" "}
        <img style={{ height: 20 }} src={icon1039} />
      </span>
    ),
    value: "/icons/solid/magnifying-glass-minus.svg",
    key: 1039,
  },
  {
    label: (
      <span>
        /icons/solid/podcast.svg <img style={{ height: 20 }} src={icon1040} />
      </span>
    ),
    value: "/icons/solid/podcast.svg",
    key: 1040,
  },
  {
    label: (
      <span>
        /icons/solid/hand-peace.svg{" "}
        <img style={{ height: 20 }} src={icon1041} />
      </span>
    ),
    value: "/icons/solid/hand-peace.svg",
    key: 1041,
  },
  {
    label: (
      <span>
        /icons/solid/people-roof.svg{" "}
        <img style={{ height: 20 }} src={icon1042} />
      </span>
    ),
    value: "/icons/solid/people-roof.svg",
    key: 1042,
  },
  {
    label: (
      <span>
        /icons/solid/up-right-from-square.svg{" "}
        <img style={{ height: 20 }} src={icon1043} />
      </span>
    ),
    value: "/icons/solid/up-right-from-square.svg",
    key: 1043,
  },
  {
    label: (
      <span>
        /icons/solid/chess-king.svg{" "}
        <img style={{ height: 20 }} src={icon1044} />
      </span>
    ),
    value: "/icons/solid/chess-king.svg",
    key: 1044,
  },
  {
    label: (
      <span>
        /icons/solid/stop.svg <img style={{ height: 20 }} src={icon1045} />
      </span>
    ),
    value: "/icons/solid/stop.svg",
    key: 1045,
  },
  {
    label: (
      <span>
        /icons/solid/newspaper.svg <img style={{ height: 20 }} src={icon1046} />
      </span>
    ),
    value: "/icons/solid/newspaper.svg",
    key: 1046,
  },
  {
    label: (
      <span>
        /icons/solid/snowflake.svg <img style={{ height: 20 }} src={icon1047} />
      </span>
    ),
    value: "/icons/solid/snowflake.svg",
    key: 1047,
  },
  {
    label: (
      <span>
        /icons/solid/cube.svg <img style={{ height: 20 }} src={icon1048} />
      </span>
    ),
    value: "/icons/solid/cube.svg",
    key: 1048,
  },
  {
    label: (
      <span>
        /icons/solid/bomb.svg <img style={{ height: 20 }} src={icon1049} />
      </span>
    ),
    value: "/icons/solid/bomb.svg",
    key: 1049,
  },
  {
    label: (
      <span>
        /icons/solid/tower-broadcast.svg{" "}
        <img style={{ height: 20 }} src={icon1050} />
      </span>
    ),
    value: "/icons/solid/tower-broadcast.svg",
    key: 1050,
  },
  {
    label: (
      <span>
        /icons/solid/file-medical.svg{" "}
        <img style={{ height: 20 }} src={icon1051} />
      </span>
    ),
    value: "/icons/solid/file-medical.svg",
    key: 1051,
  },
  {
    label: (
      <span>
        /icons/solid/crop-simple.svg{" "}
        <img style={{ height: 20 }} src={icon1052} />
      </span>
    ),
    value: "/icons/solid/crop-simple.svg",
    key: 1052,
  },
  {
    label: (
      <span>
        /icons/solid/right-long.svg{" "}
        <img style={{ height: 20 }} src={icon1053} />
      </span>
    ),
    value: "/icons/solid/right-long.svg",
    key: 1053,
  },
  {
    label: (
      <span>
        /icons/solid/plane-lock.svg{" "}
        <img style={{ height: 20 }} src={icon1054} />
      </span>
    ),
    value: "/icons/solid/plane-lock.svg",
    key: 1054,
  },
  {
    label: (
      <span>
        /icons/solid/clipboard-user.svg{" "}
        <img style={{ height: 20 }} src={icon1055} />
      </span>
    ),
    value: "/icons/solid/clipboard-user.svg",
    key: 1055,
  },
  {
    label: (
      <span>
        /icons/solid/h.svg <img style={{ height: 20 }} src={icon1056} />
      </span>
    ),
    value: "/icons/solid/h.svg",
    key: 1056,
  },
  {
    label: (
      <span>
        /icons/solid/star-half.svg <img style={{ height: 20 }} src={icon1057} />
      </span>
    ),
    value: "/icons/solid/star-half.svg",
    key: 1057,
  },
  {
    label: (
      <span>
        /icons/solid/bridge-lock.svg{" "}
        <img style={{ height: 20 }} src={icon1058} />
      </span>
    ),
    value: "/icons/solid/bridge-lock.svg",
    key: 1058,
  },
  {
    label: (
      <span>
        /icons/solid/house-chimney.svg{" "}
        <img style={{ height: 20 }} src={icon1059} />
      </span>
    ),
    value: "/icons/solid/house-chimney.svg",
    key: 1059,
  },
  {
    label: (
      <span>
        /icons/solid/arrows-up-to-line.svg{" "}
        <img style={{ height: 20 }} src={icon1060} />
      </span>
    ),
    value: "/icons/solid/arrows-up-to-line.svg",
    key: 1060,
  },
  {
    label: (
      <span>
        /icons/solid/ruler.svg <img style={{ height: 20 }} src={icon1061} />
      </span>
    ),
    value: "/icons/solid/ruler.svg",
    key: 1061,
  },
  {
    label: (
      <span>
        /icons/solid/truck-monster.svg{" "}
        <img style={{ height: 20 }} src={icon1062} />
      </span>
    ),
    value: "/icons/solid/truck-monster.svg",
    key: 1062,
  },
  {
    label: (
      <span>
        /icons/solid/money-bill-trend-up.svg{" "}
        <img style={{ height: 20 }} src={icon1063} />
      </span>
    ),
    value: "/icons/solid/money-bill-trend-up.svg",
    key: 1063,
  },
  {
    label: (
      <span>
        /icons/solid/building-lock.svg{" "}
        <img style={{ height: 20 }} src={icon1064} />
      </span>
    ),
    value: "/icons/solid/building-lock.svg",
    key: 1064,
  },
  {
    label: (
      <span>
        /icons/solid/outdent.svg <img style={{ height: 20 }} src={icon1065} />
      </span>
    ),
    value: "/icons/solid/outdent.svg",
    key: 1065,
  },
  {
    label: (
      <span>
        /icons/solid/person-pregnant.svg{" "}
        <img style={{ height: 20 }} src={icon1066} />
      </span>
    ),
    value: "/icons/solid/person-pregnant.svg",
    key: 1066,
  },
  {
    label: (
      <span>
        /icons/solid/clipboard-list.svg{" "}
        <img style={{ height: 20 }} src={icon1067} />
      </span>
    ),
    value: "/icons/solid/clipboard-list.svg",
    key: 1067,
  },
  {
    label: (
      <span>
        /icons/solid/fax.svg <img style={{ height: 20 }} src={icon1068} />
      </span>
    ),
    value: "/icons/solid/fax.svg",
    key: 1068,
  },
  {
    label: (
      <span>
        /icons/solid/biohazard.svg <img style={{ height: 20 }} src={icon1069} />
      </span>
    ),
    value: "/icons/solid/biohazard.svg",
    key: 1069,
  },
  {
    label: (
      <span>
        /icons/solid/money-check-dollar.svg{" "}
        <img style={{ height: 20 }} src={icon1070} />
      </span>
    ),
    value: "/icons/solid/money-check-dollar.svg",
    key: 1070,
  },
  {
    label: (
      <span>
        /icons/solid/j.svg <img style={{ height: 20 }} src={icon1071} />
      </span>
    ),
    value: "/icons/solid/j.svg",
    key: 1071,
  },
  {
    label: (
      <span>
        /icons/solid/diagram-predecessor.svg{" "}
        <img style={{ height: 20 }} src={icon1072} />
      </span>
    ),
    value: "/icons/solid/diagram-predecessor.svg",
    key: 1072,
  },
  {
    label: (
      <span>
        /icons/solid/caret-right.svg{" "}
        <img style={{ height: 20 }} src={icon1073} />
      </span>
    ),
    value: "/icons/solid/caret-right.svg",
    key: 1073,
  },
  {
    label: (
      <span>
        /icons/solid/user-graduate.svg{" "}
        <img style={{ height: 20 }} src={icon1074} />
      </span>
    ),
    value: "/icons/solid/user-graduate.svg",
    key: 1074,
  },
  {
    label: (
      <span>
        /icons/solid/smog.svg <img style={{ height: 20 }} src={icon1075} />
      </span>
    ),
    value: "/icons/solid/smog.svg",
    key: 1075,
  },
  {
    label: (
      <span>
        /icons/solid/bold.svg <img style={{ height: 20 }} src={icon1076} />
      </span>
    ),
    value: "/icons/solid/bold.svg",
    key: 1076,
  },
  {
    label: (
      <span>
        /icons/solid/square-share-nodes.svg{" "}
        <img style={{ height: 20 }} src={icon1077} />
      </span>
    ),
    value: "/icons/solid/square-share-nodes.svg",
    key: 1077,
  },
  {
    label: (
      <span>
        /icons/solid/temperature-empty.svg{" "}
        <img style={{ height: 20 }} src={icon1078} />
      </span>
    ),
    value: "/icons/solid/temperature-empty.svg",
    key: 1078,
  },
  {
    label: (
      <span>
        /icons/solid/bridge-circle-xmark.svg{" "}
        <img style={{ height: 20 }} src={icon1079} />
      </span>
    ),
    value: "/icons/solid/bridge-circle-xmark.svg",
    key: 1079,
  },
  {
    label: (
      <span>
        /icons/solid/arrows-to-eye.svg{" "}
        <img style={{ height: 20 }} src={icon1080} />
      </span>
    ),
    value: "/icons/solid/arrows-to-eye.svg",
    key: 1080,
  },
  {
    label: (
      <span>
        /icons/solid/tablets.svg <img style={{ height: 20 }} src={icon1081} />
      </span>
    ),
    value: "/icons/solid/tablets.svg",
    key: 1081,
  },
  {
    label: (
      <span>
        /icons/solid/bridge-water.svg{" "}
        <img style={{ height: 20 }} src={icon1082} />
      </span>
    ),
    value: "/icons/solid/bridge-water.svg",
    key: 1082,
  },
  {
    label: (
      <span>
        /icons/solid/envelope-open.svg{" "}
        <img style={{ height: 20 }} src={icon1083} />
      </span>
    ),
    value: "/icons/solid/envelope-open.svg",
    key: 1083,
  },
  {
    label: (
      <span>
        /icons/solid/trophy.svg <img style={{ height: 20 }} src={icon1084} />
      </span>
    ),
    value: "/icons/solid/trophy.svg",
    key: 1084,
  },
  {
    label: (
      <span>
        /icons/solid/battery-full.svg{" "}
        <img style={{ height: 20 }} src={icon1085} />
      </span>
    ),
    value: "/icons/solid/battery-full.svg",
    key: 1085,
  },
  {
    label: (
      <span>
        /icons/solid/equals.svg <img style={{ height: 20 }} src={icon1086} />
      </span>
    ),
    value: "/icons/solid/equals.svg",
    key: 1086,
  },
  {
    label: (
      <span>
        /icons/solid/arrow-up-from-ground-water.svg{" "}
        <img style={{ height: 20 }} src={icon1087} />
      </span>
    ),
    value: "/icons/solid/arrow-up-from-ground-water.svg",
    key: 1087,
  },
  {
    label: (
      <span>
        /icons/solid/plus.svg <img style={{ height: 20 }} src={icon1088} />
      </span>
    ),
    value: "/icons/solid/plus.svg",
    key: 1088,
  },
  {
    label: (
      <span>
        /icons/solid/wand-magic.svg{" "}
        <img style={{ height: 20 }} src={icon1089} />
      </span>
    ),
    value: "/icons/solid/wand-magic.svg",
    key: 1089,
  },
  {
    label: (
      <span>
        /icons/solid/scale-balanced.svg{" "}
        <img style={{ height: 20 }} src={icon1090} />
      </span>
    ),
    value: "/icons/solid/scale-balanced.svg",
    key: 1090,
  },
  {
    label: (
      <span>
        /icons/solid/check.svg <img style={{ height: 20 }} src={icon1091} />
      </span>
    ),
    value: "/icons/solid/check.svg",
    key: 1091,
  },
  {
    label: (
      <span>
        /icons/solid/seedling.svg <img style={{ height: 20 }} src={icon1092} />
      </span>
    ),
    value: "/icons/solid/seedling.svg",
    key: 1092,
  },
  {
    label: (
      <span>
        /icons/solid/face-frown.svg{" "}
        <img style={{ height: 20 }} src={icon1093} />
      </span>
    ),
    value: "/icons/solid/face-frown.svg",
    key: 1093,
  },
  {
    label: (
      <span>
        /icons/solid/left-long.svg <img style={{ height: 20 }} src={icon1094} />
      </span>
    ),
    value: "/icons/solid/left-long.svg",
    key: 1094,
  },
  {
    label: (
      <span>
        /icons/solid/star-of-david.svg{" "}
        <img style={{ height: 20 }} src={icon1095} />
      </span>
    ),
    value: "/icons/solid/star-of-david.svg",
    key: 1095,
  },
  {
    label: (
      <span>
        /icons/solid/ruler-combined.svg{" "}
        <img style={{ height: 20 }} src={icon1096} />
      </span>
    ),
    value: "/icons/solid/ruler-combined.svg",
    key: 1096,
  },
  {
    label: (
      <span>
        /icons/solid/beer-mug-empty.svg{" "}
        <img style={{ height: 20 }} src={icon1097} />
      </span>
    ),
    value: "/icons/solid/beer-mug-empty.svg",
    key: 1097,
  },
  {
    label: (
      <span>
        /icons/solid/heart-crack.svg{" "}
        <img style={{ height: 20 }} src={icon1098} />
      </span>
    ),
    value: "/icons/solid/heart-crack.svg",
    key: 1098,
  },
  {
    label: (
      <span>
        /icons/solid/hard-drive.svg{" "}
        <img style={{ height: 20 }} src={icon1099} />
      </span>
    ),
    value: "/icons/solid/hard-drive.svg",
    key: 1099,
  },
  {
    label: (
      <span>
        /icons/solid/tent.svg <img style={{ height: 20 }} src={icon1100} />
      </span>
    ),
    value: "/icons/solid/tent.svg",
    key: 1100,
  },
  {
    label: (
      <span>
        /icons/solid/trademark.svg <img style={{ height: 20 }} src={icon1101} />
      </span>
    ),
    value: "/icons/solid/trademark.svg",
    key: 1101,
  },
  {
    label: (
      <span>
        /icons/solid/kaaba.svg <img style={{ height: 20 }} src={icon1102} />
      </span>
    ),
    value: "/icons/solid/kaaba.svg",
    key: 1102,
  },
  {
    label: (
      <span>
        /icons/solid/bridge-circle-exclamation.svg{" "}
        <img style={{ height: 20 }} src={icon1103} />
      </span>
    ),
    value: "/icons/solid/bridge-circle-exclamation.svg",
    key: 1103,
  },
  {
    label: (
      <span>
        /icons/solid/smoking.svg <img style={{ height: 20 }} src={icon1104} />
      </span>
    ),
    value: "/icons/solid/smoking.svg",
    key: 1104,
  },
  {
    label: (
      <span>
        /icons/solid/scale-unbalanced-flip.svg{" "}
        <img style={{ height: 20 }} src={icon1105} />
      </span>
    ),
    value: "/icons/solid/scale-unbalanced-flip.svg",
    key: 1105,
  },
  {
    label: (
      <span>
        /icons/solid/plug-circle-bolt.svg{" "}
        <img style={{ height: 20 }} src={icon1106} />
      </span>
    ),
    value: "/icons/solid/plug-circle-bolt.svg",
    key: 1106,
  },
  {
    label: (
      <span>
        /icons/solid/hospital.svg <img style={{ height: 20 }} src={icon1107} />
      </span>
    ),
    value: "/icons/solid/hospital.svg",
    key: 1107,
  },
  {
    label: (
      <span>
        /icons/solid/arrow-right-to-city.svg{" "}
        <img style={{ height: 20 }} src={icon1108} />
      </span>
    ),
    value: "/icons/solid/arrow-right-to-city.svg",
    key: 1108,
  },
  {
    label: (
      <span>
        /icons/solid/flask.svg <img style={{ height: 20 }} src={icon1109} />
      </span>
    ),
    value: "/icons/solid/flask.svg",
    key: 1109,
  },
  {
    label: (
      <span>
        /icons/solid/headphones.svg{" "}
        <img style={{ height: 20 }} src={icon1110} />
      </span>
    ),
    value: "/icons/solid/headphones.svg",
    key: 1110,
  },
  {
    label: (
      <span>
        /icons/solid/hand-holding-hand.svg{" "}
        <img style={{ height: 20 }} src={icon1111} />
      </span>
    ),
    value: "/icons/solid/hand-holding-hand.svg",
    key: 1111,
  },
  {
    label: (
      <span>
        /icons/solid/people-carry-box.svg{" "}
        <img style={{ height: 20 }} src={icon1112} />
      </span>
    ),
    value: "/icons/solid/people-carry-box.svg",
    key: 1112,
  },
  {
    label: (
      <span>
        /icons/solid/arrow-right-arrow-left.svg{" "}
        <img style={{ height: 20 }} src={icon1113} />
      </span>
    ),
    value: "/icons/solid/arrow-right-arrow-left.svg",
    key: 1113,
  },
  {
    label: (
      <span>
        /icons/solid/horse-head.svg{" "}
        <img style={{ height: 20 }} src={icon1114} />
      </span>
    ),
    value: "/icons/solid/horse-head.svg",
    key: 1114,
  },
  {
    label: (
      <span>
        /icons/solid/mobile-button.svg{" "}
        <img style={{ height: 20 }} src={icon1115} />
      </span>
    ),
    value: "/icons/solid/mobile-button.svg",
    key: 1115,
  },
  {
    label: (
      <span>
        /icons/solid/rss.svg <img style={{ height: 20 }} src={icon1116} />
      </span>
    ),
    value: "/icons/solid/rss.svg",
    key: 1116,
  },
  {
    label: (
      <span>
        /icons/solid/horse.svg <img style={{ height: 20 }} src={icon1117} />
      </span>
    ),
    value: "/icons/solid/horse.svg",
    key: 1117,
  },
  {
    label: (
      <span>
        /icons/solid/person-walking-arrow-loop-left.svg{" "}
        <img style={{ height: 20 }} src={icon1118} />
      </span>
    ),
    value: "/icons/solid/person-walking-arrow-loop-left.svg",
    key: 1118,
  },
  {
    label: (
      <span>
        /icons/solid/landmark.svg <img style={{ height: 20 }} src={icon1119} />
      </span>
    ),
    value: "/icons/solid/landmark.svg",
    key: 1119,
  },
  {
    label: (
      <span>
        /icons/solid/wifi.svg <img style={{ height: 20 }} src={icon1120} />
      </span>
    ),
    value: "/icons/solid/wifi.svg",
    key: 1120,
  },
  {
    label: (
      <span>
        /icons/solid/arrow-right-long.svg{" "}
        <img style={{ height: 20 }} src={icon1121} />
      </span>
    ),
    value: "/icons/solid/arrow-right-long.svg",
    key: 1121,
  },
  {
    label: (
      <span>
        /icons/solid/face-grin-tongue-squint.svg{" "}
        <img style={{ height: 20 }} src={icon1122} />
      </span>
    ),
    value: "/icons/solid/face-grin-tongue-squint.svg",
    key: 1122,
  },
  {
    label: (
      <span>
        /icons/solid/heart-circle-bolt.svg{" "}
        <img style={{ height: 20 }} src={icon1123} />
      </span>
    ),
    value: "/icons/solid/heart-circle-bolt.svg",
    key: 1123,
  },
  {
    label: (
      <span>
        /icons/solid/shield-heart.svg{" "}
        <img style={{ height: 20 }} src={icon1124} />
      </span>
    ),
    value: "/icons/solid/shield-heart.svg",
    key: 1124,
  },
  {
    label: (
      <span>
        /icons/solid/building-un.svg{" "}
        <img style={{ height: 20 }} src={icon1125} />
      </span>
    ),
    value: "/icons/solid/building-un.svg",
    key: 1125,
  },
  {
    label: (
      <span>
        /icons/solid/face-smile-wink.svg{" "}
        <img style={{ height: 20 }} src={icon1126} />
      </span>
    ),
    value: "/icons/solid/face-smile-wink.svg",
    key: 1126,
  },
  {
    label: (
      <span>
        /icons/solid/person-through-window.svg{" "}
        <img style={{ height: 20 }} src={icon1127} />
      </span>
    ),
    value: "/icons/solid/person-through-window.svg",
    key: 1127,
  },
  {
    label: (
      <span>
        /icons/solid/angle-right.svg{" "}
        <img style={{ height: 20 }} src={icon1128} />
      </span>
    ),
    value: "/icons/solid/angle-right.svg",
    key: 1128,
  },
  {
    label: (
      <span>
        /icons/solid/peso-sign.svg <img style={{ height: 20 }} src={icon1129} />
      </span>
    ),
    value: "/icons/solid/peso-sign.svg",
    key: 1129,
  },
  {
    label: (
      <span>
        /icons/solid/venus.svg <img style={{ height: 20 }} src={icon1130} />
      </span>
    ),
    value: "/icons/solid/venus.svg",
    key: 1130,
  },
  {
    label: (
      <span>
        /icons/solid/mug-hot.svg <img style={{ height: 20 }} src={icon1131} />
      </span>
    ),
    value: "/icons/solid/mug-hot.svg",
    key: 1131,
  },
  {
    label: (
      <span>
        /icons/solid/info.svg <img style={{ height: 20 }} src={icon1132} />
      </span>
    ),
    value: "/icons/solid/info.svg",
    key: 1132,
  },
  {
    label: (
      <span>
        /icons/solid/face-dizzy.svg{" "}
        <img style={{ height: 20 }} src={icon1133} />
      </span>
    ),
    value: "/icons/solid/face-dizzy.svg",
    key: 1133,
  },
  {
    label: (
      <span>
        /icons/solid/bowl-food.svg <img style={{ height: 20 }} src={icon1134} />
      </span>
    ),
    value: "/icons/solid/bowl-food.svg",
    key: 1134,
  },
  {
    label: (
      <span>
        /icons/solid/school-circle-xmark.svg{" "}
        <img style={{ height: 20 }} src={icon1135} />
      </span>
    ),
    value: "/icons/solid/school-circle-xmark.svg",
    key: 1135,
  },
  {
    label: (
      <span>
        /icons/solid/mound.svg <img style={{ height: 20 }} src={icon1136} />
      </span>
    ),
    value: "/icons/solid/mound.svg",
    key: 1136,
  },
  {
    label: (
      <span>
        /icons/solid/bullhorn.svg <img style={{ height: 20 }} src={icon1137} />
      </span>
    ),
    value: "/icons/solid/bullhorn.svg",
    key: 1137,
  },
  {
    label: (
      <span>
        /icons/solid/arrows-turn-to-dots.svg{" "}
        <img style={{ height: 20 }} src={icon1138} />
      </span>
    ),
    value: "/icons/solid/arrows-turn-to-dots.svg",
    key: 1138,
  },
  {
    label: (
      <span>
        /icons/solid/bread-slice.svg{" "}
        <img style={{ height: 20 }} src={icon1139} />
      </span>
    ),
    value: "/icons/solid/bread-slice.svg",
    key: 1139,
  },
  {
    label: (
      <span>
        /icons/solid/dog.svg <img style={{ height: 20 }} src={icon1140} />
      </span>
    ),
    value: "/icons/solid/dog.svg",
    key: 1140,
  },
  {
    label: (
      <span>
        /icons/solid/user-nurse.svg{" "}
        <img style={{ height: 20 }} src={icon1141} />
      </span>
    ),
    value: "/icons/solid/user-nurse.svg",
    key: 1141,
  },
  {
    label: (
      <span>
        /icons/solid/fan.svg <img style={{ height: 20 }} src={icon1142} />
      </span>
    ),
    value: "/icons/solid/fan.svg",
    key: 1142,
  },
  {
    label: (
      <span>
        /icons/solid/palette.svg <img style={{ height: 20 }} src={icon1143} />
      </span>
    ),
    value: "/icons/solid/palette.svg",
    key: 1143,
  },
  {
    label: (
      <span>
        /icons/solid/spoon.svg <img style={{ height: 20 }} src={icon1144} />
      </span>
    ),
    value: "/icons/solid/spoon.svg",
    key: 1144,
  },
  {
    label: (
      <span>
        /icons/solid/k.svg <img style={{ height: 20 }} src={icon1145} />
      </span>
    ),
    value: "/icons/solid/k.svg",
    key: 1145,
  },
  {
    label: (
      <span>
        /icons/solid/cart-arrow-down.svg{" "}
        <img style={{ height: 20 }} src={icon1146} />
      </span>
    ),
    value: "/icons/solid/cart-arrow-down.svg",
    key: 1146,
  },
  {
    label: (
      <span>
        /icons/solid/gear.svg <img style={{ height: 20 }} src={icon1147} />
      </span>
    ),
    value: "/icons/solid/gear.svg",
    key: 1147,
  },
  {
    label: (
      <span>
        /icons/solid/truck-fast.svg{" "}
        <img style={{ height: 20 }} src={icon1148} />
      </span>
    ),
    value: "/icons/solid/truck-fast.svg",
    key: 1148,
  },
  {
    label: (
      <span>
        /icons/solid/om.svg <img style={{ height: 20 }} src={icon1149} />
      </span>
    ),
    value: "/icons/solid/om.svg",
    key: 1149,
  },
  {
    label: (
      <span>
        /icons/solid/hourglass-half.svg{" "}
        <img style={{ height: 20 }} src={icon1150} />
      </span>
    ),
    value: "/icons/solid/hourglass-half.svg",
    key: 1150,
  },
  {
    label: (
      <span>
        /icons/solid/lungs-virus.svg{" "}
        <img style={{ height: 20 }} src={icon1151} />
      </span>
    ),
    value: "/icons/solid/lungs-virus.svg",
    key: 1151,
  },
  {
    label: (
      <span>
        /icons/solid/manat-sign.svg{" "}
        <img style={{ height: 20 }} src={icon1152} />
      </span>
    ),
    value: "/icons/solid/manat-sign.svg",
    key: 1152,
  },
  {
    label: (
      <span>
        /icons/solid/face-sad-tear.svg{" "}
        <img style={{ height: 20 }} src={icon1153} />
      </span>
    ),
    value: "/icons/solid/face-sad-tear.svg",
    key: 1153,
  },
  {
    label: (
      <span>
        /icons/solid/holly-berry.svg{" "}
        <img style={{ height: 20 }} src={icon1154} />
      </span>
    ),
    value: "/icons/solid/holly-berry.svg",
    key: 1154,
  },
  {
    label: (
      <span>
        /icons/solid/volleyball.svg{" "}
        <img style={{ height: 20 }} src={icon1155} />
      </span>
    ),
    value: "/icons/solid/volleyball.svg",
    key: 1155,
  },
  {
    label: (
      <span>
        /icons/solid/code-commit.svg{" "}
        <img style={{ height: 20 }} src={icon1156} />
      </span>
    ),
    value: "/icons/solid/code-commit.svg",
    key: 1156,
  },
  {
    label: (
      <span>
        /icons/solid/prescription-bottle-medical.svg{" "}
        <img style={{ height: 20 }} src={icon1157} />
      </span>
    ),
    value: "/icons/solid/prescription-bottle-medical.svg",
    key: 1157,
  },
  {
    label: (
      <span>
        /icons/solid/flag-usa.svg <img style={{ height: 20 }} src={icon1158} />
      </span>
    ),
    value: "/icons/solid/flag-usa.svg",
    key: 1158,
  },
  {
    label: (
      <span>
        /icons/solid/handcuffs.svg <img style={{ height: 20 }} src={icon1159} />
      </span>
    ),
    value: "/icons/solid/handcuffs.svg",
    key: 1159,
  },
  {
    label: (
      <span>
        /icons/solid/plug-circle-minus.svg{" "}
        <img style={{ height: 20 }} src={icon1160} />
      </span>
    ),
    value: "/icons/solid/plug-circle-minus.svg",
    key: 1160,
  },
  {
    label: (
      <span>
        /icons/solid/kip-sign.svg <img style={{ height: 20 }} src={icon1161} />
      </span>
    ),
    value: "/icons/solid/kip-sign.svg",
    key: 1161,
  },
  {
    label: (
      <span>
        /icons/solid/arrows-up-down-left-right.svg{" "}
        <img style={{ height: 20 }} src={icon1162} />
      </span>
    ),
    value: "/icons/solid/arrows-up-down-left-right.svg",
    key: 1162,
  },
  {
    label: (
      <span>
        /icons/solid/f.svg <img style={{ height: 20 }} src={icon1163} />
      </span>
    ),
    value: "/icons/solid/f.svg",
    key: 1163,
  },
  {
    label: (
      <span>
        /icons/solid/q.svg <img style={{ height: 20 }} src={icon1164} />
      </span>
    ),
    value: "/icons/solid/q.svg",
    key: 1164,
  },
  {
    label: (
      <span>
        /icons/solid/0.svg <img style={{ height: 20 }} src={icon1165} />
      </span>
    ),
    value: "/icons/solid/0.svg",
    key: 1165,
  },
  {
    label: (
      <span>
        /icons/solid/cubes-stacked.svg{" "}
        <img style={{ height: 20 }} src={icon1166} />
      </span>
    ),
    value: "/icons/solid/cubes-stacked.svg",
    key: 1166,
  },
  {
    label: (
      <span>
        /icons/solid/folder-plus.svg{" "}
        <img style={{ height: 20 }} src={icon1167} />
      </span>
    ),
    value: "/icons/solid/folder-plus.svg",
    key: 1167,
  },
  {
    label: (
      <span>
        /icons/solid/pen-ruler.svg <img style={{ height: 20 }} src={icon1168} />
      </span>
    ),
    value: "/icons/solid/pen-ruler.svg",
    key: 1168,
  },
  {
    label: (
      <span>
        /icons/solid/hat-cowboy.svg{" "}
        <img style={{ height: 20 }} src={icon1169} />
      </span>
    ),
    value: "/icons/solid/hat-cowboy.svg",
    key: 1169,
  },
  {
    label: (
      <span>
        /icons/solid/sign-hanging.svg{" "}
        <img style={{ height: 20 }} src={icon1170} />
      </span>
    ),
    value: "/icons/solid/sign-hanging.svg",
    key: 1170,
  },
  {
    label: (
      <span>
        /icons/solid/calendar-xmark.svg{" "}
        <img style={{ height: 20 }} src={icon1171} />
      </span>
    ),
    value: "/icons/solid/calendar-xmark.svg",
    key: 1171,
  },
  {
    label: (
      <span>
        /icons/solid/virus-slash.svg{" "}
        <img style={{ height: 20 }} src={icon1172} />
      </span>
    ),
    value: "/icons/solid/virus-slash.svg",
    key: 1172,
  },
  {
    label: (
      <span>
        /icons/solid/user-doctor.svg{" "}
        <img style={{ height: 20 }} src={icon1173} />
      </span>
    ),
    value: "/icons/solid/user-doctor.svg",
    key: 1173,
  },
  {
    label: (
      <span>
        /icons/solid/hat-cowboy-side.svg{" "}
        <img style={{ height: 20 }} src={icon1174} />
      </span>
    ),
    value: "/icons/solid/hat-cowboy-side.svg",
    key: 1174,
  },
  {
    label: (
      <span>
        /icons/solid/rainbow.svg <img style={{ height: 20 }} src={icon1175} />
      </span>
    ),
    value: "/icons/solid/rainbow.svg",
    key: 1175,
  },
  {
    label: (
      <span>
        /icons/solid/cookie-bite.svg{" "}
        <img style={{ height: 20 }} src={icon1176} />
      </span>
    ),
    value: "/icons/solid/cookie-bite.svg",
    key: 1176,
  },
  {
    label: (
      <span>
        /icons/solid/sheet-plastic.svg{" "}
        <img style={{ height: 20 }} src={icon1177} />
      </span>
    ),
    value: "/icons/solid/sheet-plastic.svg",
    key: 1177,
  },
  {
    label: (
      <span>
        /icons/solid/suitcase-medical.svg{" "}
        <img style={{ height: 20 }} src={icon1178} />
      </span>
    ),
    value: "/icons/solid/suitcase-medical.svg",
    key: 1178,
  },
  {
    label: (
      <span>
        /icons/solid/copy.svg <img style={{ height: 20 }} src={icon1179} />
      </span>
    ),
    value: "/icons/solid/copy.svg",
    key: 1179,
  },
  {
    label: (
      <span>
        /icons/solid/masks-theater.svg{" "}
        <img style={{ height: 20 }} src={icon1180} />
      </span>
    ),
    value: "/icons/solid/masks-theater.svg",
    key: 1180,
  },
  {
    label: (
      <span>
        /icons/solid/fish.svg <img style={{ height: 20 }} src={icon1181} />
      </span>
    ),
    value: "/icons/solid/fish.svg",
    key: 1181,
  },
  {
    label: (
      <span>
        /icons/solid/martini-glass-citrus.svg{" "}
        <img style={{ height: 20 }} src={icon1182} />
      </span>
    ),
    value: "/icons/solid/martini-glass-citrus.svg",
    key: 1182,
  },
  {
    label: (
      <span>
        /icons/solid/circle-chevron-up.svg{" "}
        <img style={{ height: 20 }} src={icon1183} />
      </span>
    ),
    value: "/icons/solid/circle-chevron-up.svg",
    key: 1183,
  },
  {
    label: (
      <span>
        /icons/solid/file-lines.svg{" "}
        <img style={{ height: 20 }} src={icon1184} />
      </span>
    ),
    value: "/icons/solid/file-lines.svg",
    key: 1184,
  },
  {
    label: (
      <span>
        /icons/solid/signature.svg <img style={{ height: 20 }} src={icon1185} />
      </span>
    ),
    value: "/icons/solid/signature.svg",
    key: 1185,
  },
  {
    label: (
      <span>
        /icons/solid/not-equal.svg <img style={{ height: 20 }} src={icon1186} />
      </span>
    ),
    value: "/icons/solid/not-equal.svg",
    key: 1186,
  },
  {
    label: (
      <span>
        /icons/solid/burger.svg <img style={{ height: 20 }} src={icon1187} />
      </span>
    ),
    value: "/icons/solid/burger.svg",
    key: 1187,
  },
  {
    label: (
      <span>
        /icons/solid/square-poll-vertical.svg{" "}
        <img style={{ height: 20 }} src={icon1188} />
      </span>
    ),
    value: "/icons/solid/square-poll-vertical.svg",
    key: 1188,
  },
  {
    label: (
      <span>
        /icons/solid/glasses.svg <img style={{ height: 20 }} src={icon1189} />
      </span>
    ),
    value: "/icons/solid/glasses.svg",
    key: 1189,
  },
  {
    label: (
      <span>
        /icons/solid/coins.svg <img style={{ height: 20 }} src={icon1190} />
      </span>
    ),
    value: "/icons/solid/coins.svg",
    key: 1190,
  },
  {
    label: (
      <span>
        /icons/solid/closed-captioning.svg{" "}
        <img style={{ height: 20 }} src={icon1191} />
      </span>
    ),
    value: "/icons/solid/closed-captioning.svg",
    key: 1191,
  },
  {
    label: (
      <span>
        /icons/solid/sink.svg <img style={{ height: 20 }} src={icon1192} />
      </span>
    ),
    value: "/icons/solid/sink.svg",
    key: 1192,
  },
  {
    label: (
      <span>
        /icons/solid/monument.svg <img style={{ height: 20 }} src={icon1193} />
      </span>
    ),
    value: "/icons/solid/monument.svg",
    key: 1193,
  },
  {
    label: (
      <span>
        /icons/solid/hat-wizard.svg{" "}
        <img style={{ height: 20 }} src={icon1194} />
      </span>
    ),
    value: "/icons/solid/hat-wizard.svg",
    key: 1194,
  },
  {
    label: (
      <span>
        /icons/solid/person-hiking.svg{" "}
        <img style={{ height: 20 }} src={icon1195} />
      </span>
    ),
    value: "/icons/solid/person-hiking.svg",
    key: 1195,
  },
  {
    label: (
      <span>
        /icons/solid/unlock-keyhole.svg{" "}
        <img style={{ height: 20 }} src={icon1196} />
      </span>
    ),
    value: "/icons/solid/unlock-keyhole.svg",
    key: 1196,
  },
  {
    label: (
      <span>
        /icons/solid/arrow-right-from-bracket.svg{" "}
        <img style={{ height: 20 }} src={icon1197} />
      </span>
    ),
    value: "/icons/solid/arrow-right-from-bracket.svg",
    key: 1197,
  },
  {
    label: (
      <span>
        /icons/solid/less-than-equal.svg{" "}
        <img style={{ height: 20 }} src={icon1198} />
      </span>
    ),
    value: "/icons/solid/less-than-equal.svg",
    key: 1198,
  },
  {
    label: (
      <span>
        /icons/solid/xmarks-lines.svg{" "}
        <img style={{ height: 20 }} src={icon1199} />
      </span>
    ),
    value: "/icons/solid/xmarks-lines.svg",
    key: 1199,
  },
  {
    label: (
      <span>
        /icons/solid/code-compare.svg{" "}
        <img style={{ height: 20 }} src={icon1200} />
      </span>
    ),
    value: "/icons/solid/code-compare.svg",
    key: 1200,
  },
  {
    label: (
      <span>
        /icons/solid/wand-sparkles.svg{" "}
        <img style={{ height: 20 }} src={icon1201} />
      </span>
    ),
    value: "/icons/solid/wand-sparkles.svg",
    key: 1201,
  },
  {
    label: (
      <span>
        /icons/solid/caravan.svg <img style={{ height: 20 }} src={icon1202} />
      </span>
    ),
    value: "/icons/solid/caravan.svg",
    key: 1202,
  },
  {
    label: (
      <span>
        /icons/solid/industry.svg <img style={{ height: 20 }} src={icon1203} />
      </span>
    ),
    value: "/icons/solid/industry.svg",
    key: 1203,
  },
  {
    label: (
      <span>
        /icons/solid/square-xmark.svg{" "}
        <img style={{ height: 20 }} src={icon1204} />
      </span>
    ),
    value: "/icons/solid/square-xmark.svg",
    key: 1204,
  },
  {
    label: (
      <span>
        /icons/solid/child.svg <img style={{ height: 20 }} src={icon1205} />
      </span>
    ),
    value: "/icons/solid/child.svg",
    key: 1205,
  },
  {
    label: (
      <span>
        /icons/solid/font-awesome.svg{" "}
        <img style={{ height: 20 }} src={icon1206} />
      </span>
    ),
    value: "/icons/solid/font-awesome.svg",
    key: 1206,
  },
  {
    label: (
      <span>
        /icons/solid/fire-extinguisher.svg{" "}
        <img style={{ height: 20 }} src={icon1207} />
      </span>
    ),
    value: "/icons/solid/fire-extinguisher.svg",
    key: 1207,
  },
  {
    label: (
      <span>
        /icons/solid/marker.svg <img style={{ height: 20 }} src={icon1208} />
      </span>
    ),
    value: "/icons/solid/marker.svg",
    key: 1208,
  },
  {
    label: (
      <span>
        /icons/solid/align-right.svg{" "}
        <img style={{ height: 20 }} src={icon1209} />
      </span>
    ),
    value: "/icons/solid/align-right.svg",
    key: 1209,
  },
  {
    label: (
      <span>
        /icons/solid/image.svg <img style={{ height: 20 }} src={icon1210} />
      </span>
    ),
    value: "/icons/solid/image.svg",
    key: 1210,
  },
  {
    label: (
      <span>
        /icons/solid/hand-point-down.svg{" "}
        <img style={{ height: 20 }} src={icon1211} />
      </span>
    ),
    value: "/icons/solid/hand-point-down.svg",
    key: 1211,
  },
  {
    label: (
      <span>
        /icons/solid/vector-square.svg{" "}
        <img style={{ height: 20 }} src={icon1212} />
      </span>
    ),
    value: "/icons/solid/vector-square.svg",
    key: 1212,
  },
  {
    label: (
      <span>
        /icons/solid/lightbulb.svg <img style={{ height: 20 }} src={icon1213} />
      </span>
    ),
    value: "/icons/solid/lightbulb.svg",
    key: 1213,
  },
  {
    label: (
      <span>
        /icons/solid/person-circle-question.svg{" "}
        <img style={{ height: 20 }} src={icon1214} />
      </span>
    ),
    value: "/icons/solid/person-circle-question.svg",
    key: 1214,
  },
  {
    label: (
      <span>
        /icons/solid/face-grin-stars.svg{" "}
        <img style={{ height: 20 }} src={icon1215} />
      </span>
    ),
    value: "/icons/solid/face-grin-stars.svg",
    key: 1215,
  },
  {
    label: (
      <span>
        /icons/solid/images.svg <img style={{ height: 20 }} src={icon1216} />
      </span>
    ),
    value: "/icons/solid/images.svg",
    key: 1216,
  },
  {
    label: (
      <span>
        /icons/solid/house-chimney-crack.svg{" "}
        <img style={{ height: 20 }} src={icon1217} />
      </span>
    ),
    value: "/icons/solid/house-chimney-crack.svg",
    key: 1217,
  },
  {
    label: (
      <span>
        /icons/solid/pager.svg <img style={{ height: 20 }} src={icon1218} />
      </span>
    ),
    value: "/icons/solid/pager.svg",
    key: 1218,
  },
  {
    label: (
      <span>
        /icons/solid/turkish-lira-sign.svg{" "}
        <img style={{ height: 20 }} src={icon1219} />
      </span>
    ),
    value: "/icons/solid/turkish-lira-sign.svg",
    key: 1219,
  },
  {
    label: (
      <span>
        /icons/solid/shield-virus.svg{" "}
        <img style={{ height: 20 }} src={icon1220} />
      </span>
    ),
    value: "/icons/solid/shield-virus.svg",
    key: 1220,
  },
  {
    label: (
      <span>
        /icons/solid/bus-simple.svg{" "}
        <img style={{ height: 20 }} src={icon1221} />
      </span>
    ),
    value: "/icons/solid/bus-simple.svg",
    key: 1221,
  },
  {
    label: (
      <span>
        /icons/solid/mountain-sun.svg{" "}
        <img style={{ height: 20 }} src={icon1222} />
      </span>
    ),
    value: "/icons/solid/mountain-sun.svg",
    key: 1222,
  },
  {
    label: (
      <span>
        /icons/solid/landmark-flag.svg{" "}
        <img style={{ height: 20 }} src={icon1223} />
      </span>
    ),
    value: "/icons/solid/landmark-flag.svg",
    key: 1223,
  },
  {
    label: (
      <span>
        /icons/solid/magnifying-glass-chart.svg{" "}
        <img style={{ height: 20 }} src={icon1224} />
      </span>
    ),
    value: "/icons/solid/magnifying-glass-chart.svg",
    key: 1224,
  },
  {
    label: (
      <span>
        /icons/solid/1.svg <img style={{ height: 20 }} src={icon1225} />
      </span>
    ),
    value: "/icons/solid/1.svg",
    key: 1225,
  },
  {
    label: (
      <span>
        /icons/solid/users-rays.svg{" "}
        <img style={{ height: 20 }} src={icon1226} />
      </span>
    ),
    value: "/icons/solid/users-rays.svg",
    key: 1226,
  },
  {
    label: (
      <span>
        /icons/solid/p.svg <img style={{ height: 20 }} src={icon1227} />
      </span>
    ),
    value: "/icons/solid/p.svg",
    key: 1227,
  },
  {
    label: (
      <span>
        /icons/solid/rectangle-xmark.svg{" "}
        <img style={{ height: 20 }} src={icon1228} />
      </span>
    ),
    value: "/icons/solid/rectangle-xmark.svg",
    key: 1228,
  },
  {
    label: (
      <span>
        /icons/solid/face-grin-squint.svg{" "}
        <img style={{ height: 20 }} src={icon1229} />
      </span>
    ),
    value: "/icons/solid/face-grin-squint.svg",
    key: 1229,
  },
  {
    label: (
      <span>
        /icons/solid/circle-up.svg <img style={{ height: 20 }} src={icon1230} />
      </span>
    ),
    value: "/icons/solid/circle-up.svg",
    key: 1230,
  },
  {
    label: (
      <span>
        /icons/solid/code-merge.svg{" "}
        <img style={{ height: 20 }} src={icon1231} />
      </span>
    ),
    value: "/icons/solid/code-merge.svg",
    key: 1231,
  },
  {
    label: (
      <span>
        /icons/solid/g.svg <img style={{ height: 20 }} src={icon1232} />
      </span>
    ),
    value: "/icons/solid/g.svg",
    key: 1232,
  },
  {
    label: (
      <span>
        /icons/solid/code-branch.svg{" "}
        <img style={{ height: 20 }} src={icon1233} />
      </span>
    ),
    value: "/icons/solid/code-branch.svg",
    key: 1233,
  },
  {
    label: (
      <span>
        /icons/solid/house-chimney-medical.svg{" "}
        <img style={{ height: 20 }} src={icon1234} />
      </span>
    ),
    value: "/icons/solid/house-chimney-medical.svg",
    key: 1234,
  },
  {
    label: (
      <span>
        /icons/solid/vr-cardboard.svg{" "}
        <img style={{ height: 20 }} src={icon1235} />
      </span>
    ),
    value: "/icons/solid/vr-cardboard.svg",
    key: 1235,
  },
  {
    label: (
      <span>
        /icons/solid/eye-dropper.svg{" "}
        <img style={{ height: 20 }} src={icon1236} />
      </span>
    ),
    value: "/icons/solid/eye-dropper.svg",
    key: 1236,
  },
  {
    label: (
      <span>
        /icons/solid/envelopes-bulk.svg{" "}
        <img style={{ height: 20 }} src={icon1237} />
      </span>
    ),
    value: "/icons/solid/envelopes-bulk.svg",
    key: 1237,
  },
  {
    label: (
      <span>
        /icons/solid/person-biking.svg{" "}
        <img style={{ height: 20 }} src={icon1238} />
      </span>
    ),
    value: "/icons/solid/person-biking.svg",
    key: 1238,
  },
  {
    label: (
      <span>
        /icons/solid/arrows-left-right-to-line.svg{" "}
        <img style={{ height: 20 }} src={icon1239} />
      </span>
    ),
    value: "/icons/solid/arrows-left-right-to-line.svg",
    key: 1239,
  },
  {
    label: (
      <span>
        /icons/solid/dice-d6.svg <img style={{ height: 20 }} src={icon1240} />
      </span>
    ),
    value: "/icons/solid/dice-d6.svg",
    key: 1240,
  },
  {
    label: (
      <span>
        /icons/solid/person-rays.svg{" "}
        <img style={{ height: 20 }} src={icon1241} />
      </span>
    ),
    value: "/icons/solid/person-rays.svg",
    key: 1241,
  },
  {
    label: (
      <span>
        /icons/solid/gauge-simple.svg{" "}
        <img style={{ height: 20 }} src={icon1242} />
      </span>
    ),
    value: "/icons/solid/gauge-simple.svg",
    key: 1242,
  },
  {
    label: (
      <span>
        /icons/solid/tachograph-digital.svg{" "}
        <img style={{ height: 20 }} src={icon1243} />
      </span>
    ),
    value: "/icons/solid/tachograph-digital.svg",
    key: 1243,
  },
  {
    label: (
      <span>
        /icons/solid/e.svg <img style={{ height: 20 }} src={icon1244} />
      </span>
    ),
    value: "/icons/solid/e.svg",
    key: 1244,
  },
  {
    label: (
      <span>
        /icons/solid/paperclip.svg <img style={{ height: 20 }} src={icon1245} />
      </span>
    ),
    value: "/icons/solid/paperclip.svg",
    key: 1245,
  },
  {
    label: (
      <span>
        /icons/solid/handshake-simple-slash.svg{" "}
        <img style={{ height: 20 }} src={icon1246} />
      </span>
    ),
    value: "/icons/solid/handshake-simple-slash.svg",
    key: 1246,
  },
  {
    label: (
      <span>
        /icons/solid/hand-back-fist.svg{" "}
        <img style={{ height: 20 }} src={icon1247} />
      </span>
    ),
    value: "/icons/solid/hand-back-fist.svg",
    key: 1247,
  },
  {
    label: (
      <span>
        /icons/solid/computer-mouse.svg{" "}
        <img style={{ height: 20 }} src={icon1248} />
      </span>
    ),
    value: "/icons/solid/computer-mouse.svg",
    key: 1248,
  },
  {
    label: (
      <span>
        /icons/solid/r.svg <img style={{ height: 20 }} src={icon1249} />
      </span>
    ),
    value: "/icons/solid/r.svg",
    key: 1249,
  },
  {
    label: (
      <span>
        /icons/solid/3.svg <img style={{ height: 20 }} src={icon1250} />
      </span>
    ),
    value: "/icons/solid/3.svg",
    key: 1250,
  },
  {
    label: (
      <span>
        /icons/solid/backward-step.svg{" "}
        <img style={{ height: 20 }} src={icon1251} />
      </span>
    ),
    value: "/icons/solid/backward-step.svg",
    key: 1251,
  },
  {
    label: (
      <span>
        /icons/solid/location-crosshairs.svg{" "}
        <img style={{ height: 20 }} src={icon1252} />
      </span>
    ),
    value: "/icons/solid/location-crosshairs.svg",
    key: 1252,
  },
  {
    label: (
      <span>
        /icons/solid/square-up-right.svg{" "}
        <img style={{ height: 20 }} src={icon1253} />
      </span>
    ),
    value: "/icons/solid/square-up-right.svg",
    key: 1253,
  },
  {
    label: (
      <span>
        /icons/solid/street-view.svg{" "}
        <img style={{ height: 20 }} src={icon1254} />
      </span>
    ),
    value: "/icons/solid/street-view.svg",
    key: 1254,
  },
  {
    label: (
      <span>
        /icons/solid/location-pin-lock.svg{" "}
        <img style={{ height: 20 }} src={icon1255} />
      </span>
    ),
    value: "/icons/solid/location-pin-lock.svg",
    key: 1255,
  },
  {
    label: (
      <span>
        /icons/solid/temperature-quarter.svg{" "}
        <img style={{ height: 20 }} src={icon1256} />
      </span>
    ),
    value: "/icons/solid/temperature-quarter.svg",
    key: 1256,
  },
  {
    label: (
      <span>
        /icons/solid/trailer.svg <img style={{ height: 20 }} src={icon1257} />
      </span>
    ),
    value: "/icons/solid/trailer.svg",
    key: 1257,
  },
  {
    label: (
      <span>
        /icons/solid/syringe.svg <img style={{ height: 20 }} src={icon1258} />
      </span>
    ),
    value: "/icons/solid/syringe.svg",
    key: 1258,
  },
  {
    label: (
      <span>
        /icons/solid/trash-arrow-up.svg{" "}
        <img style={{ height: 20 }} src={icon1259} />
      </span>
    ),
    value: "/icons/solid/trash-arrow-up.svg",
    key: 1259,
  },
  {
    label: (
      <span>
        /icons/solid/message.svg <img style={{ height: 20 }} src={icon1260} />
      </span>
    ),
    value: "/icons/solid/message.svg",
    key: 1260,
  },
  {
    label: (
      <span>
        /icons/solid/car-battery.svg{" "}
        <img style={{ height: 20 }} src={icon1261} />
      </span>
    ),
    value: "/icons/solid/car-battery.svg",
    key: 1261,
  },
  {
    label: (
      <span>
        /icons/solid/award.svg <img style={{ height: 20 }} src={icon1262} />
      </span>
    ),
    value: "/icons/solid/award.svg",
    key: 1262,
  },
  {
    label: (
      <span>
        /icons/solid/toilet-portable.svg{" "}
        <img style={{ height: 20 }} src={icon1263} />
      </span>
    ),
    value: "/icons/solid/toilet-portable.svg",
    key: 1263,
  },
  {
    label: (
      <span>
        /icons/solid/baby-carriage.svg{" "}
        <img style={{ height: 20 }} src={icon1264} />
      </span>
    ),
    value: "/icons/solid/baby-carriage.svg",
    key: 1264,
  },
  {
    label: (
      <span>
        /icons/solid/box.svg <img style={{ height: 20 }} src={icon1265} />
      </span>
    ),
    value: "/icons/solid/box.svg",
    key: 1265,
  },
  {
    label: (
      <span>
        /icons/solid/thumbs-up.svg <img style={{ height: 20 }} src={icon1266} />
      </span>
    ),
    value: "/icons/solid/thumbs-up.svg",
    key: 1266,
  },
  {
    label: (
      <span>
        /icons/solid/superscript.svg{" "}
        <img style={{ height: 20 }} src={icon1267} />
      </span>
    ),
    value: "/icons/solid/superscript.svg",
    key: 1267,
  },
  {
    label: (
      <span>
        /icons/solid/person-circle-xmark.svg{" "}
        <img style={{ height: 20 }} src={icon1268} />
      </span>
    ),
    value: "/icons/solid/person-circle-xmark.svg",
    key: 1268,
  },
  {
    label: (
      <span>
        /icons/solid/mask.svg <img style={{ height: 20 }} src={icon1269} />
      </span>
    ),
    value: "/icons/solid/mask.svg",
    key: 1269,
  },
  {
    label: (
      <span>
        /icons/solid/peace.svg <img style={{ height: 20 }} src={icon1270} />
      </span>
    ),
    value: "/icons/solid/peace.svg",
    key: 1270,
  },
  {
    label: (
      <span>
        /icons/solid/percent.svg <img style={{ height: 20 }} src={icon1271} />
      </span>
    ),
    value: "/icons/solid/percent.svg",
    key: 1271,
  },
  {
    label: (
      <span>
        /icons/solid/heart-circle-xmark.svg{" "}
        <img style={{ height: 20 }} src={icon1272} />
      </span>
    ),
    value: "/icons/solid/heart-circle-xmark.svg",
    key: 1272,
  },
  {
    label: (
      <span>
        /icons/solid/guarani-sign.svg{" "}
        <img style={{ height: 20 }} src={icon1273} />
      </span>
    ),
    value: "/icons/solid/guarani-sign.svg",
    key: 1273,
  },
  {
    label: (
      <span>
        /icons/solid/face-smile.svg{" "}
        <img style={{ height: 20 }} src={icon1274} />
      </span>
    ),
    value: "/icons/solid/face-smile.svg",
    key: 1274,
  },
  {
    label: (
      <span>
        /icons/solid/dong-sign.svg <img style={{ height: 20 }} src={icon1275} />
      </span>
    ),
    value: "/icons/solid/dong-sign.svg",
    key: 1275,
  },
  {
    label: (
      <span>
        /icons/solid/tree-city.svg <img style={{ height: 20 }} src={icon1276} />
      </span>
    ),
    value: "/icons/solid/tree-city.svg",
    key: 1276,
  },
  {
    label: (
      <span>
        /icons/solid/square.svg <img style={{ height: 20 }} src={icon1277} />
      </span>
    ),
    value: "/icons/solid/square.svg",
    key: 1277,
  },
  {
    label: (
      <span>
        /icons/solid/crown.svg <img style={{ height: 20 }} src={icon1278} />
      </span>
    ),
    value: "/icons/solid/crown.svg",
    key: 1278,
  },
  {
    label: (
      <span>
        /icons/solid/broom.svg <img style={{ height: 20 }} src={icon1279} />
      </span>
    ),
    value: "/icons/solid/broom.svg",
    key: 1279,
  },
  {
    label: (
      <span>
        /icons/solid/stethoscope.svg{" "}
        <img style={{ height: 20 }} src={icon1280} />
      </span>
    ),
    value: "/icons/solid/stethoscope.svg",
    key: 1280,
  },
  {
    label: (
      <span>
        /icons/solid/virus-covid-slash.svg{" "}
        <img style={{ height: 20 }} src={icon1281} />
      </span>
    ),
    value: "/icons/solid/virus-covid-slash.svg",
    key: 1281,
  },
  {
    label: (
      <span>
        /icons/solid/book-atlas.svg{" "}
        <img style={{ height: 20 }} src={icon1282} />
      </span>
    ),
    value: "/icons/solid/book-atlas.svg",
    key: 1282,
  },
  {
    label: (
      <span>
        /icons/solid/eye-slash.svg <img style={{ height: 20 }} src={icon1283} />
      </span>
    ),
    value: "/icons/solid/eye-slash.svg",
    key: 1283,
  },
  {
    label: (
      <span>
        /icons/solid/candy-cane.svg{" "}
        <img style={{ height: 20 }} src={icon1284} />
      </span>
    ),
    value: "/icons/solid/candy-cane.svg",
    key: 1284,
  },
  {
    label: (
      <span>
        /icons/solid/play.svg <img style={{ height: 20 }} src={icon1285} />
      </span>
    ),
    value: "/icons/solid/play.svg",
    key: 1285,
  },
  {
    label: (
      <span>
        /icons/solid/backward.svg <img style={{ height: 20 }} src={icon1286} />
      </span>
    ),
    value: "/icons/solid/backward.svg",
    key: 1286,
  },
  {
    label: (
      <span>
        /icons/solid/paper-plane.svg{" "}
        <img style={{ height: 20 }} src={icon1287} />
      </span>
    ),
    value: "/icons/solid/paper-plane.svg",
    key: 1287,
  },
  {
    label: (
      <span>
        /icons/solid/hands-praying.svg{" "}
        <img style={{ height: 20 }} src={icon1288} />
      </span>
    ),
    value: "/icons/solid/hands-praying.svg",
    key: 1288,
  },
  {
    label: (
      <span>
        /icons/solid/cake-candles.svg{" "}
        <img style={{ height: 20 }} src={icon1289} />
      </span>
    ),
    value: "/icons/solid/cake-candles.svg",
    key: 1289,
  },
  {
    label: (
      <span>
        /icons/solid/greater-than.svg{" "}
        <img style={{ height: 20 }} src={icon1290} />
      </span>
    ),
    value: "/icons/solid/greater-than.svg",
    key: 1290,
  },
  {
    label: (
      <span>
        /icons/solid/face-grin-hearts.svg{" "}
        <img style={{ height: 20 }} src={icon1291} />
      </span>
    ),
    value: "/icons/solid/face-grin-hearts.svg",
    key: 1291,
  },
  {
    label: (
      <span>
        /icons/solid/users-viewfinder.svg{" "}
        <img style={{ height: 20 }} src={icon1292} />
      </span>
    ),
    value: "/icons/solid/users-viewfinder.svg",
    key: 1292,
  },
  {
    label: (
      <span>
        /icons/solid/explosion.svg <img style={{ height: 20 }} src={icon1293} />
      </span>
    ),
    value: "/icons/solid/explosion.svg",
    key: 1293,
  },
  {
    label: (
      <span>
        /icons/solid/martini-glass-empty.svg{" "}
        <img style={{ height: 20 }} src={icon1294} />
      </span>
    ),
    value: "/icons/solid/martini-glass-empty.svg",
    key: 1294,
  },
  {
    label: (
      <span>
        /icons/solid/dungeon.svg <img style={{ height: 20 }} src={icon1295} />
      </span>
    ),
    value: "/icons/solid/dungeon.svg",
    key: 1295,
  },
  {
    label: (
      <span>
        /icons/solid/helmet-safety.svg{" "}
        <img style={{ height: 20 }} src={icon1296} />
      </span>
    ),
    value: "/icons/solid/helmet-safety.svg",
    key: 1296,
  },
  {
    label: (
      <span>
        /icons/solid/table.svg <img style={{ height: 20 }} src={icon1297} />
      </span>
    ),
    value: "/icons/solid/table.svg",
    key: 1297,
  },
  {
    label: (
      <span>
        /icons/solid/person-falling.svg{" "}
        <img style={{ height: 20 }} src={icon1298} />
      </span>
    ),
    value: "/icons/solid/person-falling.svg",
    key: 1298,
  },
  {
    label: (
      <span>
        /icons/solid/barcode.svg <img style={{ height: 20 }} src={icon1299} />
      </span>
    ),
    value: "/icons/solid/barcode.svg",
    key: 1299,
  },
  {
    label: (
      <span>
        /icons/solid/thumbtack.svg <img style={{ height: 20 }} src={icon1300} />
      </span>
    ),
    value: "/icons/solid/thumbtack.svg",
    key: 1300,
  },
  {
    label: (
      <span>
        /icons/solid/temperature-arrow-up.svg{" "}
        <img style={{ height: 20 }} src={icon1301} />
      </span>
    ),
    value: "/icons/solid/temperature-arrow-up.svg",
    key: 1301,
  },
  {
    label: (
      <span>
        /icons/solid/house-fire.svg{" "}
        <img style={{ height: 20 }} src={icon1302} />
      </span>
    ),
    value: "/icons/solid/house-fire.svg",
    key: 1302,
  },
  {
    label: (
      <span>
        /icons/solid/hourglass-end.svg{" "}
        <img style={{ height: 20 }} src={icon1303} />
      </span>
    ),
    value: "/icons/solid/hourglass-end.svg",
    key: 1303,
  },
  {
    label: (
      <span>
        /icons/solid/scroll.svg <img style={{ height: 20 }} src={icon1304} />
      </span>
    ),
    value: "/icons/solid/scroll.svg",
    key: 1304,
  },
  {
    label: (
      <span>
        /icons/solid/flag-checkered.svg{" "}
        <img style={{ height: 20 }} src={icon1305} />
      </span>
    ),
    value: "/icons/solid/flag-checkered.svg",
    key: 1305,
  },
  {
    label: (
      <span>
        /icons/solid/mars-stroke-right.svg{" "}
        <img style={{ height: 20 }} src={icon1306} />
      </span>
    ),
    value: "/icons/solid/mars-stroke-right.svg",
    key: 1306,
  },
  {
    label: (
      <span>
        /icons/solid/people-pulling.svg{" "}
        <img style={{ height: 20 }} src={icon1307} />
      </span>
    ),
    value: "/icons/solid/people-pulling.svg",
    key: 1307,
  },
  {
    label: (
      <span>
        /icons/solid/box-archive.svg{" "}
        <img style={{ height: 20 }} src={icon1308} />
      </span>
    ),
    value: "/icons/solid/box-archive.svg",
    key: 1308,
  },
  {
    label: (
      <span>
        /icons/solid/cloud-showers-water.svg{" "}
        <img style={{ height: 20 }} src={icon1309} />
      </span>
    ),
    value: "/icons/solid/cloud-showers-water.svg",
    key: 1309,
  },
  {
    label: (
      <span>
        /icons/solid/chalkboard.svg{" "}
        <img style={{ height: 20 }} src={icon1310} />
      </span>
    ),
    value: "/icons/solid/chalkboard.svg",
    key: 1310,
  },
  {
    label: (
      <span>
        /icons/solid/indian-rupee-sign.svg{" "}
        <img style={{ height: 20 }} src={icon1311} />
      </span>
    ),
    value: "/icons/solid/indian-rupee-sign.svg",
    key: 1311,
  },
  {
    label: (
      <span>
        /icons/solid/radiation.svg <img style={{ height: 20 }} src={icon1312} />
      </span>
    ),
    value: "/icons/solid/radiation.svg",
    key: 1312,
  },
  {
    label: (
      <span>
        /icons/solid/house-lock.svg{" "}
        <img style={{ height: 20 }} src={icon1313} />
      </span>
    ),
    value: "/icons/solid/house-lock.svg",
    key: 1313,
  },
  {
    label: (
      <span>
        /icons/solid/plane-up.svg <img style={{ height: 20 }} src={icon1314} />
      </span>
    ),
    value: "/icons/solid/plane-up.svg",
    key: 1314,
  },
  {
    label: (
      <span>
        /icons/solid/baby.svg <img style={{ height: 20 }} src={icon1315} />
      </span>
    ),
    value: "/icons/solid/baby.svg",
    key: 1315,
  },
  {
    label: (
      <span>
        /icons/solid/2.svg <img style={{ height: 20 }} src={icon1316} />
      </span>
    ),
    value: "/icons/solid/2.svg",
    key: 1316,
  },
  {
    label: (
      <span>
        /icons/solid/s.svg <img style={{ height: 20 }} src={icon1317} />
      </span>
    ),
    value: "/icons/solid/s.svg",
    key: 1317,
  },
  {
    label: (
      <span>
        /icons/solid/shekel-sign.svg{" "}
        <img style={{ height: 20 }} src={icon1318} />
      </span>
    ),
    value: "/icons/solid/shekel-sign.svg",
    key: 1318,
  },
  {
    label: (
      <span>
        /icons/solid/d.svg <img style={{ height: 20 }} src={icon1319} />
      </span>
    ),
    value: "/icons/solid/d.svg",
    key: 1319,
  },
  {
    label: (
      <span>
        /icons/solid/battery-empty.svg{" "}
        <img style={{ height: 20 }} src={icon1320} />
      </span>
    ),
    value: "/icons/solid/battery-empty.svg",
    key: 1320,
  },
  {
    label: (
      <span>
        /icons/solid/star-of-life.svg{" "}
        <img style={{ height: 20 }} src={icon1321} />
      </span>
    ),
    value: "/icons/solid/star-of-life.svg",
    key: 1321,
  },
  {
    label: (
      <span>
        /icons/solid/camera.svg <img style={{ height: 20 }} src={icon1322} />
      </span>
    ),
    value: "/icons/solid/camera.svg",
    key: 1322,
  },
  {
    label: (
      <span>
        /icons/solid/square-phone-flip.svg{" "}
        <img style={{ height: 20 }} src={icon1323} />
      </span>
    ),
    value: "/icons/solid/square-phone-flip.svg",
    key: 1323,
  },
  {
    label: (
      <span>
        /icons/solid/baseball-bat-ball.svg{" "}
        <img style={{ height: 20 }} src={icon1324} />
      </span>
    ),
    value: "/icons/solid/baseball-bat-ball.svg",
    key: 1324,
  },
  {
    label: (
      <span>
        /icons/solid/circle-right.svg{" "}
        <img style={{ height: 20 }} src={icon1325} />
      </span>
    ),
    value: "/icons/solid/circle-right.svg",
    key: 1325,
  },
  {
    label: (
      <span>
        /icons/solid/users-slash.svg{" "}
        <img style={{ height: 20 }} src={icon1326} />
      </span>
    ),
    value: "/icons/solid/users-slash.svg",
    key: 1326,
  },
  {
    label: (
      <span>
        /icons/solid/floppy-disk.svg{" "}
        <img style={{ height: 20 }} src={icon1327} />
      </span>
    ),
    value: "/icons/solid/floppy-disk.svg",
    key: 1327,
  },
  {
    label: (
      <span>
        /icons/solid/circle-nodes.svg{" "}
        <img style={{ height: 20 }} src={icon1328} />
      </span>
    ),
    value: "/icons/solid/circle-nodes.svg",
    key: 1328,
  },
  {
    label: (
      <span>
        /icons/solid/file-powerpoint.svg{" "}
        <img style={{ height: 20 }} src={icon1329} />
      </span>
    ),
    value: "/icons/solid/file-powerpoint.svg",
    key: 1329,
  },
  {
    label: (
      <span>
        /icons/solid/folder-minus.svg{" "}
        <img style={{ height: 20 }} src={icon1330} />
      </span>
    ),
    value: "/icons/solid/folder-minus.svg",
    key: 1330,
  },
  {
    label: (
      <span>
        /icons/solid/otter.svg <img style={{ height: 20 }} src={icon1331} />
      </span>
    ),
    value: "/icons/solid/otter.svg",
    key: 1331,
  },
  {
    label: (
      <span>
        /icons/solid/sun-plant-wilt.svg{" "}
        <img style={{ height: 20 }} src={icon1332} />
      </span>
    ),
    value: "/icons/solid/sun-plant-wilt.svg",
    key: 1332,
  },
  {
    label: (
      <span>
        /icons/solid/sim-card.svg <img style={{ height: 20 }} src={icon1333} />
      </span>
    ),
    value: "/icons/solid/sim-card.svg",
    key: 1333,
  },
  {
    label: (
      <span>
        /icons/solid/truck.svg <img style={{ height: 20 }} src={icon1334} />
      </span>
    ),
    value: "/icons/solid/truck.svg",
    key: 1334,
  },
  {
    label: (
      <span>
        /icons/solid/book-open-reader.svg{" "}
        <img style={{ height: 20 }} src={icon1335} />
      </span>
    ),
    value: "/icons/solid/book-open-reader.svg",
    key: 1335,
  },
  {
    label: (
      <span>
        /icons/solid/note-sticky.svg{" "}
        <img style={{ height: 20 }} src={icon1336} />
      </span>
    ),
    value: "/icons/solid/note-sticky.svg",
    key: 1336,
  },
  {
    label: (
      <span>
        /icons/solid/fire-flame-curved.svg{" "}
        <img style={{ height: 20 }} src={icon1337} />
      </span>
    ),
    value: "/icons/solid/fire-flame-curved.svg",
    key: 1337,
  },
  {
    label: (
      <span>
        /icons/solid/oil-can.svg <img style={{ height: 20 }} src={icon1338} />
      </span>
    ),
    value: "/icons/solid/oil-can.svg",
    key: 1338,
  },
  {
    label: (
      <span>
        /icons/solid/w.svg <img style={{ height: 20 }} src={icon1339} />
      </span>
    ),
    value: "/icons/solid/w.svg",
    key: 1339,
  },
  {
    label: (
      <span>
        /icons/solid/6.svg <img style={{ height: 20 }} src={icon1340} />
      </span>
    ),
    value: "/icons/solid/6.svg",
    key: 1340,
  },
  {
    label: (
      <span>
        /icons/solid/fire-burner.svg{" "}
        <img style={{ height: 20 }} src={icon1341} />
      </span>
    ),
    value: "/icons/solid/fire-burner.svg",
    key: 1341,
  },
  {
    label: (
      <span>
        /icons/solid/blender.svg <img style={{ height: 20 }} src={icon1342} />
      </span>
    ),
    value: "/icons/solid/blender.svg",
    key: 1342,
  },
  {
    label: (
      <span>
        /icons/solid/bridge-circle-check.svg{" "}
        <img style={{ height: 20 }} src={icon1343} />
      </span>
    ),
    value: "/icons/solid/bridge-circle-check.svg",
    key: 1343,
  },
  {
    label: (
      <span>
        /icons/solid/person-cane.svg{" "}
        <img style={{ height: 20 }} src={icon1344} />
      </span>
    ),
    value: "/icons/solid/person-cane.svg",
    key: 1344,
  },
  {
    label: (
      <span>
        /icons/solid/magnifying-glass-dollar.svg{" "}
        <img style={{ height: 20 }} src={icon1345} />
      </span>
    ),
    value: "/icons/solid/magnifying-glass-dollar.svg",
    key: 1345,
  },
  {
    label: (
      <span>
        /icons/solid/bottle-water.svg{" "}
        <img style={{ height: 20 }} src={icon1346} />
      </span>
    ),
    value: "/icons/solid/bottle-water.svg",
    key: 1346,
  },
  {
    label: (
      <span>
        /icons/solid/wine-glass.svg{" "}
        <img style={{ height: 20 }} src={icon1347} />
      </span>
    ),
    value: "/icons/solid/wine-glass.svg",
    key: 1347,
  },
  {
    label: (
      <span>
        /icons/solid/hand-spock.svg{" "}
        <img style={{ height: 20 }} src={icon1348} />
      </span>
    ),
    value: "/icons/solid/hand-spock.svg",
    key: 1348,
  },
  {
    label: (
      <span>
        /icons/solid/hands-holding-circle.svg{" "}
        <img style={{ height: 20 }} src={icon1349} />
      </span>
    ),
    value: "/icons/solid/hands-holding-circle.svg",
    key: 1349,
  },
  {
    label: (
      <span>
        /icons/solid/hand-holding-dollar.svg{" "}
        <img style={{ height: 20 }} src={icon1350} />
      </span>
    ),
    value: "/icons/solid/hand-holding-dollar.svg",
    key: 1350,
  },
  {
    label: (
      <span>
        /icons/solid/faucet-drip.svg{" "}
        <img style={{ height: 20 }} src={icon1351} />
      </span>
    ),
    value: "/icons/solid/faucet-drip.svg",
    key: 1351,
  },
  {
    label: (
      <span>
        /icons/solid/arrow-up-right-from-square.svg{" "}
        <img style={{ height: 20 }} src={icon1352} />
      </span>
    ),
    value: "/icons/solid/arrow-up-right-from-square.svg",
    key: 1352,
  },
  {
    label: (
      <span>
        /icons/solid/circle-arrow-right.svg{" "}
        <img style={{ height: 20 }} src={icon1353} />
      </span>
    ),
    value: "/icons/solid/circle-arrow-right.svg",
    key: 1353,
  },
  {
    label: (
      <span>
        /icons/solid/toilet-paper.svg{" "}
        <img style={{ height: 20 }} src={icon1354} />
      </span>
    ),
    value: "/icons/solid/toilet-paper.svg",
    key: 1354,
  },
  {
    label: (
      <span>
        /icons/solid/magnifying-glass-location.svg{" "}
        <img style={{ height: 20 }} src={icon1355} />
      </span>
    ),
    value: "/icons/solid/magnifying-glass-location.svg",
    key: 1355,
  },
  {
    label: (
      <span>
        /icons/solid/angles-right.svg{" "}
        <img style={{ height: 20 }} src={icon1356} />
      </span>
    ),
    value: "/icons/solid/angles-right.svg",
    key: 1356,
  },
  {
    label: (
      <span>
        /icons/solid/bucket.svg <img style={{ height: 20 }} src={icon1357} />
      </span>
    ),
    value: "/icons/solid/bucket.svg",
    key: 1357,
  },
  {
    label: (
      <span>
        /icons/solid/circle-info.svg{" "}
        <img style={{ height: 20 }} src={icon1358} />
      </span>
    ),
    value: "/icons/solid/circle-info.svg",
    key: 1358,
  },
  {
    label: (
      <span>
        /icons/solid/head-side-virus.svg{" "}
        <img style={{ height: 20 }} src={icon1359} />
      </span>
    ),
    value: "/icons/solid/head-side-virus.svg",
    key: 1359,
  },
  {
    label: (
      <span>
        /icons/solid/egg.svg <img style={{ height: 20 }} src={icon1360} />
      </span>
    ),
    value: "/icons/solid/egg.svg",
    key: 1360,
  },
  {
    label: (
      <span>
        /icons/solid/up-long.svg <img style={{ height: 20 }} src={icon1361} />
      </span>
    ),
    value: "/icons/solid/up-long.svg",
    key: 1361,
  },
  {
    label: (
      <span>
        /icons/solid/scroll-torah.svg{" "}
        <img style={{ height: 20 }} src={icon1362} />
      </span>
    ),
    value: "/icons/solid/scroll-torah.svg",
    key: 1362,
  },
  {
    label: (
      <span>
        /icons/solid/hand-fist.svg <img style={{ height: 20 }} src={icon1363} />
      </span>
    ),
    value: "/icons/solid/hand-fist.svg",
    key: 1363,
  },
  {
    label: (
      <span>
        /icons/solid/circle-radiation.svg{" "}
        <img style={{ height: 20 }} src={icon1364} />
      </span>
    ),
    value: "/icons/solid/circle-radiation.svg",
    key: 1364,
  },
  {
    label: (
      <span>
        /icons/solid/bullseye.svg <img style={{ height: 20 }} src={icon1365} />
      </span>
    ),
    value: "/icons/solid/bullseye.svg",
    key: 1365,
  },
  {
    label: (
      <span>
        /icons/solid/bus.svg <img style={{ height: 20 }} src={icon1366} />
      </span>
    ),
    value: "/icons/solid/bus.svg",
    key: 1366,
  },
  {
    label: (
      <span>
        /icons/solid/person-dress-burst.svg{" "}
        <img style={{ height: 20 }} src={icon1367} />
      </span>
    ),
    value: "/icons/solid/person-dress-burst.svg",
    key: 1367,
  },
  {
    label: (
      <span>
        /icons/solid/mercury.svg <img style={{ height: 20 }} src={icon1368} />
      </span>
    ),
    value: "/icons/solid/mercury.svg",
    key: 1368,
  },
  {
    label: (
      <span>
        /icons/solid/infinity.svg <img style={{ height: 20 }} src={icon1369} />
      </span>
    ),
    value: "/icons/solid/infinity.svg",
    key: 1369,
  },
  {
    label: (
      <span>
        /icons/solid/xmark.svg <img style={{ height: 20 }} src={icon1370} />
      </span>
    ),
    value: "/icons/solid/xmark.svg",
    key: 1370,
  },
  {
    label: (
      <span>
        /icons/solid/bacteria.svg <img style={{ height: 20 }} src={icon1371} />
      </span>
    ),
    value: "/icons/solid/bacteria.svg",
    key: 1371,
  },
  {
    label: (
      <span>
        /icons/solid/gem.svg <img style={{ height: 20 }} src={icon1372} />
      </span>
    ),
    value: "/icons/solid/gem.svg",
    key: 1372,
  },
  {
    label: (
      <span>
        /icons/solid/table-columns.svg{" "}
        <img style={{ height: 20 }} src={icon1373} />
      </span>
    ),
    value: "/icons/solid/table-columns.svg",
    key: 1373,
  },
  {
    label: (
      <span>
        /icons/solid/audio-description.svg{" "}
        <img style={{ height: 20 }} src={icon1374} />
      </span>
    ),
    value: "/icons/solid/audio-description.svg",
    key: 1374,
  },
  {
    label: (
      <span>
        /icons/solid/skull-crossbones.svg{" "}
        <img style={{ height: 20 }} src={icon1375} />
      </span>
    ),
    value: "/icons/solid/skull-crossbones.svg",
    key: 1375,
  },
  {
    label: (
      <span>
        /icons/solid/feather.svg <img style={{ height: 20 }} src={icon1376} />
      </span>
    ),
    value: "/icons/solid/feather.svg",
    key: 1376,
  },
  {
    label: (
      <span>
        /icons/solid/trash.svg <img style={{ height: 20 }} src={icon1377} />
      </span>
    ),
    value: "/icons/solid/trash.svg",
    key: 1377,
  },
  {
    label: (
      <span>
        /icons/solid/chart-bar.svg <img style={{ height: 20 }} src={icon1378} />
      </span>
    ),
    value: "/icons/solid/chart-bar.svg",
    key: 1378,
  },
  {
    label: (
      <span>
        /icons/solid/transgender.svg{" "}
        <img style={{ height: 20 }} src={icon1379} />
      </span>
    ),
    value: "/icons/solid/transgender.svg",
    key: 1379,
  },
  {
    label: (
      <span>
        /icons/solid/person-running.svg{" "}
        <img style={{ height: 20 }} src={icon1380} />
      </span>
    ),
    value: "/icons/solid/person-running.svg",
    key: 1380,
  },
  {
    label: (
      <span>
        /icons/solid/dumpster-fire.svg{" "}
        <img style={{ height: 20 }} src={icon1381} />
      </span>
    ),
    value: "/icons/solid/dumpster-fire.svg",
    key: 1381,
  },
  {
    label: (
      <span>
        /icons/solid/toilet.svg <img style={{ height: 20 }} src={icon1382} />
      </span>
    ),
    value: "/icons/solid/toilet.svg",
    key: 1382,
  },
  {
    label: (
      <span>
        /icons/solid/leaf.svg <img style={{ height: 20 }} src={icon1383} />
      </span>
    ),
    value: "/icons/solid/leaf.svg",
    key: 1383,
  },
  {
    label: (
      <span>
        /icons/solid/paste.svg <img style={{ height: 20 }} src={icon1384} />
      </span>
    ),
    value: "/icons/solid/paste.svg",
    key: 1384,
  },
  {
    label: (
      <span>
        /icons/solid/window-maximize.svg{" "}
        <img style={{ height: 20 }} src={icon1385} />
      </span>
    ),
    value: "/icons/solid/window-maximize.svg",
    key: 1385,
  },
  {
    label: (
      <span>
        /icons/solid/magnifying-glass-plus.svg{" "}
        <img style={{ height: 20 }} src={icon1386} />
      </span>
    ),
    value: "/icons/solid/magnifying-glass-plus.svg",
    key: 1386,
  },
  {
    label: (
      <span>
        /icons/solid/arrow-up-z-a.svg{" "}
        <img style={{ height: 20 }} src={icon1387} />
      </span>
    ),
    value: "/icons/solid/arrow-up-z-a.svg",
    key: 1387,
  },
  {
    label: (
      <span>
        /icons/solid/gopuram.svg <img style={{ height: 20 }} src={icon1388} />
      </span>
    ),
    value: "/icons/solid/gopuram.svg",
    key: 1388,
  },
  {
    label: (
      <span>
        /icons/solid/sterling-sign.svg{" "}
        <img style={{ height: 20 }} src={icon1389} />
      </span>
    ),
    value: "/icons/solid/sterling-sign.svg",
    key: 1389,
  },
  {
    label: (
      <span>
        /icons/solid/address-book.svg{" "}
        <img style={{ height: 20 }} src={icon1390} />
      </span>
    ),
    value: "/icons/solid/address-book.svg",
    key: 1390,
  },
  {
    label: (
      <span>
        /icons/solid/memory.svg <img style={{ height: 20 }} src={icon1391} />
      </span>
    ),
    value: "/icons/solid/memory.svg",
    key: 1391,
  },
  {
    label: (
      <span>
        /icons/solid/stairs.svg <img style={{ height: 20 }} src={icon1392} />
      </span>
    ),
    value: "/icons/solid/stairs.svg",
    key: 1392,
  },
  {
    label: (
      <span>
        /icons/solid/dollar-sign.svg{" "}
        <img style={{ height: 20 }} src={icon1393} />
      </span>
    ),
    value: "/icons/solid/dollar-sign.svg",
    key: 1393,
  },
  {
    label: (
      <span>
        /icons/solid/truck-front.svg{" "}
        <img style={{ height: 20 }} src={icon1394} />
      </span>
    ),
    value: "/icons/solid/truck-front.svg",
    key: 1394,
  },
  {
    label: (
      <span>
        /icons/solid/circle-plus.svg{" "}
        <img style={{ height: 20 }} src={icon1395} />
      </span>
    ),
    value: "/icons/solid/circle-plus.svg",
    key: 1395,
  },
  {
    label: (
      <span>
        /icons/solid/arrow-turn-up.svg{" "}
        <img style={{ height: 20 }} src={icon1396} />
      </span>
    ),
    value: "/icons/solid/arrow-turn-up.svg",
    key: 1396,
  },
  {
    label: (
      <span>
        /icons/solid/graduation-cap.svg{" "}
        <img style={{ height: 20 }} src={icon1397} />
      </span>
    ),
    value: "/icons/solid/graduation-cap.svg",
    key: 1397,
  },
  {
    label: (
      <span>
        /icons/solid/kiwi-bird.svg <img style={{ height: 20 }} src={icon1398} />
      </span>
    ),
    value: "/icons/solid/kiwi-bird.svg",
    key: 1398,
  },
  {
    label: (
      <span>
        /icons/solid/train-tram.svg{" "}
        <img style={{ height: 20 }} src={icon1399} />
      </span>
    ),
    value: "/icons/solid/train-tram.svg",
    key: 1399,
  },
  {
    label: (
      <span>
        /icons/solid/house-tsunami.svg{" "}
        <img style={{ height: 20 }} src={icon1400} />
      </span>
    ),
    value: "/icons/solid/house-tsunami.svg",
    key: 1400,
  },
  {
    label: (
      <span>
        /icons/solid/fill-drip.svg <img style={{ height: 20 }} src={icon1401} />
      </span>
    ),
    value: "/icons/solid/fill-drip.svg",
    key: 1401,
  },
  {
    label: (
      <span>
        /icons/solid/hashtag.svg <img style={{ height: 20 }} src={icon1402} />
      </span>
    ),
    value: "/icons/solid/hashtag.svg",
    key: 1402,
  },
  {
    label: (
      <span>
        /icons/solid/rectangle-list.svg{" "}
        <img style={{ height: 20 }} src={icon1403} />
      </span>
    ),
    value: "/icons/solid/rectangle-list.svg",
    key: 1403,
  },
  {
    label: (
      <span>
        /icons/solid/guitar.svg <img style={{ height: 20 }} src={icon1404} />
      </span>
    ),
    value: "/icons/solid/guitar.svg",
    key: 1404,
  },
  {
    label: (
      <span>
        /icons/solid/bong.svg <img style={{ height: 20 }} src={icon1405} />
      </span>
    ),
    value: "/icons/solid/bong.svg",
    key: 1405,
  },
  {
    label: (
      <span>
        /icons/solid/star.svg <img style={{ height: 20 }} src={icon1406} />
      </span>
    ),
    value: "/icons/solid/star.svg",
    key: 1406,
  },
  {
    label: (
      <span>
        /icons/solid/border-top-left.svg{" "}
        <img style={{ height: 20 }} src={icon1407} />
      </span>
    ),
    value: "/icons/solid/border-top-left.svg",
    key: 1407,
  },
  {
    label: (
      <span>
        /icons/solid/a.svg <img style={{ height: 20 }} src={icon1408} />
      </span>
    ),
    value: "/icons/solid/a.svg",
    key: 1408,
  },
  {
    label: (
      <span>
        /icons/solid/school-circle-check.svg{" "}
        <img style={{ height: 20 }} src={icon1409} />
      </span>
    ),
    value: "/icons/solid/school-circle-check.svg",
    key: 1409,
  },
  {
    label: (
      <span>
        /icons/solid/person-military-rifle.svg{" "}
        <img style={{ height: 20 }} src={icon1410} />
      </span>
    ),
    value: "/icons/solid/person-military-rifle.svg",
    key: 1410,
  },
  {
    label: (
      <span>
        /icons/solid/7.svg <img style={{ height: 20 }} src={icon1411} />
      </span>
    ),
    value: "/icons/solid/7.svg",
    key: 1411,
  },
  {
    label: (
      <span>
        /icons/solid/euro-sign.svg <img style={{ height: 20 }} src={icon1412} />
      </span>
    ),
    value: "/icons/solid/euro-sign.svg",
    key: 1412,
  },
  {
    label: (
      <span>
        /icons/solid/v.svg <img style={{ height: 20 }} src={icon1413} />
      </span>
    ),
    value: "/icons/solid/v.svg",
    key: 1413,
  },
  {
    label: (
      <span>
        /icons/solid/sun.svg <img style={{ height: 20 }} src={icon1414} />
      </span>
    ),
    value: "/icons/solid/sun.svg",
    key: 1414,
  },
  {
    label: (
      <span>
        /icons/solid/wrench.svg <img style={{ height: 20 }} src={icon1415} />
      </span>
    ),
    value: "/icons/solid/wrench.svg",
    key: 1415,
  },
  {
    label: (
      <span>
        /icons/solid/lines-leaning.svg{" "}
        <img style={{ height: 20 }} src={icon1416} />
      </span>
    ),
    value: "/icons/solid/lines-leaning.svg",
    key: 1416,
  },
  {
    label: (
      <span>
        /icons/solid/arrow-up-from-bracket.svg{" "}
        <img style={{ height: 20 }} src={icon1417} />
      </span>
    ),
    value: "/icons/solid/arrow-up-from-bracket.svg",
    key: 1417,
  },
  {
    label: (
      <span>
        /icons/solid/gauge.svg <img style={{ height: 20 }} src={icon1418} />
      </span>
    ),
    value: "/icons/solid/gauge.svg",
    key: 1418,
  },
  {
    label: (
      <span>
        /icons/solid/house-medical-circle-check.svg{" "}
        <img style={{ height: 20 }} src={icon1419} />
      </span>
    ),
    value: "/icons/solid/house-medical-circle-check.svg",
    key: 1419,
  },
  {
    label: (
      <span>
        /icons/solid/circle-notch.svg{" "}
        <img style={{ height: 20 }} src={icon1420} />
      </span>
    ),
    value: "/icons/solid/circle-notch.svg",
    key: 1420,
  },
  {
    label: (
      <span>
        /icons/solid/chart-column.svg{" "}
        <img style={{ height: 20 }} src={icon1421} />
      </span>
    ),
    value: "/icons/solid/chart-column.svg",
    key: 1421,
  },
  {
    label: (
      <span>
        /icons/solid/van-shuttle.svg{" "}
        <img style={{ height: 20 }} src={icon1422} />
      </span>
    ),
    value: "/icons/solid/van-shuttle.svg",
    key: 1422,
  },
  {
    label: (
      <span>
        /icons/solid/shoe-prints.svg{" "}
        <img style={{ height: 20 }} src={icon1423} />
      </span>
    ),
    value: "/icons/solid/shoe-prints.svg",
    key: 1423,
  },
  {
    label: (
      <span>
        /icons/solid/t.svg <img style={{ height: 20 }} src={icon1424} />
      </span>
    ),
    value: "/icons/solid/t.svg",
    key: 1424,
  },
  {
    label: (
      <span>
        /icons/solid/5.svg <img style={{ height: 20 }} src={icon1425} />
      </span>
    ),
    value: "/icons/solid/5.svg",
    key: 1425,
  },
  {
    label: (
      <span>
        /icons/solid/c.svg <img style={{ height: 20 }} src={icon1426} />
      </span>
    ),
    value: "/icons/solid/c.svg",
    key: 1426,
  },
  {
    label: (
      <span>
        /icons/solid/soap.svg <img style={{ height: 20 }} src={icon1427} />
      </span>
    ),
    value: "/icons/solid/soap.svg",
    key: 1427,
  },
  {
    label: (
      <span>
        /icons/solid/bacon.svg <img style={{ height: 20 }} src={icon1428} />
      </span>
    ),
    value: "/icons/solid/bacon.svg",
    key: 1428,
  },
  {
    label: (
      <span>
        /icons/solid/rectangle-ad.svg{" "}
        <img style={{ height: 20 }} src={icon1429} />
      </span>
    ),
    value: "/icons/solid/rectangle-ad.svg",
    key: 1429,
  },
  {
    label: (
      <span>
        /icons/solid/plane-arrival.svg{" "}
        <img style={{ height: 20 }} src={icon1430} />
      </span>
    ),
    value: "/icons/solid/plane-arrival.svg",
    key: 1430,
  },
  {
    label: (
      <span>
        /icons/solid/bone.svg <img style={{ height: 20 }} src={icon1431} />
      </span>
    ),
    value: "/icons/solid/bone.svg",
    key: 1431,
  },
  {
    label: (
      <span>
        /icons/solid/florin-sign.svg{" "}
        <img style={{ height: 20 }} src={icon1432} />
      </span>
    ),
    value: "/icons/solid/florin-sign.svg",
    key: 1432,
  },
  {
    label: (
      <span>
        /icons/solid/book-journal-whills.svg{" "}
        <img style={{ height: 20 }} src={icon1433} />
      </span>
    ),
    value: "/icons/solid/book-journal-whills.svg",
    key: 1433,
  },
  {
    label: (
      <span>
        /icons/solid/crutch.svg <img style={{ height: 20 }} src={icon1434} />
      </span>
    ),
    value: "/icons/solid/crutch.svg",
    key: 1434,
  },
  {
    label: (
      <span>
        /icons/solid/anchor.svg <img style={{ height: 20 }} src={icon1435} />
      </span>
    ),
    value: "/icons/solid/anchor.svg",
    key: 1435,
  },
  {
    label: (
      <span>
        /icons/solid/jedi.svg <img style={{ height: 20 }} src={icon1436} />
      </span>
    ),
    value: "/icons/solid/jedi.svg",
    key: 1436,
  },
  {
    label: (
      <span>
        /icons/solid/group-arrows-rotate.svg{" "}
        <img style={{ height: 20 }} src={icon1437} />
      </span>
    ),
    value: "/icons/solid/group-arrows-rotate.svg",
    key: 1437,
  },
  {
    label: (
      <span>
        /icons/solid/head-side-cough-slash.svg{" "}
        <img style={{ height: 20 }} src={icon1438} />
      </span>
    ),
    value: "/icons/solid/head-side-cough-slash.svg",
    key: 1438,
  },
  {
    label: (
      <span>
        /icons/solid/hammer.svg <img style={{ height: 20 }} src={icon1439} />
      </span>
    ),
    value: "/icons/solid/hammer.svg",
    key: 1439,
  },
  {
    label: (
      <span>
        /icons/solid/highlighter.svg{" "}
        <img style={{ height: 20 }} src={icon1440} />
      </span>
    ),
    value: "/icons/solid/highlighter.svg",
    key: 1440,
  },
  {
    label: (
      <span>
        /icons/solid/water-ladder.svg{" "}
        <img style={{ height: 20 }} src={icon1441} />
      </span>
    ),
    value: "/icons/solid/water-ladder.svg",
    key: 1441,
  },
  {
    label: (
      <span>
        /icons/solid/square-arrow-up-right.svg{" "}
        <img style={{ height: 20 }} src={icon1442} />
      </span>
    ),
    value: "/icons/solid/square-arrow-up-right.svg",
    key: 1442,
  },
  {
    label: (
      <span>
        /icons/solid/file-circle-check.svg{" "}
        <img style={{ height: 20 }} src={icon1443} />
      </span>
    ),
    value: "/icons/solid/file-circle-check.svg",
    key: 1443,
  },
  {
    label: (
      <span>
        /icons/solid/head-side-mask.svg{" "}
        <img style={{ height: 20 }} src={icon1444} />
      </span>
    ),
    value: "/icons/solid/head-side-mask.svg",
    key: 1444,
  },
  {
    label: (
      <span>
        /icons/solid/address-card.svg{" "}
        <img style={{ height: 20 }} src={icon1445} />
      </span>
    ),
    value: "/icons/solid/address-card.svg",
    key: 1445,
  },
  {
    label: (
      <span>
        /icons/solid/wheelchair.svg{" "}
        <img style={{ height: 20 }} src={icon1446} />
      </span>
    ),
    value: "/icons/solid/wheelchair.svg",
    key: 1446,
  },
  {
    label: (
      <span>
        /icons/solid/scale-unbalanced.svg{" "}
        <img style={{ height: 20 }} src={icon1447} />
      </span>
    ),
    value: "/icons/solid/scale-unbalanced.svg",
    key: 1447,
  },
  {
    label: (
      <span>
        /icons/solid/circle-stop.svg{" "}
        <img style={{ height: 20 }} src={icon1448} />
      </span>
    ),
    value: "/icons/solid/circle-stop.svg",
    key: 1448,
  },
  {
    label: (
      <span>
        /icons/solid/chart-pie.svg <img style={{ height: 20 }} src={icon1449} />
      </span>
    ),
    value: "/icons/solid/chart-pie.svg",
    key: 1449,
  },
  {
    label: (
      <span>
        /icons/solid/boxes-stacked.svg{" "}
        <img style={{ height: 20 }} src={icon1450} />
      </span>
    ),
    value: "/icons/solid/boxes-stacked.svg",
    key: 1450,
  },
  {
    label: (
      <span>
        /icons/solid/arrows-left-right.svg{" "}
        <img style={{ height: 20 }} src={icon1451} />
      </span>
    ),
    value: "/icons/solid/arrows-left-right.svg",
    key: 1451,
  },
  {
    label: (
      <span>
        /icons/solid/cloud-arrow-down.svg{" "}
        <img style={{ height: 20 }} src={icon1452} />
      </span>
    ),
    value: "/icons/solid/cloud-arrow-down.svg",
    key: 1452,
  },
  {
    label: (
      <span>
        /icons/solid/broom-ball.svg{" "}
        <img style={{ height: 20 }} src={icon1453} />
      </span>
    ),
    value: "/icons/solid/broom-ball.svg",
    key: 1453,
  },
  {
    label: (
      <span>
        /icons/solid/magnifying-glass-arrow-right.svg{" "}
        <img style={{ height: 20 }} src={icon1454} />
      </span>
    ),
    value: "/icons/solid/magnifying-glass-arrow-right.svg",
    key: 1454,
  },
  {
    label: (
      <span>
        /icons/solid/wand-magic-sparkles.svg{" "}
        <img style={{ height: 20 }} src={icon1455} />
      </span>
    ),
    value: "/icons/solid/wand-magic-sparkles.svg",
    key: 1455,
  },
  {
    label: (
      <span>
        /icons/solid/circle-xmark.svg{" "}
        <img style={{ height: 20 }} src={icon1456} />
      </span>
    ),
    value: "/icons/solid/circle-xmark.svg",
    key: 1456,
  },
  {
    label: (
      <span>
        /icons/solid/lemon.svg <img style={{ height: 20 }} src={icon1457} />
      </span>
    ),
    value: "/icons/solid/lemon.svg",
    key: 1457,
  },
  {
    label: (
      <span>
        /icons/solid/house-flag.svg{" "}
        <img style={{ height: 20 }} src={icon1458} />
      </span>
    ),
    value: "/icons/solid/house-flag.svg",
    key: 1458,
  },
  {
    label: (
      <span>
        /icons/solid/building-circle-xmark.svg{" "}
        <img style={{ height: 20 }} src={icon1459} />
      </span>
    ),
    value: "/icons/solid/building-circle-xmark.svg",
    key: 1459,
  },
  {
    label: (
      <span>
        /icons/solid/place-of-worship.svg{" "}
        <img style={{ height: 20 }} src={icon1460} />
      </span>
    ),
    value: "/icons/solid/place-of-worship.svg",
    key: 1460,
  },
  {
    label: (
      <span>
        /icons/solid/road-circle-xmark.svg{" "}
        <img style={{ height: 20 }} src={icon1461} />
      </span>
    ),
    value: "/icons/solid/road-circle-xmark.svg",
    key: 1461,
  },
  {
    label: (
      <span>
        /icons/solid/house-circle-xmark.svg{" "}
        <img style={{ height: 20 }} src={icon1462} />
      </span>
    ),
    value: "/icons/solid/house-circle-xmark.svg",
    key: 1462,
  },
  {
    label: (
      <span>
        /icons/solid/file-circle-question.svg{" "}
        <img style={{ height: 20 }} src={icon1463} />
      </span>
    ),
    value: "/icons/solid/file-circle-question.svg",
    key: 1463,
  },
  {
    label: (
      <span>
        /icons/solid/bug.svg <img style={{ height: 20 }} src={icon1464} />
      </span>
    ),
    value: "/icons/solid/bug.svg",
    key: 1464,
  },
  {
    label: (
      <span>
        /icons/solid/volume-high.svg{" "}
        <img style={{ height: 20 }} src={icon1465} />
      </span>
    ),
    value: "/icons/solid/volume-high.svg",
    key: 1465,
  },
  {
    label: (
      <span>
        /icons/solid/road-barrier.svg{" "}
        <img style={{ height: 20 }} src={icon1466} />
      </span>
    ),
    value: "/icons/solid/road-barrier.svg",
    key: 1466,
  },
  {
    label: (
      <span>
        /icons/solid/cedi-sign.svg <img style={{ height: 20 }} src={icon1467} />
      </span>
    ),
    value: "/icons/solid/cedi-sign.svg",
    key: 1467,
  },
  {
    label: (
      <span>
        /icons/solid/spinner.svg <img style={{ height: 20 }} src={icon1468} />
      </span>
    ),
    value: "/icons/solid/spinner.svg",
    key: 1468,
  },
  {
    label: (
      <span>
        /icons/solid/lari-sign.svg <img style={{ height: 20 }} src={icon1469} />
      </span>
    ),
    value: "/icons/solid/lari-sign.svg",
    key: 1469,
  },
  {
    label: (
      <span>
        /icons/solid/unlock.svg <img style={{ height: 20 }} src={icon1470} />
      </span>
    ),
    value: "/icons/solid/unlock.svg",
    key: 1470,
  },
  {
    label: (
      <span>
        /icons/solid/jar-wheat.svg <img style={{ height: 20 }} src={icon1471} />
      </span>
    ),
    value: "/icons/solid/jar-wheat.svg",
    key: 1471,
  },
  {
    label: (
      <span>
        /icons/solid/wheelchair-move.svg{" "}
        <img style={{ height: 20 }} src={icon1472} />
      </span>
    ),
    value: "/icons/solid/wheelchair-move.svg",
    key: 1472,
  },
  {
    label: (
      <span>
        /icons/solid/person-military-pointing.svg{" "}
        <img style={{ height: 20 }} src={icon1473} />
      </span>
    ),
    value: "/icons/solid/person-military-pointing.svg",
    key: 1473,
  },
  {
    label: (
      <span>
        /icons/solid/file-arrow-down.svg{" "}
        <img style={{ height: 20 }} src={icon1474} />
      </span>
    ),
    value: "/icons/solid/file-arrow-down.svg",
    key: 1474,
  },
  {
    label: (
      <span>
        /icons/solid/hand-holding-heart.svg{" "}
        <img style={{ height: 20 }} src={icon1475} />
      </span>
    ),
    value: "/icons/solid/hand-holding-heart.svg",
    key: 1475,
  },
  {
    label: (
      <span>
        /icons/solid/compass.svg <img style={{ height: 20 }} src={icon1476} />
      </span>
    ),
    value: "/icons/solid/compass.svg",
    key: 1476,
  },
  {
    label: (
      <span>
        /icons/solid/meteor.svg <img style={{ height: 20 }} src={icon1477} />
      </span>
    ),
    value: "/icons/solid/meteor.svg",
    key: 1477,
  },
  {
    label: (
      <span>
        /icons/solid/faucet.svg <img style={{ height: 20 }} src={icon1478} />
      </span>
    ),
    value: "/icons/solid/faucet.svg",
    key: 1478,
  },
  {
    label: (
      <span>
        /icons/solid/face-surprise.svg{" "}
        <img style={{ height: 20 }} src={icon1479} />
      </span>
    ),
    value: "/icons/solid/face-surprise.svg",
    key: 1479,
  },
  {
    label: (
      <span>
        /icons/solid/traffic-light.svg{" "}
        <img style={{ height: 20 }} src={icon1480} />
      </span>
    ),
    value: "/icons/solid/traffic-light.svg",
    key: 1480,
  },
  {
    label: (
      <span>
        /icons/solid/bicycle.svg <img style={{ height: 20 }} src={icon1481} />
      </span>
    ),
    value: "/icons/solid/bicycle.svg",
    key: 1481,
  },
  {
    label: (
      <span>
        /icons/solid/person-dots-from-line.svg{" "}
        <img style={{ height: 20 }} src={icon1482} />
      </span>
    ),
    value: "/icons/solid/person-dots-from-line.svg",
    key: 1482,
  },
  {
    label: (
      <span>
        /icons/solid/menorah.svg <img style={{ height: 20 }} src={icon1483} />
      </span>
    ),
    value: "/icons/solid/menorah.svg",
    key: 1483,
  },
  {
    label: (
      <span>
        /icons/solid/hospital-user.svg{" "}
        <img style={{ height: 20 }} src={icon1484} />
      </span>
    ),
    value: "/icons/solid/hospital-user.svg",
    key: 1484,
  },
  {
    label: (
      <span>
        /icons/solid/cloud-moon.svg{" "}
        <img style={{ height: 20 }} src={icon1485} />
      </span>
    ),
    value: "/icons/solid/cloud-moon.svg",
    key: 1485,
  },
  {
    label: (
      <span>
        /icons/solid/warehouse.svg <img style={{ height: 20 }} src={icon1486} />
      </span>
    ),
    value: "/icons/solid/warehouse.svg",
    key: 1486,
  },
  {
    label: (
      <span>
        /icons/solid/arrow-up-short-wide.svg{" "}
        <img style={{ height: 20 }} src={icon1487} />
      </span>
    ),
    value: "/icons/solid/arrow-up-short-wide.svg",
    key: 1487,
  },
  {
    label: (
      <span>
        /icons/solid/hryvnia-sign.svg{" "}
        <img style={{ height: 20 }} src={icon1488} />
      </span>
    ),
    value: "/icons/solid/hryvnia-sign.svg",
    key: 1488,
  },
  {
    label: (
      <span>
        /icons/solid/credit-card.svg{" "}
        <img style={{ height: 20 }} src={icon1489} />
      </span>
    ),
    value: "/icons/solid/credit-card.svg",
    key: 1489,
  },
  {
    label: (
      <span>
        /icons/solid/circle-arrow-down.svg{" "}
        <img style={{ height: 20 }} src={icon1490} />
      </span>
    ),
    value: "/icons/solid/circle-arrow-down.svg",
    key: 1490,
  },
  {
    label: (
      <span>
        /icons/solid/joint.svg <img style={{ height: 20 }} src={icon1491} />
      </span>
    ),
    value: "/icons/solid/joint.svg",
    key: 1491,
  },
  {
    label: (
      <span>
        /icons/solid/user-tie.svg <img style={{ height: 20 }} src={icon1492} />
      </span>
    ),
    value: "/icons/solid/user-tie.svg",
    key: 1492,
  },
  {
    label: (
      <span>
        /icons/solid/snowman.svg <img style={{ height: 20 }} src={icon1493} />
      </span>
    ),
    value: "/icons/solid/snowman.svg",
    key: 1493,
  },
  {
    label: (
      <span>
        /icons/solid/truck-droplet.svg{" "}
        <img style={{ height: 20 }} src={icon1494} />
      </span>
    ),
    value: "/icons/solid/truck-droplet.svg",
    key: 1494,
  },
  {
    label: (
      <span>
        /icons/solid/computer.svg <img style={{ height: 20 }} src={icon1495} />
      </span>
    ),
    value: "/icons/solid/computer.svg",
    key: 1495,
  },
  {
    label: (
      <span>
        /icons/solid/b.svg <img style={{ height: 20 }} src={icon1496} />
      </span>
    ),
    value: "/icons/solid/b.svg",
    key: 1496,
  },
  {
    label: (
      <span>
        /icons/solid/forward-step.svg{" "}
        <img style={{ height: 20 }} src={icon1497} />
      </span>
    ),
    value: "/icons/solid/forward-step.svg",
    key: 1497,
  },
  {
    label: (
      <span>
        /icons/solid/person-rifle.svg{" "}
        <img style={{ height: 20 }} src={icon1498} />
      </span>
    ),
    value: "/icons/solid/person-rifle.svg",
    key: 1498,
  },
  {
    label: (
      <span>
        /icons/solid/circle-half-stroke.svg{" "}
        <img style={{ height: 20 }} src={icon1499} />
      </span>
    ),
    value: "/icons/solid/circle-half-stroke.svg",
    key: 1499,
  },
  {
    label: (
      <span>
        /icons/solid/vial.svg <img style={{ height: 20 }} src={icon1500} />
      </span>
    ),
    value: "/icons/solid/vial.svg",
    key: 1500,
  },
  {
    label: (
      <span>
        /icons/solid/4.svg <img style={{ height: 20 }} src={icon1501} />
      </span>
    ),
    value: "/icons/solid/4.svg",
    key: 1501,
  },
  {
    label: (
      <span>
        /icons/solid/u.svg <img style={{ height: 20 }} src={icon1502} />
      </span>
    ),
    value: "/icons/solid/u.svg",
    key: 1502,
  },
  {
    label: (
      <span>
        /icons/solid/shop-slash.svg{" "}
        <img style={{ height: 20 }} src={icon1503} />
      </span>
    ),
    value: "/icons/solid/shop-slash.svg",
    key: 1503,
  },
  {
    label: (
      <span>
        /icons/solid/reply-all.svg <img style={{ height: 20 }} src={icon1504} />
      </span>
    ),
    value: "/icons/solid/reply-all.svg",
    key: 1504,
  },
  {
    label: (
      <span>
        /icons/solid/truck-medical.svg{" "}
        <img style={{ height: 20 }} src={icon1505} />
      </span>
    ),
    value: "/icons/solid/truck-medical.svg",
    key: 1505,
  },
  {
    label: (
      <span>
        /icons/solid/pencil.svg <img style={{ height: 20 }} src={icon1506} />
      </span>
    ),
    value: "/icons/solid/pencil.svg",
    key: 1506,
  },
  {
    label: (
      <span>
        /icons/solid/phone-flip.svg{" "}
        <img style={{ height: 20 }} src={icon1507} />
      </span>
    ),
    value: "/icons/solid/phone-flip.svg",
    key: 1507,
  },
  {
    label: (
      <span>
        /icons/solid/diagram-project.svg{" "}
        <img style={{ height: 20 }} src={icon1508} />
      </span>
    ),
    value: "/icons/solid/diagram-project.svg",
    key: 1508,
  },
  {
    label: (
      <span>
        /icons/solid/language.svg <img style={{ height: 20 }} src={icon1509} />
      </span>
    ),
    value: "/icons/solid/language.svg",
    key: 1509,
  },
  {
    label: (
      <span>
        /icons/solid/cloud-rain.svg{" "}
        <img style={{ height: 20 }} src={icon1510} />
      </span>
    ),
    value: "/icons/solid/cloud-rain.svg",
    key: 1510,
  },
  {
    label: (
      <span>
        /icons/solid/mars-stroke.svg{" "}
        <img style={{ height: 20 }} src={icon1511} />
      </span>
    ),
    value: "/icons/solid/mars-stroke.svg",
    key: 1511,
  },
  {
    label: (
      <span>
        /icons/solid/child-rifle.svg{" "}
        <img style={{ height: 20 }} src={icon1512} />
      </span>
    ),
    value: "/icons/solid/child-rifle.svg",
    key: 1512,
  },
  {
    label: (
      <span>
        /icons/solid/venus-double.svg{" "}
        <img style={{ height: 20 }} src={icon1513} />
      </span>
    ),
    value: "/icons/solid/venus-double.svg",
    key: 1513,
  },
  {
    label: (
      <span>
        /icons/solid/naira-sign.svg{" "}
        <img style={{ height: 20 }} src={icon1514} />
      </span>
    ),
    value: "/icons/solid/naira-sign.svg",
    key: 1514,
  },
  {
    label: (
      <span>
        /icons/solid/user-group.svg{" "}
        <img style={{ height: 20 }} src={icon1515} />
      </span>
    ),
    value: "/icons/solid/user-group.svg",
    key: 1515,
  },
  {
    label: (
      <span>
        /icons/solid/person-walking-dashed-line-arrow-right.svg{" "}
        <img style={{ height: 20 }} src={icon1516} />
      </span>
    ),
    value: "/icons/solid/person-walking-dashed-line-arrow-right.svg",
    key: 1516,
  },
  {
    label: (
      <span>
        /icons/solid/pen-clip.svg <img style={{ height: 20 }} src={icon1517} />
      </span>
    ),
    value: "/icons/solid/pen-clip.svg",
    key: 1517,
  },
  {
    label: (
      <span>
        /icons/solid/vest.svg <img style={{ height: 20 }} src={icon1518} />
      </span>
    ),
    value: "/icons/solid/vest.svg",
    key: 1518,
  },
  {
    label: (
      <span>
        /icons/solid/person-snowboarding.svg{" "}
        <img style={{ height: 20 }} src={icon1519} />
      </span>
    ),
    value: "/icons/solid/person-snowboarding.svg",
    key: 1519,
  },
  {
    label: (
      <span>
        /icons/solid/golf-ball-tee.svg{" "}
        <img style={{ height: 20 }} src={icon1520} />
      </span>
    ),
    value: "/icons/solid/golf-ball-tee.svg",
    key: 1520,
  },
  {
    label: (
      <span>
        /icons/solid/circle-user.svg{" "}
        <img style={{ height: 20 }} src={icon1521} />
      </span>
    ),
    value: "/icons/solid/circle-user.svg",
    key: 1521,
  },
  {
    label: (
      <span>
        /icons/solid/vest-patches.svg{" "}
        <img style={{ height: 20 }} src={icon1522} />
      </span>
    ),
    value: "/icons/solid/vest-patches.svg",
    key: 1522,
  },
  {
    label: (
      <span>
        /icons/solid/sitemap.svg <img style={{ height: 20 }} src={icon1523} />
      </span>
    ),
    value: "/icons/solid/sitemap.svg",
    key: 1523,
  },
  {
    label: (
      <span>
        /icons/solid/crosshairs.svg{" "}
        <img style={{ height: 20 }} src={icon1524} />
      </span>
    ),
    value: "/icons/solid/crosshairs.svg",
    key: 1524,
  },
  {
    label: (
      <span>
        /icons/solid/mill-sign.svg <img style={{ height: 20 }} src={icon1525} />
      </span>
    ),
    value: "/icons/solid/mill-sign.svg",
    key: 1525,
  },
  {
    label: (
      <span>
        /icons/solid/basketball.svg{" "}
        <img style={{ height: 20 }} src={icon1526} />
      </span>
    ),
    value: "/icons/solid/basketball.svg",
    key: 1526,
  },
  {
    label: (
      <span>
        /icons/solid/poop.svg <img style={{ height: 20 }} src={icon1527} />
      </span>
    ),
    value: "/icons/solid/poop.svg",
    key: 1527,
  },
  {
    label: (
      <span>
        /icons/solid/land-mine-on.svg{" "}
        <img style={{ height: 20 }} src={icon1528} />
      </span>
    ),
    value: "/icons/solid/land-mine-on.svg",
    key: 1528,
  },
  {
    label: (
      <span>
        /icons/solid/diamond.svg <img style={{ height: 20 }} src={icon1529} />
      </span>
    ),
    value: "/icons/solid/diamond.svg",
    key: 1529,
  },
  {
    label: (
      <span>
        /icons/solid/clock.svg <img style={{ height: 20 }} src={icon1530} />
      </span>
    ),
    value: "/icons/solid/clock.svg",
    key: 1530,
  },
  {
    label: (
      <span>
        /icons/solid/window-minimize.svg{" "}
        <img style={{ height: 20 }} src={icon1531} />
      </span>
    ),
    value: "/icons/solid/window-minimize.svg",
    key: 1531,
  },
  {
    label: (
      <span>
        /icons/solid/retweet.svg <img style={{ height: 20 }} src={icon1532} />
      </span>
    ),
    value: "/icons/solid/retweet.svg",
    key: 1532,
  },
  {
    label: (
      <span>
        /icons/solid/phone.svg <img style={{ height: 20 }} src={icon1533} />
      </span>
    ),
    value: "/icons/solid/phone.svg",
    key: 1533,
  },
  {
    label: (
      <span>
        /icons/solid/football.svg <img style={{ height: 20 }} src={icon1534} />
      </span>
    ),
    value: "/icons/solid/football.svg",
    key: 1534,
  },
  {
    label: (
      <span>
        /icons/solid/toilet-paper-slash.svg{" "}
        <img style={{ height: 20 }} src={icon1535} />
      </span>
    ),
    value: "/icons/solid/toilet-paper-slash.svg",
    key: 1535,
  },
  {
    label: (
      <span>
        /icons/solid/person-circle-minus.svg{" "}
        <img style={{ height: 20 }} src={icon1536} />
      </span>
    ),
    value: "/icons/solid/person-circle-minus.svg",
    key: 1536,
  },
  {
    label: (
      <span>
        /icons/solid/person-circle-exclamation.svg{" "}
        <img style={{ height: 20 }} src={icon1537} />
      </span>
    ),
    value: "/icons/solid/person-circle-exclamation.svg",
    key: 1537,
  },
  {
    label: (
      <span>
        /icons/solid/people-robbery.svg{" "}
        <img style={{ height: 20 }} src={icon1538} />
      </span>
    ),
    value: "/icons/solid/people-robbery.svg",
    key: 1538,
  },
  {
    label: (
      <span>
        /icons/solid/lungs.svg <img style={{ height: 20 }} src={icon1539} />
      </span>
    ),
    value: "/icons/solid/lungs.svg",
    key: 1539,
  },
  {
    label: (
      <span>
        /icons/solid/house-user.svg{" "}
        <img style={{ height: 20 }} src={icon1540} />
      </span>
    ),
    value: "/icons/solid/house-user.svg",
    key: 1540,
  },
  {
    label: (
      <span>
        /icons/solid/heart-circle-minus.svg{" "}
        <img style={{ height: 20 }} src={icon1541} />
      </span>
    ),
    value: "/icons/solid/heart-circle-minus.svg",
    key: 1541,
  },
  {
    label: (
      <span>
        /icons/solid/arrow-down-a-z.svg{" "}
        <img style={{ height: 20 }} src={icon1542} />
      </span>
    ),
    value: "/icons/solid/arrow-down-a-z.svg",
    key: 1542,
  },
  {
    label: (
      <span>
        /icons/solid/dumbbell.svg <img style={{ height: 20 }} src={icon1543} />
      </span>
    ),
    value: "/icons/solid/dumbbell.svg",
    key: 1543,
  },
  {
    label: (
      <span>
        /icons/solid/wine-bottle.svg{" "}
        <img style={{ height: 20 }} src={icon1544} />
      </span>
    ),
    value: "/icons/solid/wine-bottle.svg",
    key: 1544,
  },
  {
    label: (
      <span>
        /icons/solid/tractor.svg <img style={{ height: 20 }} src={icon1545} />
      </span>
    ),
    value: "/icons/solid/tractor.svg",
    key: 1545,
  },
  {
    label: (
      <span>
        /icons/solid/folder-closed.svg{" "}
        <img style={{ height: 20 }} src={icon1546} />
      </span>
    ),
    value: "/icons/solid/folder-closed.svg",
    key: 1546,
  },
  {
    label: (
      <span>
        /icons/solid/eye.svg <img style={{ height: 20 }} src={icon1547} />
      </span>
    ),
    value: "/icons/solid/eye.svg",
    key: 1547,
  },
  {
    label: (
      <span>
        /icons/solid/car-burst.svg <img style={{ height: 20 }} src={icon1548} />
      </span>
    ),
    value: "/icons/solid/car-burst.svg",
    key: 1548,
  },
  {
    label: (
      <span>
        /icons/solid/mobile.svg <img style={{ height: 20 }} src={icon1549} />
      </span>
    ),
    value: "/icons/solid/mobile.svg",
    key: 1549,
  },
  {
    label: (
      <span>
        /icons/solid/person-arrow-up-from-line.svg{" "}
        <img style={{ height: 20 }} src={icon1550} />
      </span>
    ),
    value: "/icons/solid/person-arrow-up-from-line.svg",
    key: 1550,
  },
  {
    label: (
      <span>
        /icons/solid/universal-access.svg{" "}
        <img style={{ height: 20 }} src={icon1551} />
      </span>
    ),
    value: "/icons/solid/universal-access.svg",
    key: 1551,
  },
  {
    label: (
      <span>
        /icons/solid/arrow-turn-down.svg{" "}
        <img style={{ height: 20 }} src={icon1552} />
      </span>
    ),
    value: "/icons/solid/arrow-turn-down.svg",
    key: 1552,
  },
  {
    label: (
      <span>
        /icons/solid/car-on.svg <img style={{ height: 20 }} src={icon1553} />
      </span>
    ),
    value: "/icons/solid/car-on.svg",
    key: 1553,
  },
  {
    label: (
      <span>
        /icons/solid/user-injured.svg{" "}
        <img style={{ height: 20 }} src={icon1554} />
      </span>
    ),
    value: "/icons/solid/user-injured.svg",
    key: 1554,
  },
  {
    label: (
      <span>
        /icons/solid/book-medical.svg{" "}
        <img style={{ height: 20 }} src={icon1555} />
      </span>
    ),
    value: "/icons/solid/book-medical.svg",
    key: 1555,
  },
  {
    label: (
      <span>
        /icons/solid/square-caret-up.svg{" "}
        <img style={{ height: 20 }} src={icon1556} />
      </span>
    ),
    value: "/icons/solid/square-caret-up.svg",
    key: 1556,
  },
  {
    label: (
      <span>
        /icons/solid/vial-virus.svg{" "}
        <img style={{ height: 20 }} src={icon1557} />
      </span>
    ),
    value: "/icons/solid/vial-virus.svg",
    key: 1557,
  },
  {
    label: (
      <span>
        /icons/solid/square-envelope.svg{" "}
        <img style={{ height: 20 }} src={icon1558} />
      </span>
    ),
    value: "/icons/solid/square-envelope.svg",
    key: 1558,
  },
  {
    label: (
      <span>
        /icons/solid/austral-sign.svg{" "}
        <img style={{ height: 20 }} src={icon1559} />
      </span>
    ),
    value: "/icons/solid/austral-sign.svg",
    key: 1559,
  },
  {
    label: (
      <span>
        /icons/solid/dove.svg <img style={{ height: 20 }} src={icon1560} />
      </span>
    ),
    value: "/icons/solid/dove.svg",
    key: 1560,
  },
  {
    label: (
      <span>
        /icons/solid/car-side.svg <img style={{ height: 20 }} src={icon1561} />
      </span>
    ),
    value: "/icons/solid/car-side.svg",
    key: 1561,
  },
  {
    label: (
      <span>
        /icons/solid/shield-dog.svg{" "}
        <img style={{ height: 20 }} src={icon1562} />
      </span>
    ),
    value: "/icons/solid/shield-dog.svg",
    key: 1562,
  },
  {
    label: (
      <span>
        /icons/solid/square-plus.svg{" "}
        <img style={{ height: 20 }} src={icon1563} />
      </span>
    ),
    value: "/icons/solid/square-plus.svg",
    key: 1563,
  },
  {
    label: (
      <span>
        /icons/solid/bahai.svg <img style={{ height: 20 }} src={icon1564} />
      </span>
    ),
    value: "/icons/solid/bahai.svg",
    key: 1564,
  },
  {
    label: (
      <span>
        /icons/solid/truck-ramp-box.svg{" "}
        <img style={{ height: 20 }} src={icon1565} />
      </span>
    ),
    value: "/icons/solid/truck-ramp-box.svg",
    key: 1565,
  },
  {
    label: (
      <span>
        /icons/solid/gift.svg <img style={{ height: 20 }} src={icon1566} />
      </span>
    ),
    value: "/icons/solid/gift.svg",
    key: 1566,
  },
  {
    label: (
      <span>
        /icons/solid/person-breastfeeding.svg{" "}
        <img style={{ height: 20 }} src={icon1567} />
      </span>
    ),
    value: "/icons/solid/person-breastfeeding.svg",
    key: 1567,
  },
  {
    label: (
      <span>
        /icons/solid/gauge-simple-high.svg{" "}
        <img style={{ height: 20 }} src={icon1568} />
      </span>
    ),
    value: "/icons/solid/gauge-simple-high.svg",
    key: 1568,
  },
  {
    label: (
      <span>
        /icons/solid/car.svg <img style={{ height: 20 }} src={icon1569} />
      </span>
    ),
    value: "/icons/solid/car.svg",
    key: 1569,
  },
  {
    label: (
      <span>
        /icons/solid/tarp.svg <img style={{ height: 20 }} src={icon1570} />
      </span>
    ),
    value: "/icons/solid/tarp.svg",
    key: 1570,
  },
  {
    label: (
      <span>
        /icons/solid/kit-medical.svg{" "}
        <img style={{ height: 20 }} src={icon1571} />
      </span>
    ),
    value: "/icons/solid/kit-medical.svg",
    key: 1571,
  },
  {
    label: (
      <span>
        /icons/solid/registered.svg{" "}
        <img style={{ height: 20 }} src={icon1572} />
      </span>
    ),
    value: "/icons/solid/registered.svg",
    key: 1572,
  },
  {
    label: (
      <span>
        /icons/solid/face-kiss-wink-heart.svg{" "}
        <img style={{ height: 20 }} src={icon1573} />
      </span>
    ),
    value: "/icons/solid/face-kiss-wink-heart.svg",
    key: 1573,
  },
  {
    label: (
      <span>
        /icons/solid/file-invoice.svg{" "}
        <img style={{ height: 20 }} src={icon1574} />
      </span>
    ),
    value: "/icons/solid/file-invoice.svg",
    key: 1574,
  },
  {
    label: (
      <span>
        /icons/solid/truck-arrow-right.svg{" "}
        <img style={{ height: 20 }} src={icon1575} />
      </span>
    ),
    value: "/icons/solid/truck-arrow-right.svg",
    key: 1575,
  },
  {
    label: (
      <span>
        /icons/solid/ear-listen.svg{" "}
        <img style={{ height: 20 }} src={icon1576} />
      </span>
    ),
    value: "/icons/solid/ear-listen.svg",
    key: 1576,
  },
  {
    label: (
      <span>
        /icons/solid/draw-polygon.svg{" "}
        <img style={{ height: 20 }} src={icon1577} />
      </span>
    ),
    value: "/icons/solid/draw-polygon.svg",
    key: 1577,
  },
  {
    label: (
      <span>
        /icons/solid/battery-quarter.svg{" "}
        <img style={{ height: 20 }} src={icon1578} />
      </span>
    ),
    value: "/icons/solid/battery-quarter.svg",
    key: 1578,
  },
  {
    label: (
      <span>
        /icons/solid/mountain-city.svg{" "}
        <img style={{ height: 20 }} src={icon1579} />
      </span>
    ),
    value: "/icons/solid/mountain-city.svg",
    key: 1579,
  },
  {
    label: (
      <span>
        /icons/solid/layer-group.svg{" "}
        <img style={{ height: 20 }} src={icon1580} />
      </span>
    ),
    value: "/icons/solid/layer-group.svg",
    key: 1580,
  },
  {
    label: (
      <span>
        /icons/solid/baseball.svg <img style={{ height: 20 }} src={icon1581} />
      </span>
    ),
    value: "/icons/solid/baseball.svg",
    key: 1581,
  },
  {
    label: (
      <span>
        /icons/solid/share.svg <img style={{ height: 20 }} src={icon1582} />
      </span>
    ),
    value: "/icons/solid/share.svg",
    key: 1582,
  },
  {
    label: (
      <span>
        /icons/solid/ticket-simple.svg{" "}
        <img style={{ height: 20 }} src={icon1583} />
      </span>
    ),
    value: "/icons/solid/ticket-simple.svg",
    key: 1583,
  },
  {
    label: (
      <span>
        /icons/solid/tags.svg <img style={{ height: 20 }} src={icon1584} />
      </span>
    ),
    value: "/icons/solid/tags.svg",
    key: 1584,
  },
  {
    label: (
      <span>
        /icons/solid/people-arrows.svg{" "}
        <img style={{ height: 20 }} src={icon1585} />
      </span>
    ),
    value: "/icons/solid/people-arrows.svg",
    key: 1585,
  },
  {
    label: (
      <span>
        /icons/solid/plug-circle-xmark.svg{" "}
        <img style={{ height: 20 }} src={icon1586} />
      </span>
    ),
    value: "/icons/solid/plug-circle-xmark.svg",
    key: 1586,
  },
  {
    label: (
      <span>
        /icons/solid/vials.svg <img style={{ height: 20 }} src={icon1587} />
      </span>
    ),
    value: "/icons/solid/vials.svg",
    key: 1587,
  },
  {
    label: (
      <span>
        /icons/solid/signs-post.svg{" "}
        <img style={{ height: 20 }} src={icon1588} />
      </span>
    ),
    value: "/icons/solid/signs-post.svg",
    key: 1588,
  },
  {
    label: (
      <span>
        /icons/solid/arrow-up.svg <img style={{ height: 20 }} src={icon1589} />
      </span>
    ),
    value: "/icons/solid/arrow-up.svg",
    key: 1589,
  },
  {
    label: (
      <span>
        /icons/solid/down-left-and-up-right-to-center.svg{" "}
        <img style={{ height: 20 }} src={icon1590} />
      </span>
    ),
    value: "/icons/solid/down-left-and-up-right-to-center.svg",
    key: 1590,
  },
  {
    label: (
      <span>
        /icons/solid/microscope.svg{" "}
        <img style={{ height: 20 }} src={icon1591} />
      </span>
    ),
    value: "/icons/solid/microscope.svg",
    key: 1591,
  },
  {
    label: (
      <span>
        /icons/solid/face-sad-cry.svg{" "}
        <img style={{ height: 20 }} src={icon1592} />
      </span>
    ),
    value: "/icons/solid/face-sad-cry.svg",
    key: 1592,
  },
  {
    label: (
      <span>
        /icons/solid/drum.svg <img style={{ height: 20 }} src={icon1593} />
      </span>
    ),
    value: "/icons/solid/drum.svg",
    key: 1593,
  },
  {
    label: (
      <span>
        /icons/solid/sort.svg <img style={{ height: 20 }} src={icon1594} />
      </span>
    ),
    value: "/icons/solid/sort.svg",
    key: 1594,
  },
  {
    label: (
      <span>
        /icons/solid/school-flag.svg{" "}
        <img style={{ height: 20 }} src={icon1595} />
      </span>
    ),
    value: "/icons/solid/school-flag.svg",
    key: 1595,
  },
  {
    label: (
      <span>
        /icons/solid/angles-up.svg <img style={{ height: 20 }} src={icon1596} />
      </span>
    ),
    value: "/icons/solid/angles-up.svg",
    key: 1596,
  },
  {
    label: (
      <span>
        /icons/solid/calendar-minus.svg{" "}
        <img style={{ height: 20 }} src={icon1597} />
      </span>
    ),
    value: "/icons/solid/calendar-minus.svg",
    key: 1597,
  },
  {
    label: (
      <span>
        /icons/solid/ethernet.svg <img style={{ height: 20 }} src={icon1598} />
      </span>
    ),
    value: "/icons/solid/ethernet.svg",
    key: 1598,
  },
  {
    label: (
      <span>
        /icons/solid/tent-arrows-down.svg{" "}
        <img style={{ height: 20 }} src={icon1599} />
      </span>
    ),
    value: "/icons/solid/tent-arrows-down.svg",
    key: 1599,
  },
  {
    label: (
      <span>
        /icons/solid/people-group.svg{" "}
        <img style={{ height: 20 }} src={icon1600} />
      </span>
    ),
    value: "/icons/solid/people-group.svg",
    key: 1600,
  },
  {
    label: (
      <span>
        /icons/solid/video.svg <img style={{ height: 20 }} src={icon1601} />
      </span>
    ),
    value: "/icons/solid/video.svg",
    key: 1601,
  },
  {
    label: (
      <span>
        /icons/solid/users-line.svg{" "}
        <img style={{ height: 20 }} src={icon1602} />
      </span>
    ),
    value: "/icons/solid/users-line.svg",
    key: 1602,
  },
  {
    label: (
      <span>
        /icons/solid/compass-drafting.svg{" "}
        <img style={{ height: 20 }} src={icon1603} />
      </span>
    ),
    value: "/icons/solid/compass-drafting.svg",
    key: 1603,
  },
  {
    label: (
      <span>
        /icons/solid/arrow-up-right-dots.svg{" "}
        <img style={{ height: 20 }} src={icon1604} />
      </span>
    ),
    value: "/icons/solid/arrow-up-right-dots.svg",
    key: 1604,
  },
  {
    label: (
      <span>
        /icons/solid/bitcoin-sign.svg{" "}
        <img style={{ height: 20 }} src={icon1605} />
      </span>
    ),
    value: "/icons/solid/bitcoin-sign.svg",
    key: 1605,
  },
  {
    label: (
      <span>
        /icons/solid/robot.svg <img style={{ height: 20 }} src={icon1606} />
      </span>
    ),
    value: "/icons/solid/robot.svg",
    key: 1606,
  },
  {
    label: (
      <span>
        /icons/solid/pepper-hot.svg{" "}
        <img style={{ height: 20 }} src={icon1607} />
      </span>
    ),
    value: "/icons/solid/pepper-hot.svg",
    key: 1607,
  },
  {
    label: (
      <span>
        /icons/solid/circle-dollar-to-slot.svg{" "}
        <img style={{ height: 20 }} src={icon1608} />
      </span>
    ),
    value: "/icons/solid/circle-dollar-to-slot.svg",
    key: 1608,
  },
  {
    label: (
      <span>
        /icons/solid/tree.svg <img style={{ height: 20 }} src={icon1609} />
      </span>
    ),
    value: "/icons/solid/tree.svg",
    key: 1609,
  },
  {
    label: (
      <span>
        /icons/solid/id-card.svg <img style={{ height: 20 }} src={icon1610} />
      </span>
    ),
    value: "/icons/solid/id-card.svg",
    key: 1610,
  },
  {
    label: (
      <span>
        /icons/solid/arrow-left-long.svg{" "}
        <img style={{ height: 20 }} src={icon1611} />
      </span>
    ),
    value: "/icons/solid/arrow-left-long.svg",
    key: 1611,
  },
  {
    label: (
      <span>
        /icons/solid/pills.svg <img style={{ height: 20 }} src={icon1612} />
      </span>
    ),
    value: "/icons/solid/pills.svg",
    key: 1612,
  },
  {
    label: (
      <span>
        /icons/solid/triangle-exclamation.svg{" "}
        <img style={{ height: 20 }} src={icon1613} />
      </span>
    ),
    value: "/icons/solid/triangle-exclamation.svg",
    key: 1613,
  },
  {
    label: (
      <span>
        /icons/solid/puzzle-piece.svg{" "}
        <img style={{ height: 20 }} src={icon1614} />
      </span>
    ),
    value: "/icons/solid/puzzle-piece.svg",
    key: 1614,
  },
  {
    label: (
      <span>
        /icons/solid/map-pin.svg <img style={{ height: 20 }} src={icon1615} />
      </span>
    ),
    value: "/icons/solid/map-pin.svg",
    key: 1615,
  },
  {
    label: (
      <span>
        /icons/solid/shop-lock.svg <img style={{ height: 20 }} src={icon1616} />
      </span>
    ),
    value: "/icons/solid/shop-lock.svg",
    key: 1616,
  },
  {
    label: (
      <span>
        /icons/solid/filter.svg <img style={{ height: 20 }} src={icon1617} />
      </span>
    ),
    value: "/icons/solid/filter.svg",
    key: 1617,
  },
  {
    label: (
      <span>
        /icons/solid/suitcase-rolling.svg{" "}
        <img style={{ height: 20 }} src={icon1618} />
      </span>
    ),
    value: "/icons/solid/suitcase-rolling.svg",
    key: 1618,
  },
  {
    label: (
      <span>
        /icons/solid/water.svg <img style={{ height: 20 }} src={icon1619} />
      </span>
    ),
    value: "/icons/solid/water.svg",
    key: 1619,
  },
  {
    label: (
      <span>
        /icons/solid/eye-low-vision.svg{" "}
        <img style={{ height: 20 }} src={icon1620} />
      </span>
    ),
    value: "/icons/solid/eye-low-vision.svg",
    key: 1620,
  },
  {
    label: (
      <span>
        /icons/solid/house-flood-water.svg{" "}
        <img style={{ height: 20 }} src={icon1621} />
      </span>
    ),
    value: "/icons/solid/house-flood-water.svg",
    key: 1621,
  },
  {
    label: (
      <span>
        /icons/solid/cloud-showers-heavy.svg{" "}
        <img style={{ height: 20 }} src={icon1622} />
      </span>
    ),
    value: "/icons/solid/cloud-showers-heavy.svg",
    key: 1622,
  },
  {
    label: (
      <span>
        /icons/solid/chess-pawn.svg{" "}
        <img style={{ height: 20 }} src={icon1623} />
      </span>
    ),
    value: "/icons/solid/chess-pawn.svg",
    key: 1623,
  },
  {
    label: (
      <span>
        /icons/solid/umbrella-beach.svg{" "}
        <img style={{ height: 20 }} src={icon1624} />
      </span>
    ),
    value: "/icons/solid/umbrella-beach.svg",
    key: 1624,
  },
  {
    label: (
      <span>
        /icons/solid/check-double.svg{" "}
        <img style={{ height: 20 }} src={icon1625} />
      </span>
    ),
    value: "/icons/solid/check-double.svg",
    key: 1625,
  },
  {
    label: (
      <span>
        /icons/solid/file-signature.svg{" "}
        <img style={{ height: 20 }} src={icon1626} />
      </span>
    ),
    value: "/icons/solid/file-signature.svg",
    key: 1626,
  },
  {
    label: (
      <span>
        /icons/solid/italic.svg <img style={{ height: 20 }} src={icon1627} />
      </span>
    ),
    value: "/icons/solid/italic.svg",
    key: 1627,
  },
  {
    label: (
      <span>
        /icons/solid/face-smile-beam.svg{" "}
        <img style={{ height: 20 }} src={icon1628} />
      </span>
    ),
    value: "/icons/solid/face-smile-beam.svg",
    key: 1628,
  },
  {
    label: (
      <span>
        /icons/solid/bell-slash.svg{" "}
        <img style={{ height: 20 }} src={icon1629} />
      </span>
    ),
    value: "/icons/solid/bell-slash.svg",
    key: 1629,
  },
  {
    label: (
      <span>
        /icons/solid/handshake.svg <img style={{ height: 20 }} src={icon1630} />
      </span>
    ),
    value: "/icons/solid/handshake.svg",
    key: 1630,
  },
  {
    label: (
      <span>
        /icons/solid/money-bill.svg{" "}
        <img style={{ height: 20 }} src={icon1631} />
      </span>
    ),
    value: "/icons/solid/money-bill.svg",
    key: 1631,
  },
  {
    label: (
      <span>
        /icons/solid/medal.svg <img style={{ height: 20 }} src={icon1632} />
      </span>
    ),
    value: "/icons/solid/medal.svg",
    key: 1632,
  },
  {
    label: (
      <span>
        /icons/solid/mars-double.svg{" "}
        <img style={{ height: 20 }} src={icon1633} />
      </span>
    ),
    value: "/icons/solid/mars-double.svg",
    key: 1633,
  },
  {
    label: (
      <span>
        /icons/solid/won-sign.svg <img style={{ height: 20 }} src={icon1634} />
      </span>
    ),
    value: "/icons/solid/won-sign.svg",
    key: 1634,
  },
  {
    label: (
      <span>
        /icons/solid/microphone-lines.svg{" "}
        <img style={{ height: 20 }} src={icon1635} />
      </span>
    ),
    value: "/icons/solid/microphone-lines.svg",
    key: 1635,
  },
  {
    label: (
      <span>
        /icons/solid/comment.svg <img style={{ height: 20 }} src={icon1636} />
      </span>
    ),
    value: "/icons/solid/comment.svg",
    key: 1636,
  },
  {
    label: (
      <span>
        /icons/solid/timeline.svg <img style={{ height: 20 }} src={icon1637} />
      </span>
    ),
    value: "/icons/solid/timeline.svg",
    key: 1637,
  },
  {
    label: (
      <span>
        /icons/solid/torii-gate.svg{" "}
        <img style={{ height: 20 }} src={icon1638} />
      </span>
    ),
    value: "/icons/solid/torii-gate.svg",
    key: 1638,
  },
  {
    label: (
      <span>
        /icons/solid/video-slash.svg{" "}
        <img style={{ height: 20 }} src={icon1639} />
      </span>
    ),
    value: "/icons/solid/video-slash.svg",
    key: 1639,
  },
  {
    label: (
      <span>
        /icons/solid/calendar.svg <img style={{ height: 20 }} src={icon1640} />
      </span>
    ),
    value: "/icons/solid/calendar.svg",
    key: 1640,
  },
  {
    label: (
      <span>
        /icons/solid/arrow-trend-up.svg{" "}
        <img style={{ height: 20 }} src={icon1641} />
      </span>
    ),
    value: "/icons/solid/arrow-trend-up.svg",
    key: 1641,
  },
  {
    label: (
      <span>
        /icons/solid/arrows-rotate.svg{" "}
        <img style={{ height: 20 }} src={icon1642} />
      </span>
    ),
    value: "/icons/solid/arrows-rotate.svg",
    key: 1642,
  },
  {
    label: (
      <span>
        /icons/solid/globe.svg <img style={{ height: 20 }} src={icon1643} />
      </span>
    ),
    value: "/icons/solid/globe.svg",
    key: 1643,
  },
  {
    label: (
      <span>
        /icons/solid/arrow-left.svg{" "}
        <img style={{ height: 20 }} src={icon1644} />
      </span>
    ),
    value: "/icons/solid/arrow-left.svg",
    key: 1644,
  },
  {
    label: (
      <span>
        /icons/solid/paintbrush.svg{" "}
        <img style={{ height: 20 }} src={icon1645} />
      </span>
    ),
    value: "/icons/solid/paintbrush.svg",
    key: 1645,
  },
  {
    label: (
      <span>
        /icons/solid/align-center.svg{" "}
        <img style={{ height: 20 }} src={icon1646} />
      </span>
    ),
    value: "/icons/solid/align-center.svg",
    key: 1646,
  },
  {
    label: (
      <span>
        /icons/solid/cross.svg <img style={{ height: 20 }} src={icon1647} />
      </span>
    ),
    value: "/icons/solid/cross.svg",
    key: 1647,
  },
  {
    label: (
      <span>
        /icons/solid/square-minus.svg{" "}
        <img style={{ height: 20 }} src={icon1648} />
      </span>
    ),
    value: "/icons/solid/square-minus.svg",
    key: 1648,
  },
  {
    label: (
      <span>
        /icons/solid/blog.svg <img style={{ height: 20 }} src={icon1649} />
      </span>
    ),
    value: "/icons/solid/blog.svg",
    key: 1649,
  },
  {
    label: (
      <span>
        /icons/solid/route.svg <img style={{ height: 20 }} src={icon1650} />
      </span>
    ),
    value: "/icons/solid/route.svg",
    key: 1650,
  },
  {
    label: (
      <span>
        /icons/solid/circle-arrow-up.svg{" "}
        <img style={{ height: 20 }} src={icon1651} />
      </span>
    ),
    value: "/icons/solid/circle-arrow-up.svg",
    key: 1651,
  },
  {
    label: (
      <span>
        /icons/solid/magnifying-glass.svg{" "}
        <img style={{ height: 20 }} src={icon1652} />
      </span>
    ),
    value: "/icons/solid/magnifying-glass.svg",
    key: 1652,
  },
  {
    label: (
      <span>
        /icons/solid/bowling-ball.svg{" "}
        <img style={{ height: 20 }} src={icon1653} />
      </span>
    ),
    value: "/icons/solid/bowling-ball.svg",
    key: 1653,
  },
  {
    label: (
      <span>
        /icons/solid/ellipsis.svg <img style={{ height: 20 }} src={icon1654} />
      </span>
    ),
    value: "/icons/solid/ellipsis.svg",
    key: 1654,
  },
  {
    label: (
      <span>
        /icons/solid/face-tired.svg{" "}
        <img style={{ height: 20 }} src={icon1655} />
      </span>
    ),
    value: "/icons/solid/face-tired.svg",
    key: 1655,
  },
  {
    label: (
      <span>
        /icons/solid/grip-lines-vertical.svg{" "}
        <img style={{ height: 20 }} src={icon1656} />
      </span>
    ),
    value: "/icons/solid/grip-lines-vertical.svg",
    key: 1656,
  },
  {
    label: (
      <span>
        /icons/solid/microchip.svg <img style={{ height: 20 }} src={icon1657} />
      </span>
    ),
    value: "/icons/solid/microchip.svg",
    key: 1657,
  },
  {
    label: (
      <span>
        /icons/solid/frog.svg <img style={{ height: 20 }} src={icon1658} />
      </span>
    ),
    value: "/icons/solid/frog.svg",
    key: 1658,
  },
  {
    label: (
      <span>
        /icons/solid/hands-asl-interpreting.svg{" "}
        <img style={{ height: 20 }} src={icon1659} />
      </span>
    ),
    value: "/icons/solid/hands-asl-interpreting.svg",
    key: 1659,
  },
  {
    label: (
      <span>
        /icons/solid/laptop.svg <img style={{ height: 20 }} src={icon1660} />
      </span>
    ),
    value: "/icons/solid/laptop.svg",
    key: 1660,
  },
  {
    label: (
      <span>
        /icons/solid/power-off.svg <img style={{ height: 20 }} src={icon1661} />
      </span>
    ),
    value: "/icons/solid/power-off.svg",
    key: 1661,
  },
  {
    label: (
      <span>
        /icons/solid/chart-area.svg{" "}
        <img style={{ height: 20 }} src={icon1662} />
      </span>
    ),
    value: "/icons/solid/chart-area.svg",
    key: 1662,
  },
  {
    label: (
      <span>
        /icons/solid/display.svg <img style={{ height: 20 }} src={icon1663} />
      </span>
    ),
    value: "/icons/solid/display.svg",
    key: 1663,
  },
  {
    label: (
      <span>
        /icons/solid/quote-left.svg{" "}
        <img style={{ height: 20 }} src={icon1664} />
      </span>
    ),
    value: "/icons/solid/quote-left.svg",
    key: 1664,
  },
  {
    label: (
      <span>
        /icons/solid/file-prescription.svg{" "}
        <img style={{ height: 20 }} src={icon1665} />
      </span>
    ),
    value: "/icons/solid/file-prescription.svg",
    key: 1665,
  },
  {
    label: (
      <span>
        /icons/solid/face-grin.svg <img style={{ height: 20 }} src={icon1666} />
      </span>
    ),
    value: "/icons/solid/face-grin.svg",
    key: 1666,
  },
  {
    label: (
      <span>
        /icons/solid/mountain.svg <img style={{ height: 20 }} src={icon1667} />
      </span>
    ),
    value: "/icons/solid/mountain.svg",
    key: 1667,
  },
  {
    label: (
      <span>
        /icons/solid/microphone-slash.svg{" "}
        <img style={{ height: 20 }} src={icon1668} />
      </span>
    ),
    value: "/icons/solid/microphone-slash.svg",
    key: 1668,
  },
  {
    label: (
      <span>
        /icons/solid/mars-and-venus-burst.svg{" "}
        <img style={{ height: 20 }} src={icon1669} />
      </span>
    ),
    value: "/icons/solid/mars-and-venus-burst.svg",
    key: 1669,
  },
  {
    label: (
      <span>
        /icons/solid/caret-left.svg{" "}
        <img style={{ height: 20 }} src={icon1670} />
      </span>
    ),
    value: "/icons/solid/caret-left.svg",
    key: 1670,
  },
  {
    label: (
      <span>
        /icons/solid/dice-two.svg <img style={{ height: 20 }} src={icon1671} />
      </span>
    ),
    value: "/icons/solid/dice-two.svg",
    key: 1671,
  },
  {
    label: (
      <span>
        /icons/solid/file-circle-plus.svg{" "}
        <img style={{ height: 20 }} src={icon1672} />
      </span>
    ),
    value: "/icons/solid/file-circle-plus.svg",
    key: 1672,
  },
  {
    label: (
      <span>
        /icons/solid/tooth.svg <img style={{ height: 20 }} src={icon1673} />
      </span>
    ),
    value: "/icons/solid/tooth.svg",
    key: 1673,
  },
  {
    label: (
      <span>
        /icons/solid/volume-low.svg{" "}
        <img style={{ height: 20 }} src={icon1674} />
      </span>
    ),
    value: "/icons/solid/volume-low.svg",
    key: 1674,
  },
  {
    label: (
      <span>
        /icons/solid/chess-queen.svg{" "}
        <img style={{ height: 20 }} src={icon1675} />
      </span>
    ),
    value: "/icons/solid/chess-queen.svg",
    key: 1675,
  },
  {
    label: (
      <span>
        /icons/solid/file-code.svg <img style={{ height: 20 }} src={icon1676} />
      </span>
    ),
    value: "/icons/solid/file-code.svg",
    key: 1676,
  },
  {
    label: (
      <span>
        /icons/solid/rupiah-sign.svg{" "}
        <img style={{ height: 20 }} src={icon1677} />
      </span>
    ),
    value: "/icons/solid/rupiah-sign.svg",
    key: 1677,
  },
  {
    label: (
      <span>
        /icons/solid/file-csv.svg <img style={{ height: 20 }} src={icon1678} />
      </span>
    ),
    value: "/icons/solid/file-csv.svg",
    key: 1678,
  },
  {
    label: (
      <span>
        /icons/solid/building-wheat.svg{" "}
        <img style={{ height: 20 }} src={icon1679} />
      </span>
    ),
    value: "/icons/solid/building-wheat.svg",
    key: 1679,
  },
  {
    label: (
      <span>
        /icons/solid/tent-arrow-turn-left.svg{" "}
        <img style={{ height: 20 }} src={icon1680} />
      </span>
    ),
    value: "/icons/solid/tent-arrow-turn-left.svg",
    key: 1680,
  },
  {
    label: (
      <span>
        /icons/solid/road-bridge.svg{" "}
        <img style={{ height: 20 }} src={icon1681} />
      </span>
    ),
    value: "/icons/solid/road-bridge.svg",
    key: 1681,
  },
  {
    label: (
      <span>
        /icons/solid/dice-six.svg <img style={{ height: 20 }} src={icon1682} />
      </span>
    ),
    value: "/icons/solid/dice-six.svg",
    key: 1682,
  },
  {
    label: (
      <span>
        /icons/solid/plate-wheat.svg{" "}
        <img style={{ height: 20 }} src={icon1683} />
      </span>
    ),
    value: "/icons/solid/plate-wheat.svg",
    key: 1683,
  },
  {
    label: (
      <span>
        /icons/solid/house-crack.svg{" "}
        <img style={{ height: 20 }} src={icon1684} />
      </span>
    ),
    value: "/icons/solid/house-crack.svg",
    key: 1684,
  },
  {
    label: (
      <span>
        /icons/solid/cloud.svg <img style={{ height: 20 }} src={icon1685} />
      </span>
    ),
    value: "/icons/solid/cloud.svg",
    key: 1685,
  },
  {
    label: (
      <span>
        /icons/solid/hockey-puck.svg{" "}
        <img style={{ height: 20 }} src={icon1686} />
      </span>
    ),
    value: "/icons/solid/hockey-puck.svg",
    key: 1686,
  },
  {
    label: (
      <span>
        /icons/solid/tty.svg <img style={{ height: 20 }} src={icon1687} />
      </span>
    ),
    value: "/icons/solid/tty.svg",
    key: 1687,
  },
  {
    label: (
      <span>
        /icons/solid/shuffle.svg <img style={{ height: 20 }} src={icon1688} />
      </span>
    ),
    value: "/icons/solid/shuffle.svg",
    key: 1688,
  },
  {
    label: (
      <span>
        /icons/solid/square-nfi.svg{" "}
        <img style={{ height: 20 }} src={icon1689} />
      </span>
    ),
    value: "/icons/solid/square-nfi.svg",
    key: 1689,
  },
  {
    label: (
      <span>
        /icons/solid/building-circle-exclamation.svg{" "}
        <img style={{ height: 20 }} src={icon1690} />
      </span>
    ),
    value: "/icons/solid/building-circle-exclamation.svg",
    key: 1690,
  },
  {
    label: (
      <span>
        /icons/solid/grip-vertical.svg{" "}
        <img style={{ height: 20 }} src={icon1691} />
      </span>
    ),
    value: "/icons/solid/grip-vertical.svg",
    key: 1691,
  },
  {
    label: (
      <span>
        /icons/solid/arrows-spin.svg{" "}
        <img style={{ height: 20 }} src={icon1692} />
      </span>
    ),
    value: "/icons/solid/arrows-spin.svg",
    key: 1692,
  },
  {
    label: (
      <span>
        /icons/solid/futbol.svg <img style={{ height: 20 }} src={icon1693} />
      </span>
    ),
    value: "/icons/solid/futbol.svg",
    key: 1693,
  },
  {
    label: (
      <span>
        /icons/solid/cheese.svg <img style={{ height: 20 }} src={icon1694} />
      </span>
    ),
    value: "/icons/solid/cheese.svg",
    key: 1694,
  },
  {
    label: (
      <span>
        /icons/solid/dice.svg <img style={{ height: 20 }} src={icon1695} />
      </span>
    ),
    value: "/icons/solid/dice.svg",
    key: 1695,
  },
  {
    label: (
      <span>
        /icons/solid/bars.svg <img style={{ height: 20 }} src={icon1696} />
      </span>
    ),
    value: "/icons/solid/bars.svg",
    key: 1696,
  },
  {
    label: (
      <span>
        /icons/solid/mask-face.svg <img style={{ height: 20 }} src={icon1697} />
      </span>
    ),
    value: "/icons/solid/mask-face.svg",
    key: 1697,
  },
  {
    label: (
      <span>
        /icons/solid/bezier-curve.svg{" "}
        <img style={{ height: 20 }} src={icon1698} />
      </span>
    ),
    value: "/icons/solid/bezier-curve.svg",
    key: 1698,
  },
  {
    label: (
      <span>
        /icons/solid/chair.svg <img style={{ height: 20 }} src={icon1699} />
      </span>
    ),
    value: "/icons/solid/chair.svg",
    key: 1699,
  },
  {
    label: (
      <span>
        /icons/solid/dice-d20.svg <img style={{ height: 20 }} src={icon1700} />
      </span>
    ),
    value: "/icons/solid/dice-d20.svg",
    key: 1700,
  },
  {
    label: (
      <span>
        /icons/solid/road-lock.svg <img style={{ height: 20 }} src={icon1701} />
      </span>
    ),
    value: "/icons/solid/road-lock.svg",
    key: 1701,
  },
  {
    label: (
      <span>
        /icons/solid/suitcase.svg <img style={{ height: 20 }} src={icon1702} />
      </span>
    ),
    value: "/icons/solid/suitcase.svg",
    key: 1702,
  },
  {
    label: (
      <span>
        /icons/solid/list-check.svg{" "}
        <img style={{ height: 20 }} src={icon1703} />
      </span>
    ),
    value: "/icons/solid/list-check.svg",
    key: 1703,
  },
  {
    label: (
      <span>
        /icons/solid/motorcycle.svg{" "}
        <img style={{ height: 20 }} src={icon1704} />
      </span>
    ),
    value: "/icons/solid/motorcycle.svg",
    key: 1704,
  },
  {
    label: (
      <span>
        /icons/solid/upload.svg <img style={{ height: 20 }} src={icon1705} />
      </span>
    ),
    value: "/icons/solid/upload.svg",
    key: 1705,
  },
  {
    label: (
      <span>
        /icons/solid/store-slash.svg{" "}
        <img style={{ height: 20 }} src={icon1706} />
      </span>
    ),
    value: "/icons/solid/store-slash.svg",
    key: 1706,
  },
  {
    label: (
      <span>
        /icons/solid/face-flushed.svg{" "}
        <img style={{ height: 20 }} src={icon1707} />
      </span>
    ),
    value: "/icons/solid/face-flushed.svg",
    key: 1707,
  },
  {
    label: (
      <span>
        /icons/solid/mobile-screen-button.svg{" "}
        <img style={{ height: 20 }} src={icon1708} />
      </span>
    ),
    value: "/icons/solid/mobile-screen-button.svg",
    key: 1708,
  },
  {
    label: (
      <span>
        /icons/solid/cart-flatbed.svg{" "}
        <img style={{ height: 20 }} src={icon1709} />
      </span>
    ),
    value: "/icons/solid/cart-flatbed.svg",
    key: 1709,
  },
  {
    label: (
      <span>
        /icons/solid/pause.svg <img style={{ height: 20 }} src={icon1710} />
      </span>
    ),
    value: "/icons/solid/pause.svg",
    key: 1710,
  },
  {
    label: (
      <span>
        /icons/solid/plane-circle-check.svg{" "}
        <img style={{ height: 20 }} src={icon1711} />
      </span>
    ),
    value: "/icons/solid/plane-circle-check.svg",
    key: 1711,
  },
  {
    label: (
      <span>
        /icons/solid/face-kiss.svg <img style={{ height: 20 }} src={icon1712} />
      </span>
    ),
    value: "/icons/solid/face-kiss.svg",
    key: 1712,
  },
  {
    label: (
      <span>
        /icons/solid/forward.svg <img style={{ height: 20 }} src={icon1713} />
      </span>
    ),
    value: "/icons/solid/forward.svg",
    key: 1713,
  },
  {
    label: (
      <span>
        /icons/solid/icons.svg <img style={{ height: 20 }} src={icon1714} />
      </span>
    ),
    value: "/icons/solid/icons.svg",
    key: 1714,
  },
  {
    label: (
      <span>
        /icons/solid/dice-four.svg <img style={{ height: 20 }} src={icon1715} />
      </span>
    ),
    value: "/icons/solid/dice-four.svg",
    key: 1715,
  },
  {
    label: (
      <span>
        /icons/solid/circle-minus.svg{" "}
        <img style={{ height: 20 }} src={icon1716} />
      </span>
    ),
    value: "/icons/solid/circle-minus.svg",
    key: 1716,
  },
  {
    label: (
      <span>
        /icons/solid/bookmark.svg <img style={{ height: 20 }} src={icon1717} />
      </span>
    ),
    value: "/icons/solid/bookmark.svg",
    key: 1717,
  },
  {
    label: (
      <span>
        /icons/solid/braille.svg <img style={{ height: 20 }} src={icon1718} />
      </span>
    ),
    value: "/icons/solid/braille.svg",
    key: 1718,
  },
  {
    label: (
      <span>
        /icons/solid/angles-left.svg{" "}
        <img style={{ height: 20 }} src={icon1719} />
      </span>
    ),
    value: "/icons/solid/angles-left.svg",
    key: 1719,
  },
  {
    label: (
      <span>
        /icons/solid/rocket.svg <img style={{ height: 20 }} src={icon1720} />
      </span>
    ),
    value: "/icons/solid/rocket.svg",
    key: 1720,
  },
  {
    label: (
      <span>
        /icons/solid/icicles.svg <img style={{ height: 20 }} src={icon1721} />
      </span>
    ),
    value: "/icons/solid/icicles.svg",
    key: 1721,
  },
  {
    label: (
      <span>
        /icons/solid/circle-dot.svg{" "}
        <img style={{ height: 20 }} src={icon1722} />
      </span>
    ),
    value: "/icons/solid/circle-dot.svg",
    key: 1722,
  },
  {
    label: (
      <span>
        /icons/solid/blender-phone.svg{" "}
        <img style={{ height: 20 }} src={icon1723} />
      </span>
    ),
    value: "/icons/solid/blender-phone.svg",
    key: 1723,
  },
  {
    label: (
      <span>
        /icons/solid/asterisk.svg <img style={{ height: 20 }} src={icon1724} />
      </span>
    ),
    value: "/icons/solid/asterisk.svg",
    key: 1724,
  },
  {
    label: (
      <span>
        /icons/solid/rotate-right.svg{" "}
        <img style={{ height: 20 }} src={icon1725} />
      </span>
    ),
    value: "/icons/solid/rotate-right.svg",
    key: 1725,
  },
  {
    label: (
      <span>
        /icons/solid/user-check.svg{" "}
        <img style={{ height: 20 }} src={icon1726} />
      </span>
    ),
    value: "/icons/solid/user-check.svg",
    key: 1726,
  },
  {
    label: (
      <span>
        /icons/solid/image-portrait.svg{" "}
        <img style={{ height: 20 }} src={icon1727} />
      </span>
    ),
    value: "/icons/solid/image-portrait.svg",
    key: 1727,
  },
  {
    label: (
      <span>
        /icons/solid/hand-pointer.svg{" "}
        <img style={{ height: 20 }} src={icon1728} />
      </span>
    ),
    value: "/icons/solid/hand-pointer.svg",
    key: 1728,
  },
  {
    label: (
      <span>
        /icons/solid/cloud-sun-rain.svg{" "}
        <img style={{ height: 20 }} src={icon1729} />
      </span>
    ),
    value: "/icons/solid/cloud-sun-rain.svg",
    key: 1729,
  },
  {
    label: (
      <span>
        /icons/solid/rupee-sign.svg{" "}
        <img style={{ height: 20 }} src={icon1730} />
      </span>
    ),
    value: "/icons/solid/rupee-sign.svg",
    key: 1730,
  },
  {
    label: (
      <span>
        /icons/solid/sleigh.svg <img style={{ height: 20 }} src={icon1731} />
      </span>
    ),
    value: "/icons/solid/sleigh.svg",
    key: 1731,
  },
  {
    label: (
      <span>
        /icons/solid/tablet.svg <img style={{ height: 20 }} src={icon1732} />
      </span>
    ),
    value: "/icons/solid/tablet.svg",
    key: 1732,
  },
  {
    label: (
      <span>
        /icons/solid/peseta-sign.svg{" "}
        <img style={{ height: 20 }} src={icon1733} />
      </span>
    ),
    value: "/icons/solid/peseta-sign.svg",
    key: 1733,
  },
  {
    label: (
      <span>
        /icons/solid/book-tanakh.svg{" "}
        <img style={{ height: 20 }} src={icon1734} />
      </span>
    ),
    value: "/icons/solid/book-tanakh.svg",
    key: 1734,
  },
  {
    label: (
      <span>
        /icons/solid/elevator.svg <img style={{ height: 20 }} src={icon1735} />
      </span>
    ),
    value: "/icons/solid/elevator.svg",
    key: 1735,
  },
  {
    label: (
      <span>
        /icons/solid/star-half-stroke.svg{" "}
        <img style={{ height: 20 }} src={icon1736} />
      </span>
    ),
    value: "/icons/solid/star-half-stroke.svg",
    key: 1736,
  },
  {
    label: (
      <span>
        /icons/solid/whiskey-glass.svg{" "}
        <img style={{ height: 20 }} src={icon1737} />
      </span>
    ),
    value: "/icons/solid/whiskey-glass.svg",
    key: 1737,
  },
  {
    label: (
      <span>
        /icons/solid/envelope-circle-check.svg{" "}
        <img style={{ height: 20 }} src={icon1738} />
      </span>
    ),
    value: "/icons/solid/envelope-circle-check.svg",
    key: 1738,
  },
  {
    label: (
      <span>
        /icons/solid/list-ol.svg <img style={{ height: 20 }} src={icon1739} />
      </span>
    ),
    value: "/icons/solid/list-ol.svg",
    key: 1739,
  },
  {
    label: (
      <span>
        /icons/solid/tower-cell.svg{" "}
        <img style={{ height: 20 }} src={icon1740} />
      </span>
    ),
    value: "/icons/solid/tower-cell.svg",
    key: 1740,
  },
  {
    label: (
      <span>
        /icons/solid/screwdriver-wrench.svg{" "}
        <img style={{ height: 20 }} src={icon1741} />
      </span>
    ),
    value: "/icons/solid/screwdriver-wrench.svg",
    key: 1741,
  },
  {
    label: (
      <span>
        /icons/solid/face-grin-beam.svg{" "}
        <img style={{ height: 20 }} src={icon1742} />
      </span>
    ),
    value: "/icons/solid/face-grin-beam.svg",
    key: 1742,
  },
  {
    label: (
      <span>
        /icons/solid/bars-staggered.svg{" "}
        <img style={{ height: 20 }} src={icon1743} />
      </span>
    ),
    value: "/icons/solid/bars-staggered.svg",
    key: 1743,
  },
  {
    label: (
      <span>
        /icons/solid/diamond-turn-right.svg{" "}
        <img style={{ height: 20 }} src={icon1744} />
      </span>
    ),
    value: "/icons/solid/diamond-turn-right.svg",
    key: 1744,
  },
  {
    label: (
      <span>
        /icons/solid/square-phone.svg{" "}
        <img style={{ height: 20 }} src={icon1745} />
      </span>
    ),
    value: "/icons/solid/square-phone.svg",
    key: 1745,
  },
  {
    label: (
      <span>
        /icons/solid/spell-check.svg{" "}
        <img style={{ height: 20 }} src={icon1746} />
      </span>
    ),
    value: "/icons/solid/spell-check.svg",
    key: 1746,
  },
  {
    label: (
      <span>
        /icons/solid/face-grin-wide.svg{" "}
        <img style={{ height: 20 }} src={icon1747} />
      </span>
    ),
    value: "/icons/solid/face-grin-wide.svg",
    key: 1747,
  },
  {
    label: (
      <span>
        /icons/solid/laptop-medical.svg{" "}
        <img style={{ height: 20 }} src={icon1748} />
      </span>
    ),
    value: "/icons/solid/laptop-medical.svg",
    key: 1748,
  },
  {
    label: (
      <span>
        /icons/solid/shuttle-space.svg{" "}
        <img style={{ height: 20 }} src={icon1749} />
      </span>
    ),
    value: "/icons/solid/shuttle-space.svg",
    key: 1749,
  },
  {
    label: (
      <span>
        /icons/solid/ruble-sign.svg{" "}
        <img style={{ height: 20 }} src={icon1750} />
      </span>
    ),
    value: "/icons/solid/ruble-sign.svg",
    key: 1750,
  },
  {
    label: (
      <span>
        /icons/solid/circle-h.svg <img style={{ height: 20 }} src={icon1751} />
      </span>
    ),
    value: "/icons/solid/circle-h.svg",
    key: 1751,
  },
  {
    label: (
      <span>
        /icons/solid/lock-open.svg <img style={{ height: 20 }} src={icon1752} />
      </span>
    ),
    value: "/icons/solid/lock-open.svg",
    key: 1752,
  },
  {
    label: (
      <span>
        /icons/solid/atom.svg <img style={{ height: 20 }} src={icon1753} />
      </span>
    ),
    value: "/icons/solid/atom.svg",
    key: 1753,
  },
  {
    label: (
      <span>
        /icons/solid/cat.svg <img style={{ height: 20 }} src={icon1754} />
      </span>
    ),
    value: "/icons/solid/cat.svg",
    key: 1754,
  },
  {
    label: (
      <span>
        /icons/solid/yin-yang.svg <img style={{ height: 20 }} src={icon1755} />
      </span>
    ),
    value: "/icons/solid/yin-yang.svg",
    key: 1755,
  },
  {
    label: (
      <span>
        /icons/solid/train-subway.svg{" "}
        <img style={{ height: 20 }} src={icon1756} />
      </span>
    ),
    value: "/icons/solid/train-subway.svg",
    key: 1756,
  },
  {
    label: (
      <span>
        /icons/solid/ice-cream.svg <img style={{ height: 20 }} src={icon1757} />
      </span>
    ),
    value: "/icons/solid/ice-cream.svg",
    key: 1757,
  },
  {
    label: (
      <span>
        /icons/solid/ring.svg <img style={{ height: 20 }} src={icon1758} />
      </span>
    ),
    value: "/icons/solid/ring.svg",
    key: 1758,
  },
  {
    label: (
      <span>
        /icons/solid/building-columns.svg{" "}
        <img style={{ height: 20 }} src={icon1759} />
      </span>
    ),
    value: "/icons/solid/building-columns.svg",
    key: 1759,
  },
  {
    label: (
      <span>
        /icons/solid/face-grin-tears.svg{" "}
        <img style={{ height: 20 }} src={icon1760} />
      </span>
    ),
    value: "/icons/solid/face-grin-tears.svg",
    key: 1760,
  },
  {
    label: (
      <span>
        /icons/solid/snowplow.svg <img style={{ height: 20 }} src={icon1761} />
      </span>
    ),
    value: "/icons/solid/snowplow.svg",
    key: 1761,
  },
  {
    label: (
      <span>
        /icons/solid/face-laugh-beam.svg{" "}
        <img style={{ height: 20 }} src={icon1762} />
      </span>
    ),
    value: "/icons/solid/face-laugh-beam.svg",
    key: 1762,
  },
  {
    label: (
      <span>
        /icons/solid/angle-left.svg{" "}
        <img style={{ height: 20 }} src={icon1763} />
      </span>
    ),
    value: "/icons/solid/angle-left.svg",
    key: 1763,
  },
  {
    label: (
      <span>
        /icons/solid/face-kiss-beam.svg{" "}
        <img style={{ height: 20 }} src={icon1764} />
      </span>
    ),
    value: "/icons/solid/face-kiss-beam.svg",
    key: 1764,
  },
  {
    label: (
      <span>
        /icons/solid/face-angry.svg{" "}
        <img style={{ height: 20 }} src={icon1765} />
      </span>
    ),
    value: "/icons/solid/face-angry.svg",
    key: 1765,
  },
  {
    label: (
      <span>
        /icons/solid/arrows-down-to-people.svg{" "}
        <img style={{ height: 20 }} src={icon1766} />
      </span>
    ),
    value: "/icons/solid/arrows-down-to-people.svg",
    key: 1766,
  },
  {
    label: (
      <span>
        /icons/solid/parachute-box.svg{" "}
        <img style={{ height: 20 }} src={icon1767} />
      </span>
    ),
    value: "/icons/solid/parachute-box.svg",
    key: 1767,
  },
  {
    label: (
      <span>
        /icons/solid/spa.svg <img style={{ height: 20 }} src={icon1768} />
      </span>
    ),
    value: "/icons/solid/spa.svg",
    key: 1768,
  },
  {
    label: (
      <span>
        /icons/solid/sort-down.svg <img style={{ height: 20 }} src={icon1769} />
      </span>
    ),
    value: "/icons/solid/sort-down.svg",
    key: 1769,
  },
  {
    label: (
      <span>
        /icons/solid/calculator.svg{" "}
        <img style={{ height: 20 }} src={icon1770} />
      </span>
    ),
    value: "/icons/solid/calculator.svg",
    key: 1770,
  },
  {
    label: (
      <span>
        /icons/solid/window-restore.svg{" "}
        <img style={{ height: 20 }} src={icon1771} />
      </span>
    ),
    value: "/icons/solid/window-restore.svg",
    key: 1771,
  },
  {
    label: (
      <span>
        /icons/solid/user-slash.svg{" "}
        <img style={{ height: 20 }} src={icon1772} />
      </span>
    ),
    value: "/icons/solid/user-slash.svg",
    key: 1772,
  },
  {
    label: (
      <span>
        /icons/solid/circle-chevron-left.svg{" "}
        <img style={{ height: 20 }} src={icon1773} />
      </span>
    ),
    value: "/icons/solid/circle-chevron-left.svg",
    key: 1773,
  },
  {
    label: (
      <span>
        /icons/solid/cart-flatbed-suitcase.svg{" "}
        <img style={{ height: 20 }} src={icon1774} />
      </span>
    ),
    value: "/icons/solid/cart-flatbed-suitcase.svg",
    key: 1774,
  },
  {
    label: (
      <span>
        /icons/solid/screwdriver.svg{" "}
        <img style={{ height: 20 }} src={icon1775} />
      </span>
    ),
    value: "/icons/solid/screwdriver.svg",
    key: 1775,
  },
  {
    label: (
      <span>
        /icons/solid/circle-pause.svg{" "}
        <img style={{ height: 20 }} src={icon1776} />
      </span>
    ),
    value: "/icons/solid/circle-pause.svg",
    key: 1776,
  },
  {
    label: (
      <span>
        /icons/solid/money-bill-wave.svg{" "}
        <img style={{ height: 20 }} src={icon1777} />
      </span>
    ),
    value: "/icons/solid/money-bill-wave.svg",
    key: 1777,
  },
  {
    label: (
      <span>
        /icons/solid/hand-middle-finger.svg{" "}
        <img style={{ height: 20 }} src={icon1778} />
      </span>
    ),
    value: "/icons/solid/hand-middle-finger.svg",
    key: 1778,
  },
  {
    label: (
      <span>
        /icons/solid/chess-bishop.svg{" "}
        <img style={{ height: 20 }} src={icon1779} />
      </span>
    ),
    value: "/icons/solid/chess-bishop.svg",
    key: 1779,
  },
  {
    label: (
      <span>
        /icons/solid/dharmachakra.svg{" "}
        <img style={{ height: 20 }} src={icon1780} />
      </span>
    ),
    value: "/icons/solid/dharmachakra.svg",
    key: 1780,
  },
  {
    label: (
      <span>
        /icons/solid/bed-pulse.svg <img style={{ height: 20 }} src={icon1781} />
      </span>
    ),
    value: "/icons/solid/bed-pulse.svg",
    key: 1781,
  },
  {
    label: (
      <span>
        /icons/solid/bore-hole.svg <img style={{ height: 20 }} src={icon1782} />
      </span>
    ),
    value: "/icons/solid/bore-hole.svg",
    key: 1782,
  },
  {
    label: (
      <span>
        /icons/solid/person-walking.svg{" "}
        <img style={{ height: 20 }} src={icon1783} />
      </span>
    ),
    value: "/icons/solid/person-walking.svg",
    key: 1783,
  },
  {
    label: (
      <span>
        /icons/solid/charging-station.svg{" "}
        <img style={{ height: 20 }} src={icon1784} />
      </span>
    ),
    value: "/icons/solid/charging-station.svg",
    key: 1784,
  },
  {
    label: (
      <span>
        /icons/solid/circle-down.svg{" "}
        <img style={{ height: 20 }} src={icon1785} />
      </span>
    ),
    value: "/icons/solid/circle-down.svg",
    key: 1785,
  },
  {
    label: (
      <span>
        /icons/solid/building.svg <img style={{ height: 20 }} src={icon1786} />
      </span>
    ),
    value: "/icons/solid/building.svg",
    key: 1786,
  },
  {
    label: (
      <span>
        /icons/solid/wheat-awn.svg <img style={{ height: 20 }} src={icon1787} />
      </span>
    ),
    value: "/icons/solid/wheat-awn.svg",
    key: 1787,
  },
  {
    label: (
      <span>
        /icons/solid/house-medical-circle-exclamation.svg{" "}
        <img style={{ height: 20 }} src={icon1788} />
      </span>
    ),
    value: "/icons/solid/house-medical-circle-exclamation.svg",
    key: 1788,
  },
  {
    label: (
      <span>
        /icons/solid/binoculars.svg{" "}
        <img style={{ height: 20 }} src={icon1789} />
      </span>
    ),
    value: "/icons/solid/binoculars.svg",
    key: 1789,
  },
  {
    label: (
      <span>
        /icons/solid/genderless.svg{" "}
        <img style={{ height: 20 }} src={icon1790} />
      </span>
    ),
    value: "/icons/solid/genderless.svg",
    key: 1790,
  },
  {
    label: (
      <span>
        /icons/solid/gavel.svg <img style={{ height: 20 }} src={icon1791} />
      </span>
    ),
    value: "/icons/solid/gavel.svg",
    key: 1791,
  },
  {
    label: (
      <span>
        /icons/solid/folder.svg <img style={{ height: 20 }} src={icon1792} />
      </span>
    ),
    value: "/icons/solid/folder.svg",
    key: 1792,
  },
  {
    label: (
      <span>
        /icons/solid/down-long.svg <img style={{ height: 20 }} src={icon1793} />
      </span>
    ),
    value: "/icons/solid/down-long.svg",
    key: 1793,
  },
  {
    label: (
      <span>
        /icons/solid/school-circle-exclamation.svg{" "}
        <img style={{ height: 20 }} src={icon1794} />
      </span>
    ),
    value: "/icons/solid/school-circle-exclamation.svg",
    key: 1794,
  },
  {
    label: (
      <span>
        /icons/solid/person-walking-luggage.svg{" "}
        <img style={{ height: 20 }} src={icon1795} />
      </span>
    ),
    value: "/icons/solid/person-walking-luggage.svg",
    key: 1795,
  },
  {
    label: (
      <span>
        /icons/solid/pump-medical.svg{" "}
        <img style={{ height: 20 }} src={icon1796} />
      </span>
    ),
    value: "/icons/solid/pump-medical.svg",
    key: 1796,
  },
  {
    label: (
      <span>
        /icons/solid/hand-dots.svg <img style={{ height: 20 }} src={icon1797} />
      </span>
    ),
    value: "/icons/solid/hand-dots.svg",
    key: 1797,
  },
  {
    label: (
      <span>
        /icons/solid/users.svg <img style={{ height: 20 }} src={icon1798} />
      </span>
    ),
    value: "/icons/solid/users.svg",
    key: 1798,
  },
  {
    label: (
      <span>
        /icons/solid/chart-simple.svg{" "}
        <img style={{ height: 20 }} src={icon1799} />
      </span>
    ),
    value: "/icons/solid/chart-simple.svg",
    key: 1799,
  },
  {
    label: (
      <span>
        /icons/solid/hill-rockslide.svg{" "}
        <img style={{ height: 20 }} src={icon1800} />
      </span>
    ),
    value: "/icons/solid/hill-rockslide.svg",
    key: 1800,
  },
  {
    label: (
      <span>
        /icons/solid/hands.svg <img style={{ height: 20 }} src={icon1801} />
      </span>
    ),
    value: "/icons/solid/hands.svg",
    key: 1801,
  },
  {
    label: (
      <span>
        /icons/solid/fingerprint.svg{" "}
        <img style={{ height: 20 }} src={icon1802} />
      </span>
    ),
    value: "/icons/solid/fingerprint.svg",
    key: 1802,
  },
  {
    label: (
      <span>
        /icons/solid/fill.svg <img style={{ height: 20 }} src={icon1803} />
      </span>
    ),
    value: "/icons/solid/fill.svg",
    key: 1803,
  },
  {
    label: (
      <span>
        /icons/solid/hanukiah.svg <img style={{ height: 20 }} src={icon1804} />
      </span>
    ),
    value: "/icons/solid/hanukiah.svg",
    key: 1804,
  },
  {
    label: (
      <span>
        /icons/solid/synagogue.svg <img style={{ height: 20 }} src={icon1805} />
      </span>
    ),
    value: "/icons/solid/synagogue.svg",
    key: 1805,
  },
  {
    label: (
      <span>
        /icons/solid/file-pdf.svg <img style={{ height: 20 }} src={icon1806} />
      </span>
    ),
    value: "/icons/solid/file-pdf.svg",
    key: 1806,
  },
  {
    label: (
      <span>
        /icons/solid/arrow-up-1-9.svg{" "}
        <img style={{ height: 20 }} src={icon1807} />
      </span>
    ),
    value: "/icons/solid/arrow-up-1-9.svg",
    key: 1807,
  },
  {
    label: (
      <span>
        /icons/solid/gamepad.svg <img style={{ height: 20 }} src={icon1808} />
      </span>
    ),
    value: "/icons/solid/gamepad.svg",
    key: 1808,
  },
  {
    label: (
      <span>
        /icons/solid/clipboard-check.svg{" "}
        <img style={{ height: 20 }} src={icon1809} />
      </span>
    ),
    value: "/icons/solid/clipboard-check.svg",
    key: 1809,
  },
  {
    label: (
      <span>
        /icons/solid/minus.svg <img style={{ height: 20 }} src={icon1810} />
      </span>
    ),
    value: "/icons/solid/minus.svg",
    key: 1810,
  },
  {
    label: (
      <span>
        /icons/solid/heart-pulse.svg{" "}
        <img style={{ height: 20 }} src={icon1811} />
      </span>
    ),
    value: "/icons/solid/heart-pulse.svg",
    key: 1811,
  },
  {
    label: (
      <span>
        /icons/solid/arrows-to-dot.svg{" "}
        <img style={{ height: 20 }} src={icon1812} />
      </span>
    ),
    value: "/icons/solid/arrows-to-dot.svg",
    key: 1812,
  },
  {
    label: (
      <span>
        /icons/solid/up-down-left-right.svg{" "}
        <img style={{ height: 20 }} src={icon1813} />
      </span>
    ),
    value: "/icons/solid/up-down-left-right.svg",
    key: 1813,
  },
  {
    label: (
      <span>
        /icons/solid/ear-deaf.svg <img style={{ height: 20 }} src={icon1814} />
      </span>
    ),
    value: "/icons/solid/ear-deaf.svg",
    key: 1814,
  },
  {
    label: (
      <span>
        /icons/solid/chevron-left.svg{" "}
        <img style={{ height: 20 }} src={icon1815} />
      </span>
    ),
    value: "/icons/solid/chevron-left.svg",
    key: 1815,
  },
  {
    label: (
      <span>
        /icons/solid/hot-tub-person.svg{" "}
        <img style={{ height: 20 }} src={icon1816} />
      </span>
    ),
    value: "/icons/solid/hot-tub-person.svg",
    key: 1816,
  },
  {
    label: (
      <span>
        /icons/solid/mattress-pillow.svg{" "}
        <img style={{ height: 20 }} src={icon1817} />
      </span>
    ),
    value: "/icons/solid/mattress-pillow.svg",
    key: 1817,
  },
  {
    label: (
      <span>
        /icons/solid/film.svg <img style={{ height: 20 }} src={icon1818} />
      </span>
    ),
    value: "/icons/solid/film.svg",
    key: 1818,
  },
  {
    label: (
      <span>
        /icons/solid/print.svg <img style={{ height: 20 }} src={icon1819} />
      </span>
    ),
    value: "/icons/solid/print.svg",
    key: 1819,
  },
  {
    label: (
      <span>
        /icons/solid/moon.svg <img style={{ height: 20 }} src={icon1820} />
      </span>
    ),
    value: "/icons/solid/moon.svg",
    key: 1820,
  },
  {
    label: (
      <span>
        /icons/solid/stroopwafel.svg{" "}
        <img style={{ height: 20 }} src={icon1821} />
      </span>
    ),
    value: "/icons/solid/stroopwafel.svg",
    key: 1821,
  },
  {
    label: (
      <span>
        /icons/solid/walkie-talkie.svg{" "}
        <img style={{ height: 20 }} src={icon1822} />
      </span>
    ),
    value: "/icons/solid/walkie-talkie.svg",
    key: 1822,
  },
  {
    label: (
      <span>
        /icons/solid/exclamation.svg{" "}
        <img style={{ height: 20 }} src={icon1823} />
      </span>
    ),
    value: "/icons/solid/exclamation.svg",
    key: 1823,
  },
  {
    label: (
      <span>
        /icons/solid/bell-concierge.svg{" "}
        <img style={{ height: 20 }} src={icon1824} />
      </span>
    ),
    value: "/icons/solid/bell-concierge.svg",
    key: 1824,
  },
  {
    label: (
      <span>
        /icons/solid/capsules.svg <img style={{ height: 20 }} src={icon1825} />
      </span>
    ),
    value: "/icons/solid/capsules.svg",
    key: 1825,
  },
  {
    label: (
      <span>
        /icons/solid/vial-circle-check.svg{" "}
        <img style={{ height: 20 }} src={icon1826} />
      </span>
    ),
    value: "/icons/solid/vial-circle-check.svg",
    key: 1826,
  },
  {
    label: (
      <span>
        /icons/solid/person-burst.svg{" "}
        <img style={{ height: 20 }} src={icon1827} />
      </span>
    ),
    value: "/icons/solid/person-burst.svg",
    key: 1827,
  },
  {
    label: (
      <span>
        /icons/solid/school-lock.svg{" "}
        <img style={{ height: 20 }} src={icon1828} />
      </span>
    ),
    value: "/icons/solid/school-lock.svg",
    key: 1828,
  },
  {
    label: (
      <span>
        /icons/solid/arrow-pointer.svg{" "}
        <img style={{ height: 20 }} src={icon1829} />
      </span>
    ),
    value: "/icons/solid/arrow-pointer.svg",
    key: 1829,
  },
  {
    label: (
      <span>
        /icons/solid/border-none.svg{" "}
        <img style={{ height: 20 }} src={icon1830} />
      </span>
    ),
    value: "/icons/solid/border-none.svg",
    key: 1830,
  },
  {
    label: (
      <span>
        /icons/solid/book-bible.svg{" "}
        <img style={{ height: 20 }} src={icon1831} />
      </span>
    ),
    value: "/icons/solid/book-bible.svg",
    key: 1831,
  },
  {
    label: (
      <span>
        /icons/solid/user-shield.svg{" "}
        <img style={{ height: 20 }} src={icon1832} />
      </span>
    ),
    value: "/icons/solid/user-shield.svg",
    key: 1832,
  },
  {
    label: (
      <span>
        /icons/solid/house-signal.svg{" "}
        <img style={{ height: 20 }} src={icon1833} />
      </span>
    ),
    value: "/icons/solid/house-signal.svg",
    key: 1833,
  },
  {
    label: (
      <span>
        /icons/solid/face-laugh-squint.svg{" "}
        <img style={{ height: 20 }} src={icon1834} />
      </span>
    ),
    value: "/icons/solid/face-laugh-squint.svg",
    key: 1834,
  },
  {
    label: (
      <span>
        /icons/solid/left-right.svg{" "}
        <img style={{ height: 20 }} src={icon1835} />
      </span>
    ),
    value: "/icons/solid/left-right.svg",
    key: 1835,
  },
  {
    label: (
      <span>
        /icons/solid/anchor-circle-xmark.svg{" "}
        <img style={{ height: 20 }} src={icon1836} />
      </span>
    ),
    value: "/icons/solid/anchor-circle-xmark.svg",
    key: 1836,
  },
  {
    label: (
      <span>
        /icons/solid/ribbon.svg <img style={{ height: 20 }} src={icon1837} />
      </span>
    ),
    value: "/icons/solid/ribbon.svg",
    key: 1837,
  },
  {
    label: (
      <span>
        /icons/solid/arrow-down-wide-short.svg{" "}
        <img style={{ height: 20 }} src={icon1838} />
      </span>
    ),
    value: "/icons/solid/arrow-down-wide-short.svg",
    key: 1838,
  },
  {
    label: (
      <span>
        /icons/solid/swatchbook.svg{" "}
        <img style={{ height: 20 }} src={icon1839} />
      </span>
    ),
    value: "/icons/solid/swatchbook.svg",
    key: 1839,
  },
  {
    label: (
      <span>
        /icons/solid/apple-whole.svg{" "}
        <img style={{ height: 20 }} src={icon1840} />
      </span>
    ),
    value: "/icons/solid/apple-whole.svg",
    key: 1840,
  },
  {
    label: (
      <span>
        /icons/solid/stamp.svg <img style={{ height: 20 }} src={icon1841} />
      </span>
    ),
    value: "/icons/solid/stamp.svg",
    key: 1841,
  },
  {
    label: (
      <span>
        /icons/solid/landmark-dome.svg{" "}
        <img style={{ height: 20 }} src={icon1842} />
      </span>
    ),
    value: "/icons/solid/landmark-dome.svg",
    key: 1842,
  },
  {
    label: (
      <span>
        /icons/solid/square-caret-left.svg{" "}
        <img style={{ height: 20 }} src={icon1843} />
      </span>
    ),
    value: "/icons/solid/square-caret-left.svg",
    key: 1843,
  },
  {
    label: (
      <span>
        /icons/solid/sort-up.svg <img style={{ height: 20 }} src={icon1844} />
      </span>
    ),
    value: "/icons/solid/sort-up.svg",
    key: 1844,
  },
  {
    label: (
      <span>
        /icons/solid/file-waveform.svg{" "}
        <img style={{ height: 20 }} src={icon1845} />
      </span>
    ),
    value: "/icons/solid/file-waveform.svg",
    key: 1845,
  },
  {
    label: (
      <span>
        /icons/solid/calendar-days.svg{" "}
        <img style={{ height: 20 }} src={icon1846} />
      </span>
    ),
    value: "/icons/solid/calendar-days.svg",
    key: 1846,
  },
  {
    label: (
      <span>
        /icons/solid/align-left.svg{" "}
        <img style={{ height: 20 }} src={icon1847} />
      </span>
    ),
    value: "/icons/solid/align-left.svg",
    key: 1847,
  },
  {
    label: (
      <span>
        /icons/solid/temperature-low.svg{" "}
        <img style={{ height: 20 }} src={icon1848} />
      </span>
    ),
    value: "/icons/solid/temperature-low.svg",
    key: 1848,
  },
  {
    label: (
      <span>
        /icons/solid/person-walking-arrow-right.svg{" "}
        <img style={{ height: 20 }} src={icon1849} />
      </span>
    ),
    value: "/icons/solid/person-walking-arrow-right.svg",
    key: 1849,
  },
  {
    label: (
      <span>
        /icons/solid/heart.svg <img style={{ height: 20 }} src={icon1850} />
      </span>
    ),
    value: "/icons/solid/heart.svg",
    key: 1850,
  },
  {
    label: (
      <span>
        /icons/solid/arrow-trend-down.svg{" "}
        <img style={{ height: 20 }} src={icon1851} />
      </span>
    ),
    value: "/icons/solid/arrow-trend-down.svg",
    key: 1851,
  },
  {
    label: (
      <span>
        /icons/solid/font.svg <img style={{ height: 20 }} src={icon1852} />
      </span>
    ),
    value: "/icons/solid/font.svg",
    key: 1852,
  },
  {
    label: (
      <span>
        /icons/solid/arrow-up-9-1.svg{" "}
        <img style={{ height: 20 }} src={icon1853} />
      </span>
    ),
    value: "/icons/solid/arrow-up-9-1.svg",
    key: 1853,
  },
  {
    label: (
      <span>
        /icons/regular/comment-dots.svg{" "}
        <img style={{ height: 20 }} src={icon1854} />
      </span>
    ),
    value: "/icons/regular/comment-dots.svg",
    key: 1854,
  },
  {
    label: (
      <span>
        /icons/regular/file-zipper.svg{" "}
        <img style={{ height: 20 }} src={icon1855} />
      </span>
    ),
    value: "/icons/regular/file-zipper.svg",
    key: 1855,
  },
  {
    label: (
      <span>
        /icons/regular/hand.svg <img style={{ height: 20 }} src={icon1856} />
      </span>
    ),
    value: "/icons/regular/hand.svg",
    key: 1856,
  },
  {
    label: (
      <span>
        /icons/regular/clone.svg <img style={{ height: 20 }} src={icon1857} />
      </span>
    ),
    value: "/icons/regular/clone.svg",
    key: 1857,
  },
  {
    label: (
      <span>
        /icons/regular/file-excel.svg{" "}
        <img style={{ height: 20 }} src={icon1858} />
      </span>
    ),
    value: "/icons/regular/file-excel.svg",
    key: 1858,
  },
  {
    label: (
      <span>
        /icons/regular/trash-can.svg{" "}
        <img style={{ height: 20 }} src={icon1859} />
      </span>
    ),
    value: "/icons/regular/trash-can.svg",
    key: 1859,
  },
  {
    label: (
      <span>
        /icons/regular/face-grin-tongue-wink.svg{" "}
        <img style={{ height: 20 }} src={icon1860} />
      </span>
    ),
    value: "/icons/regular/face-grin-tongue-wink.svg",
    key: 1860,
  },
  {
    label: (
      <span>
        /icons/regular/hourglass.svg{" "}
        <img style={{ height: 20 }} src={icon1861} />
      </span>
    ),
    value: "/icons/regular/hourglass.svg",
    key: 1861,
  },
  {
    label: (
      <span>
        /icons/regular/chess-rook.svg{" "}
        <img style={{ height: 20 }} src={icon1862} />
      </span>
    ),
    value: "/icons/regular/chess-rook.svg",
    key: 1862,
  },
  {
    label: (
      <span>
        /icons/regular/face-grimace.svg{" "}
        <img style={{ height: 20 }} src={icon1863} />
      </span>
    ),
    value: "/icons/regular/face-grimace.svg",
    key: 1863,
  },
  {
    label: (
      <span>
        /icons/regular/chess-knight.svg{" "}
        <img style={{ height: 20 }} src={icon1864} />
      </span>
    ),
    value: "/icons/regular/chess-knight.svg",
    key: 1864,
  },
  {
    label: (
      <span>
        /icons/regular/user.svg <img style={{ height: 20 }} src={icon1865} />
      </span>
    ),
    value: "/icons/regular/user.svg",
    key: 1865,
  },
  {
    label: (
      <span>
        /icons/regular/id-badge.svg{" "}
        <img style={{ height: 20 }} src={icon1866} />
      </span>
    ),
    value: "/icons/regular/id-badge.svg",
    key: 1866,
  },
  {
    label: (
      <span>
        /icons/regular/circle.svg <img style={{ height: 20 }} src={icon1867} />
      </span>
    ),
    value: "/icons/regular/circle.svg",
    key: 1867,
  },
  {
    label: (
      <span>
        /icons/regular/copyright.svg{" "}
        <img style={{ height: 20 }} src={icon1868} />
      </span>
    ),
    value: "/icons/regular/copyright.svg",
    key: 1868,
  },
  {
    label: (
      <span>
        /icons/regular/square-caret-down.svg{" "}
        <img style={{ height: 20 }} src={icon1869} />
      </span>
    ),
    value: "/icons/regular/square-caret-down.svg",
    key: 1869,
  },
  {
    label: (
      <span>
        /icons/regular/hand-point-right.svg{" "}
        <img style={{ height: 20 }} src={icon1870} />
      </span>
    ),
    value: "/icons/regular/hand-point-right.svg",
    key: 1870,
  },
  {
    label: (
      <span>
        /icons/regular/face-laugh.svg{" "}
        <img style={{ height: 20 }} src={icon1871} />
      </span>
    ),
    value: "/icons/regular/face-laugh.svg",
    key: 1871,
  },
  {
    label: (
      <span>
        /icons/regular/money-bill-1.svg{" "}
        <img style={{ height: 20 }} src={icon1872} />
      </span>
    ),
    value: "/icons/regular/money-bill-1.svg",
    key: 1872,
  },
  {
    label: (
      <span>
        /icons/regular/file-word.svg{" "}
        <img style={{ height: 20 }} src={icon1873} />
      </span>
    ),
    value: "/icons/regular/file-word.svg",
    key: 1873,
  },
  {
    label: (
      <span>
        /icons/regular/face-grin-tongue.svg{" "}
        <img style={{ height: 20 }} src={icon1874} />
      </span>
    ),
    value: "/icons/regular/face-grin-tongue.svg",
    key: 1874,
  },
  {
    label: (
      <span>
        /icons/regular/object-group.svg{" "}
        <img style={{ height: 20 }} src={icon1875} />
      </span>
    ),
    value: "/icons/regular/object-group.svg",
    key: 1875,
  },
  {
    label: (
      <span>
        /icons/regular/circle-left.svg{" "}
        <img style={{ height: 20 }} src={icon1876} />
      </span>
    ),
    value: "/icons/regular/circle-left.svg",
    key: 1876,
  },
  {
    label: (
      <span>
        /icons/regular/file-audio.svg{" "}
        <img style={{ height: 20 }} src={icon1877} />
      </span>
    ),
    value: "/icons/regular/file-audio.svg",
    key: 1877,
  },
  {
    label: (
      <span>
        /icons/regular/face-meh.svg{" "}
        <img style={{ height: 20 }} src={icon1878} />
      </span>
    ),
    value: "/icons/regular/face-meh.svg",
    key: 1878,
  },
  {
    label: (
      <span>
        /icons/regular/map.svg <img style={{ height: 20 }} src={icon1879} />
      </span>
    ),
    value: "/icons/regular/map.svg",
    key: 1879,
  },
  {
    label: (
      <span>
        /icons/regular/comments.svg{" "}
        <img style={{ height: 20 }} src={icon1880} />
      </span>
    ),
    value: "/icons/regular/comments.svg",
    key: 1880,
  },
  {
    label: (
      <span>
        /icons/regular/square-caret-right.svg{" "}
        <img style={{ height: 20 }} src={icon1881} />
      </span>
    ),
    value: "/icons/regular/square-caret-right.svg",
    key: 1881,
  },
  {
    label: (
      <span>
        /icons/regular/file.svg <img style={{ height: 20 }} src={icon1882} />
      </span>
    ),
    value: "/icons/regular/file.svg",
    key: 1882,
  },
  {
    label: (
      <span>
        /icons/regular/share-from-square.svg{" "}
        <img style={{ height: 20 }} src={icon1883} />
      </span>
    ),
    value: "/icons/regular/share-from-square.svg",
    key: 1883,
  },
  {
    label: (
      <span>
        /icons/regular/pen-to-square.svg{" "}
        <img style={{ height: 20 }} src={icon1884} />
      </span>
    ),
    value: "/icons/regular/pen-to-square.svg",
    key: 1884,
  },
  {
    label: (
      <span>
        /icons/regular/square-full.svg{" "}
        <img style={{ height: 20 }} src={icon1885} />
      </span>
    ),
    value: "/icons/regular/square-full.svg",
    key: 1885,
  },
  {
    label: (
      <span>
        /icons/regular/file-video.svg{" "}
        <img style={{ height: 20 }} src={icon1886} />
      </span>
    ),
    value: "/icons/regular/file-video.svg",
    key: 1886,
  },
  {
    label: (
      <span>
        /icons/regular/folder-open.svg{" "}
        <img style={{ height: 20 }} src={icon1887} />
      </span>
    ),
    value: "/icons/regular/folder-open.svg",
    key: 1887,
  },
  {
    label: (
      <span>
        /icons/regular/keyboard.svg{" "}
        <img style={{ height: 20 }} src={icon1888} />
      </span>
    ),
    value: "/icons/regular/keyboard.svg",
    key: 1888,
  },
  {
    label: (
      <span>
        /icons/regular/face-grin-squint-tears.svg{" "}
        <img style={{ height: 20 }} src={icon1889} />
      </span>
    ),
    value: "/icons/regular/face-grin-squint-tears.svg",
    key: 1889,
  },
  {
    label: (
      <span>
        /icons/regular/face-laugh-wink.svg{" "}
        <img style={{ height: 20 }} src={icon1890} />
      </span>
    ),
    value: "/icons/regular/face-laugh-wink.svg",
    key: 1890,
  },
  {
    label: (
      <span>
        /icons/regular/face-grin-wink.svg{" "}
        <img style={{ height: 20 }} src={icon1891} />
      </span>
    ),
    value: "/icons/regular/face-grin-wink.svg",
    key: 1891,
  },
  {
    label: (
      <span>
        /icons/regular/face-rolling-eyes.svg{" "}
        <img style={{ height: 20 }} src={icon1892} />
      </span>
    ),
    value: "/icons/regular/face-rolling-eyes.svg",
    key: 1892,
  },
  {
    label: (
      <span>
        /icons/regular/clipboard.svg{" "}
        <img style={{ height: 20 }} src={icon1893} />
      </span>
    ),
    value: "/icons/regular/clipboard.svg",
    key: 1893,
  },
  {
    label: (
      <span>
        /icons/regular/circle-question.svg{" "}
        <img style={{ height: 20 }} src={icon1894} />
      </span>
    ),
    value: "/icons/regular/circle-question.svg",
    key: 1894,
  },
  {
    label: (
      <span>
        /icons/regular/life-ring.svg{" "}
        <img style={{ height: 20 }} src={icon1895} />
      </span>
    ),
    value: "/icons/regular/life-ring.svg",
    key: 1895,
  },
  {
    label: (
      <span>
        /icons/regular/calendar-plus.svg{" "}
        <img style={{ height: 20 }} src={icon1896} />
      </span>
    ),
    value: "/icons/regular/calendar-plus.svg",
    key: 1896,
  },
  {
    label: (
      <span>
        /icons/regular/circle-play.svg{" "}
        <img style={{ height: 20 }} src={icon1897} />
      </span>
    ),
    value: "/icons/regular/circle-play.svg",
    key: 1897,
  },
  {
    label: (
      <span>
        /icons/regular/notdef.svg <img style={{ height: 20 }} src={icon1898} />
      </span>
    ),
    value: "/icons/regular/notdef.svg",
    key: 1898,
  },
  {
    label: (
      <span>
        /icons/regular/calendar-check.svg{" "}
        <img style={{ height: 20 }} src={icon1899} />
      </span>
    ),
    value: "/icons/regular/calendar-check.svg",
    key: 1899,
  },
  {
    label: (
      <span>
        /icons/regular/thumbs-down.svg{" "}
        <img style={{ height: 20 }} src={icon1900} />
      </span>
    ),
    value: "/icons/regular/thumbs-down.svg",
    key: 1900,
  },
  {
    label: (
      <span>
        /icons/regular/envelope.svg{" "}
        <img style={{ height: 20 }} src={icon1901} />
      </span>
    ),
    value: "/icons/regular/envelope.svg",
    key: 1901,
  },
  {
    label: (
      <span>
        /icons/regular/hand-point-left.svg{" "}
        <img style={{ height: 20 }} src={icon1902} />
      </span>
    ),
    value: "/icons/regular/hand-point-left.svg",
    key: 1902,
  },
  {
    label: (
      <span>
        /icons/regular/face-grin-beam-sweat.svg{" "}
        <img style={{ height: 20 }} src={icon1903} />
      </span>
    ),
    value: "/icons/regular/face-grin-beam-sweat.svg",
    key: 1903,
  },
  {
    label: (
      <span>
        /icons/regular/hand-lizard.svg{" "}
        <img style={{ height: 20 }} src={icon1904} />
      </span>
    ),
    value: "/icons/regular/hand-lizard.svg",
    key: 1904,
  },
  {
    label: (
      <span>
        /icons/regular/square-check.svg{" "}
        <img style={{ height: 20 }} src={icon1905} />
      </span>
    ),
    value: "/icons/regular/square-check.svg",
    key: 1905,
  },
  {
    label: (
      <span>
        /icons/regular/face-frown-open.svg{" "}
        <img style={{ height: 20 }} src={icon1906} />
      </span>
    ),
    value: "/icons/regular/face-frown-open.svg",
    key: 1906,
  },
  {
    label: (
      <span>
        /icons/regular/hand-scissors.svg{" "}
        <img style={{ height: 20 }} src={icon1907} />
      </span>
    ),
    value: "/icons/regular/hand-scissors.svg",
    key: 1907,
  },
  {
    label: (
      <span>
        /icons/regular/face-meh-blank.svg{" "}
        <img style={{ height: 20 }} src={icon1908} />
      </span>
    ),
    value: "/icons/regular/face-meh-blank.svg",
    key: 1908,
  },
  {
    label: (
      <span>
        /icons/regular/hand-point-up.svg{" "}
        <img style={{ height: 20 }} src={icon1909} />
      </span>
    ),
    value: "/icons/regular/hand-point-up.svg",
    key: 1909,
  },
  {
    label: (
      <span>
        /icons/regular/object-ungroup.svg{" "}
        <img style={{ height: 20 }} src={icon1910} />
      </span>
    ),
    value: "/icons/regular/object-ungroup.svg",
    key: 1910,
  },
  {
    label: (
      <span>
        /icons/regular/circle-check.svg{" "}
        <img style={{ height: 20 }} src={icon1911} />
      </span>
    ),
    value: "/icons/regular/circle-check.svg",
    key: 1911,
  },
  {
    label: (
      <span>
        /icons/regular/file-image.svg{" "}
        <img style={{ height: 20 }} src={icon1912} />
      </span>
    ),
    value: "/icons/regular/file-image.svg",
    key: 1912,
  },
  {
    label: (
      <span>
        /icons/regular/bell.svg <img style={{ height: 20 }} src={icon1913} />
      </span>
    ),
    value: "/icons/regular/bell.svg",
    key: 1913,
  },
  {
    label: (
      <span>
        /icons/regular/flag.svg <img style={{ height: 20 }} src={icon1914} />
      </span>
    ),
    value: "/icons/regular/flag.svg",
    key: 1914,
  },
  {
    label: (
      <span>
        /icons/regular/hand-peace.svg{" "}
        <img style={{ height: 20 }} src={icon1915} />
      </span>
    ),
    value: "/icons/regular/hand-peace.svg",
    key: 1915,
  },
  {
    label: (
      <span>
        /icons/regular/chess-king.svg{" "}
        <img style={{ height: 20 }} src={icon1916} />
      </span>
    ),
    value: "/icons/regular/chess-king.svg",
    key: 1916,
  },
  {
    label: (
      <span>
        /icons/regular/newspaper.svg{" "}
        <img style={{ height: 20 }} src={icon1917} />
      </span>
    ),
    value: "/icons/regular/newspaper.svg",
    key: 1917,
  },
  {
    label: (
      <span>
        /icons/regular/snowflake.svg{" "}
        <img style={{ height: 20 }} src={icon1918} />
      </span>
    ),
    value: "/icons/regular/snowflake.svg",
    key: 1918,
  },
  {
    label: (
      <span>
        /icons/regular/star-half.svg{" "}
        <img style={{ height: 20 }} src={icon1919} />
      </span>
    ),
    value: "/icons/regular/star-half.svg",
    key: 1919,
  },
  {
    label: (
      <span>
        /icons/regular/envelope-open.svg{" "}
        <img style={{ height: 20 }} src={icon1920} />
      </span>
    ),
    value: "/icons/regular/envelope-open.svg",
    key: 1920,
  },
  {
    label: (
      <span>
        /icons/regular/face-frown.svg{" "}
        <img style={{ height: 20 }} src={icon1921} />
      </span>
    ),
    value: "/icons/regular/face-frown.svg",
    key: 1921,
  },
  {
    label: (
      <span>
        /icons/regular/hard-drive.svg{" "}
        <img style={{ height: 20 }} src={icon1922} />
      </span>
    ),
    value: "/icons/regular/hard-drive.svg",
    key: 1922,
  },
  {
    label: (
      <span>
        /icons/regular/hospital.svg{" "}
        <img style={{ height: 20 }} src={icon1923} />
      </span>
    ),
    value: "/icons/regular/hospital.svg",
    key: 1923,
  },
  {
    label: (
      <span>
        /icons/regular/face-grin-tongue-squint.svg{" "}
        <img style={{ height: 20 }} src={icon1924} />
      </span>
    ),
    value: "/icons/regular/face-grin-tongue-squint.svg",
    key: 1924,
  },
  {
    label: (
      <span>
        /icons/regular/face-smile-wink.svg{" "}
        <img style={{ height: 20 }} src={icon1925} />
      </span>
    ),
    value: "/icons/regular/face-smile-wink.svg",
    key: 1925,
  },
  {
    label: (
      <span>
        /icons/regular/face-dizzy.svg{" "}
        <img style={{ height: 20 }} src={icon1926} />
      </span>
    ),
    value: "/icons/regular/face-dizzy.svg",
    key: 1926,
  },
  {
    label: (
      <span>
        /icons/regular/hourglass-half.svg{" "}
        <img style={{ height: 20 }} src={icon1927} />
      </span>
    ),
    value: "/icons/regular/hourglass-half.svg",
    key: 1927,
  },
  {
    label: (
      <span>
        /icons/regular/face-sad-tear.svg{" "}
        <img style={{ height: 20 }} src={icon1928} />
      </span>
    ),
    value: "/icons/regular/face-sad-tear.svg",
    key: 1928,
  },
  {
    label: (
      <span>
        /icons/regular/calendar-xmark.svg{" "}
        <img style={{ height: 20 }} src={icon1929} />
      </span>
    ),
    value: "/icons/regular/calendar-xmark.svg",
    key: 1929,
  },
  {
    label: (
      <span>
        /icons/regular/copy.svg <img style={{ height: 20 }} src={icon1930} />
      </span>
    ),
    value: "/icons/regular/copy.svg",
    key: 1930,
  },
  {
    label: (
      <span>
        /icons/regular/file-lines.svg{" "}
        <img style={{ height: 20 }} src={icon1931} />
      </span>
    ),
    value: "/icons/regular/file-lines.svg",
    key: 1931,
  },
  {
    label: (
      <span>
        /icons/regular/closed-captioning.svg{" "}
        <img style={{ height: 20 }} src={icon1932} />
      </span>
    ),
    value: "/icons/regular/closed-captioning.svg",
    key: 1932,
  },
  {
    label: (
      <span>
        /icons/regular/font-awesome.svg{" "}
        <img style={{ height: 20 }} src={icon1933} />
      </span>
    ),
    value: "/icons/regular/font-awesome.svg",
    key: 1933,
  },
  {
    label: (
      <span>
        /icons/regular/image.svg <img style={{ height: 20 }} src={icon1934} />
      </span>
    ),
    value: "/icons/regular/image.svg",
    key: 1934,
  },
  {
    label: (
      <span>
        /icons/regular/hand-point-down.svg{" "}
        <img style={{ height: 20 }} src={icon1935} />
      </span>
    ),
    value: "/icons/regular/hand-point-down.svg",
    key: 1935,
  },
  {
    label: (
      <span>
        /icons/regular/lightbulb.svg{" "}
        <img style={{ height: 20 }} src={icon1936} />
      </span>
    ),
    value: "/icons/regular/lightbulb.svg",
    key: 1936,
  },
  {
    label: (
      <span>
        /icons/regular/face-grin-stars.svg{" "}
        <img style={{ height: 20 }} src={icon1937} />
      </span>
    ),
    value: "/icons/regular/face-grin-stars.svg",
    key: 1937,
  },
  {
    label: (
      <span>
        /icons/regular/images.svg <img style={{ height: 20 }} src={icon1938} />
      </span>
    ),
    value: "/icons/regular/images.svg",
    key: 1938,
  },
  {
    label: (
      <span>
        /icons/regular/rectangle-xmark.svg{" "}
        <img style={{ height: 20 }} src={icon1939} />
      </span>
    ),
    value: "/icons/regular/rectangle-xmark.svg",
    key: 1939,
  },
  {
    label: (
      <span>
        /icons/regular/face-grin-squint.svg{" "}
        <img style={{ height: 20 }} src={icon1940} />
      </span>
    ),
    value: "/icons/regular/face-grin-squint.svg",
    key: 1940,
  },
  {
    label: (
      <span>
        /icons/regular/circle-up.svg{" "}
        <img style={{ height: 20 }} src={icon1941} />
      </span>
    ),
    value: "/icons/regular/circle-up.svg",
    key: 1941,
  },
  {
    label: (
      <span>
        /icons/regular/hand-back-fist.svg{" "}
        <img style={{ height: 20 }} src={icon1942} />
      </span>
    ),
    value: "/icons/regular/hand-back-fist.svg",
    key: 1942,
  },
  {
    label: (
      <span>
        /icons/regular/message.svg <img style={{ height: 20 }} src={icon1943} />
      </span>
    ),
    value: "/icons/regular/message.svg",
    key: 1943,
  },
  {
    label: (
      <span>
        /icons/regular/thumbs-up.svg{" "}
        <img style={{ height: 20 }} src={icon1944} />
      </span>
    ),
    value: "/icons/regular/thumbs-up.svg",
    key: 1944,
  },
  {
    label: (
      <span>
        /icons/regular/face-smile.svg{" "}
        <img style={{ height: 20 }} src={icon1945} />
      </span>
    ),
    value: "/icons/regular/face-smile.svg",
    key: 1945,
  },
  {
    label: (
      <span>
        /icons/regular/square.svg <img style={{ height: 20 }} src={icon1946} />
      </span>
    ),
    value: "/icons/regular/square.svg",
    key: 1946,
  },
  {
    label: (
      <span>
        /icons/regular/eye-slash.svg{" "}
        <img style={{ height: 20 }} src={icon1947} />
      </span>
    ),
    value: "/icons/regular/eye-slash.svg",
    key: 1947,
  },
  {
    label: (
      <span>
        /icons/regular/paper-plane.svg{" "}
        <img style={{ height: 20 }} src={icon1948} />
      </span>
    ),
    value: "/icons/regular/paper-plane.svg",
    key: 1948,
  },
  {
    label: (
      <span>
        /icons/regular/face-grin-hearts.svg{" "}
        <img style={{ height: 20 }} src={icon1949} />
      </span>
    ),
    value: "/icons/regular/face-grin-hearts.svg",
    key: 1949,
  },
  {
    label: (
      <span>
        /icons/regular/circle-right.svg{" "}
        <img style={{ height: 20 }} src={icon1950} />
      </span>
    ),
    value: "/icons/regular/circle-right.svg",
    key: 1950,
  },
  {
    label: (
      <span>
        /icons/regular/floppy-disk.svg{" "}
        <img style={{ height: 20 }} src={icon1951} />
      </span>
    ),
    value: "/icons/regular/floppy-disk.svg",
    key: 1951,
  },
  {
    label: (
      <span>
        /icons/regular/file-powerpoint.svg{" "}
        <img style={{ height: 20 }} src={icon1952} />
      </span>
    ),
    value: "/icons/regular/file-powerpoint.svg",
    key: 1952,
  },
  {
    label: (
      <span>
        /icons/regular/note-sticky.svg{" "}
        <img style={{ height: 20 }} src={icon1953} />
      </span>
    ),
    value: "/icons/regular/note-sticky.svg",
    key: 1953,
  },
  {
    label: (
      <span>
        /icons/regular/hand-spock.svg{" "}
        <img style={{ height: 20 }} src={icon1954} />
      </span>
    ),
    value: "/icons/regular/hand-spock.svg",
    key: 1954,
  },
  {
    label: (
      <span>
        /icons/regular/gem.svg <img style={{ height: 20 }} src={icon1955} />
      </span>
    ),
    value: "/icons/regular/gem.svg",
    key: 1955,
  },
  {
    label: (
      <span>
        /icons/regular/chart-bar.svg{" "}
        <img style={{ height: 20 }} src={icon1956} />
      </span>
    ),
    value: "/icons/regular/chart-bar.svg",
    key: 1956,
  },
  {
    label: (
      <span>
        /icons/regular/paste.svg <img style={{ height: 20 }} src={icon1957} />
      </span>
    ),
    value: "/icons/regular/paste.svg",
    key: 1957,
  },
  {
    label: (
      <span>
        /icons/regular/window-maximize.svg{" "}
        <img style={{ height: 20 }} src={icon1958} />
      </span>
    ),
    value: "/icons/regular/window-maximize.svg",
    key: 1958,
  },
  {
    label: (
      <span>
        /icons/regular/address-book.svg{" "}
        <img style={{ height: 20 }} src={icon1959} />
      </span>
    ),
    value: "/icons/regular/address-book.svg",
    key: 1959,
  },
  {
    label: (
      <span>
        /icons/regular/rectangle-list.svg{" "}
        <img style={{ height: 20 }} src={icon1960} />
      </span>
    ),
    value: "/icons/regular/rectangle-list.svg",
    key: 1960,
  },
  {
    label: (
      <span>
        /icons/regular/star.svg <img style={{ height: 20 }} src={icon1961} />
      </span>
    ),
    value: "/icons/regular/star.svg",
    key: 1961,
  },
  {
    label: (
      <span>
        /icons/regular/sun.svg <img style={{ height: 20 }} src={icon1962} />
      </span>
    ),
    value: "/icons/regular/sun.svg",
    key: 1962,
  },
  {
    label: (
      <span>
        /icons/regular/address-card.svg{" "}
        <img style={{ height: 20 }} src={icon1963} />
      </span>
    ),
    value: "/icons/regular/address-card.svg",
    key: 1963,
  },
  {
    label: (
      <span>
        /icons/regular/circle-stop.svg{" "}
        <img style={{ height: 20 }} src={icon1964} />
      </span>
    ),
    value: "/icons/regular/circle-stop.svg",
    key: 1964,
  },
  {
    label: (
      <span>
        /icons/regular/circle-xmark.svg{" "}
        <img style={{ height: 20 }} src={icon1965} />
      </span>
    ),
    value: "/icons/regular/circle-xmark.svg",
    key: 1965,
  },
  {
    label: (
      <span>
        /icons/regular/lemon.svg <img style={{ height: 20 }} src={icon1966} />
      </span>
    ),
    value: "/icons/regular/lemon.svg",
    key: 1966,
  },
  {
    label: (
      <span>
        /icons/regular/compass.svg <img style={{ height: 20 }} src={icon1967} />
      </span>
    ),
    value: "/icons/regular/compass.svg",
    key: 1967,
  },
  {
    label: (
      <span>
        /icons/regular/face-surprise.svg{" "}
        <img style={{ height: 20 }} src={icon1968} />
      </span>
    ),
    value: "/icons/regular/face-surprise.svg",
    key: 1968,
  },
  {
    label: (
      <span>
        /icons/regular/credit-card.svg{" "}
        <img style={{ height: 20 }} src={icon1969} />
      </span>
    ),
    value: "/icons/regular/credit-card.svg",
    key: 1969,
  },
  {
    label: (
      <span>
        /icons/regular/circle-user.svg{" "}
        <img style={{ height: 20 }} src={icon1970} />
      </span>
    ),
    value: "/icons/regular/circle-user.svg",
    key: 1970,
  },
  {
    label: (
      <span>
        /icons/regular/clock.svg <img style={{ height: 20 }} src={icon1971} />
      </span>
    ),
    value: "/icons/regular/clock.svg",
    key: 1971,
  },
  {
    label: (
      <span>
        /icons/regular/window-minimize.svg{" "}
        <img style={{ height: 20 }} src={icon1972} />
      </span>
    ),
    value: "/icons/regular/window-minimize.svg",
    key: 1972,
  },
  {
    label: (
      <span>
        /icons/regular/folder-closed.svg{" "}
        <img style={{ height: 20 }} src={icon1973} />
      </span>
    ),
    value: "/icons/regular/folder-closed.svg",
    key: 1973,
  },
  {
    label: (
      <span>
        /icons/regular/eye.svg <img style={{ height: 20 }} src={icon1974} />
      </span>
    ),
    value: "/icons/regular/eye.svg",
    key: 1974,
  },
  {
    label: (
      <span>
        /icons/regular/square-caret-up.svg{" "}
        <img style={{ height: 20 }} src={icon1975} />
      </span>
    ),
    value: "/icons/regular/square-caret-up.svg",
    key: 1975,
  },
  {
    label: (
      <span>
        /icons/regular/square-plus.svg{" "}
        <img style={{ height: 20 }} src={icon1976} />
      </span>
    ),
    value: "/icons/regular/square-plus.svg",
    key: 1976,
  },
  {
    label: (
      <span>
        /icons/regular/registered.svg{" "}
        <img style={{ height: 20 }} src={icon1977} />
      </span>
    ),
    value: "/icons/regular/registered.svg",
    key: 1977,
  },
  {
    label: (
      <span>
        /icons/regular/face-kiss-wink-heart.svg{" "}
        <img style={{ height: 20 }} src={icon1978} />
      </span>
    ),
    value: "/icons/regular/face-kiss-wink-heart.svg",
    key: 1978,
  },
  {
    label: (
      <span>
        /icons/regular/face-sad-cry.svg{" "}
        <img style={{ height: 20 }} src={icon1979} />
      </span>
    ),
    value: "/icons/regular/face-sad-cry.svg",
    key: 1979,
  },
  {
    label: (
      <span>
        /icons/regular/calendar-minus.svg{" "}
        <img style={{ height: 20 }} src={icon1980} />
      </span>
    ),
    value: "/icons/regular/calendar-minus.svg",
    key: 1980,
  },
  {
    label: (
      <span>
        /icons/regular/id-card.svg <img style={{ height: 20 }} src={icon1981} />
      </span>
    ),
    value: "/icons/regular/id-card.svg",
    key: 1981,
  },
  {
    label: (
      <span>
        /icons/regular/chess-pawn.svg{" "}
        <img style={{ height: 20 }} src={icon1982} />
      </span>
    ),
    value: "/icons/regular/chess-pawn.svg",
    key: 1982,
  },
  {
    label: (
      <span>
        /icons/regular/face-smile-beam.svg{" "}
        <img style={{ height: 20 }} src={icon1983} />
      </span>
    ),
    value: "/icons/regular/face-smile-beam.svg",
    key: 1983,
  },
  {
    label: (
      <span>
        /icons/regular/bell-slash.svg{" "}
        <img style={{ height: 20 }} src={icon1984} />
      </span>
    ),
    value: "/icons/regular/bell-slash.svg",
    key: 1984,
  },
  {
    label: (
      <span>
        /icons/regular/handshake.svg{" "}
        <img style={{ height: 20 }} src={icon1985} />
      </span>
    ),
    value: "/icons/regular/handshake.svg",
    key: 1985,
  },
  {
    label: (
      <span>
        /icons/regular/comment.svg <img style={{ height: 20 }} src={icon1986} />
      </span>
    ),
    value: "/icons/regular/comment.svg",
    key: 1986,
  },
  {
    label: (
      <span>
        /icons/regular/calendar.svg{" "}
        <img style={{ height: 20 }} src={icon1987} />
      </span>
    ),
    value: "/icons/regular/calendar.svg",
    key: 1987,
  },
  {
    label: (
      <span>
        /icons/regular/square-minus.svg{" "}
        <img style={{ height: 20 }} src={icon1988} />
      </span>
    ),
    value: "/icons/regular/square-minus.svg",
    key: 1988,
  },
  {
    label: (
      <span>
        /icons/regular/face-tired.svg{" "}
        <img style={{ height: 20 }} src={icon1989} />
      </span>
    ),
    value: "/icons/regular/face-tired.svg",
    key: 1989,
  },
  {
    label: (
      <span>
        /icons/regular/face-grin.svg{" "}
        <img style={{ height: 20 }} src={icon1990} />
      </span>
    ),
    value: "/icons/regular/face-grin.svg",
    key: 1990,
  },
  {
    label: (
      <span>
        /icons/regular/chess-queen.svg{" "}
        <img style={{ height: 20 }} src={icon1991} />
      </span>
    ),
    value: "/icons/regular/chess-queen.svg",
    key: 1991,
  },
  {
    label: (
      <span>
        /icons/regular/file-code.svg{" "}
        <img style={{ height: 20 }} src={icon1992} />
      </span>
    ),
    value: "/icons/regular/file-code.svg",
    key: 1992,
  },
  {
    label: (
      <span>
        /icons/regular/futbol.svg <img style={{ height: 20 }} src={icon1993} />
      </span>
    ),
    value: "/icons/regular/futbol.svg",
    key: 1993,
  },
  {
    label: (
      <span>
        /icons/regular/face-flushed.svg{" "}
        <img style={{ height: 20 }} src={icon1994} />
      </span>
    ),
    value: "/icons/regular/face-flushed.svg",
    key: 1994,
  },
  {
    label: (
      <span>
        /icons/regular/face-kiss.svg{" "}
        <img style={{ height: 20 }} src={icon1995} />
      </span>
    ),
    value: "/icons/regular/face-kiss.svg",
    key: 1995,
  },
  {
    label: (
      <span>
        /icons/regular/bookmark.svg{" "}
        <img style={{ height: 20 }} src={icon1996} />
      </span>
    ),
    value: "/icons/regular/bookmark.svg",
    key: 1996,
  },
  {
    label: (
      <span>
        /icons/regular/circle-dot.svg{" "}
        <img style={{ height: 20 }} src={icon1997} />
      </span>
    ),
    value: "/icons/regular/circle-dot.svg",
    key: 1997,
  },
  {
    label: (
      <span>
        /icons/regular/hand-pointer.svg{" "}
        <img style={{ height: 20 }} src={icon1998} />
      </span>
    ),
    value: "/icons/regular/hand-pointer.svg",
    key: 1998,
  },
  {
    label: (
      <span>
        /icons/regular/star-half-stroke.svg{" "}
        <img style={{ height: 20 }} src={icon1999} />
      </span>
    ),
    value: "/icons/regular/star-half-stroke.svg",
    key: 1999,
  },
  {
    label: (
      <span>
        /icons/regular/face-grin-beam.svg{" "}
        <img style={{ height: 20 }} src={icon2000} />
      </span>
    ),
    value: "/icons/regular/face-grin-beam.svg",
    key: 2000,
  },
  {
    label: (
      <span>
        /icons/regular/face-grin-wide.svg{" "}
        <img style={{ height: 20 }} src={icon2001} />
      </span>
    ),
    value: "/icons/regular/face-grin-wide.svg",
    key: 2001,
  },
  {
    label: (
      <span>
        /icons/regular/face-grin-tears.svg{" "}
        <img style={{ height: 20 }} src={icon2002} />
      </span>
    ),
    value: "/icons/regular/face-grin-tears.svg",
    key: 2002,
  },
  {
    label: (
      <span>
        /icons/regular/face-laugh-beam.svg{" "}
        <img style={{ height: 20 }} src={icon2003} />
      </span>
    ),
    value: "/icons/regular/face-laugh-beam.svg",
    key: 2003,
  },
  {
    label: (
      <span>
        /icons/regular/face-kiss-beam.svg{" "}
        <img style={{ height: 20 }} src={icon2004} />
      </span>
    ),
    value: "/icons/regular/face-kiss-beam.svg",
    key: 2004,
  },
  {
    label: (
      <span>
        /icons/regular/face-angry.svg{" "}
        <img style={{ height: 20 }} src={icon2005} />
      </span>
    ),
    value: "/icons/regular/face-angry.svg",
    key: 2005,
  },
  {
    label: (
      <span>
        /icons/regular/window-restore.svg{" "}
        <img style={{ height: 20 }} src={icon2006} />
      </span>
    ),
    value: "/icons/regular/window-restore.svg",
    key: 2006,
  },
  {
    label: (
      <span>
        /icons/regular/circle-pause.svg{" "}
        <img style={{ height: 20 }} src={icon2007} />
      </span>
    ),
    value: "/icons/regular/circle-pause.svg",
    key: 2007,
  },
  {
    label: (
      <span>
        /icons/regular/chess-bishop.svg{" "}
        <img style={{ height: 20 }} src={icon2008} />
      </span>
    ),
    value: "/icons/regular/chess-bishop.svg",
    key: 2008,
  },
  {
    label: (
      <span>
        /icons/regular/circle-down.svg{" "}
        <img style={{ height: 20 }} src={icon2009} />
      </span>
    ),
    value: "/icons/regular/circle-down.svg",
    key: 2009,
  },
  {
    label: (
      <span>
        /icons/regular/building.svg{" "}
        <img style={{ height: 20 }} src={icon2010} />
      </span>
    ),
    value: "/icons/regular/building.svg",
    key: 2010,
  },
  {
    label: (
      <span>
        /icons/regular/folder.svg <img style={{ height: 20 }} src={icon2011} />
      </span>
    ),
    value: "/icons/regular/folder.svg",
    key: 2011,
  },
  {
    label: (
      <span>
        /icons/regular/file-pdf.svg{" "}
        <img style={{ height: 20 }} src={icon2012} />
      </span>
    ),
    value: "/icons/regular/file-pdf.svg",
    key: 2012,
  },
  {
    label: (
      <span>
        /icons/regular/moon.svg <img style={{ height: 20 }} src={icon2013} />
      </span>
    ),
    value: "/icons/regular/moon.svg",
    key: 2013,
  },
  {
    label: (
      <span>
        /icons/regular/face-laugh-squint.svg{" "}
        <img style={{ height: 20 }} src={icon2014} />
      </span>
    ),
    value: "/icons/regular/face-laugh-squint.svg",
    key: 2014,
  },
  {
    label: (
      <span>
        /icons/regular/square-caret-left.svg{" "}
        <img style={{ height: 20 }} src={icon2015} />
      </span>
    ),
    value: "/icons/regular/square-caret-left.svg",
    key: 2015,
  },
  {
    label: (
      <span>
        /icons/regular/calendar-days.svg{" "}
        <img style={{ height: 20 }} src={icon2016} />
      </span>
    ),
    value: "/icons/regular/calendar-days.svg",
    key: 2016,
  },
  {
    label: (
      <span>
        /icons/regular/heart.svg <img style={{ height: 20 }} src={icon2017} />
      </span>
    ),
    value: "/icons/regular/heart.svg",
    key: 2017,
  },
];
