import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Collapse,
  Card,
} from "reactstrap";
import parse from "html-react-parser";
import Editor from "@monaco-editor/react";
import { Colxx } from "../../../../../../components/common/CustomBootstrap";
import Select from "react-select";
import { CourseContentOptions } from "../../../../../../constants/selectOptions";
import { CourseContentTempletes } from "../../../../../../data/templetes";

const ContentPopUp = (props) => {
  const {
    isContentCreating,
    setIsContentCreating,
    onSubmitHandler,
    values,
    setValues,
    isEditing,
    setCurrentData,
  } = props;
  const [showingIndex, setShowIndex] = useState(0);

  return (
    <Modal
      isOpen={isContentCreating}
      toggle={() => {
        setIsContentCreating(!isContentCreating);
        setValues({});
      }}
      backdrop={true}
      size={"xl"}
      contentClassName="content-model-content-style"
      className="br-20px delete-modal content-model-style"
    >
      <ModalHeader className="mt-n10px h-81px ">
        <div className="d-flex">
          <span className="fs-24px fw-600">
            {isEditing ? "Update Your Content" : "Create New Content"}
          </span>
          <div className="d-flex">
            <div className="w-303px">
              <Select
                isClearable
                options={CourseContentOptions}
                placeholder="Content Type"
                className="react-select ml-20px"
                classNamePrefix="react-select"
                value={values.category}
                onChange={(data) => setValues({ ...values, category: data })}
              />
            </div>
            <div className="w-303px">
              <Select
                isClearable
                options={CourseContentTempletes}
                placeholder="Content Templetes"
                className="react-select ml-20px"
                classNamePrefix="react-select"
                onChange={(data) => {
                  if (data) {
                    setValues({
                      ...values,
                      name: data.value.title,
                      description: data.value.description,
                    });
                  }
                }}
              />
            </div>
            <Button
              size={"sm"}
              color={"primary"}
              className="br-20px"
              onClick={() => {
                setIsContentCreating(false);
                setCurrentData(null);
              }}
            >
              <b>Cancle</b>
            </Button>{" "}
            <Button
              size={"sm"}
              color="secondry"
              className="br-20px c_white shadow-box create-new-button"
              onClick={onSubmitHandler}
            >
              {isEditing ? "Update" : "Create"}
            </Button>{" "}
          </div>
        </div>
      </ModalHeader>
      <ModalBody className="mt-n10px" style={{ overflowY: "scroll" }}>
        <Row>
          <Colxx xxs="12" className="mb-2px">
            <Card className="d-flex mb-10px br-14px">
              <span
                color="link"
                className="ml-20px mt-10px mb-10px cursor-pointer  fw-600 fs-20px list-item-heading text-left text-one "
                onClick={() => {
                  setShowIndex(showingIndex === 0 ? -1 : 0);
                }}
                aria-expanded={showingIndex === 0}
              >
                Content Title
              </span>
              <Collapse className="pl-20px" isOpen={showingIndex === 0}>
                <Row>
                  <Colxx lg="12" xl="6" className="mt-10px">
                    <label className="input-label fw-600 fs-14px">
                      Content Title
                    </label>
                    <Editor
                      height={400}
                      defaultLanguage="html"
                      defaultValue="<!--Its Content Description-->"
                      onChange={(data) =>
                        setValues({
                          ...values,
                          name: data,
                        })
                      }
                      value={values.name}
                    />
                  </Colxx>

                  <Colxx
                    lg="12"
                    xl="6"
                    className="mt-10px"
                    style={{ overflowY: "scroll", height: 400 }}
                  >
                    <label className="input-label fw-600 fs-14px">
                      Content Title
                    </label>
                    <div className="max-h-200px p-2 pl-20px br-20px">
                      {values.name ? parse(values.name) : ""}
                    </div>
                  </Colxx>
                </Row>
              </Collapse>
            </Card>
          </Colxx>

          <Colxx xxs="12" className="mb-2">
            <Card className="d-flex mb-3 br-14px" key={`faqItem_0`}>
              <span
                color="link"
                className="ml-20px mt-10px mb-10px cursor-pointer  fw-600 fs-20px list-item-heading text-left text-one "
                onClick={() => {
                  setShowIndex(showingIndex === 1 ? -1 : 1);
                }}
                aria-expanded={showingIndex === 1}
              >
                Main Content
              </span>
              <Collapse className="pl-20px" isOpen={showingIndex === 1}>
                <Row>
                  <Colxx lg="12" xl="8">
                    <Editor
                      height={500}
                      defaultLanguage="html"
                      defaultValue="<!--Its Content Description-->"
                      onChange={(data) =>
                        setValues({
                          ...values,
                          description: data,
                        })
                      }
                      options={{ minimap: { enabled: false } }}
                      value={values.description}
                    />
                  </Colxx>

                  <Colxx
                    lg="12"
                    xl="4"
                    className="mt-10px"
                    style={{ overflowY: "scroll", height: 500 }}
                  >
                    <div className="p-2 pl-20px br-20px">
                      {values.description ? parse(values.description) : ""}
                    </div>
                  </Colxx>
                </Row>
              </Collapse>
            </Card>
          </Colxx>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default ContentPopUp;
