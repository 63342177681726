import React, { useState, useEffect } from "react";
import { Button, Card, Row, Collapse, Badge } from "reactstrap";
import {
  Colxx,
  Separator,
} from "../../../../../../components/common/CustomBootstrap";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { CourseContentOptions } from "../../../../../../constants/selectOptions";
import AlertMessage from "../../../../../../helpers/alerts";
import parse from "html-react-parser";
import Editor from "@monaco-editor/react";
import {
  addNewCourseAPI,
  addNewCourseContentAPI,
  deleteCourseContentAPI,
  updateCourseContentAPI,
  updateCourseDetailsAPI,
} from "../../../../../../services/master";
import {
  errorHandler,
  getOneCourseDataAction,
} from "../../../../../../redux/master/actions";
import DeleteConfirmationPopUp from "../../../../../../components/common/deleteConfirmationPopup";
import ContentPopUp from "./contentPopUp";

const CourseContent = (props) => {
  const currentCourse = useSelector((state) => state.master.oneCourse);

  let defaultValues = {
    category: { label: "Content", value: "Content", key: 0 },
    name: "",
    description: "",
  };
  const [values, setValues] = useState(defaultValues);
  const dispatch = useDispatch();
  const [oneContent, setOneContent] = useState(null);
  const [currentContents, setCurrentContents] = useState([]);
  const [isContentDeleting, setIsContentDeleting] = useState(false);
  const [isContentCreating, setIsContentCreating] = useState(false);

  const onSubmitHandler = async () => {
    if (oneContent) {
      let newValues = {
        ...values,
        category: values.category.value,
        course: currentCourse.id,
      };
      updateCourseContentAPI(newValues, { _id: oneContent.id }).then(
        (res) => {
          AlertMessage("Course Criteria Updated Successfully", "success");
          setValues(defaultValues);
          dispatch(getOneCourseDataAction(currentCourse.id));
          setIsContentCreating(false);
          setOneContent(null);
        },
        (error) => {
          dispatch(errorHandler(error));
        }
      );
    } else {
      let newValues = {
        ...values,
        category: values.category.value,
        course: currentCourse.id,
      };
      addNewCourseContentAPI(newValues).then(
        (res) => {
          AlertMessage("Course Content Created Successfully.", "success");
          dispatch(getOneCourseDataAction(currentCourse.id));
          setIsContentCreating(false);
          setValues(defaultValues);
          setOneContent(null);
        },
        (error) => {
          dispatch(errorHandler(error));
        }
      );
    }
  };

  const deleteCourseContent = (_id) => {
    deleteCourseContentAPI({ _id: _id }).then(
      (res) => {
        AlertMessage("Course Content Deleted Successfully", "success");
        setValues(defaultValues);
        dispatch(getOneCourseDataAction(currentCourse.id));
      },
      (error) => {
        AlertMessage("Internal Server Error", "error");
      }
    );
  };

  const onDeleteAction = () => {
    deleteCourseContent(oneContent.id);
    setCurrentContents(null);
    setIsContentDeleting(false);
    setValues(defaultValues);
  };

  useEffect(() => {
    if (currentCourse) {
      setCurrentContents(currentCourse.course_content);
    }
  }, [currentCourse]);

  useEffect(() => {
    if (oneContent && !isContentDeleting) {
      let d = { name: oneContent.name, description: oneContent.description };
      let oneCategory = CourseContentOptions.find(
        (x) => oneContent.category === x.value
      );
      d["category"] = oneCategory;
      setValues(d);
    } else {
      setValues(defaultValues);
    }
  }, [oneContent]);

  props = {
    ...props,
    isDeleting: isContentDeleting,
    setIsDeleting: setIsContentDeleting,
    currentData: oneContent,
    setCurrentData: setOneContent,
    onDeleteClick: onDeleteAction,
    values,
    setValues,
    onSubmitHandler,
    isContentCreating,
    setIsContentCreating,
  };

  return (
    <React.Fragment>
      <DeleteConfirmationPopUp {...props} />
      {isContentCreating && <ContentPopUp {...props} />}
      <div className="card-body accordion-content pt-0">
        <Row className="max-h-400px overflow-auto">
          {currentContents?.map((content, index) => {
            return (
              <Colxx lg="12" xl="6">
                <div className="shadow-box pt-4px pl-10px pb-10px pr-4px br-10px mb-4px max-h-300px">
                  <div className="d-flex justify-content-spacebetween">
                    <span className="input-label fw-500 fs-15px">
                      {parse(content.name)}
                    </span>
                    <span className="input-label fs-14px c_black-60p">
                      <Badge
                        color={content.category === "Project" ? "dark" : "info"}
                        className="br-20px"
                      >
                        {content.category}
                      </Badge>
                      <i
                        onClick={() => {
                          setOneContent(content);
                          setIsContentCreating(true);
                        }}
                        className="simple-icon-pencil c_ed7117 ml-4px cursor-pointer"
                      />
                      <i
                        onClick={() => {
                          setIsContentDeleting(true);
                          setOneContent(content);
                        }}
                        className="simple-icon-trash c_B00020 ml-4px cursor-pointer"
                      />
                    </span>
                  </div>
                  <div className="mt-10px max-h-200px overflow-auto">
                    {parse(content.description)}
                  </div>
                </div>
              </Colxx>
            );
          })}
        </Row>
      </div>
      <div
        onClick={() => {
          setIsContentCreating(!isContentCreating);
        }}
        className="btn btn-dark br-30px fs-20px pl-25px pr-25px pt-5px pb-5px mb-n20px "
      >
        Add New
      </div>
    </React.Fragment>
  );
};

export default CourseContent;
