import React from "react";
import AppLayout from "../../../layout/AppLayout";

const InstitutePanel = (props) => {
  return (
    <AppLayout subDomain={process.env.REACT_APP_INSTITUTE_SUB_DOMAIN}>
      <div>Institute Panel</div>
    </AppLayout>
  );
};

export default InstitutePanel;
