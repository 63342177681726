import React, { useState, useEffect } from "react";
import { Button, Card, Row } from "reactstrap";
import {
  Colxx,
  Separator,
} from "../../../../../components/common/CustomBootstrap";
import Select from "react-select";
import { MDBDataTable } from "mdbreact";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteFilesDataAction,
  errorHandler,
  getFilesDataAction,
} from "../../../../../redux/master/actions";
import AlertMessage from "../../../../../helpers/alerts";
import DeleteConfirmationPopUp from "../../../../../components/common/deleteConfirmationPopup";
import { FileCategoryTypeOptions } from "../../../../../constants/selectOptions";
import uploadAllFiles from "../../../../../helpers/file-helper";
import FileFilterSection from "./fileFilter";
import CustomeTable from "../../../../../components/common/table";

const Files = (props) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [searchParams, setSearchParams] = useState({});
  const [currentData, setCurrentData] = useState(null);
  const [values, setValues] = useState({
    category: "",
    file: "",
    file_name: "",
  });

  const dispatch = useDispatch();
  const { files: fetchedData, totalCount } = useSelector(
    (state) => state.master.files
  );

  const onDeleteClick = (_id) => {
    dispatch(deleteFilesDataAction(_id));
    setCurrentData(null);
    setIsDeleting(false);
  };

  const [datatable, setDatatable] = React.useState({
    columns: [
      {
        label: "#",
        field: "sn",
        width: 50,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "id",
        },
        sort: "disabled",
      },

      {
        label: "Category",
        field: "category",
        width: 170,
      },

      {
        label: "Uploaded By",
        field: "user",
        width: 170,
      },

      {
        label: "File Name",
        field: "file_name",
        width: 300,
      },

      {
        label: "View File",
        field: "file_url",
        width: 150,
      },

      {
        label: "Date",
        field: "date",
        width: 150,
      },

      {
        label: "Action",
        field: "action",
        width: 100,
      },
    ],
    rows: [],
  });

  const onSubmitHandler = () => {
    if (
      values.file_name === "" ||
      values.category === "" ||
      values.category === null
    ) {
      AlertMessage("All Fields Required", "info");
      return;
    }
    uploadAllFiles(
      [values.file],
      values?.category?.value,
      values.file_name
    ).then(
      (res) => {
        dispatch(getFilesDataAction());
        setValues({
          // category: "",
          file: "",
          file_name: "",
        });
      },
      (error) => {
        dispatch(errorHandler(error));
      }
    );
  };

  useEffect(() => {
    let allRows = [];

    fetchedData?.map((data, index) => {
      let oneRow = {
        sn: index + 1,
        category: data.category,
        file_name: data.file_name,
        user: data.user?.name,
        file_url: (
          <>
            {data.file_type !== "image" && (
              <a
                href={`${process.env.REACT_APP_MAIN_BACKEND_HOST}${data.file_url}`}
              >
                {data.file_url}
              </a>
            )}
            {data.file_type === "image" && (
              <span>
                <img
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `${process.env.REACT_APP_MAIN_BACKEND_HOST}${data.file_url}`
                    );
                    AlertMessage("File Url Copied", "info");
                  }}
                  alt=""
                  className="h-35px max-w-200px  cursor-pointer"
                  src={`${process.env.REACT_APP_MAIN_BACKEND_HOST}${data.file_url}`}
                />
              </span>
            )}

            {data.file_type !== "image" && (
              <i
                onClick={() => {
                  navigator.clipboard.writeText(
                    `${process.env.REACT_APP_MAIN_BACKEND_HOST}${data.file_url}`
                  );
                  AlertMessage("File Url Copied", "info");
                }}
                className="iconsminds-file-copy c_FFA726 cursor-pointer"
              />
            )}
          </>
        ),
        date: data.date,
        action: (
          <span>
            <i
              onClick={() => {
                setIsDeleting(true);
                setCurrentData(data);
              }}
              className="simple-icon-trash cursor-pointer c_ed7117 mr-4px"
            />
          </span>
        ),
      };
      allRows.push(oneRow);
    });

    setDatatable({ ...datatable, rows: allRows });
  }, [fetchedData]);

  const onPageChange = (e) => {
    setSearchParams({ ...searchParams, ...e });
  };

  props = {
    ...props,
    isDeleting,
    setIsDeleting,
    currentData,
    setCurrentData,
    onDeleteClick,
  };

  return (
    <React.Fragment>
      {isDeleting && <DeleteConfirmationPopUp {...props} />}
      <Row>
        <Colxx xxs="12">
          <div className="table-header-container">
            <span className="table-header-title">
              List of Files
              <p className="fs-14px c_black-60p">
                Here we have access of all uploaded files.
              </p>
            </span>
          </div>
          <Separator className="mb-5" />
        </Colxx>
      </Row>
      <Row>
        <FileFilterSection
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        />
      </Row>
      <Row>
        <Colxx lg="12" xl="8">
          <Card className="d-flex mb-3 br-20px" key={`faqItem_1`}>
            <div className="d-flex flex-grow-1 min-width-zero">
              <div
                className="card-body accordion-content pt-0"
                style={{ maxWidth: "100%" }}
              >
                <CustomeTable
                  onPageChange={onPageChange}
                  datatable={datatable}
                  total={totalCount}
                />
              </div>
            </div>
          </Card>
        </Colxx>
        <Colxx lg="12" xl="4">
          <Card className="d-flex mb-3 br-20px" key={`faqItem_1`}>
            <div className="d-flex flex-grow-1 min-width-zero">
              <div className="card-body accordion-content pt-0">
                <Row className="mt-30px">
                  <Colxx lg="12" xl="12">
                    <label className="input-label fw-400 fs-14px">
                      File Category
                    </label>
                    <Select
                      isClearable
                      options={FileCategoryTypeOptions}
                      className="react-select"
                      classNamePrefix="react-select"
                      value={values.category}
                      onChange={(data) =>
                        setValues({ ...values, category: data })
                      }
                    />
                  </Colxx>
                  <Colxx lg="12" xl="12" className="mt-20px">
                    <label className="input-label fw-400 fs-14px">
                      File Name
                    </label>
                    <input
                      type={"text"}
                      className="form-control br-10px"
                      placeholder="ex. TechSim+"
                      value={values.file_name}
                      onChange={(data) =>
                        setValues({
                          ...values,
                          file_name: data.target.value,
                        })
                      }
                    />
                  </Colxx>

                  <Colxx lg="12" xl="12" className="mt-20px">
                    <label className="input-label fw-400 fs-14px">
                      Choose File
                    </label>
                    <input
                      type="file"
                      id="image"
                      onChange={(e) =>
                        setValues({
                          ...values,
                          file: e.target.files[0],
                          file_name:
                            values.file_name || e.target?.files[0]?.name,
                        })
                      }
                      required
                    />
                  </Colxx>

                  <div className="d-flex justify-content-flex-end w-100percent">
                    <Button
                      onClick={onSubmitHandler}
                      size={"sm"}
                      color="secondry"
                      className="br-20px c_white shadow-box create-new-button mt-30px"
                    >
                      Create
                    </Button>
                  </div>
                </Row>
              </div>
            </div>
          </Card>
        </Colxx>
      </Row>
    </React.Fragment>
  );
};

export default Files;
