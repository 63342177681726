// eslint-disable-next-line import/no-cycle

import { getDashboardDataAPI } from "../../services/master";
import { GET_DASHBOARD_DATA } from "./actionType";

export const getDashboardDataAction = () => {
  return (dispatch) => {
    getDashboardDataAPI().then(
      (response) => {
        dispatch({ type: GET_DASHBOARD_DATA, payload: response.data });
      },
      (error) => {
        dispatch({ type: GET_DASHBOARD_DATA, payload: [] });
      }
    );
  };
};
