import React, { useState, useEffect } from "react";
import { Button, Card, Row, Collapse } from "reactstrap";
import { Colxx } from "../../../../../../components/common/CustomBootstrap";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import AlertMessage from "../../../../../../helpers/alerts";
import {
  updateCourseUrlAPI,
  deleteCourseUrlAPI,
  createNewCourseUrlAPI,
} from "../../../../../../services/master";
import parse from "html-react-parser";
import {
  errorHandler,
  getOneCourseDataAction,
} from "../../../../../../redux/master/actions";
import DeleteConfirmationPopUp from "../../../../../../components/common/deleteConfirmationPopup";
import { iconOptions } from "../../../../../../constants/iconsOptions";

const CourseSEOUrls = (props) => {
  const currentCourse = useSelector((state) => state.master.oneCourse);
  const dispatch = useDispatch();

  const [isKeyDeleting, setIsKeyDeleting] = useState(false);
  const [currentKey, setCurrentKey] = useState(null);
  let defaultValues = {
    url: "",
    title: "",
  };
  const [values, setValues] = useState(defaultValues);

  const deleteCourseKeyPoint = (_id) => {
    deleteCourseUrlAPI(_id).then(
      (res) => {
        AlertMessage("Course URL Deleted Successfully", "success");
        setValues(defaultValues);
        dispatch(getOneCourseDataAction(currentCourse.id));
      },
      (error) => {
        AlertMessage("Internal Server Error", "error");
      }
    );
  };

  const onDeleteAction = () => {
    console.log(currentKey.id);
    deleteCourseKeyPoint(currentKey.id);
    setCurrentKey(null);
    setIsKeyDeleting(false);
  };

  const onSubmitHandler = async () => {
    let newValues = {
      ...values,
      course: currentCourse.id,
    };

    if (newValues.url === "" || !newValues.url) {
      AlertMessage("URL Required");
      return;
    }
    if (currentKey) {
      updateCourseUrlAPI(newValues, { _id: currentKey.id }).then(
        (res) => {
          setCurrentKey(null);
          setValues(defaultValues);
          dispatch(getOneCourseDataAction(currentCourse.id));
        },
        (error) => {
          dispatch(errorHandler(error));
        }
      );
    } else {
      createNewCourseUrlAPI(newValues).then(
        (res) => {
          dispatch(getOneCourseDataAction(currentCourse.id));
          setCurrentKey(null);
          setValues(defaultValues);
        },
        (error) => {
          dispatch(errorHandler(error));
        }
      );
    }
  };

  useEffect(() => {
    if (currentKey) {
      setValues({
        title: currentKey.title,
        url: currentKey.url,
      });
    }
  }, [currentKey]);

  props = {
    ...props,
    isDeleting: isKeyDeleting,
    setIsDeleting: setIsKeyDeleting,
    currentData: currentKey,
    setCurrentData: setCurrentKey,
    onDeleteClick: onDeleteAction,
  };

  return (
    <div className="card-body accordion-content pt-0">
      <DeleteConfirmationPopUp {...props} />
      <Row className="">
        <Colxx lg="12" xl="12" className="mb-20px shadow-box br-20px p-3">
          <div className="d-flex ">
            <div className="w-100percent">
              <label className="input-label fw-600 fs-14px">URL</label>
              <input
                type={"text"}
                className="form-control br-10px"
                placeholder=""
                value={values.url}
                onChange={(data) =>
                  setValues({
                    ...values,
                    url: data.target.value,
                  })
                }
              />
            </div>
            <div className="w-100percent mx-10px">
              <label className="input-label fw-600 fs-14px">Page Title</label>
              <input
                type={"text"}
                className="form-control br-10px"
                placeholder=""
                value={values.title}
                onChange={(data) =>
                  setValues({
                    ...values,
                    title: data.target.value,
                  })
                }
              />
            </div>
          </div>
        </Colxx>
        <Colxx lg="12" xl="12">
          {currentCourse?.course_urls?.map((key, index) => {
            return (
              <React.Suspense>
                <Card className="mb-2  br-20px p-2 pl-4">
                  <div className="d-flex justify-content-spacebetween">
                    <div>
                      <>{parse(key.url)}</>
                    </div>
                    <div>
                      <>{parse(key.title)}</>
                    </div>
                    <div>
                      <i
                        onClick={() => setCurrentKey(key)}
                        className="simple-icon-pencil c_ed7117 ml-4px cursor-pointer"
                      />
                      <i
                        onClick={() => {
                          setIsKeyDeleting(true);
                          setCurrentKey(key);
                        }}
                        className="simple-icon-trash c_B00020 ml-4px cursor-pointer"
                      />
                    </div>
                  </div>
                </Card>
              </React.Suspense>
            );
          })}
        </Colxx>

        <div className="d-flex justify-content-flex-end w-100percent">
          <Button
            size={"sm"}
            color="secondry"
            onClick={onSubmitHandler}
            className="br-20px c_white shadow-box create-new-button mt-30px"
          >
            {!currentKey && "Create"}
            {currentKey && "Update"}
          </Button>
        </div>
      </Row>
    </div>
  );
};

export default CourseSEOUrls;
