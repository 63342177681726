const CourseContentTempletes = [
  {
    label: "Templete - 1",
    value: {
      title: `
    <style>
        /* Title Style  */
        .content-temp-title-side-text {
            margin-right: 10px;
            background-color: #dbece5;
            color: white;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            padding: 5px;
        }

        .content-temp-title-image {
            width: 25px !important;
            height: 25px !important;
        }

        .content-temp-title {
            color: #006d77;
            font-weight: 900;
            line-height: normal;
        }
    </style>
    <div class="d-flex align-items-center">
      <div class="content-temp-title-side-text">
        <!-- Here We can change image URL Path -->
        <img
          class="content-temp-title-image"
          src="https://seeklogo.com/images/N/nodejs-logo-FBE122E377-seeklogo.com.png"
        />
      </div>
      <div class="d-flex flex-direction-column">
        <!-- Here we have to change the title -->
        <div class="mb-n5px">
          <span class="content-temp-title">
            Web Development withPython and Django
          </span>
        </div>
        <div><span class="color-gray-400 fs-12px">4 Weeks</span></div>
      </div>
    </div>
    `,

      description: `
    <style>
      /* Content Style */

      .content-temp-sticky-text {
        margin: 3px;
        padding: 3px 10px;
        border-radius: 20px;
        font-size: 13px;
        background-color: #dbece5;
        color: #006d77;
      }

      .content-temp-side-dot {
        width: 15px;
        height: 15px;
      }
    </style>


    <div class="">
      <p>In this section we are going to learn these technologies.</p>
      <div
        class="d-flex justify-content-flex-start flex-wrap align-items-center"
      >
        <p class="content-temp-sticky-text">MongoDB</p>
        <p class="content-temp-sticky-text">ExpressJs</p>
        <p class="content-temp-sticky-text">ReactJs</p>
        <p class="content-temp-sticky-text">NodeJs</p>
        <p class="content-temp-sticky-text">REST API</p>
        <p class="content-temp-sticky-text">AWS Cloud</p>
        <p class="content-temp-sticky-text">Git</p>
        <p class="content-temp-sticky-text">DevOps</p>
        <p class="color-gray-500">Many More...</p>
      </div>
      <div class="d-flex mt-8px text-left align-items-center">
        Build 5+ Major Projects, and 20+ mini projects.
      </div>

      <div class="d-flex mt-5px text-left align-items-start">
        <img
          class="content-temp-side-dot mt-5px"
          src="https://cdn-icons-png.flaticon.com/512/0/14.png"
        />
        <p class="ml-10px">
          Learn with Tech Professionals from{" "}
          <strong>VIRTUSA, XEBIA, KPMG </strong> and many more.
        </p>
      </div>

      <div class="d-flex mt-2px text-left align-items-start">
        <img
          class="content-temp-side-dot mt-5px"
          src="https://cdn-icons-png.flaticon.com/512/0/14.png"
        />
        <p class="ml-10px">
          Learn with Tech Professionals from{" "}
          <strong>VIRTUSA, XEBIA, KPMG </strong> and many more.
        </p>
      </div>
    </div>
    `,
    },
    key: 1,
  },
];

export { CourseContentTempletes };
