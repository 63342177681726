import axios from "axios";
import { trackPromise } from "react-promise-tracker";
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export const isAuthenticatedAPI = async () => {
  try {
    return trackPromise(
      axios.get("/auth/is-authenticated/", {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};
