import React, { useState, useEffect } from "react";
import { Button, Card, Row } from "reactstrap";
import {
  Colxx,
  Separator,
} from "../../../../../components/common/CustomBootstrap";
import { MDBDataTable } from "mdbreact";
import { useSelector, useDispatch } from "react-redux";
import {
  getJobTagsDataAction,
  updateJobTagsDataAction,
  addNewJobTagsDataAction,
  deleteJobTagsDataAction,
} from "../../../../../redux/master/actions";
import AlertMessage from "../../../../../helpers/alerts";
import DeleteConfirmationPopUp from "../../../../../components/common/deleteConfirmationPopup";
import ViewImageByUrl from "../../../../../components/common/viewImageByUrl";

const Technologies = (props) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [values, setValues] = useState({
    name: "",
  });

  const dispatch = useDispatch();
  const fetchedData = useSelector((state) => state.master.jobTags);

  const onDeleteClick = (_id) => {
    dispatch(deleteJobTagsDataAction(_id));
    setCurrentData(null);
    setIsDeleting(false);
  };

  const [datatable, setDatatable] = React.useState({
    columns: [
      {
        label: "#",
        field: "sn",
        width: 50,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "id",
        },
        sort: "disabled",
      },

      {
        label: "Name",
        field: "name",
        width: 200,
      },

      // {
      //   label: "Course Count",
      //   field: "total_courses",
      //   width: 200,
      // },

      {
        label: "Action",
        field: "action",
        width: 100,
      },
    ],
    rows: [],
  });

  const onSubmitHandler = () => {
    if (values.name === "" || values.image === "") {
      AlertMessage("All Fields Required", "info");
      return;
    }
    if (isEditing) {
      dispatch(updateJobTagsDataAction(values, currentData.id, setValues));
      setCurrentData(null);
      setIsEditing(false);
    } else dispatch(addNewJobTagsDataAction(values, setValues));
  };

  useEffect(() => {
    let allRows = [];
    fetchedData?.map((data, index) => {
      let oneRow = {
        sn: index + 1,
        name: data.name,
        total_courses: data.total_courses,
        action: (
          <span>
            <i
              onClick={() => {
                setIsEditing(true);
                setCurrentData(data);
                setValues({
                  ...values,
                  name: data.name,
                  image: data.image,
                });
              }}
              className="simple-icon-pencil cursor-pointer c_ed7117 mr-4px"
            />
            <i
              onClick={() => {
                setIsDeleting(true);
                setCurrentData(data);
              }}
              className="simple-icon-trash cursor-pointer c_ed7117 mr-4px"
            />
          </span>
        ),
      };
      allRows.push(oneRow);
    });

    setDatatable({ ...datatable, rows: allRows });
  }, [fetchedData]);

  useEffect(() => {
    dispatch(getJobTagsDataAction());
  }, []);

  props = {
    ...props,
    isDeleting,
    setIsDeleting,
    currentData,
    setCurrentData,
    onDeleteClick,
  };

  return (
    <React.Fragment>
      {isDeleting && <DeleteConfirmationPopUp {...props} />}
      <Row>
        <Colxx xxs="12">
          <div className="table-header-container">
            <span className="table-header-title">
              Job Tags
              <p className="fs-14px c_black-60p">
                This is the list of job categories, which can get by our
                students after course.
              </p>
            </span>
          </div>
          <Separator className="mb-5" />
        </Colxx>
      </Row>
      <Row>
        <Colxx lg="12" xl="6">
          <Card className="d-flex mb-3 br-20px" key={`faqItem_1`}>
            <div className="d-flex flex-grow-1 min-width-zero">
              <div
                className="card-body accordion-content pt-0"
                style={{ maxWidth: "100%" }}
              >
                <MDBDataTable
                  fixed
                  hover
                  entriesOptions={[25, 50, 100, 500, 1000]}
                  entries={25}
                  pagesAmount={4}
                  data={datatable}
                  materialSearch
                  // searchTop
                  fullPagination
                  striped
                  theadColor="dark"
                  searching={false}
                  pagingTop
                  scrollX={true}
                  // scrollY
                  maxHeight={1000}
                  searchBottom={false}
                  // barReverse={true}
                />
              </div>
            </div>
          </Card>
        </Colxx>
        <Colxx lg="12" xl="6">
          <Card className="d-flex mb-3 br-20px" key={`faqItem_1`}>
            <div className="d-flex flex-grow-1 min-width-zero">
              <div className="card-body accordion-content pt-0">
                <Row className="mt-30px">
                  <Colxx lg="12" xl="6">
                    <label className="input-label fw-400 fs-14px">
                      Job Tag Name
                    </label>
                    <input
                      type={"text"}
                      className="form-control br-10px"
                      placeholder="ex. TechSim+"
                      value={values.name}
                      onChange={(data) =>
                        setValues({
                          ...values,
                          name: data.target.value,
                        })
                      }
                    />
                  </Colxx>

                  <div className="d-flex justify-content-flex-end w-100percent">
                    <Button
                      onClick={onSubmitHandler}
                      size={"sm"}
                      color="secondry"
                      className="br-20px c_white shadow-box create-new-button mt-30px"
                    >
                      {isEditing ? "Update" : "Create"}
                    </Button>
                  </div>
                </Row>
              </div>
            </div>
          </Card>
        </Colxx>
      </Row>
    </React.Fragment>
  );
};

export default Technologies;
